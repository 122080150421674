import React, { createContext, useState, useContext, useEffect } from 'react';
import CartService from '../services/cartService';
import { useAuth } from '../AuthContext';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItemCount, setCartItemCount] = useState(0);
  const [loadingItems, setLoadingItems] = useState(new Set());
  const { user, loading: authLoading } = useAuth();

  const updateCartCount = async () => {
    try {
      if (!user || authLoading) return;
      
      const cart = await CartService.getCurrentCart();
      setCartItemCount(cart.items?.length || 0);
    } catch (error) {
      console.error('Error updating cart count:', error);
      // Only log the error if user is actually logged in
      if (user && !authLoading) {
        console.error('Error updating cart count:', error);
      }
    }
  };

  useEffect(() => {
    // Only fetch cart when auth is ready and user is logged in
    if (!authLoading && user) {
      updateCartCount();
    } else {
      // Reset cart count when logged out
      setCartItemCount(0);
    }
  }, [user, authLoading]);

  const addToLoadingItems = (itemId) => {
    setLoadingItems(prev => new Set([...prev, itemId]));
  };

  const removeFromLoadingItems = (itemId) => {
    setLoadingItems(prev => {
      const newSet = new Set(prev);
      newSet.delete(itemId);
      return newSet;
    });
  };

  const isItemLoading = (itemId) => {
    return loadingItems.has(itemId);
  };

  return (
    <CartContext.Provider value={{ 
      cartItemCount, 
      updateCartCount,
      isItemLoading,
      addToLoadingItems,
      removeFromLoadingItems
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

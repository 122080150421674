import React from 'react';
import { Typography, Container, Paper, Grid } from '@material-ui/core';

const Events = () => {
  return (
    <Container maxWidth="lg" className="py-8">
      <Typography variant="h4" component="h1" gutterBottom>
        Your Events
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p-4">
            <Typography variant="h6" gutterBottom>
              Upcoming Events
            </Typography>
            {/* Event list will go here */}
            <Typography variant="body1" color="textSecondary">
              No upcoming events found.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper className="p-4">
            <Typography variant="h6" gutterBottom>
              Past Events
            </Typography>
            {/* Past events list will go here */}
            <Typography variant="body1" color="textSecondary">
              No past events found.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Events;

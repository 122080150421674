import { useState, useEffect, useCallback } from 'react';
import CartService from '../services/cartService';
import useAuth from './useAuth';
import { useCart } from '../context/CartContext';

export function useCartService() {
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = useAuth();
  const { user } = auth || {};
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { updateCartCount } = useCart();

  useEffect(() => {
    const getToken = async () => {
      if (user) {
        try {
          const token = await user.getIdToken();
          setToken(token);
          setIsAuthenticated(true);
        } catch (err) {
          console.error('Error getting token:', err);
          setToken(null);
          setIsAuthenticated(false);
        }
      } else {
        setToken(null);
        setIsAuthenticated(false);
      }
    };

    getToken();
  }, [user]);

  const fetchCart = useCallback(async () => {
    try {
      setLoading(true);
      const cartData = await CartService.getCurrentCart(token);
      setCart(cartData);
      setError(null);
      updateCartCount();
    } catch (err) {
      setError(err.message || 'Error fetching cart');
      console.error('Error fetching cart:', err);
    } finally {
      setLoading(false);
    }
  }, [token, updateCartCount]);

  useEffect(() => {
    if (token) {
      fetchCart();
    } else {
      setCart(null);
      setLoading(false);
    }
  }, [fetchCart, token]);

  const addItem = async (merchItemId, variantId = null, quantity = 1) => {
    try {
      await CartService.addItem(merchItemId, variantId, quantity, token);
      await fetchCart();
      updateCartCount();
    } catch (err) {
      setError(err.message || 'Error adding item to cart');
      throw err;
    }
  };

  const removeItem = async (itemId) => {
    try {
      await CartService.removeItem(itemId, token);
      await fetchCart();
      updateCartCount();
    } catch (err) {
      setError(err.message || 'Error removing item from cart');
      throw err;
    }
  };

  const updateQuantity = async (itemId, quantity) => {
    try {
      await CartService.updateQuantity(itemId, quantity, token);
      await fetchCart();
      updateCartCount();
    } catch (err) {
      setError(err.message || 'Error updating quantity');
      throw err;
    }
  };

  const clearCart = async () => {
    try {
      await CartService.clearCart(token);
      await fetchCart();
      updateCartCount();
    } catch (err) {
      setError(err.message || 'Error clearing cart');
      throw err;
    }
  };

  const createCheckoutSession = async (shippingDetails) => {
    if (!isAuthenticated) {
      throw new Error('Please log in to checkout');
    }
    try {
      return await CartService.createCheckoutSession(token, shippingDetails);
    } catch (err) {
      setError(err.message || 'Error creating checkout session');
      throw err;
    }
  };

  return {
    cart,
    loading,
    error,
    addItem,
    removeItem,
    updateQuantity,
    clearCart,
    createCheckoutSession,
    refreshCart: fetchCart,
    isAuthenticated
  };
}

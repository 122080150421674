import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import CreateAlbumView from "./album_creator/CreateAlbumView";
import AlbumDraftImageUploader from "./album_creator/views/images/AlbumDraftImageUploader";
import EditAlbumMetadataView from "./EditAlbumMetadataView";
import SetAlbumArtistView from "./album_creator/views/SetAlbumArtistView";
import useAlbumLoader from "./hooks/useAlbumLoader";
import useDraftValidationObserver from "./hooks/useDraftValidationObserver";
import NoArtistsOnboarding from "./onboarding/NoArtistsOnboarding";
import { useUserProfileContext } from "../../../context/UserProfileContext";
import LightSpinner from "../../loading/LightSpinner";
import AlbumProgressBar from "./AlbumProgressBar";
import AlbumSettingsView from "./AlbumSettingsView";

function DraftEditView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { album, isLoading, finalize, loadAlbum } = useAlbumLoader({}, location);
  const { isValid, validationState, isLoading: isValidating, validationMessage } = useDraftValidationObserver(album);
  const { linkedArtists, loading: profileLoading } = useUserProfileContext();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    loadAlbum();
  }, [loadAlbum]);

  const handleFinalize = async () => {
    if (!isValid) return;
    await finalize();
  };

  if (profileLoading) {
    return (
      <div className="flex items-center justify-center h-screen font-mono">
        <LightSpinner />
      </div>
    );
  }

  if (!linkedArtists?.length) {
    return <NoArtistsOnboarding />;
  }

  return (
    <div className="flex flex-col w-full h-screen bg-black font-theFutureRegular overflow-hidden">
      <main className="flex-grow px-4 py-8 overflow-auto w-full bg-[#f3f3f3] dark:bg-black">
        {isLoading ? (
          <div className="flex items-center justify-center p-10">
            <LightSpinner />
          </div>
        ) : (
          <div className="flex flex-col space-y-8">
            {/* ALBUM HEADER */}
            <div className="border border-black dark:border-white flex flex-col md:flex-row">
              {/* LEFT: Album cover */}
              <div className="md:w-1/3">
                <AlbumDraftImageUploader album={album} />
              </div>

              {/* RIGHT: Album info */}
              <div className="flex-1 p-4 md:py-6 md:px-8">
                {/* Settings Button */}
                <div className="flex justify-end mb-4">
                  <button
                    onClick={() => setIsSettingsOpen(true)}
                    className="p-2 text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100"
                  >
                    <Cog6ToothIcon className="h-5 w-5" />
                  </button>
                </div>

                {/* Title & Artist */}
                <div className="space-y-4 mb-8">
                  <EditAlbumMetadataView album={album} />
                  <SetAlbumArtistView album={album} />
                </div>

                {/* Validation & Progress */}
                <AlbumProgressBar
                  validationState={validationState}
                  validationMessage={validationMessage}
                />

                {/* Finalize Button */}
                <div className="mt-8 flex justify-center">
                  {isValid && !isValidating && (
                    <button
                      onClick={handleFinalize}
                      className="border border-green-500 text-green-500 px-6 py-2 hover:bg-green-500 hover:text-white transition-colors"
                    >
                      Finalize Album
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* TRACK EDITOR */}
            <div className="border border-black dark:border-white p-4">
              <CreateAlbumView album={album} />
            </div>

            {/* SETTINGS MODAL */}
            <AlbumSettingsView
              album={album}
              isOpen={isSettingsOpen}
              onClose={() => setIsSettingsOpen(false)}
            />
          </div>
        )}
      </main>
    </div>
  );
}

export default DraftEditView;
import { isTestMode } from './featureFlags';

// Stripe configuration
const STRIPE_CONFIG = {
  // Test mode keys
  test: {
    publishableKey: 'pk_test_51E1LQfJoqPTP5BBTfYq9DcHVQRXKIQqRRkJLvHLXDtQOQwcFVWYKHes4vqEVOPpKSyPQnZgXXjbgVVZXwsD5EXwj00aDlnXKAa',
    connectClientId: 'ca_HuhF4jFw4cd7qClv8261Kx6gWYgZYeUb',
  },
  // Live mode keys
  live: {
    publishableKey: 'pk_live_51E1LQfJoqPTP5BBTtXgOH8GV3QbhKyYXgHljdFwLaFCGqM1zqtV7PC8xGGrfWxQ7WGwqpHyZgXXWqWYWHhgZXwsD00aDlnXKAa',
    connectClientId: 'ca_HuhF4jFw4cd7qClv8261Kx6gWYgZYeUb',
  }
};

// Get Stripe configuration based on test mode flag
export function getStripeConfig() {
  return isTestMode() ? STRIPE_CONFIG.test : STRIPE_CONFIG.live;
}

// Export individual config values
export const getStripePublishableKey = () => getStripeConfig().publishableKey;
export const getStripeConnectClientId = () => getStripeConfig().connectClientId;

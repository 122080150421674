import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { PhotoIcon } from "@heroicons/react/24/outline";
import useImageUpload from "../../hooks/useImageUpload";
import useAlbumDraftCover from "../../hooks/useAlbumDraftCover";
import usePastUploads from "../../hooks/usePastUploads";
import firebase from "firebase/compat/app";

const isValidImageUrl = (url) => url && url.trim() !== "";

const AlbumDraftImageUploader = ({ album }) => {
  const [showGallery, setShowGallery] = useState(false);
  const { uploadImage, uploadStatus, progress, previewUrl } = useImageUpload(album);
  const { albumCoverUrl, coverLoading } = useAlbumDraftCover(album);
  const { pastUploads, loading: uploadsLoading } = usePastUploads(firebase.auth()?.currentUser?.uid);

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file) {
        try {
          await uploadImage(file);
        } catch (error) {
          console.error("Failed to upload file:", error);
        }
      }
    }
  };

  const handlePastUploadSelect = async (imageUrl) => {
    try {
      await uploadImage(imageUrl, true);
      setShowGallery(false);
    } catch (error) {
      console.error("Failed to set past upload:", error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp"] },
    multiple: false,
  });

  const formattedProgress = Math.round(progress);

  return (
    <div className="relative w-full aspect-square bg-[#f3f3f3] dark:bg-black font-mono border border-black dark:border-white">
      {/* Main Upload Area */}
      <div
        {...getRootProps()}
        className={`absolute inset-0 flex items-center justify-center cursor-pointer transition-colors ${
          isDragActive
            ? "bg-gray-200 dark:bg-gray-800"
            : "hover:bg-gray-100 dark:hover:bg-gray-900"
        }`}
        style={{ outline: "none" }}
      >
        <input {...getInputProps()} />

        {/* Current Cover Display */}
        {!coverLoading && (previewUrl || isValidImageUrl(albumCoverUrl)) ? (
          <div className="relative w-full h-full group">
            <img
              src={previewUrl || albumCoverUrl}
              alt="Album Cover"
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
              <span className="text-white text-sm">Click or drop to change</span>
            </div>
          </div>
        ) : coverLoading ? (
          <div className="flex flex-col items-center justify-center h-full w-full">
            <span className="text-gray-700 dark:text-gray-300 text-base animate-pulse">
              Loading cover...
            </span>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full w-full pointer-events-none">
            <PhotoIcon className="h-8 w-8 text-gray-600 dark:text-gray-400 mb-2" />
            <span className="text-gray-600 dark:text-gray-400 text-sm">
              {isDragActive ? "Drop to upload" : "Upload Cover"}
            </span>
          </div>
        )}
      </div>

      {/* Past Uploads Button */}
      <button
        onClick={() => setShowGallery(true)}
        className="absolute bottom-4 right-4 px-3 py-1 bg-black dark:bg-white text-white dark:text-black text-xs hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors z-10"
      >
        Past Uploads
      </button>

      {/* Past Uploads Gallery Modal */}
      {showGallery && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-900 p-6 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold dark:text-white">Past Uploads</h3>
              <button
                onClick={() => setShowGallery(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                Close
              </button>
            </div>
            
            {uploadsLoading ? (
              <div className="flex justify-center py-8">
                <span className="text-gray-500 dark:text-gray-400 animate-pulse">
                  Loading past uploads...
                </span>
              </div>
            ) : pastUploads.length === 0 ? (
              <div className="flex justify-center py-8">
                <span className="text-gray-500 dark:text-gray-400">
                  No past uploads found
                </span>
              </div>
            ) : (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {pastUploads.map((upload) => (
                  <button
                    key={upload.id}
                    onClick={() => handlePastUploadSelect(upload.url)}
                    className="relative aspect-square group border border-gray-200 dark:border-gray-700 hover:border-blue-500 dark:hover:border-blue-400 transition-colors"
                  >
                    <img
                      src={upload.url}
                      alt={upload.title}
                      className="object-cover w-full h-full"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                      <span className="text-white text-xs">Select</span>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Upload Progress */}
      {uploadStatus === "uploading" && (
        <div className="absolute left-0 right-0 bottom-0 bg-[#f3f3f3] dark:bg-black px-4 py-2">
          <div className="relative w-full h-2 bg-gray-300 dark:bg-gray-700">
            <div
              className="absolute top-0 left-0 h-full bg-gray-800 dark:bg-white transition-all duration-200"
              style={{ width: `${formattedProgress}%` }}
            />
          </div>
          <p className="text-center text-sm text-gray-700 dark:text-gray-300 mt-2">
            Uploading... {formattedProgress}%
          </p>
        </div>
      )}
    </div>
  );
};

export default AlbumDraftImageUploader;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserProfileContext } from '../../../context/UserProfileContext';
import useAuth from '../../../hooks/useAuth';
import { useMerch } from '../../../hooks/useMerch';
import APIConfig from '../../../APIConfig';
import MerchAIAssistant from './MerchAIAssistant';
import MerchEditor from '../../../components/merch/MerchEditor';
import MerchImageUpload from './MerchImageUpload';
import { merchService } from '../../../services/merch_service';

const MerchEditorView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isSeller, loading: profileLoading } = useUserProfileContext();
  const { user, loading: authLoading } = useAuth();
  const { createMerchItem, updateMerchItem, fetchMerchItem } = useMerch();
  
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    published: false,
  });
  const [options, setOptions] = useState([]);
  const [variants, setVariants] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(id ? true : false);
  const [isSaving, setIsSaving] = useState(false);
  const [showAIAssistant, setShowAIAssistant] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(null);

  // Load existing merch item if editing
  useEffect(() => {
    const loadMerchItem = async () => {
      console.log('Loading merch item with ID:', id);
      if (!id) return;
      
      try {
        const merchItem = await fetchMerchItem(id);
        console.log('Loaded merch item:', merchItem);
        setFormData({
          name: merchItem.name || '',
          description: merchItem.description || '',
          price: merchItem.price ? merchItem.price.toFixed(2) : '',
          published: merchItem.published || false,
        });
        if (merchItem.options) {
          setOptions(merchItem.options);
        }
        if (merchItem.variants) {
          setVariants(merchItem.variants);
        }
        if (merchItem.shipping_info) {
          console.log('Setting shipping info:', merchItem.shipping_info);
          setShippingInfo(merchItem.shipping_info);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error loading merch item:', err);
        setError('Failed to load merch item');
        setLoading(false);
      }
    };

    loadMerchItem();
  }, [id, fetchMerchItem]);

  // Check seller status
  useEffect(() => {
    if (!profileLoading && !isSeller) {
      navigate('/');
    }
  }, [isSeller, profileLoading, navigate]);

  const handleSave = async () => {
    try {
      // Validate required fields before saving
      const errors = [];
      if (!formData.name.trim()) errors.push('Name is required');
      if (!formData.price) errors.push('Price is required');
      
      if (errors.length > 0) {
        setError(errors.join(', '));
        return;
      }

      // Additional validation for published items
      if (formData.published) {
        if (!formData.description) errors.push('Description is required for published items');
        if (!shippingInfo?.shipping_methods?.length) errors.push('At least one shipping method is required for published items');
        
        if (errors.length > 0) {
          setError(errors.join(', '));
          return;
        }
      }

      setIsSaving(true);
      const saveData = {
        name: formData.name,
        description: formData.description,
        price: parseFloat(formData.price),
        options,
        variants,
        shipping_info: shippingInfo,
        published: formData.published
      };
      
      console.log('[MerchEditorView] Saving merch item with data:', saveData);
      console.log('[MerchEditorView] Current shipping info state:', shippingInfo);

      let response;
      if (id) {
        console.log('[MerchEditorView] Updating existing merch item:', id);
        response = await merchService.updateMerchItem(id, saveData);
      } else {
        console.log('[MerchEditorView] Creating new merch item');
        response = await merchService.createMerchItem(saveData);
      }
      
      console.log('[MerchEditorView] Successfully saved merch item:', response);
      setIsSaving(false);
      navigate('/drafts/merch');
    } catch (error) {
      console.error('[MerchEditorView] Error saving merch item:', error);
      setError(error.message || 'Failed to save merch item');
      setIsSaving(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/drafts/merch');
  };

  const handleAISuggestion = (suggestion) => {
    if (suggestion) {
      setFormData(prev => ({
        ...prev,
        name: suggestion.product_name || suggestion.name || prev.name,
        description: suggestion.description || prev.description,
        price: suggestion.price || prev.price,
      }));
      if (suggestion.options) {
        const formattedOptions = suggestion.options.map(option => ({
          name: option.name,
          values: Array.isArray(option.values) ? option.values : [option.value]
        }));
        setOptions(formattedOptions);
      }
      if (suggestion.variants) {
        setVariants(suggestion.variants.map(variant => ({
          ...variant,
          options: variant.options || []
        })));
      }
    }
  };

  const handleImageChange = useCallback((images) => {
    console.log('Images updated:', images);
  }, []);

  const handleShippingChange = useCallback((newShippingInfo) => {
    console.log('[MerchEditorView] Shipping info changed:', newShippingInfo);
    setShippingInfo(newShippingInfo);
  }, []);

  const isFormValid = () => {
    return formData.name.trim() !== '' && formData.price !== '';
  };

  if (authLoading || profileLoading || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-100">
        Loading...
      </div>
    );
  }
  
  if (!isSeller) return null;
  if (error) {
    return (
      <div className="text-red-500 dark:text-red-400 bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center">
        {error}
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-4 bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 min-h-screen">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">
            {id ? 'Edit Merch Item' : 'Create New Merch Item'}
          </h1>
          <div className="flex items-center space-x-2">
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-indigo-600"
                checked={formData.published}
                onChange={(e) => setFormData(prev => ({ ...prev, published: e.target.checked }))}
              />
              <span className="text-gray-700 dark:text-gray-300">Published</span>
            </label>
            <button
              type="button"
              onClick={handleSave}
              disabled={isSaving || !isFormValid()}
              className={`px-4 py-2 rounded-lg ${
                isSaving || !isFormValid()
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-700'
              } text-white font-medium transition-colors duration-200`}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Name
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => {
              setFormData(prev => ({ ...prev, name: e.target.value }));
              setError(null); // Clear error when user starts typing
            }}
            disabled={isSaving}
            className={`mt-1 block w-full rounded-md border ${
              error?.includes('Name') ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
            } bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
              isSaving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            placeholder="Enter item name"
            required
          />
          {error?.includes('Name') && (
            <p className="mt-1 text-sm text-red-500">Name is required</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Description</label>
          <textarea
            value={formData.description}
            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            disabled={isSaving}
            rows={4}
            className={`mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
              isSaving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Price
            <span className="text-red-500 ml-1">*</span>
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              value={formData.price}
              onChange={(e) => {
                const value = e.target.value;
                // Ensure only 2 decimal places
                const formattedValue = value.includes('.') ? 
                  value.split('.').map((part, i) => i === 1 ? part.slice(0, 2) : part).join('.') :
                  value;
                setFormData(prev => ({ ...prev, price: formattedValue }));
                setError(null);
              }}
              onKeyDown={(e) => {
                // Handle up/down arrow keys
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                  const currentValue = parseFloat(formData.price) || 0;
                  const newValue = e.key === 'ArrowUp' ? currentValue + 1 : currentValue - 1;
                  if (newValue >= 0) {
                    setFormData(prev => ({ ...prev, price: newValue.toFixed(2) }));
                  }
                }
              }}
              disabled={isSaving}
              step="0.01"
              min="0"
              className={`block w-full pl-7 pr-12 rounded-md border ${
                error?.includes('Price') ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'
              } bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
                isSaving ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              placeholder="0.00"
              required
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">USD</span>
            </div>
          </div>
          {error?.includes('Price') && (
            <p className="mt-1 text-sm text-red-500">Price is required</p>
          )}
          <p className="mt-1 text-sm text-gray-500">
            Enter the price in USD. Use decimal points for cents (e.g., 19.99).
          </p>
        </div>

        <div>
          {/* <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Variants</label> */}
          <MerchEditor
            options={options}
            variants={variants}
            shipping_info={shippingInfo}
            onOptionsChange={setOptions}
            onVariantsChange={setVariants}
            onShippingChange={handleShippingChange}
            isSaving={isSaving}
            merchId={id}
          />
        </div>

        <div className="flex space-x-4">
          <button
            type="button"
            onClick={handleCancel}
            disabled={isSaving}
            className={`flex-1 px-4 py-2 bg-gray-200 dark:bg-gray-800 text-black dark:text-gray-100 rounded hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
              isSaving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default MerchEditorView;
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BsGearWideConnected } from "react-icons/bs";
import { AuthContext } from '../../context/AuthContext';
import TerminalController from "./TerminalController";
import DarkModeToggle from "../aesthetic/DarkModeToggle";
import Terminal from "./Terminal";
// import MyTapesView from "../file_mgmt/MyTapesView";
import Dash from "./dashboard/Dash";
import Checkbox from "./Checkbox";
import MxtMenu from "./MxtMenu";

import GridContainer from "../themes/GridContainer";
import AlbumDraftsView from "./CassetteDraftingView";

const Services = (props) => {
  // const [theme, setTheme] = useState("light");

  // // The function that toggles between themes
  // const toggleTheme = () => {
  //   // if the theme is not light, then set it to dark
  //   if (theme === "light") {
  //     setTheme("dark");
  //   } else {
  //     setTheme("light");
  //   }
  // };

  const { user } = useContext(AuthContext);
  return (
    <GridContainer>
    <h1 className="padded center">
      cassette dubbing
    </h1>
    <AlbumDraftsView></AlbumDraftsView>

    </GridContainer>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import api from '../../../api';
import ArtistComponent from './ArtistComponent';
import ProfileHeader from './ProfileHeader';
import AddArtistComponent from './AddArtistComponent';

const ProfileDetailsView = ({ data }) => {
  const [editMode, setEditMode] = useState({});
  const [editedNames, setEditedNames] = useState({});
  const [artists, setArtists] = useState(data?.artists || []);

  useEffect(() => {
    if (data?.artists) {
      setArtists(data.artists);
      const initialEditMode = {};
      const initialEditedNames = {};
      data.artists.forEach((artist) => {
        initialEditMode[artist.id] = false;
        initialEditedNames[artist.id] = artist.name;
      });
      setEditMode(initialEditMode);
      setEditedNames(initialEditedNames);
    }
  }, [data]);

  const handleArtistAdded = (newArtist) => {
    setArtists(prevArtists => [...prevArtists, newArtist]);
    setEditMode(prev => ({ ...prev, [newArtist.id]: false }));
    setEditedNames(prev => ({ ...prev, [newArtist.id]: newArtist.name }));
  };

  const handleEditToggle = (artistId) => {
    setEditMode((prev) => ({ ...prev, [artistId]: !prev[artistId] }));
  };

  const handleNameChange = (artistId, newName) => {
    setEditedNames((prev) => ({ ...prev, [artistId]: newName }));
  };

  const handleUpdate = async (artistId) => {
    try {
      await api.updateArtistName(artistId, editedNames[artistId]);
      setEditMode((prev) => ({ ...prev, [artistId]: false }));
      setArtists(prevArtists => 
        prevArtists.map(artist => 
          artist.id === artistId 
            ? { ...artist, name: editedNames[artistId] }
            : artist
        )
      );
    } catch (error) {
      console.error('Error updating artist name:', error);
      alert('Error updating artist name.');
    }
  };

  const handleDeleteArtist = async (artistId) => {
    try {
      await api.deleteArtist(artistId);
      setArtists(prevArtists => prevArtists.filter(artist => artist.id !== artistId));
      setEditMode(prev => {
        const newState = { ...prev };
        delete newState[artistId];
        return newState;
      });
      setEditedNames(prev => {
        const newState = { ...prev };
        delete newState[artistId];
        return newState;
      });
    } catch (error) {
      console.error('Error deleting artist:', error);
      alert('Error deleting artist.');
    }
  };

  if (!data) return <p className="text-center text-black dark:text-white">Loading...</p>;

  return (
    <div className="p-6 border-black dark:border-white bg-gray-100 dark:bg-gray-900">
      <ProfileHeader
        username={data.username}
        bio={data.bio}
        created={data.created}
        is_followed={data.is_followed}
        is_seller={data.is_seller}
      />

      <div className="mt-6">
        <h3 className="text-2xl font-bold uppercase text-black dark:text-white">Artists</h3>
        <AddArtistComponent onArtistAdded={handleArtistAdded} />
        {artists && artists.length > 0 ? (
          <ul className="space-y-4 mt-4 border-t-2 border-black dark:border-white pt-4">
            {artists.map((artist) => (
              <ArtistComponent
                key={artist.id}
                artist={{ ...artist, name: editedNames[artist.id] ?? artist.name }}
                isEditMode={editMode[artist.id]}
                onEditToggle={handleEditToggle}
                onNameChange={handleNameChange}
                onUpdate={handleUpdate}
                onDelete={handleDeleteArtist}
              />
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No artists data available.</p>
        )}
      </div>
    </div>
  );
};

export default ProfileDetailsView;
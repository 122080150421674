// Konsole.js
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WideButton from "../buttons/WideButton";
import LoadingSkeleton from "./LoadingSkeleton";
import storage from "../../services/storage";
import useAuth from "../../hooks/useAuth";
import { useProfile } from "../account/profile/context/ProfileContext";
import APIConfig from '../../APIConfig';
import useFetchWithAuth from '../../data layer/useFetchWithAuth';
import { chatService } from '../../services/chat_service';

const Konsole = () => {
  const { user, loading: authLoading } = useAuth();
  const { 
    isSeller, 
    isArtist,
    hasLinkedArtists,
    primaryArtist,
    artistInfo,
    profileInfo,
    loading: profileLoading 
  } = useProfile();
  const navigate = useNavigate();
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tempMessage, setTempMessage] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const currentMessage = inputMessage;
    setTempMessage(currentMessage);
    setInputMessage('');

    try {
      setIsLoading(true);
      setMessages(prev => [...prev, { role: 'user', content: currentMessage }]);
      
      const data = await chatService.sendMessage(currentMessage);
      
      setMessages(prev => [...prev, { 
        role: 'assistant',
        content: data.answer,
        recommendations: data.recommendations,
        confidence: data.confidence,
        sources: data.sources
      }]);
      
      setTempMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev, { role: 'error', content: 'Error: ' + error.message }]);
      setInputMessage(currentMessage); // Restore the message if there's an error
    } finally {
      setIsLoading(false);
    }
  };

  const renderChatInterface = () => (
    <div className="font-mono border border-black dark:border-white p-4 mb-4">
      <div className="text-xl mb-4 border-b border-black dark:border-white pb-2">
        Merch Assistant
      </div>
      
      {/* Messages Container */}
      <div className="h-96 overflow-y-auto mb-4 space-y-4">
        {messages.map((msg, idx) => (
          <div 
            key={idx} 
            className={`p-2 ${
              msg.role === 'user' 
                ? 'border-l-4 border-black dark:border-white' 
                : 'border border-black dark:border-white'
            }`}
          >
            <div className="text-xs mb-1 flex justify-between">
              <span>{msg.role === 'user' ? 'You' : 'Assistant'}</span>
              {msg.confidence && (
                <span className="text-gray-500">
                  {Math.round(msg.confidence * 100)}% confident
                </span>
              )}
            </div>
            <div className="whitespace-pre-wrap">{msg.content}</div>
            {msg.sources && msg.sources.length > 0 && (
              <div className="mt-2 text-xs text-gray-500">
                Sources: {msg.sources.join(', ')}
              </div>
            )}
          </div>
        ))}
        {isLoading && (
          <div className="border border-black dark:border-white p-2">
            <div className="text-xs mb-1">Assistant</div>
            <div className="animate-pulse">...</div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Form */}
      <form onSubmit={handleSubmit} className="flex space-x-2">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (inputMessage.trim()) {
                handleSubmit(e);
              }
            }
          }}
          placeholder="Ask about your merch..."
          className="flex-1 border border-black dark:border-white p-2 bg-transparent font-mono"
          disabled={isLoading}
        />
        <button 
          type="submit"
          disabled={isLoading || !inputMessage.trim()}
          className="border border-black dark:border-white px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? '...' : 'Send'}
        </button>
      </form>
    </div>
  );

  if (authLoading || profileLoading) {
    return <LoadingSkeleton />;
  }

  if (!user) {
    return (
      <div className="p-4">
        <p className="text-gray-600 dark:text-gray-400">Please log in to use the Konsole.</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      {renderChatInterface()}
    </div>
  );
};

export default Konsole;
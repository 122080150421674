import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCartService } from '../hooks/useCartService';
import ShippingSelector from '../components/shipping/ShippingSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Cart = () => {
  const navigate = useNavigate();
  const { 
    cart, 
    loading, 
    error,
    removeItem,
    updateQuantity,
    createCheckoutSession,
    isAuthenticated 
  } = useCartService();

  const [selectedShipping, setSelectedShipping] = useState(null);
  const [loadingItems, setLoadingItems] = useState({});
  const [errorState, setError] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const addToLoadingItems = (itemId) => {
    setLoadingItems((prevLoadingItems) => ({ ...prevLoadingItems, [itemId]: true }));
  };

  const removeFromLoadingItems = (itemId) => {
    setLoadingItems((prevLoadingItems) => {
      const newLoadingItems = { ...prevLoadingItems };
      delete newLoadingItems[itemId];
      return newLoadingItems;
    });
  };

  const isItemLoading = (itemId) => loadingItems[itemId];

  const handleRemoveItem = async (itemId) => {
    try {
      addToLoadingItems(itemId);
      await removeItem(itemId);
    } catch (error) {
      console.error('Error removing item:', error.message);
    } finally {
      removeFromLoadingItems(itemId);
    }
  };

  const handleUpdateQuantity = async (itemId, quantity) => {
    try {
      addToLoadingItems(itemId);
      await updateQuantity(itemId, quantity);
    } catch (error) {
      console.error('Error updating quantity:', error.message);
    } finally {
      removeFromLoadingItems(itemId);
    }
  };

  const handleShippingChange = (shipping) => {
    setSelectedShipping(shipping);
    // The address is already included in the shipping object from ShippingSelector
  };

  const handleCheckout = async () => {
    if (!isAuthenticated) {
      navigate('/login', { state: { returnTo: '/cart' } });
      return;
    }

    if (!selectedShipping || !selectedShipping.address) {
      setError('Please select both a shipping method and address');
      return;
    }
    
    try {
      setCheckoutLoading(true);
      const shippingDetails = {
        shipping_id: selectedShipping.id,
        address_id: selectedShipping.address.id
      };
      
      const response = await createCheckoutSession(shippingDetails);
      if (response?.sessionId && response?.url) {
        window.location.href = response.url;
      } else {
        setError('Error creating checkout session: No session ID received. Please try again.');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error.message);
      setError('Error creating checkout session. Please try again.');
    } finally {
      setCheckoutLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p>Loading cart...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  if (!cart?.items?.length) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p>Your cart is empty</p>
          <button
            onClick={() => navigate('/shop')}
            className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
          >
            Continue Shopping
          </button>
        </div>
      </div>
    );
  }

  const subtotal = cart.items.reduce((total, item) => {
    return total + (item.merch_item.price * item.quantity);
  }, 0);

  const shippingCost = selectedShipping?.amount || 0;
  const total = subtotal + shippingCost; // shippingCost is already in cents

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Shopping Cart</h1>
      
      <div className="space-y-4">
        {cart.items.map((item) => (
          <div key={item.id} className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
            <div className="flex items-center space-x-4">
              {item.merch_item.images?.[0] && (
                <img
                  src={item.merch_item.images[0].url}
                  alt={item.merch_item.name}
                  className="w-20 h-20 object-cover rounded"
                />
              )}
              <div>
                <h3 className="font-semibold">{item.merch_item.name}</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  ${(item.merch_item.price / 100).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <button
                  onClick={() => handleUpdateQuantity(item.id, Math.max(1, item.quantity - 1))}
                  className="px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded-l disabled:opacity-50"
                  disabled={isItemLoading(item.id)}
                >
                  -
                </button>
                <span className="px-4 py-1 bg-gray-50 dark:bg-gray-600">
                  {isItemLoading(item.id) ? (
                    <div className="w-4 h-4 border-2 border-gray-500 border-t-transparent rounded-full animate-spin" />
                  ) : (
                    item.quantity
                  )}
                </span>
                <button
                  onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
                  className="px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded-r disabled:opacity-50"
                  disabled={isItemLoading(item.id)}
                >
                  +
                </button>
              </div>
              <button
                onClick={() => handleRemoveItem(item.id)}
                className="text-red-500 hover:text-red-700 disabled:opacity-50 flex items-center space-x-1"
                disabled={isItemLoading(item.id)}
              >
                {isItemLoading(item.id) ? (
                  <div className="w-4 h-4 border-2 border-red-500 border-t-transparent rounded-full animate-spin" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <ShippingSelector 
          cartItems={cart.items} 
          onShippingChange={handleShippingChange} 
        />
      </div>

      <div className="mt-8 p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Subtotal:</span>
            <span>${(subtotal / 100).toFixed(2)}</span>
          </div>
          {selectedShipping && (
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Shipping ({selectedShipping.name}):</span>
              <span>${(selectedShipping.amount / 100).toFixed(2)}</span>
            </div>
          )}
          <div className="flex justify-between items-center text-lg font-semibold">
            <span>Total:</span>
            <span>${(total / 100).toFixed(2)}</span>
          </div>
        </div>
        <button
          onClick={handleCheckout}
          className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors disabled:opacity-50 flex items-center justify-center"
          disabled={!cart.items.length || !selectedShipping || !selectedShipping.address || checkoutLoading}
        >
          {checkoutLoading && (
            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
          )}
          Proceed to Checkout
        </button>
        {errorState && (
          <p className="text-red-500 mt-2">{errorState}</p>
        )}
      </div>
    </div>
  );
};

export default Cart;

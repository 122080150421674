import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const { updateCartCount } = useCart();

  useEffect(() => {
    // Update cart count since the order was successful
    updateCartCount();
  }, [updateCartCount]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center px-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <div className="mb-6">
          <svg
            className="mx-auto h-12 w-12 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Order Successful!</h2>
        <p className="text-gray-600 mb-8">
          Thank you for your purchase. We'll send you an email with your order details shortly.
        </p>
        <button
          onClick={() => navigate('/orders')}
          className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200 mb-4 w-full"
        >
          View Orders
        </button>
        <button
          onClick={() => navigate('/')}
          className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default CheckoutSuccess;

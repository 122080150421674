import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import LinkedArtistsView from "../album_creator/LinkedArtistsView";
import api from "../../../../api";
import profile_service from "../../../../services/profile_service";
import { Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useUserProfileContext } from "../../../../context/UserProfileContext";

const ArtistLinkingView = () => {
  const [artistName, setArtistName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasLinkedArtist, setHasLinkedArtist] = useState(false);
  const [linkedArtistsKey, setLinkedArtistsKey] = useState(0);
  const [user] = useAuthState(getAuth());
  const navigate = useNavigate();
  const { refreshProfile } = useUserProfileContext();

  const clearError = () => {
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearError();
    
    if (!artistName.trim()) {
      setError("Please enter an artist name");
      return;
    }

    setIsLoading(true);
    try {
      const newArtist = await api.newArtist(artistName, user?.uid);
      setArtistName("");
      // Clear profile cache to force a refresh
      const token = await user.getIdToken();
      profile_service.clearProfileCache(token);
      await refreshProfile();
      
      // Show success message and force LinkedArtistsView refresh
      setShowSuccess(true);
      setHasLinkedArtist(true);
      setLinkedArtistsKey(prev => prev + 1);
      
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error adding new artist:", error);
      setError(error.message || "Failed to link artist");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="font-mono">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="artistName" className="block text-black dark:text-white">
            Artist Name
          </label>
          <input
            type="text"
            id="artistName"
            value={artistName}
            onChange={(e) => {
              setArtistName(e.target.value);
              clearError();
            }}
            className="mt-1 block w-full border border-black dark:border-white bg-transparent p-2 text-black dark:text-white focus:bg-gray-100 dark:focus:bg-gray-900"
            placeholder="Enter artist name"
            disabled={isLoading}
          />
        </div>

        {error && (
          <div className="border border-black dark:border-white p-2 text-sm">
            {error}
          </div>
        )}

        <button
          type="submit"
          disabled={isLoading || !artistName.trim()}
          className={`w-full border border-black dark:border-white p-2 
            ${!isLoading && artistName.trim() 
              ? 'hover:bg-gray-200 dark:hover:bg-gray-900 active:bg-gray-300 dark:active:bg-gray-800' 
              : 'opacity-50 cursor-not-allowed'
            }
            transition-colors duration-150`}
        >
          {isLoading ? "Linking..." : "Link Artist"}
        </button>
      </form>

      {/* Success Toast */}
      <Transition
        show={showSuccess}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed top-4 right-4 bg-black dark:bg-white text-white dark:text-black p-4">
          Artist successfully linked!
        </div>
      </Transition>

      {/* Linked Artists Section */}
      <div className="mt-8 border border-black dark:border-white p-4">
        <LinkedArtistsView key={linkedArtistsKey} />
      </div>

      {/* Return to Drafts Button */}
      {hasLinkedArtist && (
        <div className="mt-8 text-center">
          <button
            onClick={() => navigate('/drafts')}
            className="inline-flex items-center px-6 py-3 border border-black dark:border-white
              bg-black dark:bg-white text-white dark:text-black
              hover:bg-white hover:text-black dark:hover:bg-black dark:hover:text-white"
          >
            Continue to Drafts
            <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ArtistLinkingView;

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const db = firebase.firestore();
const getCurrentUser = () => firebase.auth().currentUser;
const getUID = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    return user.uid;
  } else {
    console.error("User is not authenticated. UID is not available.");
    throw new Error("User UID is not available.");
  }
};
/**
 * Adds, updates or deletes a track in an album. Uses Firebase Firestore for storage.
 * @param {Object} album - Album object containing the album's document ID.
 * @param {Object} track - Track object containing track details.
 * @param {string} operationType - The operation to perform: "add", "update" or "delete".
 */
async function manageTrack(album, track, operationType = "add") {
  try {
    const uid = await getUID(); // Fetch UID directly from Firebase Auth
    const tracksRef = db
      .collection("studio")
      .doc(uid)
      .collection("drafts")
      .doc(album.docId)
      .collection("tracks");

    if (operationType === "delete") {
      if (!track.id) {
        throw new Error("Track ID is undefined. Cannot delete track without a valid ID.");
      }
      await tracksRef.doc(track.id).delete();
      return track.id;
    }

    // If we have a compressed filename but no compressed URL, construct it
    let compressed_url = track.compressed_url;
    if (track.compressed_filename && !compressed_url && track.url) {
      // Extract the base path from the original URL
      const urlParts = track.url.split('/o/')[1].split('?')[0];
      const basePath = urlParts.split('/').slice(0, -1).join('/');
      
      // Construct the compressed URL using the same pattern
      const encodedFilename = encodeURIComponent(track.compressed_filename);
      compressed_url = `https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/${basePath}/${encodedFilename}?alt=media`;
    }

    const trackData = {
      index: track.index,
      track_name: track.track_name || track.trackName || "",
      filename: track.content || "",
      compressed_filename: track.compressed_filename || "",
      compressed_url: track.compressed_url ? track.compressed_url : "",
      url: track.url || track.losslessUrl || "",
      metadata: track.metadata || {},
      last_updated: firebase.firestore.Timestamp.now(),
    };

    if (operationType === "update") {
      // Ensure track.id exists before trying to update
      if (!track.id) {
        throw new Error(
          "Track ID is undefined. Cannot update track without a valid ID."
        );
      }

      const trackDoc = await tracksRef.doc(track.id).get();
      if (!trackDoc.exists) {
        throw new Error(`Track document ${track.id} does not exist.`);
      }
      await tracksRef.doc(track.id).update(trackData);
      return track.id; // Return existing track ID on successful update
    } else if (operationType === "add") {
      const docRef = await tracksRef.add(trackData);
      return docRef.id; // Return new track ID on successful add
    } else {
      throw new Error(`Unsupported operation type: ${operationType}`);
    }
  } catch (error) {
    console.error("Error managing track:", error);
    throw error;
  }
}

function updateAlbumIndices(album) {
  const user = getCurrentUser();
  if (!user) throw new Error("User is not signed in");

  const uid = user.uid;
  const batch = db.batch();
  var lastUpdated = firebase.firestore.Timestamp.now();
  album.songs.forEach((song, index) => {
    const songRef = db.doc(
      `studio/${uid}/drafts/${album.docId}/tracks/${song.id}`
    );
    batch.update(songRef, { index: index });
  });
  //   update the album last updated timestamp
  const albumRef = db.doc(`studio/${uid}/drafts/${album.docId}`);
  batch.update(albumRef, { last_updated: lastUpdated });
  return batch.commit();
}

async function getAlbumDraft(album, callback) {
  const user = getCurrentUser();
  if (!user) throw new Error("User is not signed in");

  const uid = user.uid;
  const tracksRef = db
    .collection("studio")
    .doc(uid)
    .collection("drafts")
    .doc(album.docId)
    .collection("tracks");

  return tracksRef.orderBy("index", "asc").onSnapshot((querySnapshot) => {
    const draftData = processAlbumDraftSnapshot(querySnapshot);
    callback(draftData); // Execute the callback function to update state in the component
  });
}

function processAlbumDraftSnapshot(querySnapshot) {
  const tasks = {};
  const columnId = "column-2";
  const taskIds = [];

  querySnapshot.forEach((doc) => {
    const trackData = doc.data();
    tasks[doc.id] = {
      id: doc.id,
      content: trackData.filename,
      compressed_filename: trackData.compressed_filename,
      url: trackData.url,
      trackName: trackData.track_name,
      index: trackData.index,
      metadata: trackData.metadata,
    };
    taskIds.push(doc.id);
  });

  return {
    tasks,
    columns: { [columnId]: { id: columnId, title: "tracks", taskIds } },
    columnOrder: [columnId],
  };
}
function getFiles(callback) {
  const user = getCurrentUser();
  if (!user) {
    console.error("User is not signed in");
    return;
  }

  const uid = user.uid;
  const filesRef = db.collection("studio").doc(uid).collection("files");

  return filesRef.onSnapshot((querySnapshot) => {
    const fileData = processFilesSnapshot(querySnapshot);
    callback(fileData);
  });
}

function processFilesSnapshot(querySnapshot) {
  const tasks = {};
  const fileIds = [];

  querySnapshot.forEach((doc) => {
    const file = doc.data();
    tasks[doc.id] = {
      id: doc.id,
      content: file.filename,
      compressed_filename: file.compressed_filename,
      url: file.url,
    };
    fileIds.push(doc.id);
  });

  return {
    tasks,
    columns: {
      "column-1": { id: "column-1", title: "/files", taskIds: fileIds },
      "column-2": { id: "column-2", title: "tracks", taskIds: [] },
    },
    columnOrder: ["column-1", "column-2"],
  };
}

async function getDrafts() {
  var albums = [];
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var drafts_ref = db
          .collection(`studio/${uid}/drafts`)
          .orderBy("last_edited", "desc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().is_published != true) {
                var _album = {
                  name: doc.data().name,
                  docId: doc.id,
                };
                albums.push(_album);
              }
            });
            resolve(albums);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
            reject(error);
          });
      }
    });
  });
}

// get tracks for a draft album
// get draft async function
async function getDraft(docId) {
  var album = { docId: docId };
  const tracks = await getRegularAlbumTracks(docId);
  console.log("tracks: ", tracks);
  const uid = localStorage.getItem("uid");
  return new Promise((resolve, reject) => {
    var drafts_ref = db
      .collection(`studio/${uid}/drafts`)
      .doc(docId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          album = {
            name: doc.data().name,
            artist_id: doc.data().artist_id,
            docId: doc.id,
            published: doc.data().published,
            last_edited: doc.data().last_edited,
            last_updated: doc.data().last_updated,
            tracks: tracks,
          };
          resolve(album);
        } else {
          console.log("No such document!");
          reject("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        reject(error);
      });
  });
}

async function getRegularAlbumTracks(docId) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");
    localStorage.setItem("uid", uid);
    ////("the docid: ", uid, "  ", album.docId);
    var album_tracks_ref = db
      .collection("studio")
      .doc(uid)
      .collection("drafts")
      .doc(docId)
      .collection("tracks");

    var tracks = [];

    album_tracks_ref.onSnapshot(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var track = {
          compressed_filename: doc.data().compressed_filename,
          filename: doc.data().filename,
          index: doc.data().index,
          last_updated: doc.data().last_updated,
          track_name: doc.data().track_name,
          url: doc.data().url,
          metadata: doc.data().metadata,
        };
        tracks.push(track);
      });

      resolve(tracks);
    });
  });
}

/**
 * Creates a new draft for an artist
 * @param {string} userUid - The user's UID
 * @param {string} artistId - The artist's ID
 * @returns {Promise<{draftId: string}>} The created draft's ID
 */
async function createDraft(userUid, artistId) {
  try {
    const draftRef = db
      .collection("studio")
      .doc(userUid)
      .collection("drafts")
      .doc();

    const draftData = {
      artist_id: artistId,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      name: "Untitled Album",
      status: "draft"
    };

    await draftRef.set(draftData);

    return { draftId: draftRef.id };
  } catch (error) {
    console.error("Error creating draft:", error);
    throw error;
  }
}

// Exporting the functions
const drafting = {
  updateAlbumIndices,
  getRegularAlbumTracks,
  manageTrack,
  getAlbumDraft,
  getFiles,
  getDrafts,
  getDraft,
  createDraft
};

export default drafting;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavLinkItem from "./NavLinkItem";
import ProfileMenu from "./ProfileMenu";
import CartButton from "./CartButton";
import { TruckIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useProfile } from '../../account/profile/context/ProfileContext';

const MenuItems = ({ user, altAuthPath, isRoot }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const onEventPage = location.pathname.startsWith("/event/");
  const { profileInfo } = useProfile();
  const isSeller = profileInfo?.is_seller;

  const handleSearchClick = () => {
    navigate('/search');
  };

  return (
    <div className="flex items-center space-x-4">
      {!onEventPage && (
        <button
          onClick={handleSearchClick}
          className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors"
        >
          <MagnifyingGlassIcon className="h-5 w-5 mr-1" />
          <span className="hidden sm:inline">Search</span>
        </button>
      )}
      {user && !isRoot && !onEventPage && <CartButton />}
      {!user && altAuthPath && !isRoot && !onEventPage && (
        <NavLinkItem to={altAuthPath.path}>{altAuthPath.text}</NavLinkItem>
      )}
      <ProfileMenu user={user} />
    </div>
  );
};

export default MenuItems;

// ArtistMusicView.jsx
import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AlbumView from "./AlbumView";
import { FEATURES } from "../../constants/features";

const ArtistMusicView = ({ albums, artistId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug: artistSlug } = useParams();
  const [expandedAlbumId, setExpandedAlbumId] = useState(null);

  if (!albums || albums.length === 0) {
    return <div className="font-mono">No albums available</div>;
  }

  const handleAlbumClick = (albumId) => {
    if (FEATURES.EXPANDABLE_ALBUMS) {
      setExpandedAlbumId(albumId === expandedAlbumId ? null : albumId);
    } else if (FEATURES.MIXTAPE_DEEP_LINKS) {
      window.location.href = `mxt://search/albums/${artistId}`;
    } else {
      navigate(`/a/${artistSlug}/albums/${albumId}`, {
        state: {
          from: location.pathname // Preserve the current path for back navigation
        }
      });
    }
  };

  if (!FEATURES.EXPANDABLE_ALBUMS) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {albums.map((album) => (
          <div 
            key={album.id} 
            className="border border-black p-2 cursor-pointer" 
            onClick={() => handleAlbumClick(album.id)}
          >
            <div className="block">
              <img
                src={album.artwork_url || album.compressed_artwork_url}
                alt={album.name}
                className="w-full aspect-square object-cover"
              />
            </div>
            <div className="mt-2 font-mono">{album.name}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {albums.map((album) => (
        <div key={album.id} className={`${album.id === expandedAlbumId ? 'col-span-full row-span-2' : ''}`}>
          <AlbumView 
            album={album} 
            artistId={artistId}
            isExpanded={album.id === expandedAlbumId}
            onToggle={() => handleAlbumClick(album.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default ArtistMusicView;
import axios from 'axios';
import APIConfig from '../APIConfig';

const API_URL = APIConfig.baseURL();

class StorefrontApi {
  static async getStorefront(username) {
    try {
      const response = await axios.get(`${API_URL}/api/v1/storefront/${username}/`, {
        withCredentials: true
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching storefront:', error);
      throw new Error(error.response?.data?.error || 'Failed to load storefront');
    }
  }
}

export default StorefrontApi;

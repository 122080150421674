import { useState, useCallback, useEffect } from 'react';

function generateTempId(prefix) {
  return `${prefix}-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
}

/**
 * Hook to manage shipping information state
 * @param {Object} initialShippingInfo - Initial shipping info with methods and rates
 * @returns {Object} Shipping info state and methods to manipulate it
 */
export function useShippingInfo(initialInfo) {
  const [shippingInfo, setShippingInfo] = useState(() => {
    return initialInfo?.shipping_methods?.length ? initialInfo : {
      shipping_methods: []
    };
  });

  // Update shipping info when initialInfo changes
  useEffect(() => {
    if (initialInfo) {
      console.log('[useShippingInfo] Received initial info:', initialInfo);
      if (JSON.stringify(initialInfo) !== JSON.stringify(shippingInfo)) {
        // Always update if we have new info
        setShippingInfo({
          ...initialInfo,
          shipping_methods: initialInfo.shipping_methods || []
        });
      }
    }
  }, [initialInfo]);

  const addMethod = useCallback((newMethod = {}) => {
    console.log('[useShippingInfo] Adding new shipping method');
    setShippingInfo(prev => ({
      ...prev,
      shipping_methods: [
        ...(prev?.shipping_methods || []),
        {
          id: generateTempId('method'),
          name: '',
          shipping_type: 'standard',
          rates: [],
          ...newMethod
        }
      ]
    }));
  }, []);

  const removeMethod = useCallback((methodId) => {
    console.log('[useShippingInfo] Removing shipping method:', methodId);
    return new Promise(resolve => {
      setShippingInfo(prev => {
        const updated = {
          ...prev,
          shipping_methods: (prev?.shipping_methods || []).filter(
            method => String(method.id) !== String(methodId)
          )
        };
        console.log('[useShippingInfo] Updated shipping info:', updated);
        // Resolve with the updated state
        resolve(updated);
        return updated;
      });
    });
  }, []);

  const updateMethod = useCallback((methodId, updates) => {
    console.log('[useShippingInfo] Updating method:', { methodId, updates });
    setShippingInfo(prev => ({
      ...prev,
      shipping_methods: (prev?.shipping_methods || []).map(method => {
        if (String(method.id) === String(methodId)) {
          // Preserve zone_id in rates when updating method
          const updatedMethod = { ...method, ...updates };
          if (updatedMethod.rates) {
            updatedMethod.rates = updatedMethod.rates.map(rate => ({
              ...rate,
              zone_id: rate.zone_id  // Ensure zone_id is preserved
            }));
          }
          return updatedMethod;
        }
        return method;
      })
    }));
  }, []);

  const addRate = useCallback((methodId, newRate = {}) => {
    console.log('[useShippingInfo] Adding rate:', { methodId, newRate });
    setShippingInfo(prev => {
      const updatedMethods = [...(prev?.shipping_methods || [])];
      const method = updatedMethods.find(m => String(m.id) === String(methodId));
      
      if (!method) {
        console.error('[useShippingInfo] Method not found:', methodId);
        return prev;
      }

      // Check if rate with same zone already exists
      const existingRate = method.rates?.find(
        rate => rate.zone_id === newRate.zone_id
      );

      if (existingRate) {
        console.log('[useShippingInfo] Rate for zone already exists:', newRate.zone_id);
        return prev;
      }

      // Add new rate with required fields
      method.rates = [
        ...(method.rates || []),
        {
          id: generateTempId('rate'),
          destination_type: newRate.destination_type || 'zone',
          zone_id: newRate.zone_id || 'us',
          single_unit: newRate.single_unit || '0.00',
          additional_unit: newRate.additional_unit || '0.00',
          ...newRate
        }
      ];

      return {
        ...prev,
        shipping_methods: updatedMethods
      };
    });
  }, []);

  const removeRate = useCallback((methodId, rateIndex) => {
    console.log('[useShippingInfo] Removing rate:', { methodId, rateIndex });
    setShippingInfo(prev => ({
      ...prev,
      shipping_methods: prev.shipping_methods.map(method => {
        if (String(method.id) === String(methodId)) {
          return {
            ...method,
            rates: method.rates.filter((_, idx) => idx !== rateIndex)
          };
        }
        return method;
      })
    }));
  }, []);

  return {
    shippingInfo,
    setShippingInfo,
    addMethod,
    removeMethod,
    updateMethod,
    addRate,
    removeRate
  };
}

import React, { useState, useEffect, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import APIConfig from '../../APIConfig';
import { useNavigate } from 'react-router-dom';
import { usePlayer } from '../../contexts/PlayerContext';
import Track from '../shared/Track';
import MixtapeListItem from './MixtapeListItem';
import MixtapeDetail from './MixtapeDetail';

function mapMixtapeTrackToPlayerTrack(mixtapeTrack, mixtape) {
  if (!mixtapeTrack?.info?.file) {
    return null;
  }
  const track = mixtapeTrack.info;
  return {
    id: track.id,
    name: track.name || 'Unknown Track',
    artist: track.artist || 'Unknown Artist',
    album: track.album?.name || mixtape.name,
    duration: track.duration,
    compressedUrl: track.file,
    losslessUrl: track.file,
    artwork_url: track.album?.artwork_url || mixtape.artwork,
    compressed_artwork_url: track.album?.compressed_artwork_url || mixtape.compressed_artwork,
  };
}

export default function MixtapesView() {
  const [user] = useAuthState(firebase.auth());
  const { currentTrack, isPlaying, play, pause } = usePlayer();
  const navigate = useNavigate();

  // States
  const [mixtapes, setMixtapes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMixtape, setSelectedMixtape] = useState(null);
  const [editingName, setEditingName] = useState(null);
  const [newName, setNewName] = useState('');
  const [showNewMixtapeForm, setShowNewMixtapeForm] = useState(false);
  const [newMixtapeName, setNewMixtapeName] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 4;
  const [loadingStates, setLoadingStates] = useState({
    publishing: new Set(),
    deleting: new Set(),
    renaming: new Set(),
    selecting: new Set(),
  });

  // Fetch current user's ID from backend
  useEffect(() => {
    const fetchCurrentUserId = async () => {
      if (!user) {
        setCurrentUserId(null);
        return;
      }
      try {
        const token = await user.getIdToken();
        const resp = await fetch(`${APIConfig.serverURI}profiles/me/`, {
          headers: { Authorization: `JWT ${token}` },
        });
        if (resp.ok) {
          const data = await resp.json();
          console.log("DEBUG: Current user data:", data);
          // Ensure we're setting the correct ID type
          setCurrentUserId(data.id);
        }
      } catch (error) {
        console.error('Error fetching current user ID:', error);
      }
    };
    fetchCurrentUserId();
  }, [user]);

  const setActionLoading = (action, id, isLoading) => {
    setLoadingStates(prev => {
      const newState = { ...prev };
      if (isLoading) {
        newState[action] = new Set(prev[action]).add(id);
      } else {
        newState[action] = new Set([...prev[action]].filter(x => x !== id));
      }
      return newState;
    });
  };

  // --- EFFECT: Fetch Mixtapes ---
  const fetchMixtapes = async (pageNum = 1, append = false) => {
    if (!user) return;
    try {
      const token = await user.getIdToken();
      const resp = await fetch(`${APIConfig.serverURI}mixtapes/get_profile_mixtapes/?page=${pageNum}&page_size=${ITEMS_PER_PAGE}`, {
        headers: { Authorization: `JWT ${token}` },
      });
      const data = await resp.json();
      console.log("DEBUG: Profile mixtapes response:", data);
      const userMixtapes = data.mixtapes || [];

      // Check if there are more mixtapes to load
      setHasMore(userMixtapes.length === ITEMS_PER_PAGE);

      if (append) {
        setMixtapes(prev => [...prev, ...userMixtapes]);
      } else {
        setMixtapes(userMixtapes);
      }
    } catch (error) {
      console.error('Error fetching mixtapes:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMixtapes(1, false);
    }
  }, [user]);

  const handleLoadMore = async () => {
    setLoadingMore(true);
    const nextPage = page + 1;
    setPage(nextPage);
    await fetchMixtapes(nextPage, true);
  };

  // --- HANDLERS ---
  const handleMixtapeClick = useCallback(async (mixtape) => {
    if (!user || loadingStates.selecting.has(mixtape.id)) return;
    
    setActionLoading('selecting', mixtape.id, true);
    try {
      const token = await user.getIdToken();
      const detailRes = await fetch(`${APIConfig.serverURI}mixtapes/${mixtape.id}/`, {
        headers: { Authorization: `JWT ${token}` },
      });
      const details = await detailRes.json();
      console.log("DEBUG: Mixtape details:", details);
      
      // Update the mixtape in the list with full details
      setMixtapes(prev => prev.map(m => 
        m.id === mixtape.id ? { ...m, ...details } : m
      ));
      
      setSelectedMixtape(details);
      navigate(`/mixtapes/${mixtape.id}`);
    } catch (error) {
      console.error('Error loading mixtape details:', error);
    } finally {
      setActionLoading('selecting', mixtape.id, false);
    }
  }, [user, navigate, loadingStates]);

  const handleTrackClick = useCallback((trackObj) => {
    if (!selectedMixtape) return;
    const playerTrack = mapMixtapeTrackToPlayerTrack(trackObj, selectedMixtape);
    if (!playerTrack) return;

    const isSameTrack = currentTrack?.id === playerTrack.id;
    if (isPlaying && isSameTrack) pause();
    else play(playerTrack);
  }, [selectedMixtape, currentTrack, isPlaying, play, pause]);

  const handlePublishToggle = useCallback(async (mixtape, e) => {
    e?.stopPropagation();
    if (!user || loadingStates.publishing.has(mixtape.id)) return;

    setActionLoading('publishing', mixtape.id, true);
    try {
      const token = await user.getIdToken();
      const resp = await fetch(`${APIConfig.serverURI}mixtapes/${mixtape.id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({ is_published: !mixtape.is_published }),
      });
      const updatedMixtape = await resp.json();
      
      // Update the mixtape in the list with the new published state
      setMixtapes(prev => prev.map(m => 
        m.id === mixtape.id ? { ...m, is_published: updatedMixtape.is_published } : m
      ));
    } catch (error) {
      console.error('Error toggling publish state:', error);
    } finally {
      setActionLoading('publishing', mixtape.id, false);
    }
  }, [user, loadingStates]);

  const handleDeleteMixtape = useCallback(async (id, e) => {
    e?.stopPropagation();
    if (!user || loadingStates.deleting.has(id)) return;

    if (!window.confirm('Are you sure you want to delete this mixtape?')) return;

    setActionLoading('deleting', id, true);
    try {
      const token = await user.getIdToken();
      await fetch(`${APIConfig.serverURI}mixtapes/${id}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      
      // Remove the mixtape from the list
      setMixtapes(prev => prev.filter(m => m.id !== id));
    } catch (error) {
      console.error('Error deleting mixtape:', error);
    } finally {
      setActionLoading('deleting', id, false);
    }
  }, [user, loadingStates]);

  const handleStartRename = useCallback((mixtape) => {
    setEditingName(mixtape?.id || null);
    setNewName(mixtape?.name || '');
  }, []);

  const handleRenameSubmit = useCallback(async (id) => {
    if (!user || loadingStates.renaming.has(id)) return;

    setActionLoading('renaming', id, true);
    try {
      const token = await user.getIdToken();
      const resp = await fetch(`${APIConfig.serverURI}mixtapes/${id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({ name: newName.trim() }),
      });
      const updatedMixtape = await resp.json();
      
      // Update the mixtape in the list with the new name
      setMixtapes(prev => prev.map(m => 
        m.id === id ? { ...m, name: updatedMixtape.name } : m
      ));
      
      // Reset rename state
      setEditingName(null);
      setNewName('');
    } catch (error) {
      console.error('Error renaming mixtape:', error);
    } finally {
      setActionLoading('renaming', id, false);
    }
  }, [user, loadingStates, newName]);

  const handleCreateMixtape = useCallback(async (e) => {
    e.preventDefault();
    if (!newMixtapeName.trim() || !user) return;
    try {
      const token = await user.getIdToken();
      const resp = await fetch(`${APIConfig.serverURI}mixtapes/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({
          name: newMixtapeName.trim(),
          is_published: false
        }),
      });
      if (resp.ok) {
        const fresh = await resp.json();
        setMixtapes((prev) => [...prev, fresh]);
        setNewMixtapeName('');
        setShowNewMixtapeForm(false);
      } else {
        console.error('Failed to create mixtape');
      }
    } catch (error) {
      console.error('Error creating mixtape:', error);
    }
  }, [newMixtapeName, user]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-mono">Your Mixtapes</h1>
        <button
          onClick={() => setShowNewMixtapeForm(true)}
          className="border border-black dark:border-white px-4 py-2 font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
        >
          Create New Mixtape
        </button>
      </div>

      {showNewMixtapeForm && (
        <form
          onSubmit={handleCreateMixtape}
          className="mb-8 p-4 border border-black dark:border-white"
        >
          <div className="flex items-center space-x-4">
            <input
              type="text"
              value={newMixtapeName}
              onChange={(e) => setNewMixtapeName(e.target.value)}
              placeholder="Enter mixtape name..."
              className="flex-1 p-2 border border-black dark:border-white bg-transparent font-mono"
              autoFocus
            />
            <button
              type="submit"
              disabled={!newMixtapeName.trim()}
              className="border border-black dark:border-white px-4 py-2 font-mono hover:bg-gray-100 dark:hover:bg-neutral-800 disabled:opacity-50"
            >
              Create
            </button>
            <button
              type="button"
              onClick={() => {
                setShowNewMixtapeForm(false);
                setNewMixtapeName('');
              }}
              className="border border-black dark:border-white px-4 py-2 font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="text-lg font-mono animate-pulse">Loading your mixtapes...</div>
        </div>
      ) : mixtapes.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-64 space-y-4">
          <div className="text-lg font-mono text-center">You haven't created any mixtapes yet</div>
          <button
            onClick={() => setShowNewMixtapeForm(true)}
            className="border border-black dark:border-white px-4 py-2 font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
          >
            Create Your First Mixtape
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          {mixtapes.map((mixtape) => (
            <MixtapeListItem
              key={mixtape.id}
              mixtape={mixtape}
              isSelected={selectedMixtape?.id === mixtape.id}
              isEditing={editingName === mixtape.id}
              newName={editingName === mixtape.id ? newName : ''}
              onClick={() => handleMixtapeClick(mixtape)}
              onRenameStart={() => handleStartRename(mixtape)}
              onRenameSubmit={(id) => handleRenameSubmit(id)}
              onPublishToggle={(m, e) => handlePublishToggle(m, e)}
              onDelete={handleDeleteMixtape}
              setNewName={setNewName}
              isPublishing={loadingStates.publishing.has(mixtape.id)}
              isDeleting={loadingStates.deleting.has(mixtape.id)}
              isRenaming={loadingStates.renaming.has(mixtape.id)}
              isLoading={loadingStates.selecting.has(mixtape.id)}
              currentUserId={currentUserId}
            />
          ))}
          
          {hasMore && (
            <div className="flex justify-center mt-4">
              <button
                onClick={handleLoadMore}
                disabled={loadingMore}
                className={`
                  border border-black dark:border-white
                  px-4 py-2
                  text-sm font-mono
                  transition-colors duration-200
                  ${loadingMore 
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black'
                  }
                `}
              >
                {loadingMore ? 'Loading more...' : 'Load More'}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';

const PlayerContext = createContext(null);

export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }
  return context;
};

export const PlayerProvider = ({ children }) => {
  const audioRef = useRef(null);
  const durationCheckTimeoutRef = useRef(null);

  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [volume, setVolume] = useState(1);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLossless, setIsLossless] = useState(false);

  const computeTrackDuration = useCallback(async (track) => {
    if (!track) return track;
    
    // If track already has a valid duration, return it
    if (track.duration && !isNaN(track.duration) && track.duration > 0) {
      return track;
    }
    
    const url = track.compressedUrl || track.losslessUrl;
    if (!url) {
      // Skip duration computation if no URL available
      return {
        ...track,
        duration: 0
      };
    }

    try {
      // Create a new Audio instance just for checking duration
      const testAudio = new Audio();
      
      const computedDuration = await new Promise((resolve, reject) => {
        const onMetadata = () => {
          const duration = Math.floor(testAudio.duration);
          if (!isNaN(duration) && duration > 0) {
            testAudio.removeEventListener('loadedmetadata', onMetadata);
            testAudio.removeEventListener('error', onError);
            resolve(duration);
          } else {
            reject(new Error('Invalid duration'));
          }
        };

        const onError = (e) => {
          testAudio.removeEventListener('loadedmetadata', onMetadata);
          testAudio.removeEventListener('error', onError);
          reject(e);
        };

        testAudio.addEventListener('loadedmetadata', onMetadata);
        testAudio.addEventListener('error', onError);
        testAudio.src = url;
        testAudio.load();
      });

      return {
        ...track,
        duration: computedDuration
      };

    } catch (error) {
      // Return track with 0 duration on error
      return {
        ...track,
        duration: 0
      };
    }
  }, []);

  // Play given track, or resume if it's the same track
  const play = useCallback(async (track, tracks = [], useLossless = false) => {
    const audio = audioRef.current;
    if (!track) return;

    const hasValidUrl = track.compressedUrl || track.losslessUrl;
    if (!hasValidUrl) {
      console.error('No valid audio URL found for track:', track);
      return;
    }

    // If we have a playlist, update it and currentIndex
    if (tracks.length > 0) {
      console.log('Setting playlist:', tracks.map(t => t.name));
      setPlaylist(tracks);
      const index = tracks.findIndex(t => t.id === track.id);
      console.log('Setting current index:', index, 'for track:', track.name);
      setCurrentIndex(Math.max(0, index));
    } else {
      // If no playlist provided, create a single-track playlist
      console.log('No playlist provided, using single track:', track.name);
      setPlaylist([track]);
      setCurrentIndex(0);
    }

    // If this is the same track currently loaded and we are just paused, resume
    if (currentTrack && currentTrack.id === track.id) {
      console.log('Resuming paused track:', track.name);
      setIsPlaying(true);
      audio?.play().catch(err => {
        console.error('Audio play error:', err);
        setIsPlaying(false);
      });
      return;
    }

    // Different track: set current track and start playing from the beginning
    console.log('Playing new track:', track.name);
    setCurrentTrack(track);
    setIsLossless(useLossless);
    setIsPlaying(true);
  }, [currentTrack]);

  const pause = useCallback(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
    }
    setIsPlaying(false);
  }, []);

  const next = useCallback(() => {
    if (playlist.length > 0) {
      console.log('Current playlist:', playlist.map(t => t.name));
      console.log('Current index:', currentIndex);
      const nextIndex = (currentIndex + 1) % playlist.length;
      console.log('Next index:', nextIndex);
      const nextTrack = playlist[nextIndex];
      console.log('Playing next track:', nextTrack.name);
      setCurrentIndex(nextIndex);
      // Pass the existing playlist to maintain continuity
      play(nextTrack, playlist, isLossless);
    }
  }, [currentIndex, playlist, isLossless, play]);

  const previous = useCallback(() => {
    const audio = audioRef.current;
    if (!audio) return;

    // If more than 3 seconds into the track, go to the beginning of the current track
    // Otherwise go to the previous track in the playlist
    if (progress > 3) {
      console.log('Restarting current track from beginning');
      audio.currentTime = 0;
      setProgress(0);
    } else if (playlist.length > 0) {
      const prevIndex = currentIndex === 0 ? playlist.length - 1 : currentIndex - 1;
      const prevTrack = playlist[prevIndex];
      console.log('Playing previous track:', prevTrack.name);
      setCurrentIndex(prevIndex);
      // Pass the existing playlist to maintain continuity
      play(prevTrack, playlist, isLossless);
    }
  }, [currentIndex, playlist, isLossless, play, progress]);

  const updateVolume = useCallback((level) => {
    const clamped = Math.max(0, Math.min(1, level));
    setVolume(clamped);
  }, []);

  const handleAudioTimeUpdate = () => {
    const audio = audioRef.current;
    if (audio) {
      setProgress(audio.currentTime);
    }
  };

  const handleAudioLoaded = () => {
    const audio = audioRef.current;
    if (audio && !isNaN(audio.duration) && audio.duration > 0) {
      const computedDuration = Math.floor(audio.duration);
      setDuration(computedDuration);
      
      // Update the track's duration if it's missing or invalid
      if (currentTrack && (!currentTrack.duration || isNaN(currentTrack.duration) || currentTrack.duration === 0)) {
        console.log('Updating track duration:', computedDuration);
        setCurrentTrack(prev => ({
          ...prev,
          duration: computedDuration
        }));
      }
    }
  };

  const handleAudioEnded = () => {
    next(); // Go to next track when current ends
  };

  // Whenever currentTrack, isPlaying, or isLossless changes, update the audio element
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    if (currentTrack) {
      const url = isLossless && currentTrack.losslessUrl ? currentTrack.losslessUrl : currentTrack.compressedUrl;
      if (url) {
        // Only set src and load if the track changed or src is empty
        if (audio.src !== url) {
          console.log('Setting new audio source:', url);
          audio.src = url;
          audio.load();
        }

        if (isPlaying) {
          console.log('Starting playback');
          audio.play().catch(err => {
            console.error('Audio play error:', err);
            setIsPlaying(false);
          });
        } else {
          console.log('Pausing playback');
          audio.pause();
        }
      } else {
        console.error('No valid URL for track:', currentTrack);
        audio.pause();
        audio.src = '';
      }
    } else {
      audio.pause();
      audio.src = '';
    }
  }, [currentTrack, isPlaying, isLossless]);

  // Update volume
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = volume;
    }
  }, [volume]);

  const value = {
    currentTrack,
    isPlaying,
    volume,
    progress,
    duration,
    playlist,
    isLossless,
    play,
    pause,
    next,
    previous,
    updateVolume,
    computeTrackDuration
  };

  return (
    <PlayerContext.Provider value={value}>
      {/* Hidden audio element */}
      <audio
        ref={audioRef}
        onTimeUpdate={handleAudioTimeUpdate}
        onLoadedMetadata={handleAudioLoaded}
        onEnded={handleAudioEnded}
      />
      {children}
    </PlayerContext.Provider>
  );
};
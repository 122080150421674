import { useState, useEffect, useRef } from 'react';
import { imageService } from '../services/image_service';
import { merchService } from '../services/merch_service';

export const useMerchImages = (itemId) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!itemId) return;

    setIsLoading(true);
    setError(null);

    (async () => {
      try {
        const merchItem = await merchService.getMerchItem(itemId);
        if (isMounted.current && merchItem.images) {
          setImages(merchItem.images.sort((a, b) => a.order - b.order));
        }
      } catch (err) {
        if (isMounted.current) {
          setError('Failed to fetch images');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    })();
  }, [itemId]); // Only runs when itemId changes

  const handleUpload = async (files) => {
    if (!files?.length || !itemId || !isMounted.current) return;

    setIsLoading(true);
    setError(null);

    try {
      const uploadedImages = await imageService.upload(itemId, files);
      if (!isMounted.current) return;
      
      // Ensure we have an array of uploaded images
      const newImages = Array.isArray(uploadedImages) ? uploadedImages : [uploadedImages];
      
      // Update state with new images
      setImages(prev => {
        const prevImages = Array.isArray(prev) ? prev : [];
        const startOrder = prevImages.length;
        
        // Add new images at the end with sequential order
        const updatedImages = [
          ...prevImages,
          ...newImages.map((img, idx) => ({
            ...img,
            order: startOrder + idx
          }))
        ];
        
        return updatedImages;
      });
      
      return newImages; // Return the new images for the component to use
    } catch (err) {
      if (isMounted.current) {
        const errorMessage = err.response?.data?.error || err.message || 'Failed to upload images';
        setError(errorMessage);
        throw new Error(errorMessage);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const handleDelete = async (imageId) => {
    if (!itemId || !isMounted.current) return;

    setError(null);

    try {
      await imageService.delete(itemId, imageId);
      if (!isMounted.current) return;
      setImages(prevImages => {
        const newImages = prevImages.filter(img => img.id !== imageId);
        return newImages.map((img, idx) => ({
          ...img,
          order: idx
        }));
      });
    } catch (err) {
      if (isMounted.current) {
        setError(err.response?.data?.error || 'Failed to delete image');
      }
      throw err;
    }
  };

  const handleReorder = async (imageOrders) => {
    if (!itemId || !isMounted.current) return;

    try {
      const response = await imageService.reorder(itemId, imageOrders);
      if (!isMounted.current) return;

      setImages(response);
      return response;
    } catch (err) {
      console.error('Failed to reorder images:', err);
      throw err;
    }
  };

  const refreshImages = async () => {
    if (!itemId || !isMounted.current) return;

    setIsLoading(true);
    setError(null);

    try {
      const merchItem = await merchService.getMerchItem(itemId);
      if (isMounted.current && merchItem.images) {
        setImages(merchItem.images.sort((a, b) => a.order - b.order));
      }
    } catch (err) {
      if (isMounted.current) {
        setError('Failed to fetch images');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  return {
    images,
    isLoading,
    error,
    handleUpload,
    handleDelete,
    handleReorder,
    refreshImages
  };
};
import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import NavBar from "./components/navigation/NavBar";
import Main from "./Main";
import ArtistPage from "./components/artist_profiles/ArtistPage";
import AlbumPage from './components/albums/AlbumPage';
import Cart from './pages/Cart';
import CheckoutSuccess from './pages/checkout/Success';
import CheckoutCancel from './pages/checkout/Cancel';
import Orders from './pages/Orders';
import SellerOrders from './pages/SellerOrders';
import { AddressBook } from './pages/AddressBook';
import { MultiTrackProvider } from "./components/file_mgmt/multi_uploader/MultiTrackContext";
import { FileContextProvider } from "./components/file_mgmt/drive/contexts/fileContext";
import UploadContextProvider from "./components/file_mgmt/drive/contexts/UploadContextProvider";
import PathContextProvider from "./components/file_mgmt/drive/contexts/PathContextProvider";
import { ModalProvider } from "./components/file_mgmt/drive/contexts/ModalContext";
import { AuthProvider, useAuth } from "./AuthContext";
import { ProfileProvider, useProfile } from "./components/account/profile/context/ProfileContext";
import { PaymentProvider } from "./context/PaymentContext";
import { UserProfileProvider, useUserProfileContext } from "./context/UserProfileContext";
import { CartProvider } from './context/CartContext';
import { PlayerProvider } from './contexts/PlayerContext';
import Events from './pages/Events';
import EventCreator from './components/events/EventCreator';
import PlayerContainer from "./containers/PlayerContainer";
import ProductPage from './components/artist_profiles/merch_item/ProductPage';
import DraftsView from "./components/drafts/DraftsView";
import Settings from "./components/account/Settings";
import ArtistOnboardingView from "./components/artist_dashboard/dashboard/onboarding/ArtistOnboardingView";
import MixtapesView from './components/mixtapes/MixtapesView';
import UserProfileView from './components/user/UserProfileView';
import SearchPage from './components/search/SearchPage';
import FBSigninView from './auth/FBSigninView';

const store = configureStore();

export default function App() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const backgroundClass = 'bg-[#f3f3f3] dark:bg-black';

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen font-sans text-black dark:text-white ${backgroundClass} ring-slate-900/5 shadow-xl`}>
      <AuthProvider>
        <Provider store={store}>
          <ProfileProvider>
            <UserProfileProvider>
              <PaymentProvider>
                <ModalProvider>
                  <PathContextProvider>
                    <FileContextProvider>
                      <UploadContextProvider>
                        <CartProvider>
                          <PlayerProvider>
                            <MultiTrackProvider>
                              <BrowserRouter>
                                <NavBar />
                                <ToastContainer />
                                <Routes>
                                  <Route path="/search" element={<SearchPage />} />
                                  <Route path="/signin" element={<FBSigninView />} />
                                  <Route path="/a/:slug" element={<ArtistPage />} />
                                  <Route path="/" element={<Main token={user ? user.uid : null} />} />
                                  <Route path="/artist/:id" element={<ArtistPage />} />
                                  <Route path="/a/:artistSlug/albums/:albumId" element={<AlbumPage />} />
                                  <Route path="/cart" element={<Cart />} />
                                  <Route path="/checkout/success" element={<CheckoutSuccess />} />
                                  <Route path="/checkout/cancel" element={<CheckoutCancel />} />
                                  {/* Orders Routes */}
                                  <Route path="/orders" element={<Orders />} />
                                  <Route path="/seller/orders" element={<SellerOrders />} />
                                  <Route path="/events" element={<Events />} />
                                  <Route path="/events/create" element={<EventCreator />} />
                                  <Route path="/drafts" element={<DraftsView />} />
                                  <Route path="/address-book" element={<AddressBook />} />
                                  <Route path="/merch/:productId" element={<ProductPage />} />
                                  <Route path="/settings" element={<Settings />} />
                                  <Route path="/onboard/artists" element={<ArtistOnboardingView />} />
                                  <Route path="/mixtapes" element={
                                    user ? <MixtapesView /> : <Navigate to="/signin" replace />
                                  } />
                                  <Route path="/u/:username" element={<UserProfileView />} />
                                  <Route path="/*" element={<Main token={user ? user.uid : null} />} />
                                </Routes>
                                <PlayerContainer />
                              </BrowserRouter>
                            </MultiTrackProvider>
                          </PlayerProvider>
                        </CartProvider>
                      </UploadContextProvider>
                    </FileContextProvider>
                  </PathContextProvider>
                </ModalProvider>
              </PaymentProvider>
            </UserProfileProvider>
          </ProfileProvider>
        </Provider>
      </AuthProvider>
    </div>
  );
}
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import StorefrontApi from '../../api/storefrontApi';
import VariantSelector from './VariantSelector';
import { toast } from 'react-toastify';
import { useCartService } from '../../hooks/useCartService';
import { useCart } from '../../context/CartContext';
import LoadingOverlay from '../loading/LoadingOverlay';

const StorefrontView = () => {
  const { username } = useParams();
  const { addItem } = useCartService();
  const { isItemLoading, addToLoadingItems, removeFromLoadingItems } = useCart();
  const [merchItems, setMerchItems] = useState([]);  
  const [seller, setSeller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState({});

  useEffect(() => {
    const fetchStorefront = async () => {
      try {
        const data = await StorefrontApi.getStorefront(username);
        setMerchItems(data.merchItems || []);
        setSeller(data.seller || null);
      } catch (err) {
        setError(err.message || 'Failed to load storefront');
        setMerchItems([]);  
      } finally {
        setLoading(false);
      }
    };
    fetchStorefront();
  }, [username]);

  const isValidVariantSelected = useCallback((item) => {
    // If item has no options/variants, it's always valid
    if (!item.options?.length) {
      return true;
    }
    const itemVariant = selectedVariants[item.id];
    return itemVariant?.variant && itemVariant.variant.stock > 0;
  }, [selectedVariants]);

  const handleVariantSelect = useCallback((itemId, variant, selectedOptions) => {
    setSelectedVariants(prev => {
      const current = prev[itemId];
      if (current?.variant === variant && current?.selectedOptions === selectedOptions) {
        return prev;
      }
      return { ...prev, [itemId]: { variant, selectedOptions } };
    });
  }, []);

  const handleAddToCart = useCallback(async (item) => {
    // If item has no options, we can add it directly
    if (!item.options?.length) {
      try {
        addToLoadingItems(item.id);
        await addItem(item.id);
        toast.success('Added to cart!');
      } catch (err) {
        toast.error(err.message || 'Failed to add item to cart');
      } finally {
        removeFromLoadingItems(item.id);
      }
      return;
    }

    // Otherwise check for valid variant selection
    const itemVariant = selectedVariants[item.id];
    if (!isValidVariantSelected(item)) {
      toast.error('Please select a valid option combination that is in stock');
      return;
    }

    try {
      addToLoadingItems(item.id);
      await addItem(item.id, itemVariant.variant.id);
      toast.success('Added to cart!');
    } catch (err) {
      toast.error(err.message || 'Failed to add item to cart');
    } finally {
      removeFromLoadingItems(item.id);
    }
  }, [addItem, selectedVariants, isValidVariantSelected, addToLoadingItems, removeFromLoadingItems]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-600">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      {seller && (
        <div className="mb-8 flex items-center gap-4">
          {seller.avatar_url && (
            <img 
              src={seller.avatar_url} 
              alt={seller.name} 
              className="w-16 h-16 rounded-full object-cover"
            />
          )}
          <div>
            <h1 className="text-3xl font-bold">{seller.name}</h1>
            {seller.description && (
              <p className="mt-2 text-gray-600 dark:text-gray-400">{seller.description}</p>
            )}
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {merchItems.map((item) => {
          const isLoading = isItemLoading(item.id);
          const hasValidVariant = isValidVariantSelected(item);
          
          return (
            <div key={item.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden relative">
              {isLoading && <LoadingOverlay message="Adding to cart..." />}
              {item.primary_image_url && (
                <img 
                  src={item.primary_image_url} 
                  alt={item.name} 
                  className="w-full h-64 object-cover"
                />
              )}
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">{item.name}</h2>
                <p className="text-gray-600 dark:text-gray-400 mb-4">{item.description}</p>
                <p className="text-lg font-bold mb-4">${(item.price / 100).toFixed(2)}</p>
                
                {item.options && item.options.length > 0 && (
                  <VariantSelector
                    key={`${item.id}-variant-selector`}
                    options={item.options}
                    variants={item.variants}
                    onVariantSelect={(variant, selectedOptions) => handleVariantSelect(item.id, variant, selectedOptions)}
                  />
                )}

                <div className="flex justify-between items-center mt-4">
                  <span className="text-lg font-bold">
                    ${(item.price / 100).toFixed(2)}
                  </span>
                  <button
                    onClick={() => handleAddToCart(item)}
                    disabled={isLoading || (item.options?.length > 0 && !hasValidVariant)}
                    className={`px-4 py-2 rounded flex items-center justify-center space-x-2 min-w-[120px] ${
                      (!item.options?.length || hasValidVariant) && !isLoading
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-gray-400 text-white cursor-not-allowed'
                    }`}
                  >
                    {isLoading ? (
                      <>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>Adding...</span>
                      </>
                    ) : item.options?.length > 0 && !selectedVariants[item.id]?.variant ? (
                      'Select Options'
                    ) : !hasValidVariant && item.options?.length > 0 ? (
                      'Out of Stock'
                    ) : (
                      'Add to Cart'
                    )}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {merchItems.length === 0 && !loading && (
        <div className="text-center py-12">
          <h2 className="text-xl font-semibold mb-2">No items available</h2>
          <p className="text-gray-600 dark:text-gray-400">This shop doesn't have any items yet.</p>
        </div>
      )}
    </div>
  );
};

export default StorefrontView;

import React, { useEffect, useRef, useCallback } from 'react';
import { useMerchOptions, useMerchVariants } from './hooks';
import { ShippingEditor } from './ShippingEditor';
import { isEqual } from 'lodash';
import APIConfig from '../../APIConfig';
import { Button } from '@material-ui/core';
import { TruckIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import MerchImageUpload from '../artist_dashboard/merch/MerchImageUpload'; // Fix import path for MerchImageUpload

export default function MerchEditor({
  options: initialOptions = [],
  variants: initialVariants = [],
  shipping_info: initialShippingInfo = null,
  onOptionsChange = () => { },
  onVariantsChange = () => { },
  onShippingChange = () => { },
  isSaving = false,
  merchId
}) {
  const navigate = useNavigate();
  const {
    options,
    optionErrors,
    localValues,
    addOption,
    removeOption,
    updateOptionName,
    updateOptionValuesFromString,
    setLocalValue,
    validateOption,
    resetOptions
  } = useMerchOptions(initialOptions || []);

  const {
    variants,
    variantErrors,
    addVariant,
    removeVariant,
    updateVariant,
    validateVariant,
    resetVariants
  } = useMerchVariants(initialVariants || [], options, onVariantsChange);

  const prevInitialOptionsRef = useRef(initialOptions);
  const prevInitialVariantsRef = useRef(initialVariants);
  const prevInitialShippingInfoRef = useRef(initialShippingInfo);

  useEffect(() => {
    if (!isEqual(initialOptions, prevInitialOptionsRef.current)) {
      resetOptions(initialOptions || []);
      prevInitialOptionsRef.current = initialOptions;
    }
  }, [initialOptions, resetOptions]);

  useEffect(() => {
    if (!isEqual(initialVariants, prevInitialVariantsRef.current)) {
      resetVariants(initialVariants || []);
      prevInitialVariantsRef.current = initialVariants;
    }
  }, [initialVariants, resetVariants]);

  useEffect(() => {
    if (!isEqual(initialShippingInfo, prevInitialShippingInfoRef.current)) {
      console.log('Initial shipping info changed:', initialShippingInfo);
      prevInitialShippingInfoRef.current = initialShippingInfo;
    }
  }, [initialShippingInfo]);

  useEffect(() => {
    if (options.every((option, index) => validateOption(index))) {
      onOptionsChange(options);
    }
  }, [options, onOptionsChange, validateOption]);

  useEffect(() => {
    onVariantsChange(variants);
  }, [variants, onVariantsChange]);

  const handleShippingChange = useCallback(async (shippingInfo) => {
    console.log('Shipping info changed:', shippingInfo);
    onShippingChange(shippingInfo);
  }, [onShippingChange]);

  const handleImageChange = useCallback((images) => {
    console.log('Images changed:', images);
    // Add logic to handle image change
  }, []);

  function renderOptionFields(option, index) {
    if (!option) return null;
    const err = optionErrors[index] || { name: '', values: '' };
    const currentValues = option.values.map(v => v.name || '').join(', ');

    return (
      <div key={option.uuid} className="flex flex-col gap-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="flex gap-4">
          <div className="flex-1">
            <input
              type="text"
              value={option.name || ''}
              onChange={(e) => updateOptionName(index, e.target.value)}
              onBlur={() => validateOption(index)}
              disabled={isSaving}
              className={`w-full bg-white dark:bg-gray-800 border ${err.name ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
                } rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              placeholder="Option name (e.g., Size, Color)"
            />
            {err.name && <p className="mt-1 text-sm text-red-500">{err.name}</p>}
          </div>
          <div className="flex-1">
            <input
              type="text"
              value={localValues[index] || currentValues}
              onChange={(e) => setLocalValue(index, e.target.value)}
              onBlur={() => {
                updateOptionValuesFromString(index, localValues[index]);
                validateOption(index);
              }}
              disabled={isSaving}
              className={`w-full bg-white dark:bg-gray-800 border ${err.values ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
                } rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              placeholder="Values (comma-separated)"
            />
            {err.values && <p className="mt-1 text-sm text-red-500">{err.values}</p>}
          </div>
          <button
            type="button"
            onClick={() => removeOption(index)}
            disabled={isSaving}
            className={`p-2 transition-colors duration-200 ${isSaving
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-500 hover:text-red-500'
              }`}
            aria-label="Remove option"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    );
  }

  const renderVariantFields = () => {
    return variants.map((variant, variantIndex) => {
      const errors = variantErrors[variantIndex] || {};
      return (
        <div key={variant.uuid} className="flex items-center gap-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
          {options.map((option) => {
            const error = errors.options?.[option.uuid];
            const selectedValue = variant.option_values?.[option.uuid] || '';
            return (
              <div key={option.uuid} className="variant-field flex-1">
                <select
                  value={selectedValue}
                  onChange={(e) => {
                    const updatedVariant = {
                      ...variant,
                      option_values: {
                        ...(variant.option_values || {}),
                        [option.uuid]: e.target.value || null
                      }
                    };
                    updateVariant(variantIndex, updatedVariant);
                  }}
                  disabled={isSaving}
                  className={`w-full bg-white dark:bg-gray-800 border ${error ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
                    } rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                  <option value="">Select {option.name || ''}</option>
                  {option.values.map((value) => (
                    <option key={value.uuid} value={value.uuid}>
                      {value.name || ''}
                    </option>
                  ))}
                </select>
                {error && <div className="text-sm text-red-500">{error}</div>}
              </div>
            );
          })}
          <div className="variant-field flex-1">
            <input
              type="number"
              value={variant.stock || 0}
              onChange={(e) => {
                const updatedVariant = { ...variant };
                updatedVariant.stock = parseInt(e.target.value, 10) || 0;
                updateVariant(variantIndex, updatedVariant);
              }}
              disabled={isSaving}
              className={`w-full bg-white dark:bg-gray-800 border ${errors.stock ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
                } rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''
                }`}
            />
            {errors.stock && <div className="text-sm text-red-500">{errors.stock}</div>}
          </div>
          <button
            type="button"
            onClick={() => removeVariant(variantIndex)}
            disabled={isSaving}
            className={`p-2 transition-colors duration-200 ${isSaving
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-500 hover:text-red-500'
              }`}
            aria-label="Remove variant"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      );
    });
  };

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Product Details</h2>

      </div>
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium">Images</h3>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Images
          </label>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Upload images of your merch. The first image will be the primary image.
          </p>
          <MerchImageUpload
            itemId={merchId}
            disabled={isSaving}
            onChange={handleImageChange}
          />
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium">Options</h3>
          <button
            type="button"
            onClick={addOption}
            disabled={isSaving}
            className={`px-4 py-2 bg-blue-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${isSaving
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-blue-600'
              }`}
          >
            Add Option
          </button>
        </div>
        {options.map((option, index) => renderOptionFields(option, index))}
      </div>

      {options.length > 0 && (
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Variants ({variants.length})</h3>
            <button
              type="button"
              onClick={addVariant}
              disabled={isSaving}
              className={`px-4 py-2 bg-blue-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${isSaving
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-blue-600'
                }`}
            >
              Add Variant
            </button>
          </div>
          <div className="space-y-4">
            {renderVariantFields()}
          </div>
        </div>
      )}


      <ShippingEditor
        initialShippingInfo={{
          ...initialShippingInfo,
          merch_item: merchId
        }}
        onChange={handleShippingChange}
        isSaving={isSaving}
      />

    </div>
  );
}
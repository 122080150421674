import React, { useState, useEffect } from "react";
import { authMethods } from "../firebase/authmethods";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import profile_service from "../services/profile_service";
import { AuthContext } from "../context/AuthContext";

const AuthProvider = (props) => {
  const initState = { email: "", password: "" };
  const [inputs, setInputs] = useState(initState);
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('AuthProvider: Setting up auth state listener');
    // Set up auth state listener
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      console.log('AuthProvider: Auth state changed', { 
        hasUser: !!user, 
        userId: user?.uid,
        currentToken: token
      });

      if (user) {
        setUser(user);
        try {
          // Always get a fresh token
          const newToken = await user.getIdToken(true);
          console.log('AuthProvider: Got fresh token');
          localStorage.setItem("token", newToken);
          setToken(newToken);
        } catch (error) {
          console.error('AuthProvider: Error getting token:', error);
        }
      } else {
        console.log('AuthProvider: No user, clearing state');
        setUser(null);
        setToken(null);
        localStorage.removeItem("token");
      }
      setLoading(false);
    });

    return () => {
      console.log('AuthProvider: Cleaning up auth listener');
      unsubscribe();
    };
  }, []);

  const handleSignup = async (props) => {
    // middle man between firebase and signup
    //("handleSignup");
    // calling signup from firebase server
    authMethods.signup(
      inputs.email,
      inputs.password,
      setErrors,
      setToken,
      props
    );

    //(errors, token);
    if (!errors) {
      return "success";
    } else {
      setErrors([]);
      return "error";
    }

    // if (errors) {
    //   //(errors);
    //   return errors;
    // }
  };
  const handleSignin = () => {
    //changed to handleSingin
    //("handleSignin!!!!");
    // made signup signin
    authMethods.signin(inputs.email, inputs.password, setErrors, setToken);
    // authListener();
    //(errors, token);
    if (!errors) {
      return "success";
    } else {
      setErrors([]);
      return "error";
    }
  };

  const handleSignout = () => {
    authMethods.signout(setErrors, setToken);
    document.location.href = "/";
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        loading,
        errors,
        handleSignup,
        handleSignin,
        handleSignout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

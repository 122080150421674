import React from 'react';
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core';
import { AddressManager } from '../components/shipping/AddressManager';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`address-tabpanel-${index}`}
      aria-labelledby={`address-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export const AddressBook = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Address Book
        </Typography>
        <Paper>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Shipping Addresses" />
            <Tab label="Billing Addresses" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <AddressManager type="shipping" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AddressManager type="billing" />
          </TabPanel>
        </Paper>
      </Box>
    </Container>
  );
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shippingService } from '../../../services/shippingService';
import { ShippingHeader } from './components';

export function ShippingMethodEditor() {
  const navigate = useNavigate();
  const [method, setMethod] = useState({
    name: '',
    shipping_type: 'standard',
    rates: []
  });
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showAddMethodModal, setShowAddMethodModal] = useState(false);
  const [showExistingMethodsDialog, setShowExistingMethodsDialog] = useState(false);

  const handleAddRate = () => {
    setMethod(prev => ({
      ...prev,
      rates: [
        ...prev.rates,
        {
          destination_country: 'US',
          single_unit: '0.00',
          additional_unit: '0.00'
        }
      ]
    }));
  };

  const handleUpdateRate = (index, field, value) => {
    setMethod(prev => ({
      ...prev,
      rates: prev.rates.map((rate, i) => 
        i === index ? { ...rate, [field]: value } : rate
      )
    }));
  };

  const handleRemoveRate = (index) => {
    setMethod(prev => ({
      ...prev,
      rates: prev.rates.filter((_, i) => i !== index)
    }));
  };

  const handleUseDefaults = async () => {
    try {
      setIsSaving(true);
      const defaultConfig = await shippingService.getDefaultConfiguration();
      setMethod(defaultConfig);
    } catch (error) {
      console.error('Error loading default configuration:', error);
      setError('Failed to load default configuration');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError(null);
      
      // Validate
      if (!method.name.trim()) {
        setError('Method name is required');
        return;
      }
      if (method.rates.length === 0) {
        setError('At least one shipping rate is required');
        return;
      }

      // Create the method
      await shippingService.createShippingMethod(method);
      navigate('/shipping-manager');
    } catch (error) {
      console.error('Error saving shipping method:', error);
      setError(error.response?.data?.detail || 'Failed to save shipping method');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ShippingHeader
        isSaving={isSaving}
        onAddMethod={() => setShowAddMethodModal(true)}
        onUseExisting={() => setShowExistingMethodsDialog(true)}
        onUseDefaults={handleUseDefaults}
        onSave={handleSave}
      />

      <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg border border-gray-200 dark:border-gray-800">
        <div className="p-8">
          {/* Content */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Method Name
              </label>
              <input
                type="text"
                value={method.name}
                onChange={e => setMethod(prev => ({ ...prev, name: e.target.value }))}
                placeholder="e.g., Standard US Shipping"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Shipping Type
              </label>
              <select
                value={method.shipping_type}
                onChange={e => setMethod(prev => ({ ...prev, shipping_type: e.target.value }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="standard">Standard</option>
                <option value="express">Express</option>
                <option value="international">International</option>
                <option value="free">Free Shipping</option>
              </select>
            </div>

            <div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                  Shipping Rates
                </h3>
                <button
                  onClick={handleAddRate}
                  className="px-3 py-1 text-sm bg-green-600 text-white rounded hover:bg-green-700"
                >
                  Add Rate
                </button>
              </div>

              <div className="space-y-4">
                {method.rates.map((rate, index) => (
                  <div
                    key={index}
                    className="flex gap-4 items-start p-4 bg-gray-50 dark:bg-gray-700 rounded-md"
                  >
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Destination
                      </label>
                      <select
                        value={rate.destination_country}
                        onChange={e => handleUpdateRate(index, 'destination_country', e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      >
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                        <option value="rest_of_world">Rest of World</option>
                      </select>
                    </div>

                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        First Unit ($)
                      </label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        value={rate.single_unit}
                        onChange={e => handleUpdateRate(index, 'single_unit', e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Additional Units ($)
                      </label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        value={rate.additional_unit}
                        onChange={e => handleUpdateRate(index, 'additional_unit', e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      />
                    </div>

                    <button
                      onClick={() => handleRemoveRate(index)}
                      className="mt-6 text-red-600 hover:text-red-800"
                      title="Remove rate"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                ))}

                {method.rates.length === 0 && (
                  <div className="text-center py-4 text-gray-500 dark:text-gray-400">
                    No rates added yet. Click "Add Rate" to add your first shipping rate.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Save Status */}
        {error && (
          <div className="mb-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LoadingSpinner = ({ className }) => (
  <div 
    className={classNames(
      'border-2 border-white border-t-transparent rounded-full animate-spin',
      className
    )}
  />
);

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  className: 'w-5 h-5',
};

export default LoadingSpinner;

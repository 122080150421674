import React, { useState, useCallback } from 'react';
import { eventService } from '../../services/eventService';
import ShowForm from './ShowForm';
import PartyForm from './PartyForm';

export default function EventCreator() {
  const [eventType, setEventType] = useState('show');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({ show: false, message: '', type: 'success' });

  const handleCreateShow = useCallback(async (showData) => {
    try {
      setLoading(true);
      await eventService.createShow(showData);
      setNotification({
        show: true,
        message: 'Show created successfully!',
        type: 'success'
      });
    } catch (error) {
      console.error('Error creating show:', error);
      setError('Failed to create show. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateParty = useCallback(async (partyData) => {
    try {
      setLoading(true);
      await eventService.createParty(partyData);
      setNotification({
        show: true,
        message: 'Party created successfully!',
        type: 'success'
      });
    } catch (error) {
      console.error('Error creating party:', error);
      setError('Failed to create party. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div className="space-y-6 p-6 bg-white dark:bg-gray-900 rounded-md shadow min-h-screen transition-colors">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Create Event</h1>
        <div className="flex gap-4">
          <button
            onClick={() => setEventType('show')}
            className={`px-4 py-2 rounded-md ${
              eventType === 'show'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Show
          </button>
          <button
            onClick={() => setEventType('party')}
            className={`px-4 py-2 rounded-md ${
              eventType === 'party'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Party
          </button>
        </div>
      </div>

      {eventType === 'show' ? (
        <ShowForm onSubmit={handleCreateShow} loading={loading} />
      ) : (
        <PartyForm onSubmit={handleCreateParty} loading={loading} />
      )}

      {notification.show && (
        <div
          className={`fixed bottom-4 right-4 px-4 py-3 rounded-md shadow-lg ${
            notification.type === 'success'
              ? 'bg-green-100 border border-green-400 text-green-700'
              : 'bg-red-100 border border-red-400 text-red-700'
          }`}
        >
          <span className="block">{notification.message}</span>
        </div>
      )}

      {error && (
        <div className="fixed bottom-4 left-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md shadow-lg" role="alert">
          <span className="block">{error}</span>
          <button
            className="absolute top-0 right-0 px-4 py-3"
            onClick={() => setError(null)}
          >
            <span className="sr-only">Close</span>
            <svg className="h-4 w-4 fill-current" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shippingService } from '../../../services/shippingService';

export function ExistingMethodsDialog({ 
  isOpen, 
  onClose, 
  onApplyMethod,
  onDeleteMethod,
  methods: initialMethods
}) {
  const [methods, setMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [applyingId, setApplyingId] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [removingId, setRemovingId] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [methodToDelete, setMethodToDelete] = useState(null);
  const [expandedMethodId, setExpandedMethodId] = useState(null);
  const [saveStatus, setSaveStatus] = useState({
    show: false,
    success: false,
    message: ''
  });
  const [clickedMethodId, setClickedMethodId] = useState(null);

  // Fetch methods whenever dialog opens
  useEffect(() => {
    if (isOpen) {
      const fetchMethods = async () => {
        setIsLoading(true);
        try {
          const response = await shippingService.getAllShippingMethods();
          setMethods(response.methods || []);
        } catch (error) {
          console.error('Error fetching shipping methods:', error);
          setSaveStatus({
            show: true,
            success: false,
            message: 'Failed to load shipping methods'
          });
        } finally {
          setIsLoading(false);
        }
      };
      fetchMethods();
    }
  }, [isOpen]);

  const handleApply = async (method) => {
    try {
      setApplyingId(method.id);
      await onApplyMethod(method.id);
      setSaveStatus({
        show: true,
        success: true,
        message: 'Shipping method applied successfully'
      });
    } catch (error) {
      console.error('Error applying shipping method:', error);
      setSaveStatus({
        show: true,
        success: false,
        message: error.response?.data?.detail || 'Failed to apply shipping method'
      });
    } finally {
      setApplyingId(null);
    }
  };

  const handleDelete = async (method) => {
    setMethodToDelete(method);
    setShowConfirmDelete(true);
  };

  const confirmDelete = async () => {
    if (!methodToDelete || !onDeleteMethod) return;
    
    try {
      setDeletingId(methodToDelete.id);
      setRemovingId(methodToDelete.id);
      await onDeleteMethod(methodToDelete.id);
      setMethods(prev => prev.filter(m => m.id !== methodToDelete.id));
      setShowConfirmDelete(false);
      setMethodToDelete(null);
      setSaveStatus({
        show: true,
        success: true,
        message: 'Shipping method deleted successfully'
      });
    } catch (error) {
      console.error('Error deleting shipping method:', error);
      setSaveStatus({
        show: true,
        success: false,
        message: error.response?.data?.detail || 'Failed to delete shipping method'
      });
    } finally {
      setDeletingId(null);
      // Keep removingId set for a brief moment to show the removal animation
      setTimeout(() => {
        setRemovingId(null);
      }, 300);
    }
  };

  const handleRemoveClick = (e, method) => {
    e.stopPropagation();
    setClickedMethodId(method.id);
    // Reset after a short delay to show the click animation
    setTimeout(() => setClickedMethodId(null), 200);
    handleDelete(method);
  };

  const toggleMethodExpansion = (methodId) => {
    setExpandedMethodId(expandedMethodId === methodId ? null : methodId);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75" onClick={onClose} />

        <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white dark:bg-gray-800 rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
              Existing Shipping Methods
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="w-12 h-12 border-4 border-t-blue-500 border-gray-200 rounded-full animate-spin" />
              <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">Loading shipping methods...</p>
            </div>
          ) : methods.length === 0 ? (
            <div className="text-center py-12">
              <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17l6-6-6-6" />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">No shipping methods</h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Get started by creating a new shipping method.
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              {methods.map((method) => (
                <div
                  key={method.id}
                  className={`bg-gray-50 dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700 hover:border-gray-300 dark:hover:border-gray-600 transition-all overflow-hidden ${
                    removingId === method.id ? 'opacity-0 transform scale-95' : 'opacity-100 transform scale-100'
                  }`}
                >
                  <div 
                    className="relative flex items-center justify-between p-4 cursor-pointer"
                    onClick={() => toggleMethodExpansion(method.id)}
                  >
                    <div className="flex-1 min-w-0 pr-4">
                      <div className="flex items-center">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                          {method.name}
                        </h4>
                        <svg
                          className={`ml-2 h-5 w-5 text-gray-500 transition-transform ${expandedMethodId === method.id ? 'transform rotate-180' : ''}`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                      <div className="mt-1 flex items-center space-x-2">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                          {method.shipping_type}
                        </span>
                        <span className="text-xs text-gray-500 dark:text-gray-400">
                          {method.rates?.length || 0} rates
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleApply(method);
                        }}
                        disabled={applyingId === method.id}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                      >
                        {applyingId === method.id ? (
                          <>
                            <div className="w-4 h-4 border-2 border-t-white border-gray-200 rounded-full animate-spin mr-2" />
                            Applying...
                          </>
                        ) : (
                          <>
                            <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                            Apply
                          </>
                        )}
                      </button>
                      <button
                        onClick={(e) => handleRemoveClick(e, method)}
                        disabled={deletingId === method.id}
                        className={`
                          inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md
                          ${deletingId === method.id 
                            ? 'bg-red-100 text-red-400'
                            : clickedMethodId === method.id
                            ? 'bg-red-200 text-red-700 transform scale-95'
                            : 'text-red-600 bg-red-100 hover:bg-red-200 active:transform active:scale-95'
                          }
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 
                          disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200
                        `}
                      >
                        {deletingId === method.id ? (
                          <>
                            <div className="w-4 h-4 border-2 border-t-red-600 border-red-200 rounded-full animate-spin mr-2" />
                            Removing...
                          </>
                        ) : (
                          <>
                            <svg 
                              className={`w-4 h-4 mr-1.5 transition-transform duration-200 ${
                                clickedMethodId === method.id ? 'scale-90' : ''
                              }`} 
                              fill="none" 
                              stroke="currentColor" 
                              viewBox="0 0 24 24"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                            Remove
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                  {/* Expanded content */}
                  {expandedMethodId === method.id && (
                    <div className="border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 p-4">
                      <div className="space-y-4">
                        <div>
                          <h5 className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">Shipping Rates</h5>
                          <div className="space-y-2">
                            {method.rates && method.rates.length > 0 ? (
                              method.rates.map((rate, index) => (
                                <div key={index} className="flex items-center justify-between text-sm">
                                  <div className="flex flex-col">
                                    <span className="text-gray-900 dark:text-gray-100">
                                      {rate.destination_type === 'country' ? 'Country' : 'State'}: {rate.destination || rate.zone_name}
                                    </span>
                                    {rate.additional_unit && (
                                      <span className="text-gray-500 dark:text-gray-400 text-xs">
                                        +{formatPrice(parseFloat(rate.additional_unit))} per additional unit
                                      </span>
                                    )}
                                  </div>
                                  <span className="font-medium text-gray-900 dark:text-gray-100">
                                    {formatPrice(parseFloat(rate.single_unit))}
                                  </span>
                                </div>
                              ))
                            ) : (
                              <p className="text-sm text-gray-500 dark:text-gray-400">No rates configured</p>
                            )}
                          </div>
                        </div>
                        
                        {method.description && (
                          <div>
                            <h5 className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-1">Description</h5>
                            <p className="text-sm text-gray-500 dark:text-gray-400">{method.description}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Delete confirmation modal */}
      {showConfirmDelete && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div 
              className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" 
              onClick={() => !deletingId && setShowConfirmDelete(false)} 
            />

            <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                      Delete Shipping Method
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Are you sure you want to delete <span className="font-medium text-gray-900 dark:text-gray-100">{methodToDelete?.name}</span>? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={confirmDelete}
                  disabled={deletingId}
                  className={`
                    w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2
                    ${deletingId 
                      ? 'bg-red-400 cursor-not-allowed'
                      : 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
                    }
                    text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2
                    sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200
                  `}
                >
                  {deletingId ? (
                    <>
                      <div className="w-4 h-4 border-2 border-t-white border-white/30 rounded-full animate-spin mr-2" />
                      Deleting...
                    </>
                  ) : (
                    'Delete'
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => !deletingId && setShowConfirmDelete(false)}
                  disabled={deletingId}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Status message */}
      {saveStatus.show && (
        <div className="fixed bottom-4 right-4 flex items-center space-x-2 px-4 py-2 rounded-lg shadow-lg transition-all transform translate-y-0 opacity-100 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          {saveStatus.success ? (
            <svg className="h-5 w-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          ) : (
            <svg className="h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          )}
          <p className={`text-sm ${saveStatus.success ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
            {saveStatus.message}
          </p>
          <button
            onClick={() => setSaveStatus({ show: false, success: false, message: '' })}
            className="ml-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
          >
            <span className="sr-only">Dismiss</span>
            <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}

ExistingMethodsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApplyMethod: PropTypes.func.isRequired,
  onDeleteMethod: PropTypes.func.isRequired,
  methods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    shipping_type: PropTypes.string.isRequired,
    rates: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      destination_country: PropTypes.string,
      single_unit: PropTypes.string,
      additional_unit: PropTypes.string
    }))
  })).isRequired,
};

import { Routes, Route } from "react-router-dom";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Signin from "./components/Signin";
import Home from "./components/Home";
import Settings from "./components/account/Settings";
import Dash from "./components/artist_dashboard/dashboard/Dash";
import Services from "./components/artist_dashboard/Services";
import StripeConnectRedirect from "./components/account/stripe/StripeConnectRedirect";
import ArtistPublicProfile from "./components/ArtistPublicProfile";
import Account from "./components/subscriptions/Account";
import StudioPlans from "./components/subscriptions/StudioPlans";
import DraftEditView from "./components/artist_dashboard/dashboard/DraftEditView";
import MultiStepForm from "./components/artist_dashboard/dashboard/MultiStepForm";
import FinalizeDraft from "./components/artist_dashboard/dashboard/FinalizeDraft";
import FinalizeMaster from "./components/artist_dashboard/dashboard/FinalizeMaster";
import CreateProductView from "./components/artist_dashboard/dashboard/CreateProductView";
import PublishedMerchView from "./components/artist_dashboard/merch/meta_views/PublishedMerchView";
import StudioPlusUpgradeView from "./components/subscriptions/StudioPlusUpgradeView";
import CassetteDraftEditView from "./components/artist_dashboard/dashboard/CassetteDraftEditView";
import LabelView from "./components/label_dashboard/LabelView";
import PublicHomePage from "./components/home/PublicHomePage";
import LabelArtistView from "./components/label_dashboard/LabelArtistView";
import Otari from "./components/artist_dashboard/services/Otari";
import FBSigninView from "./auth/FBSigninView";
import CreateEventView from "./components/events/create/CreateEventView.jsx";
import CreateUsernameFromEventView from "./components/events/CreateUsernameFromEventView.jsx";
import ArtistShopView from "./components/ArtistShopView";
import NameEntryForm from "./components/events/NameEntryForm";
import PhazeSplash from "./services/PhazeSplash";
import Terminal from "./components/artist_dashboard/Terminal";
import AlbumsContainer from "./containers/AlbumsContainer";
import LabelCreationView from "./components/label_dashboard/LabelCreationView";
import MTRDriveView from "./components/artist_dashboard/drive/MTRDriveView";
import DriveView from "./components/file_mgmt/drive/DriveView";
import PublicFolderView from "./components/file_mgmt/drive/PublicFolderView";
import SharedFolderViewer from "./components/file_mgmt/drive/SharedFolderViewer";
import SignupView from "./auth/signup/SignupView";
import AudioMixer from "./components/artist_dashboard/drive/mtr_player/mixer/AudioMixer";
import SubscriptionCards from "./components/file_mgmt/drive/subscription/SubscriptionCards";
import JoinWaitlistForm from "./auth/onboarding/waitlist/JoinWaitlistForm";
import MemoriesPageView from "./components/events/memories/MemoriesPageView";
import ProfileView from "./components/events/profiles/ProfileView";
import APIConfig from "./APIConfig";
import PhazeSplashPage from "./components/artist_dashboard/drive/PhazeSplashPage.jsx";
import Draft from "./components/artist_dashboard/drafting/Draft.jsx";
import ArtistRegistrationForm from "./components/forms/ArtistRegistrationForm.js";
import Konsole from "./components/artist_dashboard/Konsole.jsx";
import AlbumsComponent from "./components/mxt/music/AlbumsComponent.jsx";
import MXTSplashPage from "./components/artist_dashboard/drive/MXTSplashPage.jsx";
import ArtistSearch from "./components/search/ArtistSearch.jsx";
import LightSpinner from "./components/loading/LightSpinner.jsx";
import PhotoDetailView from "./components/events/memories/PhotoDetailView.jsx";
import EventView from "./components/events/components/EventView.jsx";
import EventsView from "./components/events/EventsView.jsx";
import CreatePostView from "./components/events/create/CreatePostView.jsx";
import ContactUs from "./components/account/legal/ContactUs.jsx";
import DMCA from "./components/account/legal/DMCA.jsx";
import TermsOfService from "./components/account/legal/TermsOfService.jsx";
import PrivacyPolicy from "./components/account/legal/PrivacyPolicy.jsx";
import CopyrightPolicy from "./components/account/legal/CopyrightPolicy.jsx";
import CommunityGuidelines from "./components/account/legal/CommunityGuidelines.jsx";
import AcceptableUsePolicy from "./components/account/legal/AcceptableUsePolicy.jsx";
import AgeRestrictionPolicy from "./components/account/legal/AgeRestrictionPolicy.jsx";
import ContentDisclaimer from "./components/account/legal/ContentDisclaimer.jsx";
import ReportContent from "./components/account/legal/ReportContent.jsx";
import SharedDraftView from "./components/artist_dashboard/drafting/SharedDraftView";
import MerchEditorView from "./components/artist_dashboard/merch/MerchEditorView";
import MerchDraftsView from "./components/artist_dashboard/merch/MerchDraftsView";
import MixtapeDetail from "./components/mixtapes/MixtapeDetail";
import { CartProvider } from "./contexts/CartContext";
import VideoCreatorView from "./components/video_generator/VideoCreatorView";
import StorefrontView from "./components/storefront/StorefrontView";
import ShippingManager from './components/merch/ShippingManager';
import { ShippingMethodEditor } from "./components/merch/shipping/ShippingMethodEditor";
import HomePage from "./components/home/HomePage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArtistPageV2 from "./components/artist_profiles/v2/ArtistPageV2";
import ProductPage from "./components/artist_profiles/merch_item/ProductPage";
import AlbumView from "./components/artist_profiles/AlbumView";
import ArtistPage from "./components/artist_profiles/ArtistPage";
import MerchFeed from "./components/merch/MerchFeed";

const audioSources = [
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.5,
    pan: 0,
  },
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.75,
    pan: -0.5,
  },
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.25,
    pan: 0.5,
  },
];

const Main = ({ token }) => {
  const [user, loading, error] = useAuthState(firebase.auth());

  if (loading) {
    return <LightSpinner />; // Or any other loading indicator
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Handle the error as you see fit
  }

  if (!user) {
    return (
      <CartProvider>
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
          <ToastContainer position="bottom-right" />
          <PublicHomePage />
        </div>
      </CartProvider>
    );
  }

  return (
    <CartProvider>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        <ToastContainer position="bottom-right" />
        <Routes>
          {/* legal */}
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/dmca" element={<DMCA />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/copyright" element={<CopyrightPolicy />} />
          <Route path="/community-guidelines" element={<CommunityGuidelines />} />
          <Route path="/acceptable-use" element={<AcceptableUsePolicy />} />
          <Route path="/age-restriction" element={<AgeRestrictionPolicy />} />
          <Route path="/disclaimer" element={<ContentDisclaimer />} />
          <Route path="/report-content" element={<ReportContent />} />
          {/* legal ^ */}
          <Route path="/shared/:publicLinkId" element={<PublicFolderView />} />
          <Route path="/shared/draft/:docId" element={<SharedDraftView />} />
          <Route path="/albums" element={<AlbumsContainer />} />
          <Route path="/subscribe" element={<SubscriptionCards />} />
          <Route path="/draft" element={<Draft />} />
          <Route
            path="/shared_folders/:publicLinkId"
            element={<SharedFolderViewer />}
          />
          <Route path="/waitlist" element={<JoinWaitlistForm />} />
          <Route
            path="/mixer"
            element={<AudioMixer audioSources={audioSources} />}
          />
          {/* label creator */}
          <Route path="/label" element={<LabelCreationView />} />

          <Route path="/drive" element={<DriveView class="min-h-screen" />} />
          {/* artist registration */}
          <Route path="/artists/register" element={<ArtistRegistrationForm />} />
          <Route path="/konsole" element={<Konsole />} />
          <Route path="/create/album" element={<DraftEditView />} />
          <Route path="/create/cassette" element={<CassetteDraftEditView />} />
          <Route path="/um" element={<PhazeSplash />} />
          <Route path="/inventory" element={<PublishedMerchView />} />
          <Route path="/drafts/merch" element={<MerchDraftsView />} />
          <Route path="/merch/edit/:id" element={<MerchEditorView />} />
          <Route path="/create/merch" element={<MerchEditorView />} />
          <Route path="/shipping-manager/:merchId" element={<ShippingManager />} />
          <Route path="/shipping-manager" element={<ShippingManager />} />
          <Route path="/shipping/new" element={<ShippingMethodEditor />} />
          <Route path="/view/artist" element={<LabelArtistView />} />
          <Route path="/create/otari_project" element={<Otari />} />
          <Route path="/publish/album" element={<FinalizeDraft />} />
          <Route path="/analog/master" element={<FinalizeMaster />} />
          <Route path="/name_entry" element={<NameEntryForm />} />
          <Route path="/a/v2/:id" element={<ArtistPageV2 />} />
          <Route path="/search" element={<ArtistSearch />} />
          <Route path="/success" element={<StripeConnectRedirect />} />
          <Route path="/subscribe" element={<ArtistPublicProfile />} />
          <Route path="/plans" element={<StudioPlans />} />
          <Route path="/account" element={<Account />} />
          <Route path="/form" element={<MultiStepForm />} />
          <Route path="/services" element={<Services />} />
          <Route path="/label" element={<LabelView />} />
          <Route path="/upload" element={<Dash />} />
          <Route path="/tapes/:id" element={<MTRDriveView />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/upgrade" element={<StudioPlusUpgradeView />} />
          <Route path="/signup" element={<SignupView />} />
          <Route path="/signin" element={<FBSigninView />} />
          <Route path="/e/:id" element={<EventView />} />
          <Route path="/create/event" element={<CreateEventView />} />
          <Route path="/create/event/name" element={<CreateUsernameFromEventView />} />
          <Route path="/event/name" element={<NameEntryForm />} />
          <Route path="/merch" element={<MerchFeed />} />
          <Route path="/shop/:productId" element={<ProductPage />} />
          <Route path="/a/:artistSlug/shop/:productId" element={<ProductPage />} />
          <Route path="/a/:artistSlug/merch/:productId" element={<ProductPage />} />
          <Route path="/a/:slug" element={<ArtistPage />} />
          <Route path="/a/v2/:slug" element={<ArtistPageV2 />} />
          <Route path="/a/:slug/albums/:albumId" element={<AlbumView />} />
          <Route path="/" element={<HomePage token={token} />} />
          <Route path="/create-post" element={<CreatePostView />} />
          <Route path="/e/:eventId/memories" element={<MemoriesPageView />} />
          <Route
            path="/events/:eventId/photos/:photoId"
            element={<PhotoDetailView />}
          />
          <Route
            path="/create"
            element={user ? <CreateEventView /> : <CreateUsernameFromEventView />}
          />
          <Route path="/create/video" element={<VideoCreatorView />} />
          <Route path="/u/:username" element={<StorefrontView />} />
          <Route path="/mixtapes/:id" element={<MixtapeDetail />} />
        </Routes>
      </div>
    </CartProvider>
  );
};

export default Main;

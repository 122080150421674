import React from "react";

const ArtistMerchViewV2 = ({ items }) => {
  if (!items || items.length === 0) {
    return <div className="font-mono text-center">No merch available</div>;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
      {items.map((item) => (
        <div key={item.id} className="font-mono">
          {item.image_url && (
            <img
              src={item.image_url}
              alt={item.name}
              className="w-full mb-4"
            />
          )}
          <div className="text-sm">{item.name}</div>
          <div className="text-sm">${(item.price / 100).toFixed(2)}</div>
        </div>
      ))}
    </div>
  );
};

export default ArtistMerchViewV2;

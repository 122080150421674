import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "../column";
import initialData from "../initial-data";
import useDragDrop from "./hooks/useDragDrop";
import AddButton from "./AddButton";
import Uploader from "../../../file_mgmt/uploader/Uploader";
import useAlbumInfo from "./hooks/useAlbumInfo";
import useTrackManager from "./hooks/useTrackManager";
import useFileAndDraftSubscriptions from "./hooks/useFileAndDraftSubscriptions";
import useApplyAINaming from "./hooks/useApplyAINaming";
import { useLocation } from "react-router-dom";

const CreateAlbumView = () => {
  const location = useLocation();
  const album = location.state?.album;
  const [data, setData] = useState(initialData);
  const { albumInfo, artistId } = useAlbumInfo(album); // Pass the entire album object
  const addNewTrack = useTrackManager(albumInfo || album, artistId, data, setData);
  useFileAndDraftSubscriptions(albumInfo || album, setData);

  const { onDragStart, onDragEnd, homeIndex } = useDragDrop(
    data,
    albumInfo || album,
    setData,
    artistId
  );

  const { applyAINamingToAllTracks } = useApplyAINaming(data, albumInfo || album);

  return (
    <div className="min-h-screen  text-gray-200">
      <div className="flex flex-col gap-4 md:flex-row md:gap-6">
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          {data.columnOrder.map((columnId, index) => {
            const column = data.columns[columnId];
            const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
            const isDropDisabled = index < homeIndex;

            return (
              <div
                key={column.id}
                className={`flex flex-col ${
                  index === 0 ? "md:w-1/3 w-full" : "md:w-2/3 w-full"
                } bg-gray-800 rounded-lg shadow-lg border border-gray-700`}
              >
                {index === 0 && (
                  <div className="font-theFutureMonoRegular text-lg text-yellow-300 p-4 border-b border-gray-700">
                    {"/files"}
                  </div>
                )}

                {index === 1 && (
                  <div className="p-4 border-b border-gray-700">
                    <AddButton
                      label="add track"
                      onClick={addNewTrack}
                      className="self-start px-5 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors duration-200"
                    />
                  </div>
                )}

                {index === 0 && <Uploader />}

                <Column
                  column={column}
                  tasks={tasks}
                  album={albumInfo || album}
                  isDropDisabled={isDropDisabled}
                  data={data}
                />
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
};

export default CreateAlbumView;

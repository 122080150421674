import APIConfig from "./APIConfig";
// import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { authMethods } from "./firebase/authmethods";
import { firebaseAuth } from "./provider/AuthProvider";
import auth from "./services/auth";
// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
var db = firebase.firestore();

// Get current Firebase auth token, waiting for auth to initialize if needed
const getAuthToken = async () => {
  // Wait for Firebase auth to initialize
  const [user] = await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve([user]);
    });
  });

  if (!user) {
    return null; // Return null when not authenticated instead of throwing
  }
  
  return user.getIdToken(true);
};

// Initialize auth state listener
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is signed in, you can now make API calls
    console.log('User authenticated');
  } else {
    // User is signed out
    console.log('User not authenticated');
  }
});

const createPaymentIntent = async (options) => {
  const token = await getAuthToken();
  
  return window
    .fetch(`${APIConfig.payments()}create-payment-intent/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: JSON.stringify(options),
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        throw new Error("PaymentIntent API Error");
      } else {
        return data.client_secret;
      }
    });
};

const sendEmailVerification = () => {
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      //("signed in");
      firebase.auth().currentUser.sendEmailVerification();
    }
  });
};

const sendPasswordResetLink = (emailAddress) => {
  var auth = firebase.auth();
  // var emailAddress = "user@example.com";

  auth
    .sendPasswordResetEmail(emailAddress)
    .then(function () {
      // Email sent.
      alert("email sent");
    })
    .catch(function (error) {
      // An error happened.
      alert("oops something went wrong");
    });
};

const getProductDetails = async () => {
  const token = await getAuthToken();
  
  return window
    .fetch(`${APIConfig.payments()}product-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json", 
        Authorization: "JWT " + token,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = async () => {
  try {
    const token = await getAuthToken();
    if (!token) return null; // Not authenticated, return null instead of error
    
    const response = await window.fetch(`${APIConfig.baseURL}api/v1/commerce/payments/mode/`, {
      method: "GET", 
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      if (!data || !data.publishableKey) {
        return null;
      }
      return data.publishableKey;
    } else {
      return null;
    }
  } catch (error) {
    console.log('Failed to get Stripe key:', error);
    return null;
  }
};

const setStudioData = (downloadURL, uid, filename) => {
  db.collection("studio")
    .doc(uid)
    .collection("files")
    .add({
      filename: filename,
      url: downloadURL,
    })
    .then(function (docRef) {})
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

const setMTRStudioData = (tapeRef, track) => {
  if (!tapeRef || typeof tapeRef.collection !== 'function') {
    console.error('Invalid tape reference:', tapeRef);
    return;
  }
  tapeRef.collection("tracks")
    .add({
      channel: track.channel,
      filename: track.filename,
      url: track.url,
    })
    .then(function (docRef) {})
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

const tape = {
  name: "Tape 1",
  uid: "10923uusadyasoialkisjda",
  description: "This is a tape",
  artist: "Artist 1",
  tracks: [
    {
      number: 1,
      filename: "file_track_1.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_1.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
    {
      number: 2,
      filename: "file_track_2.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_2.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
    {
      number: 3,
      filename: "file_track_3.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_3.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
    {
      number: 4,
      filename: "file_track_4.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_4.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
  ],
};

// test createnewtape functionality with tape object async

const testCreateNewTape = (user_uid) => {

  var result = createNewTape(tape, user_uid);
  return result;
}

const createNewTape = async (tape, user_uid) => {
  const tape_name = tape.name;
  const tape_uid = tape.uid;
  const tape_description = tape.description;
  const tape_artist = tape.artist;
  const collectionRef = db.collection("studio").doc(user_uid).collection("drive");
  const docRef = collectionRef.doc();
  await docRef.set({
    name: tape_name,
    uid: tape_uid,
    description: tape_description,
    artist: tape_artist,
  });
  const tapeRef = collectionRef.doc(tape_uid); // create a document reference object
  for (const track of tape.tracks) {
    await setMTRStudioData(tapeRef, track);
  }
  return tapeRef; // return the document reference object
};
const createBlankTape = async (tape, user_uid) => {
  const tape_name = tape.name;
  const tape_description = tape.description;
  const collectionRef = db.collection("studio").doc(user_uid).collection("drive");
  const docRef = collectionRef.doc(); // Create a document reference object
  await docRef.set({
    name: tape_name,
    description: tape_description,
    tracks: [],
  });
  return docRef; // Return the document reference object
};


const handleResult = () => {};

async function submitArtistName(name) {
  const response = await submit(name);
  return response;
}

const me = () => {
  fetch(`${APIConfig.profiles()}me/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });
};

const validateArtistName = (name) => {
  if (!name || typeof name !== 'string') {
    throw new Error('Artist name is required');
  }
  
  const trimmedName = name.trim();
  if (trimmedName.length < 2) {
    throw new Error('Artist name must be at least 2 characters');
  }
  if (trimmedName.length > 100) {
    throw new Error('Artist name must be less than 100 characters');
  }
  
  // Check for valid characters
  if (!/^[a-zA-Z0-9\s\-'.,&!]+$/.test(trimmedName)) {
    throw new Error('Artist name contains invalid characters');
  }
  
  return trimmedName;
};

// Retry function with exponential backoff
const retry = async (fn, retries = 3, delay = 1000) => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0 || error.message.includes('Not authenticated')) {
      throw error;
    }
    await new Promise(resolve => setTimeout(resolve, delay));
    return retry(fn, retries - 1, delay * 2);
  }
};

const newArtist = async (name, fs_uid) => {
  // Validate inputs
  if (!fs_uid) {
    throw new Error('User ID is required');
  }
  if (!name) {
    throw new Error('Artist name is required');
  }
  const validatedName = validateArtistName(name);
  
  // Get Firebase user
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error('Please sign in to add an artist');
  }
  
  // Function to make the API call
  const makeRequest = async () => {
    const idToken = await user.getIdToken(true);
    const response = await window.fetch(`${APIConfig.artists()}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + idToken,
      },
      body: JSON.stringify({
        artist: {
          name: validatedName
        },
        fs_uid: fs_uid
      }),
    });

    const data = await response.json();
    
    if (!response.ok) {
      // Handle specific error cases
      const errorMessage = data.error || data.detail || 'An error occurred';
      
      // If error is an object (serializer errors), format it
      const formattedError = typeof errorMessage === 'object' 
        ? Object.entries(errorMessage)
            .map(([field, errors]) => `${field}: ${errors.join(', ')}`)
            .join('; ')
        : errorMessage;
        
      switch (response.status) {
        case 400:
          throw new Error(formattedError);
        case 401:
          throw new Error('Your session has expired. Please sign in again.');
        case 403:
          throw new Error('You do not have permission to add an artist.');
        case 404:
          throw new Error(formattedError);
        case 409:
          throw new Error('An artist with this name already exists.');
        case 429:
          throw new Error('Too many requests. Please try again in a few minutes.');
        case 500:
          throw new Error('Server error. Please try again later.');
        default:
          throw new Error(`Failed to add artist: ${formattedError}`);
      }
    }
    
    return data;
  };

  // Make the request with retry logic
  try {
    return await retry(makeRequest);
  } catch (error) {
    // Log error for debugging
    console.error('Error in newArtist:', error);
    
    // Rethrow with user-friendly message
    if (error.message.includes('Network')) {
      throw new Error('Network error. Please check your connection and try again.');
    }
    throw error;
  }
};

const updateArtistName = async (artistId, name) => {
  const artist_data = { artist_id: artistId, name: name };
  const d = JSON.stringify(artist_data);
  const headers = await auth.getAuthHeaders();

  return fetch(`${APIConfig.profiles()}update_artist/`, {
    method: "POST",
    headers,
    body: d,
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error('Error updating artist');
    }
  });
};

const deleteArtist = async (artistId) => {  
  const artist_data = { artist_id: artistId };
  const d = JSON.stringify(artist_data);
  const headers = await auth.getAuthHeaders();

  return fetch(`${APIConfig.profiles()}delete_artist/`, {
    method: "POST",
    headers,
    body: d,
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error('Error deleting artist');
    }
  });
}

const newLabel = async (name, fs_uid) => {
  const label_data = { name: name, fs_uid: fs_uid };
  const d = JSON.stringify(label_data);
  const token = localStorage.getItem("token");
  const r = await fetch(`${APIConfig.profiles()}create_label/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: d,
  });

  return r;
};

const newAlbum = async (draft_uid) => {
  
  const album_data = { draft_uid: draft_uid};
  const d = JSON.stringify(album_data);
  // const token = localStorage.getItem("token");
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      //("signed in");
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          fetch(`${APIConfig.profilesURI()}new_album/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          }).then(function (res) {});
        });
    }
  });

  // return r;
};

const checkout = async (stripe, merch_item_id, quantity, user_id) => {
  var stripe = window.Stripe(APIConfig.stripePublicKey());
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          const data = {
            merch_item_id: merch_item_id,
            quantity: quantity,
            user_id,
          };
          const d = JSON.stringify(data);
          fetch(`${APIConfig.payments()}create-checkout-session/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          }).then(
            function (res) {
              res.json().then(function (data) {
                stripe.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });
              //
            },
            function (value) {}
          );
        });
    }
  });
};

//does not work
function submit(name) {
  const profile_id = localStorage.getItem("profile_id");
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(`${APIConfig.profiles()}new_artist/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: { name: name },
    }).then((res) => {
      if (res.status === 200) {
        resolve(res.json());
      } else {
        resolve(res.json());
        return null;
      }
    });
  });
}

const generateVideo = async (prompt, options) => {
  try {
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new Error('User must be authenticated to generate videos');
    }

    const token = await user.getIdToken();
    const response = await fetch('/api/video/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`
      },
      body: JSON.stringify({ prompt, options })
    });

    if (!response.ok) {
      throw new Error('Failed to generate video');
    }

    return await response.json();
  } catch (error) {
    console.error('Error generating video:', error);
    throw error;
  }
};

const getVideoStatus = async (jobId) => {
  const token = await getAuthToken();
  return window
    .fetch(`${APIConfig.baseURL()}api/video/status/${jobId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `JWT ${token}` : "",
      },
    })
    .then((res) => res.json());
};

const getStorefront = async (username) => {
  return window
    .fetch(APIConfig.storefrontURI(username), {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((res) => res.json());
};

const api = {
  createPaymentIntent,
  me,
  newArtist,
  updateArtistName,
  deleteArtist,
  newLabel,
  newAlbum,
  checkout,
  submit,
  getPublicStripeKey,
  setStudioData,
  setMTRStudioData,
  testCreateNewTape,
  createNewTape,
  createBlankTape,
  generateVideo,
  getVideoStatus,
  getStorefront,
};

export default api;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import useUserProfile from '../../hooks/useUserProfile';
import APIConfig from '../../APIConfig';
import { usePayment } from '../../context/PaymentContext';

function StripeConnect() {
  const [stripeStatus, setStripeStatus] = useState(null);
  const [stripeBalance, setStripeBalance] = useState(null);
  const [fetchingData, setFetchingData] = useState(true); // More explicit: loading data from API
  const [error, setError] = useState(null);
  
  const { isSeller, stripeDashLink, loading: loadingProfile } = useUserProfile();
  const [user, loadingAuth] = useAuthState(firebase.auth());
  const { stripeMode } = usePayment();

  // We'll start by considering the entire process as loading until we know what to show
  // Once we have user and isSeller info, we decide what to fetch next.
  
  useEffect(() => {
    // If we're still loading user or user profile info, just wait
    if (loadingAuth || loadingProfile) return;

    // If no user, we can stop loading but show nothing for now
    if (!user) {
      setFetchingData(false);
      return;
    }

    // If user is a seller, we fetch Stripe status and balance
    if (isSeller) {
      fetchStripeStatusAndBalance();
    } else {
      // If not a seller, we don't need to fetch Stripe info
      setFetchingData(false);
    }
  }, [isSeller, user, loadingAuth, loadingProfile]);

  const fetchStripeStatusAndBalance = async () => {
    try {
      const idToken = await user.getIdToken(true);

      // Fetch status
      const statusResponse = await axios.get(`${APIConfig.baseURL()}/api/v1/commerce/payments/connect/account/`, {
        headers: {
          'Authorization': `JWT ${idToken}`,
          'Content-Type': 'application/json',
        },
        validateStatus: status => status < 500
      });

      if (statusResponse.status === 404) {
        setStripeStatus({
          is_enabled: false,
          details_submitted: false,
          charges_enabled: false,
          payouts_enabled: false,
          requirements: {
            currently_due: [],
            eventually_due: [],
            past_due: [],
          }
        });
      } else if (!statusResponse.data) {
        throw new Error('Invalid Stripe status response format');
      } else {
        setStripeStatus(statusResponse.data);
      }

      // Fetch balance
      const balanceResponse = await axios.get(`${APIConfig.baseURL()}/api/v1/commerce/payments/connect/balance/`, {
        headers: {
          'Authorization': `JWT ${idToken}`,
          'Content-Type': 'application/json',
        },
        validateStatus: status => status < 500
      });

      if (balanceResponse.status !== 404 && balanceResponse.data) {
        setStripeBalance(balanceResponse.data);
      } else {
        setStripeBalance(null);
      }

    } catch (err) {
      console.error('Error fetching Stripe info:', err.response || err);
      if (err.response?.status !== 401) {
        setError('Failed to fetch Stripe account information. Please try again later.');
      }
    } finally {
      setFetchingData(false);
    }
  };

  const handleStripeDashboard = async () => {
    if (!user) return;
    try {
      setFetchingData(true);
      setError(null);

      const idToken = await user.getIdToken(true);
      const response = await axios.post(`${APIConfig.baseURL()}/api/v1/commerce/payments/connect/onboarding/`, {}, {
        headers: {
          'Authorization': `JWT ${idToken}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        setError('Failed to get Stripe onboarding URL');
      }
    } catch (error) {
      console.error('Error accessing Stripe dashboard:', error.response || error);
      setError(error.response?.data?.message || 'Failed to access Stripe dashboard');
    } finally {
      setFetchingData(false);
    }
  };

  const handleConnectStripe = async () => {
    if (!user) return;
    try {
      setFetchingData(true);
      setError(null);

      const idToken = await user.getIdToken(true);
      const response = await axios.get(`${APIConfig.baseURL()}/api/v1/commerce/payments/connect/authorize/`, {
        headers: {
          'Authorization': `JWT ${idToken}`,
          'Content-Type': 'application/json',
        }
      });
      
      if (response.data?.url) {
        localStorage.setItem('stripeConnectReturnUrl', window.location.href);
        window.location.href = response.data.url;
      } else {
        setError('Invalid response from server. Please try again later.');
      }
    } catch (error) {
      console.error('Error starting OAuth flow:', error.response || error);
      setError(error.response?.data?.error || 'Failed to start Stripe Connect process. Please try again later.');
    } finally {
      setFetchingData(false);
    }
  };

  const handleStripeOnboarding = async () => {
    if (!user) return;
    try {
      setFetchingData(true);
      setError(null);

      const idToken = await user.getIdToken(true);
      const response = await axios.post(`${APIConfig.baseURL()}/api/v1/commerce/payments/connect/onboard/`, {}, {
        headers: {
          'Authorization': `JWT ${idToken}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.data?.url) {
        window.location.href = response.data.url;
      } else {
        setError('Failed to start Stripe onboarding. Please try again later.');
      }
    } catch (error) {
      console.error('Error starting onboarding:', error.response || error);
      if (error.response?.status === 400 && error.response.data?.error === 'Missing contact information') {
        setError('Please add a phone number to your account in Settings before connecting with Stripe.');
      } else {
        setError(error.response?.data?.message || 'Failed to start Stripe onboarding. Please try again later.');
      }
    } finally {
      setFetchingData(false);
    }
  };

  // Check for redirect from Stripe OAuth errors
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const oauthError = params.get('error');
    const errorMessage = params.get('error_message');

    if (oauthError || errorMessage) {
      console.error('OAuth error:', oauthError, errorMessage);
      setError(errorMessage || 'Failed to connect Stripe account. Please try again.');
    }
    if (window.history.replaceState) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  // Decide what to render
  // If user is not known or still loading profile, show a loading indicator
  if (loadingAuth || loadingProfile) {
    return <SkeletonLoading />;
  }

  // If we have user but are fetching Stripe data (for seller), show a loading indicator
  if (fetchingData) {
    return <SkeletonLoading />;
  }

  // If there's an error, show error message
  if (error) {
    return (
      <div className="p-4 bg-red-50 dark:bg-red-900 rounded-lg border border-red-200 dark:border-red-800">
        <p className="text-red-700 dark:text-red-200">{error}</p>
        <button
          onClick={fetchStripeStatusAndBalance}
          className="mt-2 text-red-600 dark:text-red-300 underline hover:no-underline focus:outline-none focus:ring-2 focus:ring-red-500 rounded"
        >
          Try Again
        </button>
      </div>
    );
  }

  // If no user signed in, show nothing
  if (!user) {
    return null;
  }

  // If user is not a seller, show "Connect with Stripe" UI
  if (!isSeller) {
    return (
      <div className="p-6 border border-gray-200 dark:border-gray-700 rounded-lg text-center">
        <h3 className="text-lg font-medium mb-4 dark:text-gray-100">Start Selling on Mixtape</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-6">
          Connect your Stripe account to start selling your music and merchandise.
        </p>
        <button
          onClick={handleConnectStripe}
          disabled={fetchingData}
          className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 ${
            fetchingData ? 'cursor-wait' : ''
          }`}
        >
          {fetchingData ? (
            <>
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
              Connecting...
            </>
          ) : (
            'Connect with Stripe'
          )}
        </button>
      </div>
    );
  }

  // If user is a seller, show account status, balances, etc.
  return (
    <div className="space-y-6">
      <SellerDashboard
        stripeStatus={stripeStatus}
        stripeBalance={stripeBalance}
        onRefresh={fetchStripeStatusAndBalance}
        onOpenDashboard={handleStripeDashboard}
      />
    </div>
  );
}

function SkeletonLoading() {
  return (
    <div className="p-6 border border-gray-200 dark:border-gray-700 rounded-lg">
      <div className="animate-pulse space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-48"></div>
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-32 mt-2"></div>
          </div>
          <div className="h-10 w-32 bg-gray-200 dark:bg-gray-700 rounded"></div>
        </div>
        
        <div className="space-y-3 pt-4 border-t border-gray-100 dark:border-gray-800">
          <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6"></div>
        </div>

        <div className="flex justify-end">
          <div className="h-10 w-24 bg-gray-200 dark:bg-gray-700 rounded"></div>
        </div>
      </div>
    </div>
  );
}

function SellerDashboard({ stripeStatus, stripeBalance, onRefresh, onOpenDashboard }) {
  return (
    <>
      {/* Account Status Section */}
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <h3 className="font-medium">Account Status</h3>
          <p className={`${stripeStatus?.charges_enabled && stripeStatus?.payouts_enabled ? 'text-green-600' : 'text-yellow-600'}`}>
            {stripeStatus?.charges_enabled && stripeStatus?.payouts_enabled ? 'Complete' : 'Incomplete'}
          </p>
        </div>
        <div className="space-y-2">
          <h3 className="font-medium">Charges</h3>
          <p className={`${stripeStatus?.charges_enabled ? 'text-green-600' : 'text-yellow-600'}`}>
            {stripeStatus?.charges_enabled ? 'Enabled' : 'Disabled'}
          </p>
        </div>
        <div className="space-y-2">
          <h3 className="font-medium">Payouts</h3>
          <p className={`${stripeStatus?.payouts_enabled ? 'text-green-600' : 'text-yellow-600'}`}>
            {stripeStatus?.payouts_enabled ? 'Enabled' : 'Disabled'}
          </p>
        </div>
      </div>

      {/* Balance Section */}
      {stripeBalance && (
        <div className="border-t pt-4">
          <h3 className="font-medium mb-3">Available Balance</h3>
          <div className="grid grid-cols-2 gap-4">
            {stripeBalance.available?.map((balance) => (
              <div key={balance.currency} className="bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                <p className="text-lg font-medium text-green-600">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: balance.currency.toUpperCase()
                  }).format(balance.amount / 100)}
                </p>
                <p className="text-sm text-gray-500">{balance.currency.toUpperCase()}</p>
              </div>
            ))}
          </div>
          
          {stripeBalance.pending?.length > 0 && (
            <div className="mt-4">
              <h4 className="font-medium text-sm text-gray-600 dark:text-gray-400">Pending</h4>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {stripeBalance.pending.map((balance) => (
                  <div key={balance.currency} className="bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                    <p className="text-lg font-medium text-yellow-600">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: balance.currency.toUpperCase()
                      }).format(balance.amount / 100)}
                    </p>
                    <p className="text-sm text-gray-500">{balance.currency.toUpperCase()}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Requirements Section */}
      {stripeStatus?.requirements?.currently_due?.length > 0 && (
        <div className="border-t pt-4">
          <div className="p-4 bg-yellow-50 dark:bg-yellow-900 rounded-md">
            <h3 className="font-medium text-yellow-800 dark:text-yellow-200">
              Additional information required:
            </h3>
            <ul className="list-disc list-inside mt-2 text-yellow-700 dark:text-yellow-300">
              {stripeStatus.requirements.currently_due.map((requirement) => (
                <li key={requirement}>{requirement}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Action Buttons */}
      <div className="border-t pt-4 flex space-x-4">
        <button
          onClick={onOpenDashboard}
          className="bg-white text-indigo-600 border border-indigo-600 px-4 py-2 rounded-md hover:bg-indigo-50 transition-colors"
        >
          View Stripe Dashboard
        </button>
        <button
          onClick={onRefresh}
          className="text-indigo-600 dark:text-indigo-400 underline hover:no-underline"
        >
          Refresh Status
        </button>
      </div>
    </>
  );
}

export default StripeConnect;
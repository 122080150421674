import React from 'react';
import PropTypes from 'prop-types';

export default function LoginModal({ onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 max-w-sm w-full mx-4 border border-black">
        <h2 className="text-xl font-bold mb-4">Sign In Required</h2>
        <p className="mb-6">You need to be signed in to like mixtapes.</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="border border-black px-4 py-2 hover:bg-gray-100 transition-colors"
          >
            Cancel
          </button>
          <a
            href="/login"
            className="border border-black bg-black text-white px-4 py-2 hover:bg-white hover:text-black transition-colors"
          >
            Sign In
          </a>
        </div>
      </div>
    </div>
  );
}

LoginModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

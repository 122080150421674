import React, { useContext } from "react";
import { AuthContext } from '../../context/AuthContext';
import useUserProfile from "../../hooks/useUserProfile";
import WideButton from "../buttons/WideButton";
import ContactEmail from "./ContactEmail";
import StorageUsage from "../file_mgmt/drive/hooks/StorageUsage";
import APIConfig from "../../APIConfig";
import ProfileView from "../events/profiles/ProfileView";
import UserInfoDisplay from "./UserInfoDisplay";
import LinkedArtistsView from "../artist_dashboard/dashboard/album_creator/LinkedArtistsView";
import UsageView from "./UsageView";
import { PaymentProvider } from '../../context/PaymentContext';
import PaymentStatus from '../payment/PaymentStatus';
import StripeProfileInfo from '../payment/StripeProfileInfo';
import StripeConnect from '../payment/StripeConnect';

function Settings() {
  const { user } = useContext(AuthContext);
  const {
    artistName,
    isArtist,
    hasStudio,
    artistLink,
    stripeDashLink,
    isSeller,
  } = useUserProfile();

  const displayStorage = () => {
    if (APIConfig.airtrak() === true || APIConfig.studio() === true) {
      return (
        <div className="dark:border-white bg-gray-100 dark:bg-gray-900">
          <ProfileView />
          <StorageUsage />
        </div>
      );
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 ">
      <h1 className="text-3xl font-bold text-center uppercase tracking-wide  border-black dark:border-white pb-4">
        Settings
      </h1>
      <div className="space-y-6">
        <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">Account Settings</h2>
        <div id="linked-artists" className="border-2 border-black dark:border-white p-4 bg-gray-100 dark:bg-gray-900">
          <LinkedArtistsView />
        </div>

        {/* Stripe Connect Section */}
        <div className="border-2 border-black dark:border-white p-4 bg-gray-100 dark:bg-gray-900">
          <h2 className="text-xl font-bold mb-4">Stripe Connect</h2>
          <StripeConnect />
        </div>

        {/* Usage History Section */}
        {/* <div className="border-2 border-black dark:border-white p-4 bg-gray-100 dark:bg-gray-900">
          <h2 className="text-xl font-bold mb-4">Usage History</h2>
          <UsageView />
        </div> */}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-4">
          <div className="p-4 border-2 border-black dark:border-white bg-gray-100 dark:bg-gray-900">
            <UserInfoDisplay
              user={{
                artistName,
                isArtist,
                hasStudio,
                isSeller,
                artistLink,
                stripeDashLink,
              }}
            />
          </div>
          {displayStorage()}
        </div>

        {/* Payment Section */}
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">
            Payment & Subscription
          </h3>
          <PaymentProvider>
            <div className="space-y-6">
              <PaymentStatus />
              <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                <h4 className="text-base font-medium text-gray-900 dark:text-gray-100 mb-4">
                  Billing Profile
                </h4>
                <StripeProfileInfo />
              </div>
            </div>
          </PaymentProvider>
        </div>

        <div className="text-center space-y-4">
          <WideButton text={"Sign Out"} lambda={user.handleSignout} />
          <ContactEmail email="samir@dotmixtape.com" />
        </div>
      </div>
    </div>
  );
}

export default Settings;

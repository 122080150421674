import { useState, useCallback } from 'react';
import { merchService } from '../services/merch_service';

export const useMerch = (initialMerchItem = null) => {
  const [merchItem, setMerchItem] = useState(initialMerchItem);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createMerchItem = useCallback(async (merchData) => {
    setLoading(true);
    setError(null);
    try {
      const newItem = await merchService.createMerchItem(merchData);
      setMerchItem(newItem);
      return newItem;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateMerchItem = useCallback(async (itemId, updateData) => {
    setLoading(true);
    setError(null);
    try {
      const updatedItem = await merchService.updateMerchItem(itemId, updateData);
      setMerchItem(updatedItem);
      return updatedItem;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMerchItem = useCallback(async (itemId) => {
    setLoading(true);
    setError(null);
    try {
      const item = await merchService.getMerchItem(itemId);
      setMerchItem(item);
      return item;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const publishMerchItem = useCallback(async (itemId) => {
    setLoading(true);
    setError(null);
    try {
      await fetchMerchItem(itemId);
      const result = await merchService.publishMerchItem(itemId);
      setMerchItem(prev => ({ ...prev, published: true }));
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const unpublishMerchItem = useCallback(async (itemId) => {
    setLoading(true);
    setError(null);
    try {
      await fetchMerchItem(itemId);
      const result = await merchService.unpublishMerchItem(itemId);
      setMerchItem(prev => ({ ...prev, published: false }));
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const uploadMerchImage = useCallback(async (itemId, imageFile) => {
    setLoading(true);
    setError(null);
    try {
      await fetchMerchItem(itemId);
      const result = await merchService.uploadMerchImage(itemId, imageFile);
      setMerchItem(prev => ({ ...prev, imageUrl: result.imageUrl }));
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateVariations = useCallback(async (itemId, variations) => {
    setLoading(true);
    setError(null);
    try {
      await fetchMerchItem(itemId);
      const result = await merchService.updateVariations(itemId, variations);
      setMerchItem(prev => ({ ...prev, variations: result.variations }));
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const getMerchDrafts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await merchService.getMerchDrafts();
      return result;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    merchItem,
    loading,
    error,
    createMerchItem,
    updateMerchItem,
    fetchMerchItem,
    publishMerchItem,
    unpublishMerchItem,
    uploadMerchImage,
    updateVariations,
    getMerchDrafts,
  };
};

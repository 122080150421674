import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useCartService } from '../../../hooks/useCartService';
import { useCart } from '../../../context/CartContext';
import LoadingOverlay from '../../loading/LoadingOverlay';
import VariantSelector from '../../storefront/VariantSelector';
import APIConfig from '../../../APIConfig';
import useAuth from '../../../hooks/useAuth';

const ProductPage = () => {
  const { productId, artistSlug } = useParams();
  const location = useLocation();
  const { addItem } = useCartService();
  const { isItemLoading, addToLoadingItems, removeFromLoadingItems } = useCart();
  const auth = useAuth();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState({});

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const token = await auth?.user?.getIdToken();
        const headers = token ? { Authorization: `JWT ${token}` } : {};
        const response = await fetch(`${APIConfig.serverURI}merch/${productId}/`, {
          headers
        });
        if (!response.ok) {
          throw new Error('Product not found');
        }
        const data = await response.json();
        // Extract the merch_item data from the response
        const merchData = data.merch_item ? {
          ...data.merch_item,
          shipping_info: data.shipping_methods ? [data.shipping_methods] : []
        } : data;
        setProduct(merchData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId, auth?.user]);

  const isValidVariantSelected = useCallback((item) => {
    // If item has no options/variants, it's always valid
    if (!item.options?.length) {
      return true;
    }
    const itemVariant = selectedVariants[item.id];
    return itemVariant?.variant && itemVariant.variant.stock > 0;
  }, [selectedVariants]);

  const handleVariantSelect = useCallback((variant, selectedOptions) => {
    setSelectedVariants(prev => {
      const current = prev[product.id];
      if (current?.variant === variant && current?.selectedOptions === selectedOptions) {
        return prev;
      }
      return { ...prev, [product.id]: { variant, selectedOptions } };
    });
  }, [product]);

  const handleAddToCart = useCallback(async () => {
    if (!product) return;

    if (!auth?.user) {
      toast.error('Please log in to add items to your cart');
      return;
    }

    // If item has no options, we can add it directly
    if (!product.options?.length) {
      try {
        addToLoadingItems(product.id);
        await addItem(product.id);
        toast.success('Added to cart!');
      } catch (err) {
        toast.error(err.message || 'Failed to add item to cart');
      } finally {
        removeFromLoadingItems(product.id);
      }
      return;
    }

    // Otherwise check for valid variant selection
    const itemVariant = selectedVariants[product.id];
    if (!isValidVariantSelected(product)) {
      toast.error('Please select a valid option combination that is in stock');
      return;
    }

    try {
      addToLoadingItems(product.id);
      await addItem(product.id, itemVariant.variant.id);
      toast.success('Added to cart!');
    } catch (err) {
      toast.error(err.message || 'Failed to add item to cart');
    } finally {
      removeFromLoadingItems(product.id);
    }
  }, [product, addItem, selectedVariants, isValidVariantSelected, addToLoadingItems, removeFromLoadingItems, auth?.user]);

  if (loading) {
    return <div className="font-mono p-4">Loading...</div>;
  }

  if (error) {
    return (
      <div className="font-mono p-4">
        <div className="text-red-500 mb-4">Error: {error}</div>
        <Link to="/" className="underline">← Back to home</Link>
      </div>
    );
  }

  if (!product) {
    return <div className="font-mono p-4">Product not found</div>;
  }

  const isLoading = isItemLoading(product.id);
  const hasValidVariant = isValidVariantSelected(product);

  // Determine back link based on context
  const backLink = artistSlug ? `/a/${artistSlug}` : '/merch';
  const backText = artistSlug ? `← back to ${product.artist_name || 'artist'}` : '← back to merch';

  return (
    <div className="container mx-auto px-4 py-8">
      <Link 
        to={backLink}
        className="inline-block mb-8 hover:underline font-mono"
      >
        {backText}
      </Link>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
        {isLoading && <LoadingOverlay message="Adding to cart..." />}
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Image Section */}
          <div className="aspect-square">
            <img
              src={product.image_url}
              alt={product.name}
              className="w-full h-full object-cover"
            />
          </div>

          {/* Product Details Section */}
          <div className="p-6 space-y-6 font-mono">
            <div>
              <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
              {product.description && (
                <p className="text-gray-600 dark:text-gray-400">{product.description}</p>
              )}
            </div>

            <div className="text-2xl font-bold">
              ${product.price}
            </div>
            
            {product.options && product.options.length > 0 && (
              <div className="space-y-4">
                <VariantSelector
                  key={`${product.id}-variant-selector`}
                  options={product.options}
                  variants={product.variants}
                  onVariantSelect={handleVariantSelect}
                />
              </div>
            )}

            <button 
              onClick={handleAddToCart}
              disabled={product.options?.length > 0 && !hasValidVariant}
              className={`w-full p-4 rounded-md transition-colors ${
                product.options?.length > 0 && !hasValidVariant
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-black text-white hover:bg-gray-800'
              }`}
            >
              {auth?.user ? 'Add to Cart' : 'Log in to Add to Cart'}
            </button>

            {product.shipping_info && product.shipping_info.length > 0 && (
              <div className="border-t pt-6">
                <h2 className="text-lg font-bold mb-4">Shipping Information</h2>
                {product.shipping_info.map(method => (
                  <div key={method.id} className="space-y-2">
                    <h3 className="font-medium">{method.name}</h3>
                    <div className="space-y-1 text-sm text-gray-600">
                      {method.rates && method.rates.map(rate => (
                        <div key={rate.id} className="flex justify-between">
                          <span>{rate.zone_name}</span>
                          <span>
                            ${parseFloat(rate.single_unit).toFixed(2)}
                            {rate.additional_unit && 
                              ` (+$${parseFloat(rate.additional_unit).toFixed(2)} per additional item)`
                            }
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

import React from 'react';

const SkeletonItem = () => (
  <div className="animate-pulse">
    <div className="h-48 bg-gray-300 dark:bg-gray-700 rounded-lg mb-4"></div>
    <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4 mb-2"></div>
    <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-1/2"></div>
  </div>
);

const SkeletonSection = ({ count = 4 }) => (
  <div className="mb-8">
    <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-1/4 mb-4"></div>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {Array(count).fill().map((_, i) => (
        <SkeletonItem key={i} />
      ))}
    </div>
  </div>
);

const HomePageSkeleton = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Featured Albums Section */}
      <SkeletonSection count={4} />
      
      {/* New Releases Section */}
      <SkeletonSection count={4} />
      
      {/* Social Mixtapes Section */}
      <SkeletonSection count={4} />
      
      {/* Trending Mixtapes Section */}
      <SkeletonSection count={4} />
      
      {/* Suggested Profiles Section */}
      <div className="mb-8">
        <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-1/4 mb-4"></div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {Array(6).fill().map((_, i) => (
            <div key={i} className="animate-pulse">
              <div className="h-24 w-24 bg-gray-300 dark:bg-gray-700 rounded-full mx-auto mb-2"></div>
              <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4 mx-auto"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePageSkeleton;

import React, { useEffect, useCallback, useRef, useState } from 'react';
import { shippingService } from '../../services/shippingService';
import { useShippingInfo } from './hooks/useShippingInfo';
import { ShippingMethodForm } from './shipping/ShippingMethodForm';
import { ExistingMethodsDialog } from './shipping/ExistingMethodsDialog';
import { AddMethodModal } from './shipping/AddMethodModal';
import { SaveStatus } from './shipping/SaveStatus';
import { ShippingHeader } from './shipping/components';

export function ShippingEditor({ initialShippingInfo, onChange, onShippingChange, isSaving, onSave }) {
  const {
    shippingInfo,
    setShippingInfo,
    addMethod,
    removeMethod,
    updateMethod,
    addRate,
    updateRate,
    removeRate
  } = useShippingInfo(initialShippingInfo);

  const [loadingZones, setLoadingZones] = useState(true);
  const [shippingZones, setShippingZones] = useState([]);
  const [shippingTypes, setShippingTypes] = useState([]);
  const [existingMethods, setExistingMethods] = useState([]);
  const [showExistingMethodsDialog, setShowExistingMethodsDialog] = useState(false);
  const [saveStatus, setSaveStatus] = useState({ show: false, success: true, message: '' });
  const [showAddMethodModal, setShowAddMethodModal] = useState(false);
  const debounceTimer = useRef(null);
  const isInitialMount = useRef(true);
  const [removingMethods, setRemovingMethods] = useState(new Set());
  const [savingMethods, setSavingMethods] = useState(new Set());

  useEffect(() => {
    loadDefaultsAndZones();
    loadExistingMethods();
  }, []);

  const loadDefaultsAndZones = async () => {
    try {
      const defaults = await shippingService.getDefaultShipping();
      console.log('[ShippingEditor] Loaded defaults:', defaults);
      
      // Initialize with default zones if none are returned
      const defaultZones = [
        { id: 'us', name: 'United States' },
        { id: 'ca', name: 'Canada' },
        { id: 'intl', name: 'International' }
      ];
      
      setShippingZones(defaults.shipping_zones?.length ? defaults.shipping_zones : defaultZones);
      setShippingTypes(defaults.shipping_types || [
        { value: 'standard', label: 'Standard' },
        { value: 'international', label: 'International' },
        { value: 'free', label: 'Free Shipping' }
      ]);
      setLoadingZones(false);
    } catch (error) {
      console.error('[ShippingEditor] Failed to load defaults/zones:', error);
      // Initialize with default zones on error
      setShippingZones([
        { id: 'us', name: 'United States' },
        { id: 'ca', name: 'Canada' },
        { id: 'intl', name: 'International' }
      ]);
      setShippingTypes([
        { value: 'standard', label: 'Standard' },
        { value: 'international', label: 'International' },
        { value: 'free', label: 'Free Shipping' }
      ]);
      setLoadingZones(false);
    }
  };

  const loadExistingMethods = async () => {
    try {
      const response = await shippingService.getAllShippingMethods();
      setExistingMethods(response.methods);
    } catch (error) {
      console.error('Error loading existing methods:', error);
    }
  };

  const handleMethodChange = useCallback((methodId, updatedMethod) => {
    console.log('Updating method:', { methodId, updatedMethod });
    updateMethod(methodId, updatedMethod);
  }, [updateMethod]);

  const handleAddMethod = useCallback((shippingType) => {
    const typeLabel = shippingTypes.find(t => t.value === shippingType)?.label;
    addMethod({
      name: typeLabel || '',
      shipping_type: shippingType,
      rates: []
    });
    setShowAddMethodModal(false);
  }, [addMethod, shippingTypes]);

  const handleRemoveMethod = useCallback(async (methodId) => {
    try {
      if (!shippingInfo?.id) {
        console.error('No shipping info ID available');
        return;
      }
      
      // Set saving state for UI feedback
      setSavingMethods(prev => new Set([...prev, methodId]));
      
      // 1. Create new shippingInfo object without the removed method
      const updatedInfo = {
        ...shippingInfo,
        shipping_methods: shippingInfo.shipping_methods.filter(
          (m) => String(m.id) !== String(methodId)
        )
      };

      // 2. Update local state to reflect removal in UI
      setShippingInfo(updatedInfo);

      // 3. Save the updated info to persist changes
      const response = await shippingService.saveShippingInfo(updatedInfo);

      // 4. Update local state with server response to ensure consistency
      setShippingInfo(response);
      
      // Notify parent of changes
      onShippingChange?.();
      
      setSaveStatus({
        show: true,
        success: true,
        message: 'Method removed and changes saved successfully'
      });
    } catch (error) {
      console.error('Error removing method:', error);
      setSaveStatus({
        show: true,
        success: false,
        message: 'Failed to remove method'
      });
    } finally {
      // Clear saving state
      setSavingMethods(prev => {
        const next = new Set(prev);
        next.delete(methodId);
        return next;
      });
    }
  }, [shippingInfo, onShippingChange]);

  const handleAddRate = useCallback((methodId) => {
    // Find the method by ID
    const method = shippingInfo.shipping_methods.find(m => m.id === methodId);
    if (!method) {
      console.error('Method not found:', methodId);
      return;
    }
    
    console.log('Current method:', method);
    console.log('Available shipping zones:', shippingZones);
    
    const usedZones = new Set(
      method.rates
        .filter(rate => rate.destination_type === 'zone')
        .map(rate => rate.zone_id)
    );
    console.log('Used zones:', usedZones);

    // Define zone order priority
    const zoneOrder = {
      'us': 0,      // US first
      'ca': 1,      // Canada second
      'intl': 2     // International last
    };

    // Find first unused zone based on priority order
    const unusedZone = Object.entries(zoneOrder)
      .sort(([, orderA], [, orderB]) => orderA - orderB)
      .map(([zoneId]) => ({ id: zoneId }))  // Create a simple zone object with id
      .find(zone => !usedZones.has(zone.id));

    console.log('Found unused zone:', unusedZone);
    if (!unusedZone) {
      console.log('No unused zones available');
      return;
    }

    console.log('Adding rate with zone:', unusedZone);
    addRate(method.id, {
      destination_type: 'zone',
      zone_id: unusedZone.id,
      single_unit: '0.00',
      additional_unit: '0.00'
    });
  }, [shippingInfo, addRate, shippingZones]);

  const handleApplyExisting = async (methodId) => {
    try {
      let infoId = shippingInfo?.id;
      
      // If we don't have a shipping info ID, create one first
      if (!infoId) {
        console.log('Creating new shipping info with data:', {
          shipping_methods: [],
          shipping_zones: shippingZones,
          shipping_types: shippingTypes,
          merch_item: shippingInfo?.merch_item
        });
        
        const response = await shippingService.saveShippingInfo({
          shipping_methods: [],
          shipping_zones: shippingZones,
          shipping_types: shippingTypes,
          merch_item: shippingInfo?.merch_item
        });
        console.log('Created shipping info:', response);
        infoId = response.id;
        setShippingInfo(response);
      }

      // Now apply the existing method
      console.log('Applying method', methodId, 'to shipping info', infoId);
      const updatedInfo = await shippingService.applyExistingMethod(infoId, methodId);
      console.log('Method applied successfully:', updatedInfo);
      
      // Update the shipping info with the full updated data
      setShippingInfo(updatedInfo);
      
      // Notify parent component
      await onShippingChange?.();
      
      // Update UI
      setShowExistingMethodsDialog(false);
      setSaveStatus({
        show: true,
        success: true,
        message: 'Shipping method applied successfully'
      });
      
      // Refresh the shipping info to ensure we have the latest data
      const refreshedInfo = await shippingService.getShippingInfo(infoId);
      if (refreshedInfo) {
        setShippingInfo(refreshedInfo);
      }
    } catch (error) {
      console.error('Error applying existing method:', error.response?.data || error);
      setSaveStatus({
        show: true,
        success: false,
        message: error.response?.data?.detail || 'Failed to apply shipping method'
      });
    }
  };

  const handleDeleteExistingMethod = async (methodId) => {
    try {
      await shippingService.deleteShippingMethod(methodId);
      // Refresh the list of existing methods
      await loadExistingMethods();
      setSaveStatus({
        show: true,
        success: true,
        message: 'Shipping method deleted successfully'
      });
    } catch (error) {
      console.error('Error deleting shipping method:', error.response?.data || error);
      setSaveStatus({
        show: true,
        success: false,
        message: error.response?.data?.detail || 'Failed to delete shipping method'
      });
    }
  };

  const handleSave = async () => {
    try {
      setSaveStatus({ show: true, success: false, message: 'Saving...' });
      
      // Ensure each rate has a zone_id if it's a zone-based rate
      const sanitizedInfo = {
        ...shippingInfo,
        merch_item: shippingInfo.merch_item, // Make sure to include merch_item
        shipping_methods: shippingInfo.shipping_methods.map(method => ({
          ...method,
          rates: method.rates.map(rate => {
            // If it's a zone-based rate, ensure zone_id is present
            if (rate.destination_type === 'zone') {
              // Default to 'us' zone if no zone_id is set
              const zoneId = rate.zone_id || 'us';
              console.log('Using zone_id:', zoneId, 'for rate:', rate);
              return {
                ...rate,
                zone_id: zoneId
              };
            }
            return rate;
          })
        }))
      };

      console.log('Saving sanitized info:', sanitizedInfo);
      const response = await shippingService.saveShippingInfo(sanitizedInfo);
      console.log('Save response:', response);
      
      // Update the local state with the response data
      setShippingInfo(response);
      
      onSave?.();
      setSaveStatus({ show: true, success: true, message: 'Saved successfully!' });
    } catch (error) {
      console.error('Failed to save shipping info:', error);
      setSaveStatus({ show: true, success: false, message: 'Failed to save' });
    }
  };

  const handleCloseSnackbar = () => {
    setSaveStatus({ ...saveStatus, show: false });
  };

  const handleUseDefaults = async () => {
    try {
      setSaveStatus({ show: true, success: false, message: 'Loading defaults...' });
      const response = await shippingService.getDefaultShipping();
      
      // Check for either default_methods or shipping_methods in the response
      const defaultMethods = response.default_methods || response.shipping_methods;
      
      if (defaultMethods && defaultMethods.length > 0) {
        // Update shipping info while preserving other properties
        setShippingInfo(prevInfo => ({
          ...prevInfo,
          shipping_methods: defaultMethods.map(method => ({
            ...method,
            // Ensure each method has an id
            id: method.id || Math.random().toString(36).substr(2, 9)
          }))
        }));
        
        setSaveStatus({
          show: true,
          success: true,
          message: 'Default shipping methods applied'
        });
      } else {
        throw new Error('No default shipping methods found');
      }
    } catch (error) {
      console.error('Error loading default shipping methods:', error);
      setSaveStatus({
        show: true,
        success: false,
        message: 'Failed to load default shipping methods'
      });
    }
  };

  if (loadingZones) {
    return (
      <div className="flex justify-center items-center h-64 bg-white dark:bg-gray-900">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg border border-gray-200 dark:border-gray-800">
      <div className="p-8">
        {/* Header Section */}
        <ShippingHeader
          isSaving={isSaving || savingMethods.size > 0}
          loadingZones={loadingZones}
          onAddMethod={() => setShowAddMethodModal(true)}
          onUseExisting={() => setShowExistingMethodsDialog(true)}
          onUseDefaults={handleUseDefaults}
          onSave={handleSave}
        />

        {/* Content Section */}
        <div className="space-y-6">
          {(!shippingInfo?.shipping_methods || shippingInfo.shipping_methods.length === 0) ? (
            <div className="text-center py-16 px-4 rounded-xl bg-gray-50 dark:bg-gray-800 border-2 border-dashed border-gray-300 dark:border-gray-600">
              <svg className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
              </svg>
              <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-gray-100">
                No shipping methods
              </h3>
              <p className="mt-2 text-gray-500 dark:text-gray-400 max-w-sm mx-auto">
                Get started by adding a new shipping method or using an existing configuration.
              </p>
              <div className="mt-6 flex flex-col sm:flex-row gap-3 justify-center">
                <button
                  onClick={() => setShowAddMethodModal(true)}
                  disabled={isSaving}
                  className="inline-flex items-center justify-center px-4 py-2.5 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>
                  Add Your First Method
                </button>
                <button
                  onClick={handleUseDefaults}
                  disabled={isSaving}
                  className="inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-800 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 transition-colors"
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                  Use Default Configuration
                </button>
              </div>
            </div>
          ) : (
            <div className="grid gap-6">
              {shippingInfo.shipping_methods.map((method) => (
                <div key={method.id} className="bg-gray-50 dark:bg-gray-800 rounded-xl p-6">
                  <ShippingMethodForm
                    method={method}
                    onMethodChange={(updated) => handleMethodChange(method.id, updated)}
                    onAddRate={() => handleAddRate(method.id)}
                    onRemoveRate={(rateIndex) => removeRate(method.id, rateIndex)}
                    onRemoveMethod={() => handleRemoveMethod(method.id)}
                    disabled={isSaving}
                    removing={removingMethods.has(method.id)}
                    saving={savingMethods.has(method.id)}
                    shippingTypes={shippingTypes}
                    shippingZones={shippingZones}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Modals */}
        <ExistingMethodsDialog
          isOpen={showExistingMethodsDialog}
          onClose={() => setShowExistingMethodsDialog(false)}
          methods={existingMethods}
          onApplyMethod={handleApplyExisting}
          onDeleteMethod={handleDeleteExistingMethod}
          isLoading={loadingZones}
        />

        <AddMethodModal
          isOpen={showAddMethodModal}
          onClose={() => setShowAddMethodModal(false)}
          onAddMethod={handleAddMethod}
        />

        <SaveStatus
          show={saveStatus.show}
          success={saveStatus.success}
          message={saveStatus.message}
        />
      </div>
    </div>
  );
}
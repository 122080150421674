import React, { useContext, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { 
  CogIcon, 
  UserIcon, 
  ArrowLeftIcon, 
  BookOpenIcon, 
  TruckIcon, 
  ShoppingBagIcon,
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  ScaleIcon,
  CommandLineIcon,
  MusicalNoteIcon
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { AuthContext } from '../../../context/AuthContext';
import { useProfile } from '../../../components/account/profile/context/ProfileContext';

const MENU_SECTIONS = [
  {
    label: "Account",
    bgClass: "bg-gray-50 dark:bg-gray-900",
    items: [
      {
        to: "/settings",
        icon: <CogIcon className="h-5 w-5" />,
        label: "Settings"
      },
      {
        to: "/address-book",
        icon: <BookOpenIcon className="h-5 w-5" />,
        label: "Address Book"
      },
      {
        to: "/konsole",
        icon: <CommandLineIcon className="h-5 w-5" />,
        label: "Konsole"
      }
    ]
  },
  {
    label: "Music",
    bgClass: "bg-gray-50 dark:bg-gray-900",
    items: [
      {
        to: "/drafts",
        icon: <PencilSquareIcon className="h-5 w-5" />,
        label: "Drafts"
      },
      {
        to: "/mixtapes",
        icon: <MusicalNoteIcon className="h-5 w-5" />,
        label: "Mixtapes"
      }
    ]
  },
  {
    label: "Buying",
    bgClass: "bg-gray-50 dark:bg-gray-900",
    items: [
      {
        to: "/orders",
        icon: <ShoppingBagIcon className="h-5 w-5" />,
        label: "Orders"
      }
    ]
  },
  {
    label: "Selling",
    bgClass: "bg-gray-50 dark:bg-gray-900",
    sellerOnly: true,
    items: [
      {
        to: "/seller/orders",
        icon: <ClipboardDocumentListIcon className="h-5 w-5" />,
        label: "Orders"
      },
      {
        to: "/drafts/merch",
        icon: <PencilSquareIcon className="h-5 w-5" />,
        label: "Merch Drafts"
      },
    ]
  },
  {
    label: "Legal & Account",
    bgClass: "bg-gray-50 dark:bg-gray-900",
    items: [
      {
        to: "/legal",
        icon: <ScaleIcon className="h-5 w-5" />,
        label: "Legal"
      }
    ]
  }
];

const ProfileMenu = ({ user }) => {
  const { user: currentUser, handleSignout } = useContext(AuthContext);
  const { profileInfo } = useProfile();
  const isSeller = profileInfo?.is_seller;

  if (!currentUser) {
    return (
      <NavLink 
        to="/signin" 
        className="flex items-center space-x-2 border-2 border-black dark:border-white px-4 py-2 bg-gray-100 dark:bg-gray-900 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 font-mono font-bold shadow-none"
      >
        <UserIcon className="h-6 w-6" />
        <span className="text-sm">Sign In</span>
      </NavLink>
    );
  }

  // Helper to render a single menu item (NavLink or button)
  const renderMenuItem = (item, isButton = false) => {
    const baseClasses = "block px-5 py-3 text-sm font-bold text-black dark:text-white uppercase tracking-wide border-b-2 border-black dark:border-gray-700 flex items-center space-x-2";
    const activeClasses = "bg-gray-200 dark:bg-gray-800";

    if (isButton) {
      return (
        <Menu.Item key={item.label}>
          {({ active }) => (
            <button
              onClick={item.onClick}
              className={`${baseClasses} ${active ? activeClasses : ""} w-full text-left`}
            >
              {item.icon}
              <span>{item.label}</span>
            </button>
          )}
        </Menu.Item>
      );
    }

    return (
      <Menu.Item key={item.label}>
        {({ active }) => (
          <NavLink
            to={item.to}
            className={`${baseClasses} ${active ? activeClasses : ""}`}
          >
            {item.icon}
            <span>{item.label}</span>
          </NavLink>
        )}
      </Menu.Item>
    );
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex items-center space-x-2 border-2 border-black dark:border-white px-4 py-2 bg-gray-100 dark:bg-gray-900 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 font-mono font-bold shadow-none">
        <UserIcon className="h-6 w-6" />
        <span className="text-sm">{currentUser.displayName || currentUser.email || "Profile"}</span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-64 bg-white dark:bg-black border-2 border-black dark:border-white shadow-none z-50">
          <div className="py-1 text-left">
            {MENU_SECTIONS.map((section) => {
              // If section is seller-only and user is not a seller, skip it
              if (section.sellerOnly && !isSeller) return null;

              return (
                <div key={section.label}>
                  <div className={`px-5 py-2 text-xs font-bold text-gray-500 dark:text-gray-400 uppercase tracking-wider ${section.bgClass}`}>
                    {section.label}
                  </div>
                  {section.items.map((item) => renderMenuItem(item))}
                </div>
              );
            })}
            <div className="px-5 py-2 text-xs font-bold text-gray-500 dark:text-gray-400 uppercase tracking-wider bg-gray-50 dark:bg-gray-900">
              Session
            </div>
            {renderMenuItem({
              label: "Sign Out",
              icon: <ArrowLeftIcon className="h-5 w-5" />,
              onClick: handleSignout
            }, true)}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileMenu;

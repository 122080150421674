import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NoArtistsOnboarding = () => {
  const location = useLocation();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 dark:bg-gray-900 p-6">
      <div className="max-w-md w-full space-y-8 text-center">
        <div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
            Link Your Artists
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            To start creating drafts, you'll need to link at least one artist profile.
          </p>
        </div>
        
        <div className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="space-y-4">
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  Why link artist profiles?
                </h3>
                <ul className="mt-4 text-sm text-gray-600 dark:text-gray-400 text-left list-disc list-inside space-y-2">
                  <li>Create and manage releases for multiple artists</li>
                  <li>Switch between different artist accounts</li>
                  <li>Manage collaborations and features</li>
                  <li>Keep your music organized by artist</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <Link 
              to="/onboard/artists" 
              state={{ returnTo: location.pathname }}
              className="block"
            >
              <button className="w-full px-4 py-2 text-base font-medium text-white bg-black dark:bg-white dark:text-black rounded-md hover:bg-gray-800 dark:hover:bg-gray-200">
                Link Artists
              </button>
            </Link>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              You can link multiple artist profiles to your account
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoArtistsOnboarding;

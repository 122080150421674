import React, { useState, useEffect } from 'react';
import { AddressManager } from './AddressManager';
import { shippingService } from '../../services/shippingService';
import AddressService from '../../services/addressService';
import { useNavigate } from 'react-router-dom';
import { MapPinIcon, BookOpenIcon, BeakerIcon } from '@heroicons/react/24/outline';

const testAddress = {
  recipient_name: "Test User",
  nickname: "Test Address",
  address_line_1: "123 Test Street",
  address_line_2: "Apt 4B",
  city: "San Francisco",
  state: "CA",
  zip_code: "94105",
  country: "US",
  delivery_instructions: "Please leave at front door",
  is_default: false
};

const defaultCountryOptions = [
  { code: 'US', name: 'United States' },
  { code: 'CA', name: 'Canada' },
  { code: 'rest_of_world', name: 'International' },
];

const getShippingCountry = (address) => {
  if (!address) return 'US';
  if (address.country === 'US') return 'US';
  if (address.country === 'CA') return 'CA';
  return 'rest_of_world';
};

const formatShippingAmount = (amount) => {
  const dollars = amount / 100;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(dollars);
};

const ShippingSelector = ({
  onShippingMethodSelect,
  selectedShippingMethod,
  shippingMethods,
  selectedCountry = 'US',
  onCountrySelect = () => {},
  countryOptions = defaultCountryOptions,
  onShippingChange,
  cartItems = [],
}) => {
  const [showAddressManager, setShowAddressManager] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(selectedCountry);
  const navigate = useNavigate();

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    const shippingCountry = getShippingCountry(address);
    setCurrentCountry(shippingCountry);
    onCountrySelect(shippingCountry);
    fetchShippingRates(shippingCountry);
    setShowAddressManager(false);

    if (selectedShipping) {
      const selectedOption = shippingOptions.find(option => option.id === selectedShipping);
      if (selectedOption) {
        onShippingChange?.({
          ...selectedOption,
          address
        });
      }
    }
  };

  const handleFillTestAddress = async () => {
    try {
      setAddressLoading(true);
      const createdAddress = await AddressService.createShippingAddress(testAddress);
      handleAddressSelect(createdAddress);
    } catch (error) {
      console.error('Error creating test address:', error);
    } finally {
      setAddressLoading(false);
    }
  };

  const handleShippingMethodChange = (e) => {
    const selectedOption = shippingOptions.find(option => option.id === parseInt(e.target.value));
    if (selectedOption) {
      setSelectedShipping(selectedOption.id);
      onShippingMethodSelect?.(selectedOption);
      onShippingChange?.({
        ...selectedOption,
        amount: selectedOption.amount,
        address: selectedAddress
      });
    }
  };

  const fetchShippingRates = async (country) => {
    setLoading(true);
    try {
      const { shipping_options } = await shippingService.calculateCartShipping(cartItems, country);
      const formattedOptions = shipping_options?.map(option => ({
        ...option,
        amount: option.amount,
        formatted_amount: formatShippingAmount(option.amount)
      })) || [];
      
      setShippingOptions(formattedOptions);

      if (formattedOptions.length && !selectedShipping) {
        setSelectedShipping(formattedOptions[0].id);
        onShippingMethodSelect?.(formattedOptions[0]);
        onShippingChange?.({
          ...formattedOptions[0],
          address: selectedAddress
        });
      }
    } catch (error) {
      console.error('Error fetching shipping rates:', error);
      setShippingOptions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAddress) {
      fetchShippingRates(currentCountry);
    } else {
      setShippingOptions([]);
      setSelectedShipping(null);
    }
  }, [selectedAddress, currentCountry]);

  return (
    <div className="dark:text-gray-200">
      <h2 className="text-xl font-semibold mb-4 dark:text-gray-100">
        Shipping Information
      </h2>

      <div className="mb-3 flex space-x-2">
        <button
          onClick={() => setShowAddressManager(true)}
          className="flex items-center space-x-1 border border-gray-300 dark:border-gray-700 rounded px-3 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
          disabled={addressLoading}
        >
          <MapPinIcon className="h-4 w-4 dark:text-gray-200" />
          <span>{selectedAddress ? 'Change Address' : 'Select Address'}</span>
        </button>
        <button
          onClick={() => navigate('/address-book')}
          className="flex items-center space-x-1 border border-gray-300 dark:border-gray-700 rounded px-3 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <BookOpenIcon className="h-4 w-4 dark:text-gray-200" />
          <span>Address Book</span>
        </button>
        {process.env.NODE_ENV === 'development' && (
          <button
            onClick={handleFillTestAddress}
            className="flex items-center space-x-1 border border-gray-300 dark:border-gray-700 rounded px-3 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
            disabled={addressLoading}
          >
            {addressLoading ? (
              <div className="w-4 h-4 border-2 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
            ) : (
              <BeakerIcon className="h-4 w-4 dark:text-gray-200" />
            )}
            <span>Test Address</span>
          </button>
        )}
      </div>

      {selectedAddress ? (
        <div className="mb-3">
          <p className="text-sm font-medium dark:text-gray-100 mb-1">Shipping to:</p>
          <p className="text-sm font-semibold dark:text-gray-200">
            {selectedAddress.recipient_name}
          </p>
          <p className="text-sm dark:text-gray-300">
            {selectedAddress.address_line_1}{selectedAddress.address_line_2 ? `, ${selectedAddress.address_line_2}` : ''}
          </p>
          <p className="text-sm dark:text-gray-300">
            {selectedAddress.city}, {selectedAddress.state} {selectedAddress.zip_code}
          </p>
          <p className="text-sm dark:text-gray-300">
            {selectedAddress.country}
          </p>
          <button
            onClick={() => setShowAddressManager(true)}
            className="mt-2 text-sm text-blue-600 hover:underline focus:outline-none"
          >
            Change Address
          </button>
        </div>
      ) : (
        <div className="mb-3">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Please select a shipping address to continue
          </p>
        </div>
      )}

      <div className="flex items-center mb-4">
        <h2 className="text-xl font-semibold dark:text-gray-100">Shipping Method</h2>
        {loading && (
          <div className="ml-2 w-4 h-4 border-2 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
        )}
      </div>
      
      {!selectedAddress ? (
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Please select a shipping address to view available shipping methods
        </p>
      ) : loading ? (
        <p className="text-sm dark:text-gray-300">Loading shipping options...</p>
      ) : shippingOptions.length > 0 ? (
        <div className="space-y-2">
          {shippingOptions.map((method) => (
            <label key={method.id} className="flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name="shipping-method"
                value={method.id}
                checked={String(selectedShipping) === String(method.id)}
                onChange={handleShippingMethodChange}
                className="accent-blue-600 h-4 w-4"
              />
              <div className="flex flex-col">
                <span className="text-sm font-medium dark:text-gray-100">{method.name}</span>
                <span className="text-xs dark:text-gray-300">{method.formatted_amount}</span>
              </div>
            </label>
          ))}
        </div>
      ) : (
        <p className="text-sm text-gray-500 dark:text-gray-400">
          No shipping options available for this location
        </p>
      )}

      {showAddressManager && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-md w-full">
            <div className="border-b border-gray-200 dark:border-gray-700 px-4 py-3">
              <h3 className="text-lg font-semibold dark:text-gray-100">Select Shipping Address</h3>
            </div>
            <div className="p-4 dark:bg-gray-900">
              <AddressManager type="shipping" onAddressSelect={handleAddressSelect} />
            </div>
            <div className="px-4 py-3 flex justify-end border-t border-gray-200 dark:border-gray-700">
              <button
                onClick={() => setShowAddressManager(false)}
                className="text-sm text-gray-600 dark:text-gray-300 hover:underline focus:outline-none"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShippingSelector;
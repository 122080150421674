import React, { useState } from 'react';
import { ZoneOrCountrySelect } from './ZoneOrCountrySelect';

export function ShippingMethodForm({
  method,
  onMethodChange,
  onAddRate,
  onRemoveRate,
  onRemoveMethod,
  disabled = false,
  showRemove = true,
  saving = false,
  removing = false,
  shippingTypes = [],
  shippingZones = []
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTypeSelect, setShowTypeSelect] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const showTemporarySnackbar = (message) => {
    setSnackbarMessage(message);
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000); // Auto dismiss after 3 seconds
  };

  // Define zone order priority (lower number = higher priority)
  const zoneOrder = {
    'us': 0,      // US first
    'ca': 1,      // Canada second
    'intl': 2     // International last
  };

  // Sort rates based on zone order
  const sortedRates = [...method.rates].sort((a, b) => {
    const orderA = zoneOrder[a.zone_id] ?? 999;
    const orderB = zoneOrder[b.zone_id] ?? 999;
    return orderA - orderB;
  });

  // Check if all zones are used
  const usedZones = new Set(
    sortedRates
      .filter(rate => rate.destination_type === 'zone')
      .map(rate => rate.zone_id)
  );
  
  const allZonesUsed = usedZones.size >= shippingZones.length;

  const validateMethod = () => {
    if (method.shipping_type !== 'free' && (!method.rates || method.rates.length === 0)) {
      showTemporarySnackbar('At least one shipping rate is required for non-free shipping methods');
      return false;
    }
    return true;
  };

  const handleMethodFieldChange = (field, value) => {
    if (field === 'shipping_type' && value === 'free') {
      // Show confirmation dialog before switching to free shipping
      if (method.rates.length > 0) {
        if (window.confirm('Switching to free shipping will remove all existing shipping rates for this shipping method. Continue?')) {
          onMethodChange({
            ...method,
            [field]: value,
            rates: [] // Clear all rates when switching to free shipping
          });
          showTemporarySnackbar('All shipping rates have been removed');
        }
      } else {
        onMethodChange({
          ...method,
          [field]: value
        });
      }
    } else {
      // For non-free shipping types, validate rates
      if (field === 'shipping_type' && value !== 'free' && (!method.rates || method.rates.length === 0)) {
        showTemporarySnackbar('Please add at least one shipping rate');
      }
      const updatedMethod = {
        ...method,
        [field]: value
      };
      if (!validateMethod()) {
        showTemporarySnackbar('Please add at least one shipping rate');
      }
      onMethodChange(updatedMethod);
    }
  };

  const handleRateChange = (rateIndex, field, value) => {
    const newRates = [...sortedRates];
    if (field === null && typeof value === 'object') {
      // When updating the entire rate object, preserve zone_id
      newRates[rateIndex] = {
        ...newRates[rateIndex],  // Keep existing fields including zone_id
        ...value,                // Apply new values
        zone_id: value.zone_id || newRates[rateIndex].zone_id  // Ensure zone_id is preserved
      };
    } else {
      // When updating a single field, preserve all other fields
      newRates[rateIndex] = {
        ...newRates[rateIndex],
        [field]: value
      };
    }
    
    // Sort rates before updating
    const sortedNewRates = newRates.sort((a, b) => {
      const orderA = zoneOrder[a.zone_id] ?? 999;
      const orderB = zoneOrder[b.zone_id] ?? 999;
      return orderA - orderB;
    });
    
    // Log the rates before updating to verify zone_id is present
    console.log('Updating rates with:', sortedNewRates);
    onMethodChange({ ...method, rates: sortedNewRates });
  };

  const handleAddRateClick = () => {
    // Call the parent's onAddRate function
    onAddRate();
    // Expand the form to show the new rate
    setIsExpanded(true);
  };

  const handleRemoveRate = (index) => {
    const newRates = sortedRates.filter((_, i) => i !== index);
    onMethodChange({ ...method, rates: newRates });
  };

  return (
    <div className="bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm">
      {/* Header with optional saving spinner */}
      <div className="p-4 flex items-center justify-between">
        <div className="flex-1 flex items-center space-x-4">
          <input
            type="text"
            value={method.name}
            onChange={(e) => handleMethodFieldChange('name', e.target.value)}
            disabled={disabled}
            className="flex-1 bg-transparent border-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 text-gray-900 dark:text-gray-100 text-lg font-medium placeholder-gray-400"
            placeholder="Enter shipping method name"
          />
          {saving && (
            <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          )}
          {/* Shipping type badge with click-to-edit */}
          <div className="relative">
            <button
              onClick={() => !disabled && setShowTypeSelect(!showTypeSelect)}
              disabled={disabled}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 hover:bg-blue-200 dark:hover:bg-blue-800 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {shippingTypes.find(t => t.value === method.shipping_type)?.label || 'Unknown Type'}
            </button>

            {/* Dropdown menu */}
            {showTypeSelect && (
              <div className="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical">
                  {shippingTypes.map((type) => (
                    <button
                      key={type.value}
                      onClick={() => {
                        handleMethodFieldChange('shipping_type', type.value);
                        setShowTypeSelect(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      role="menuitem"
                    >
                      {type.label}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Only show chevron if not free shipping */}
        {method.shipping_type !== 'free' && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-4 p-1 rounded-full text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
            aria-label={isExpanded ? "Collapse shipping rates" : "Expand shipping rates"}
          >
            <svg
              className={`w-5 h-5 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        )}
      </div>

      {/* Rates caption - show for non-free shipping types */}
      {method.shipping_type !== 'free' && !isExpanded && (
        <div className="px-4 pb-4 text-sm text-gray-500 dark:text-gray-400">
          {sortedRates.length === 0 ? (
            "No shipping rates defined"
          ) : (
            `${sortedRates.length} shipping rate${sortedRates.length === 1 ? '' : 's'} defined`
          )}
        </div>
      )}

      {/* Rates section - only show if not free shipping */}
      {method.shipping_type !== 'free' && isExpanded && (
        <div className="border-t border-gray-200 dark:border-gray-700 p-4 space-y-4">
          {sortedRates.map((rate, index) => (
            <div key={index} className="flex items-center gap-4">
              <div className="flex-1">
                <ZoneOrCountrySelect
                  zones={shippingZones}
                  value={{ type: 'zone', zoneId: rate.zone_id }}
                  onChange={(dest) => handleRateChange(index, 'zone_id', dest.zoneId)}
                  existingRates={sortedRates.filter((_, i) => i !== index)}
                  disabled={disabled}
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500 dark:text-gray-400">$</span>
                <input
                  type="number"
                  value={rate.single_unit}
                  onChange={(e) => handleRateChange(index, 'single_unit', e.target.value)}
                  className="w-24 bg-transparent border border-gray-300 dark:border-gray-600 rounded-md px-2 py-1.5 text-sm"
                  min="0"
                  step="0.01"
                  disabled={disabled}
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500 dark:text-gray-400">$</span>
                <input
                  type="number"
                  value={rate.additional_unit}
                  onChange={(e) => handleRateChange(index, 'additional_unit', e.target.value)}
                  className="w-24 bg-transparent border border-gray-300 dark:border-gray-600 rounded-md px-2 py-1.5 text-sm"
                  min="0"
                  step="0.01"
                  disabled={disabled}
                />
              </div>
              <button
                onClick={() => handleRemoveRate(index)}
                disabled={disabled}
                className="text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 disabled:opacity-50 disabled:cursor-not-allowed p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
                title="Remove rate"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          ))}
          <div className="flex justify-end">
            <button
              onClick={handleAddRateClick}
              disabled={disabled || allZonesUsed}
              className="px-3 py-1.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Add Rate
            </button>
          </div>
        </div>
      )}
      {showRemove && (
        <div className="px-4 py-3 mt-2 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={onRemoveMethod}
            disabled={disabled || removing}
            className={`
              flex items-center gap-2 px-3 py-1.5 text-sm rounded-md
              focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2
              disabled:cursor-not-allowed
              transition-colors duration-200 ease-in-out
              ${removing 
                ? 'bg-red-50 dark:bg-red-900/20 text-red-400 dark:text-red-300'
                : 'text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20'
              }
            `}
          >
            {removing ? (
              <>
                <svg className="animate-spin h-4 w-4 text-red-500 dark:text-red-400" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>Removing...</span>
              </>
            ) : (
              <>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <span>Remove Method</span>
              </>
            )}
          </button>
        </div>
      )}
      {/* Snackbar */}
      {showSnackbar && (
        <div className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg transition-opacity duration-300">
          {snackbarMessage}
        </div>
      )}
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { usePlayer } from "../../contexts/PlayerContext";
import Track from '../shared/Track';
import { mapAlbumTrackToPlayerTrack } from '../../utils/trackUtils';

const formatDuration = (duration) => {
  // Return placeholder if duration is invalid
  if (!duration || isNaN(duration) || duration <= 0) {
    return '--:--';
  }
  
  try {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  } catch (error) {
    console.error('Error formatting duration:', error, 'Duration value:', duration);
    return '--:--';
  }
};

const AlbumView = ({ album, artistId, isExpanded, onToggle }) => {
  const navigate = useNavigate();
  const { currentTrack, isPlaying, play, pause } = usePlayer();

  const handleTrackClick = (track, album) => {
    const playerTrack = mapAlbumTrackToPlayerTrack(track, album);
    if (!playerTrack) return;

    const isCurrentTrack = currentTrack?.id === playerTrack.id;
    if (isPlaying && isCurrentTrack) {
      pause();
    } else {
      play(playerTrack);
    }
  };

  const handleAlbumClick = (e) => {
    // If clicking the toggle area, expand/collapse
    const toggleArea = e.target.closest('.toggle-area');
    if (toggleArea) {
      onToggle(e);
      return;
    }
    
    // Otherwise navigate to album page
    navigate(`/a/${artistId}/albums/${album.id}`);
  };

  return (
    <div className="border border-black dark:border-gray-700 bg-white dark:bg-gray-900">
      <button 
        onClick={handleAlbumClick}
        className="w-full text-left p-2 focus:outline-none"
      >
        <div className="relative">
          <img
            src={album.artwork_url || album.compressed_artwork_url}
            alt={album.name}
            className="w-full aspect-square object-cover"
            loading="lazy"
          />
          <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-10 transition-opacity" />
          <div 
            className="toggle-area absolute bottom-0 right-0 p-2 text-white opacity-0 group-hover:opacity-100 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onToggle(e);
            }}
          >
            {isExpanded ? '[-]' : '[+]'}
          </div>
        </div>
        <div className="mt-2 font-mono dark:text-white">{album.name}</div>
      </button>
      
      {isExpanded && album.tracks && (
        <div className="border-t border-black dark:border-gray-700">
          <div className="p-2 space-y-2">
            {album.tracks.map((track, index) => (
              <div 
                key={track.id} 
                className={`
                  font-mono flex justify-between items-center 
                  hover:bg-gray-50 dark:hover:bg-gray-800 p-1 cursor-pointer
                  ${currentTrack?.id === track.id ? 'bg-gray-100 dark:bg-gray-800' : ''}
                `}
                onClick={() => handleTrackClick(track, album)}
              >
                <div className="flex items-center">
                  <span className="mr-4">{index + 1}.</span>
                  <span>{track.name}</span>
                </div>
                <span className="text-sm">
                  {formatDuration(track.duration)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

AlbumView.propTypes = {
  album: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    artwork_url: PropTypes.string,
    compressed_artwork_url: PropTypes.string,
    release_date: PropTypes.string,
    tracks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      duration: PropTypes.number,
    })),
  }).isRequired,
  artistId: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default AlbumView;
import React, { useState, useEffect } from 'react';
import { eventService } from '../../services/eventService';

export default function VenueSelector({ value, onChange }) {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNewVenueForm, setShowNewVenueForm] = useState(false);
  const [newVenue, setNewVenue] = useState({
    name: '',
    description: '',
    venue_type: 'HOUSE',
    address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
      informal_location: '',
      secret: false
    }
  });

  useEffect(() => {
    loadVenues();
  }, []);

  const loadVenues = async () => {
    try {
      setLoading(true);
      const data = await eventService.getVenues();
      setVenues(data);
      setError(null);
    } catch (error) {
      console.error('Error loading venues:', error);
      setError('Failed to load venues. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateVenue = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const createdVenue = await eventService.createVenue(newVenue);
      setVenues([...venues, createdVenue]);
      onChange(createdVenue.id);
      setShowNewVenueForm(false);
      setNewVenue({
        name: '',
        description: '',
        venue_type: 'HOUSE',
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          state: '',
          postal_code: '',
          country: 'US',
          informal_location: '',
          secret: false
        }
      });
    } catch (error) {
      console.error('Error creating venue:', error);
      setError('Failed to create venue. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="animate-pulse">Loading venues...</div>;
  }

  if (error) {
    return <div className="text-red-600">{error}</div>;
  }

  if (showNewVenueForm) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">New Venue</h3>
          <button
            type="button"
            onClick={() => setShowNewVenueForm(false)}
            className="text-sm text-gray-600 hover:text-gray-500"
          >
            Cancel
          </button>
        </div>

        <form onSubmit={handleCreateVenue} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Venue Name
            </label>
            <input
              type="text"
              value={newVenue.name}
              onChange={(e) => setNewVenue({ ...newVenue, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Venue Type
            </label>
            <select
              value={newVenue.venue_type}
              onChange={(e) => setNewVenue({ ...newVenue, venue_type: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="HOUSE">House</option>
              <option value="BAR">Bar</option>
              <option value="CONCERT_HALL">Concert Hall</option>
              <option value="OTHER">Other</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Description
            </label>
            <textarea
              value={newVenue.description}
              onChange={(e) => setNewVenue({ ...newVenue, description: e.target.value })}
              rows={2}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Address Line 1
              </label>
              <input
                type="text"
                value={newVenue.address.address_line_1}
                onChange={(e) => setNewVenue({
                  ...newVenue,
                  address: { ...newVenue.address, address_line_1: e.target.value }
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Address Line 2
              </label>
              <input
                type="text"
                value={newVenue.address.address_line_2}
                onChange={(e) => setNewVenue({
                  ...newVenue,
                  address: { ...newVenue.address, address_line_2: e.target.value }
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                City
              </label>
              <input
                type="text"
                value={newVenue.address.city}
                onChange={(e) => setNewVenue({
                  ...newVenue,
                  address: { ...newVenue.address, city: e.target.value }
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                State
              </label>
              <input
                type="text"
                value={newVenue.address.state}
                onChange={(e) => setNewVenue({
                  ...newVenue,
                  address: { ...newVenue.address, state: e.target.value }
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Postal Code
              </label>
              <input
                type="text"
                value={newVenue.address.postal_code}
                onChange={(e) => setNewVenue({
                  ...newVenue,
                  address: { ...newVenue.address, postal_code: e.target.value }
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Informal Location
              </label>
              <input
                type="text"
                value={newVenue.address.informal_location}
                onChange={(e) => setNewVenue({
                  ...newVenue,
                  address: { ...newVenue.address, informal_location: e.target.value }
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g., Behind the red door"
              />
            </div>
          </div>

          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={newVenue.address.secret}
              onChange={(e) => setNewVenue({
                ...newVenue,
                address: { ...newVenue.address, secret: e.target.checked }
              })}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700 dark:text-gray-300">Secret Location</span>
          </label>

          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Create Venue
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
        Venue
      </label>
      <div className="flex gap-2">
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        >
          <option value="">Select a venue</option>
          {venues.map((venue) => (
            <option key={venue.id} value={venue.id}>
              {venue.name}
            </option>
          ))}
        </select>
        <button
          type="button"
          onClick={() => setShowNewVenueForm(true)}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
        >
          New Venue
        </button>
      </div>
    </div>
  );
}

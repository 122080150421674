import React, { createContext, useContext } from 'react';
import useUserProfile from '../hooks/useUserProfile';

const UserProfileContext = createContext(null);

export const UserProfileProvider = ({ children }) => {
  const userProfileData = useUserProfile();
  
  return (
    <UserProfileContext.Provider value={userProfileData}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfileContext = () => {
  const context = useContext(UserProfileContext);
  if (!context) {
    throw new Error('useUserProfileContext must be used within a UserProfileProvider');
  }
  return context;
};

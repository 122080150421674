import React, { createContext, useContext, useReducer, useEffect } from 'react';
import CartService from '../services/cartService';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CART':
      return {
        ...state,
        items: action.payload.items,
        totalItems: action.payload.total_items,
        subtotal: action.payload.subtotal,
        loading: false
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };

    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, {
    items: [],
    totalItems: 0,
    subtotal: 0,
    loading: true,
    error: null
  });

  const fetchCart = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const cart = await CartService.getCurrentCart();
      dispatch({ type: 'SET_CART', payload: cart });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  useEffect(() => {
    fetchCart();
  }, []);

  const addItem = async (merchItemId, variantId, quantity = 1) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      await CartService.addItem(merchItemId, variantId, quantity);
      await fetchCart(); // Refresh cart after adding item
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const removeItem = async (itemId) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      await CartService.removeItem(itemId);
      await fetchCart(); // Refresh cart after removing item
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const updateQuantity = async (itemId, quantity) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      await CartService.updateQuantity(itemId, quantity);
      await fetchCart(); // Refresh cart after updating quantity
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const clearCart = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      await CartService.clearCart();
      await fetchCart(); // Refresh cart after clearing
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const value = {
    items: state.items,
    totalItems: state.totalItems,
    subtotal: state.subtotal,
    loading: state.loading,
    error: state.error,
    addItem,
    removeItem,
    updateQuantity,
    clearCart
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

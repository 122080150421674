import { useState, useEffect, useContext, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

const useAlbumDraftCover = (album) => {
  const [user] = useAuthState(firebase.auth());
  const [albumCover, setAlbumCover] = useState({ url: '', loading: true, error: null });

  const fetchAlbumCover = useCallback(() => {
    if (!user || !album?.docId) {
      console.log('No user or album provided. Setting default state.');
      setAlbumCover({ url: '', loading: false, error: null });
      return;
    }

    console.log(`Fetching album cover for ${album.docId}`);
    
    // Get the cover URL directly from the album document
    const db = firebase.firestore();
    const albumRef = db
      .collection(`studio/${user.uid}/drafts`)
      .doc(album.docId);

    const unsubscribe = albumRef.onSnapshot(doc => {
      if (!doc.exists) {
        console.log('Album document not found');
        setAlbumCover({ url: '', loading: false, error: null });
        return;
      }

      const data = doc.data();
      if (data?.cover_art_url) {
        console.log('Found cover URL in album document:', data.cover_art_url);
        setAlbumCover({ url: data.cover_art_url, loading: false, error: null });
      } else {
        console.log('No cover URL found in album document');
        setAlbumCover({ url: '', loading: false, error: null });
      }
    }, error => {
      console.error('Error fetching album cover:', error);
      setAlbumCover({ url: '', loading: false, error });
    });

    return () => {
      console.log('Unsubscribing from album cover updates');
      unsubscribe();
    };
  }, [user, album]);

  useEffect(() => {
    const cleanup = fetchAlbumCover();
    return () => cleanup && cleanup();
  }, [fetchAlbumCover]);

  return { albumCoverUrl: albumCover.url, coverLoading: albumCover.loading, error: albumCover.error, refetch: fetchAlbumCover };
};

export default useAlbumDraftCover;

import axios from 'axios';
import firebase from 'firebase/compat/app';
import APIConfig from '../APIConfig';

class MerchService {
  constructor() {
    this.baseURL = APIConfig.baseURL();
    this.apiPath = '/api/v1';
    this.api = axios.create({
      baseURL: `${this.baseURL}${this.apiPath}`,
    });
  }

  async getAuthHeaders() {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();
      return {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      };
    }
    throw new Error('User not authenticated');
  }

  async createMerchItem(merchData) {
    try {
      const headers = await this.getAuthHeaders();
      console.log('Creating merch item with data:', merchData);

      const formData = new FormData();
      formData.append('name', merchData.name);
      formData.append('price', merchData.price);
      formData.append('description', merchData.description);
      formData.append('published', merchData.published || false);

      // Add options and variants as JSON strings
      if (merchData.options) {
        formData.append('options', JSON.stringify(merchData.options));
      }
      if (merchData.variants) {
        formData.append('variants', JSON.stringify(merchData.variants));
      }

      const response = await this.api.post(
        `/merch/`,
        formData,
        { 
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error creating merch item:', error.response?.data || error);
      throw error;
    }
  }

  async getMerchItem(itemId) {
    try {
      const headers = await this.getAuthHeaders();
      const response = await this.api.get(
        `/merch/${itemId}/`,
        { headers }
      );
      
      const data = response.data;

      // Format images consistently
      if (data.images) {
        data.images = data.images.map(img => ({
          id: img.id,
          url: img.url || img.image,
          order: img.order
        })).sort((a, b) => a.order - b.order);

        // Set image_url to the first image for thumbnails
        if (data.images.length > 0) {
          data.image_url = data.images[0].url;
        }
      } else {
        data.images = [];
      }

      console.log('Formatted merch item data:', data);
      return data;
    } catch (error) {
      console.error('Error getting merch item:', error.response?.data || error);
      throw error;
    }
  }

  async updateMerchItem(id, data) {
    try {
      const headers = await this.getAuthHeaders();
      console.log('Updating merch item with data:', data);

      // Don't use FormData for updates that include shipping info
      if (data.shipping_info) {
        console.log('Sending shipping info update as JSON');
        const updateData = { ...data };
        const response = await this.api.patch(
          `/merch/${id}/`,
          updateData,
          {
            headers: {
              ...headers,
              'Content-Type': 'application/json'
            }
          }
        );

        return response.data;
      }

      // Use FormData for updates without shipping info (e.g., image uploads)
      const formData = new FormData();
      const updateData = { ...data };

      // Handle all fields including options and variants
      for (const key of Object.keys(updateData)) {
        if (updateData[key] !== undefined && updateData[key] !== null) {
          if (key === 'options' || key === 'variants') {
            formData.append(key, JSON.stringify(updateData[key]));
          } else {
            formData.append(key, updateData[key]);
          }
        }
      }

      const response = await this.api.patch(
        `/merch/${id}/`,
        formData,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error updating merch item:', error.response?.data || error);
      throw error;
    }
  }

  async publishMerchItem(itemId) {
    try {
      const headers = await this.getAuthHeaders();
      const response = await this.api.post(
        `/merch/save_product/`,
        {
          id: itemId,
          fs_uid: firebase.auth().currentUser.uid,
          published: true,
        },
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error('Error publishing merch item:', error);
      throw error;
    }
  }

  async unpublishMerchItem(itemId) {
    try {
      const headers = await this.getAuthHeaders();
      const response = await this.api.post(
        `/merch/save_product/`,
        {
          id: itemId,
          fs_uid: firebase.auth().currentUser.uid,
          published: false,
        },
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error('Error unpublishing merch item:', error);
      throw error;
    }
  }

  async uploadImages(itemId, formData) {
    const response = await this.api.post(`/merch/${itemId}/upload_images/`, formData);
    return response.data;
  }

  async deleteImage(itemId, imageId) {
    await this.api.delete(`/merch/${itemId}/delete_image/?image_id=${imageId}`);
  }

  async reorderImages(itemId, imageOrders) {
    const response = await this.api.post(`/merch/${itemId}/reorder_images/`, {
      image_orders: imageOrders
    });
    return response.data;
  }

  async getMerchDrafts() {
    try {
      const headers = await this.getAuthHeaders();
      const response = await this.api.get(
        `/merch/drafts/`,
        { headers }
      );

      // Format the data for frontend use
      const formattedData = response.data.map(item => {
        // Format images consistently
        if (item.images) {
          item.images = item.images.map(img => ({
            id: img.id,
            url: img.url || img.image,
            order: img.order
          })).sort((a, b) => a.order - b.order);

          // Set image_url to the first image for thumbnails
          if (item.images.length > 0) {
            item.image_url = item.images[0].url;
          }
        } else {
          item.images = [];
        }

        return item;
      });

      console.log('Formatted merch drafts:', formattedData);
      return formattedData;
    } catch (error) {
      console.error('Error fetching merch drafts:', error.response?.data || error);
      throw error;
    }
  }

  async listMerchItems() {
    try {
      const headers = await this.getAuthHeaders();
      const response = await this.api.get(
        `/merch/list/`,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error('Error listing merch items:', error);
      throw error;
    }
  }
}

export const merchService = new MerchService();

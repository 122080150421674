import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import APIConfig from '../APIConfig';


class ImageService {
  constructor() {
    this.baseURL = APIConfig.baseURL();
    this.apiPath = '/api/v1';
  }

  async getAuthHeaders() {
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new Error('User not authenticated');
    }
    const token = await user.getIdToken();
    return {
      'Authorization': `JWT ${token}` // Using JWT prefix as per DRF_FIREBASE_AUTH settings
    };
  }

  validateImageSize(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        URL.revokeObjectURL(img.src); // Clean up
        const valid = (
          img.height >= 128 &&
          img.height <= 8030 &&
          img.width >= 128 &&
          img.width <= 8030
        );
        resolve({
          valid,
          dimensions: { width: img.width, height: img.height }
        });
      };

      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        reject(new Error('Failed to load image'));
      };
    });
  }

  async validateAndOptimizeImage(file) {
    try {
      const { valid, dimensions } = await this.validateImageSize(file);
      if (!valid) {
        throw new Error(`Image dimensions must be between 128x128 and 8030x8030. Current: ${dimensions.width}x${dimensions.height}`);
      }

      // For now, return the original file
      // TODO: Add image optimization if needed
      return file;
    } catch (error) {
      console.error('Image validation failed:', error);
      throw error;
    }
  }

  async upload(itemId, files) {
    console.log('ImageService: Starting image upload', { itemId });
    
    try {
      const headers = await this.getAuthHeaders();
      const formData = new FormData();
      
      // Handle both single file and FileList/Array
      const fileArray = files instanceof FileList || Array.isArray(files) 
        ? Array.from(files) 
        : [files];

      // Validate and optimize all images before uploading
      const validatedFiles = await Promise.all(
        fileArray.map(file => this.validateAndOptimizeImage(file))
      );

      // Add all validated files to formData with unique keys
      validatedFiles.forEach((file, index) => {
        console.log('ImageService: Adding file to formData:', { name: file.name, size: file.size });
        formData.append(`image${index}`, file);
      });

      // Note: Don't set Content-Type header when using FormData
      const response = await fetch(`${this.baseURL}${this.apiPath}/merch/${itemId}/upload_images/`, {
        method: 'POST',
        headers: headers, // Only send auth header
        body: formData
      });

      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || `Upload failed with status ${response.status}`;
        } catch (e) {
          errorMessage = `Upload failed with status ${response.status}`;
        }
        throw new Error(errorMessage);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('ImageService: Upload failed:', error);
      throw error;
    }
  }

  async delete(itemId, imageId) {
    console.log('ImageService: Deleting image', { itemId, imageId });
    
    try {
      const headers = await this.getAuthHeaders();
      const response = await fetch(
        `${this.baseURL}${this.apiPath}/merch/${itemId}/delete_image/?image_id=${imageId}`,
        {
          method: 'DELETE',
          headers: headers
        }
      );

      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || `Delete failed with status ${response.status}`;
        } catch (e) {
          errorMessage = `Delete failed with status ${response.status}`;
        }
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('ImageService: Delete failed', error);
      throw error;
    }
  }

  async reorder(itemId, imageOrders) {
    try {
      const headers = await this.getAuthHeaders();
      const response = await fetch(
        `${this.baseURL}${this.apiPath}/merch/${itemId}/reorder_images/`,
        {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ image_orders: imageOrders.map(({ id, order }) => ({ id, order })) })
        }
      );

      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || `Reorder failed with status ${response.status}`;
        } catch (e) {
          errorMessage = `Reorder failed with status ${response.status}`;
        }
        throw new Error(errorMessage);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('ImageService: Reorder failed', error);
      throw error;
    }
  }
}

export const imageService = new ImageService();

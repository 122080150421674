import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMerch } from '../../../hooks/useMerch';
import { useUserProfileContext } from '../../../context/UserProfileContext';
import { TruckIcon } from '@heroicons/react/24/outline';

const LoadingSkeleton = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {[1, 2, 3].map((n) => (
      <div key={n} className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg">
        <div className="animate-pulse">
          <div className="aspect-w-16 aspect-h-9 bg-gray-300 dark:bg-gray-700" />
          <div className="p-4">
            <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4 mb-4" />
            <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-1/2 mb-4" />
            <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-1/4" />
          </div>
        </div>
      </div>
    ))}
  </div>
);

const MerchDraftsView = () => {
  const navigate = useNavigate();
  const { isSeller, loading: profileLoading } = useUserProfileContext();
  const { getMerchDrafts, loading: merchLoading, error } = useMerch();
  const [drafts, setDrafts] = useState([]);

  // Handle seller check in an effect
  useEffect(() => {
    if (!profileLoading && !isSeller) {
      navigate('/');
    }
  }, [isSeller, profileLoading, navigate]);

  // Fetch drafts in a separate effect
  useEffect(() => {
    const fetchDrafts = async () => {
      if (!profileLoading && isSeller) {
        try {
          const data = await getMerchDrafts();
          setDrafts(data);
        } catch (err) {
          console.error('Error fetching drafts:', err);
        }
      }
    };

    fetchDrafts();
  }, [getMerchDrafts, profileLoading, isSeller]);

  if (profileLoading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <div className="h-8 bg-gray-300 dark:bg-gray-700 rounded w-48 animate-pulse" />
            <div className="flex space-x-4">
              <div className="h-10 bg-gray-300 dark:bg-gray-700 rounded w-32 animate-pulse" />
              <div className="h-10 bg-gray-300 dark:bg-gray-700 rounded w-24 animate-pulse" />
            </div>
          </div>
          <LoadingSkeleton />
        </div>
      </div>
    );
  }

  // Don't render anything if not a seller
  if (!isSeller) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Merch Drafts</h1>
          <div className="flex space-x-4">
            <button
              onClick={() => navigate(`/shipping-manager/${drafts[0]?.id}`)}
              disabled={drafts.length === 0}
              className={`flex items-center px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors ${
                drafts.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <TruckIcon className="h-5 w-5 mr-2" />
              Shipping Manager
            </button>
            <button
              onClick={() => navigate('/create/merch')}
              className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
            >
              Create New
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-500 text-white p-4 rounded mb-4">
            {error.message || 'An error occurred'}
          </div>
        )}

        {merchLoading ? (
          <LoadingSkeleton />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {drafts.map((draft) => (
              <div
                key={draft.id}
                className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors relative"
                onClick={() => navigate(`/merch/edit/${draft.id}`)}
              >
                {/* Published status badge */}
                <div className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-medium ${
                  draft.published 
                    ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' 
                    : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
                }`}>
                  {draft.published ? 'Published' : 'Draft'}
                </div>
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={draft.image_url || '/placeholder-image.jpg'}
                    alt={draft.name}
                    className="object-cover w-full h-full"
                    loading="lazy"
                  />
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{draft.name}</h3>
                  <p className="text-gray-600 dark:text-gray-400 mb-2">{draft.description}</p>
                  <p className="text-lg font-bold">${draft.price.toFixed(2)}</p>
                </div>
              </div>
            ))}

            {drafts.length === 0 && !merchLoading && (
              <div className="col-span-full text-center py-12">
                <p className="text-gray-500 dark:text-gray-400">No merch drafts found. Create your first one!</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MerchDraftsView;

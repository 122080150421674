import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlayer } from '../../contexts/PlayerContext';
import APIConfig from '../../APIConfig';
import useAuth from '../../hooks/useAuth';
import GenreFilter from './GenreFilter';

// Reusable Components
const LoadingIndicator = () => (
  <div className="w-full h-full flex items-center justify-center">
    <div className="animate-pulse space-y-4 w-full">
      <div className="h-12 bg-gray-200 dark:bg-gray-800 rounded w-full" />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {Array(10).fill(null).map((_, i) => (
          <div key={i} className="space-y-2">
            <div className="aspect-square bg-gray-200 dark:bg-gray-800 rounded" />
            <div className="h-4 bg-gray-200 dark:bg-gray-800 rounded w-3/4" />
            <div className="h-3 bg-gray-200 dark:bg-gray-800 rounded w-1/2" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

const TrackList = ({ tracks, onTrackClick, currentTrack, isPlaying }) => {
  if (!tracks?.length) return null;

  return (
    <div className="space-y-2">
      {tracks.map(track => {
        const isCurrent = currentTrack?.id === track.id;
        return (
          <div
            key={track.id}
            onClick={() => onTrackClick(track)}
            className="
              p-4 border-2 border-black dark:border-white
              cursor-pointer
              hover:bg-black hover:text-white
              dark:hover:bg-white dark:hover:text-black
              transition-colors
              flex items-center justify-between
            "
          >
            <div>
              <div className="text-sm font-bold">{track.name}</div>
              <div className="text-xs">
                {track.artist_name} • {track.album_name || 'Single'}
              </div>
            </div>
            {isCurrent && (
              <div className="text-sm">
                {isPlaying ? '▶️' : '⏸️'}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const ResultsSection = ({ title, items, renderItem }) => (
  <div>
    <h2 className="text-xl border-b-2 border-black dark:border-white mb-2 font-bold">
      {title}
    </h2>
    <div className="space-y-2">
      {items.map(item => (
        <div key={item.id}>{renderItem(item)}</div>
      ))}
    </div>
  </div>
);

export default function SearchPage() {
  const navigate = useNavigate();
  const { play, pause, isPlaying, currentTrack } = usePlayer();
  const { user } = useAuth();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState({
    profiles: [],
    mixtapes: [],
    artists: [],
    albums: [],
    tracks: [],
    merch: []
  });
  const [isSearching, setIsSearching] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [availableGenres, setAvailableGenres] = useState([]);
  const [genreTracks, setGenreTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGenre, setIsLoadingGenre] = useState(false);
  const [error, setError] = useState(null);

  // Fetch genres only when filters are shown
  useEffect(() => {
    if (!showFilters) return;

    const fetchGenres = async () => {
      try {
        const headers = {};
        if (user) {
          const token = await user.getIdToken();
          headers.Authorization = `JWT ${token}`;
        }

        const resp = await fetch(
          `${APIConfig.baseURL()}/api/v1/music/genres/?limit=1000`,
          { headers }
        );

        if (!resp.ok) {
          throw new Error('Failed to fetch genres');
        }

        const data = await resp.json();
        const genreNames = data.results.map(genre => genre.name);
        setAvailableGenres(genreNames || []);
      } catch (err) {
        console.error('Failed to fetch genres:', err);
      }
    };

    fetchGenres();
  }, [user, showFilters]);

  // Handle genre selection
  const handleGenreSelect = async (genre) => {
    setSelectedGenre(genre);
    if (!genre) {
      setGenreTracks([]);
      return;
    }
    
    try {
      setIsLoadingGenre(true);
      const headers = {};
      if (user) {
        const token = await user.getIdToken();
        headers.Authorization = `JWT ${token}`;
      }

      const resp = await fetch(
        `${APIConfig.baseURL()}/api/v1/music/tracks/?genre=${genre}&limit=20&sort=genre_weight`,
        { headers }
      );

      if (!resp.ok) {
        throw new Error('Failed to fetch genre tracks');
      }

      const data = await resp.json();
      setGenreTracks(data.results || []);
    } catch (err) {
      console.error('Failed to fetch genre tracks:', err);
    } finally {
      setIsLoadingGenre(false);
    }
  };

  // Debounced search
  useEffect(() => {
    const searchTimer = setTimeout(() => {
      if (!searchTerm.trim()) {
        setSearchResults({
          profiles: [],
          mixtapes: [],
          artists: [],
          albums: [],
          tracks: [],
          merch: []
        });
        return;
      }

      const doSearch = async () => {
        try {
          setIsLoading(true);
          setError(null);

          const headers = {};
          if (user) {
            const token = await user.getIdToken();
            headers.Authorization = `JWT ${token}`;
          }

          const queryParams = new URLSearchParams({
            q: searchTerm,
            ...(selectedGenre && { genre: selectedGenre })
          });

          const resp = await fetch(
            `${APIConfig.baseURL()}/api/v1/search/?${queryParams}`,
            { headers }
          );

          if (!resp.ok) {
            throw new Error('Search failed');
          }

          const data = await resp.json();
          setSearchResults(data);
        } catch (err) {
          setError(err.message);
          console.error('Search error:', err);
        } finally {
          setIsLoading(false);
        }
      };

      doSearch();
    }, 300);

    return () => clearTimeout(searchTimer);
  }, [searchTerm, user, selectedGenre]);

  const handleTrackClick = (track) => {
    if (!track.file && !track.compressed_file) {
      console.error('No playable URL found for track:', track);
      return;
    }

    const playerTrack = {
      id: track.id,
      name: track.name,
      artist: track.artist_name || track.artist || 'Unknown Artist',
      album: track.album_name || track.album?.name || 'Unknown Album',
      duration: track.duration || 0,
      compressedUrl: track.compressed_file || track.compressed_url,
      losslessUrl: track.file || track.url,
      artwork_url: track.artwork_url || track.album?.artwork_url,
      compressed_artwork_url: track.compressed_artwork_url || track.album?.compressed_artwork_url,
    };

    const isCurrentTrack = currentTrack?.id === playerTrack.id;
    if (isPlaying && isCurrentTrack) {
      pause();
    } else {
      play(playerTrack);
    }
  };

  const handleResultSelect = (result) => {
    if (!result) return;

    if ('username' in result && result.username) {
      navigate(`/u/${result.username}`);
    } else if ('creator_username' in result && result.id) {
      navigate(`/mixtapes/${result.id}`);
    } else if ('albums' in result && result.slug) {
      navigate(`/a/${result.slug}`);
    } else if ('file' in result || 'compressed_file' in result) {
      handleTrackClick(result);
    } else if ('seller' in result) {
      navigate(`/merch/${result.id}`);
    } else if (result.id && (result.artist_id || result.artist)) {
      const artistSlug = result.artist?.slug || result.artist_slug;
      if (!artistSlug) {
        console.error('No artist slug found for album:', result);
        return;
      }
      navigate(`/a/${artistSlug}/albums/${result.id}`);
    }
  };

  const renderSearchResult = (result) => {
    const rowClass = "p-4 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer flex items-center justify-between";

    if ('username' in result && result.username) {
      return (
        <div onClick={() => handleResultSelect(result)} className={rowClass}>
          <div className="flex-1">
            <div className="text-base font-bold">{result.username}</div>
            <div className="text-xs text-gray-600 dark:text-gray-400">User</div>
          </div>
        </div>
      );
    }

    if ('creator_username' in result && result.id) {
      return (
        <div onClick={() => handleResultSelect(result)} className={rowClass}>
          <div className="flex-1">
            <div className="text-base font-bold">{result.name}</div>
            <div className="text-xs text-gray-600 dark:text-gray-400">
              Mixtape • {result.creator_username}
            </div>
          </div>
        </div>
      );
    }

    if ('albums' in result && result.slug) {
      return (
        <div onClick={() => handleResultSelect(result)} className={rowClass}>
          <div className="flex-1">
            <div className="text-base font-bold">{result.name}</div>
            <div className="text-xs text-gray-600 dark:text-gray-400">Artist</div>
          </div>
        </div>
      );
    }

    if ('file' in result || 'compressed_file' in result) {
      return (
        <div onClick={() => handleTrackClick(result)} className={rowClass}>
          <div className="flex-1">
            <div className="text-base font-bold">{result.name}</div>
            <div className="text-xs text-gray-600 dark:text-gray-400">
              {result.artist_name || result.artist} • {result.album_name || result.album?.name || 'Single'}
            </div>
          </div>
          {currentTrack?.id === result.id && (
            <div className="text-xs text-gray-600 dark:text-gray-400 ml-2">
              {isPlaying ? '▶️' : '⏸️'}
            </div>
          )}
        </div>
      );
    }

    if ('seller' in result) {
      return (
        <div onClick={() => handleResultSelect(result)} className={rowClass}>
          <div className="flex-1">
            <div className="text-base font-bold">{result.name}</div>
            <div className="text-xs text-gray-600 dark:text-gray-400">
              ${result.price.toFixed(2)} • by {result.seller_username}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full min-h-screen font-theFutureMonoRegular">
      <div className="container mx-auto px-4 py-8">
        {/* Search input */}
        <div className="mb-8">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="profiles, mixtapes, artists, albums, tracks, merch..."
              className="
                w-full
                border-2 border-black dark:border-white
                px-4 py-3
                bg-transparent
                focus:outline-none
                font-theFutureMonoRegular
                placeholder-gray-500 dark:placeholder-gray-400
              "
            />
          </div>
        </div>

        {/* Toggle Filters Button */}
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="
            mb-4
            border-2 border-black dark:border-white
            px-4 py-2
            text-sm
            font-theFutureMonoRegular
            bg-gray-100 dark:bg-neutral-900
            hover:bg-black hover:text-white
            dark:hover:bg-white dark:hover:text-black
            transition-colors
          "
        >
          {showFilters ? '[ Hide Filters ]' : '[ Show Filters ]'}
        </button>

        {/* Genre Filters - Only show when toggled */}
        {showFilters && (
          <div className="mb-8">
            <GenreFilter
              genres={availableGenres}
              selectedGenre={selectedGenre}
              onGenreSelect={handleGenreSelect}
            />
          </div>
        )}

        {/* Search Results */}
        {searchTerm && (
          <div className="space-y-8">
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                {/* Profiles */}
                {searchResults.profiles.length > 0 && (
                  <ResultsSection
                    title="Profiles"
                    items={searchResults.profiles}
                    renderItem={renderSearchResult}
                  />
                )}

                {/* Mixtapes */}
                {searchResults.mixtapes.length > 0 && (
                  <ResultsSection
                    title="Mixtapes"
                    items={searchResults.mixtapes}
                    renderItem={renderSearchResult}
                  />
                )}

                {/* Artists */}
                {searchResults.artists.length > 0 && (
                  <ResultsSection
                    title="Artists"
                    items={searchResults.artists}
                    renderItem={renderSearchResult}
                  />
                )}

                {/* Tracks */}
                {searchResults.tracks.length > 0 && (
                  <ResultsSection
                    title="Tracks"
                    items={searchResults.tracks}
                    renderItem={renderSearchResult}
                  />
                )}

                {/* Merch */}
                {searchResults.merch.length > 0 && (
                  <ResultsSection
                    title="Merch"
                    items={searchResults.merch}
                    renderItem={renderSearchResult}
                  />
                )}

                {/* No results */}
                {Object.values(searchResults).every(arr => arr.length === 0) && searchTerm && (
                  <div className="text-center text-gray-500 py-8">
                    No results found
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* Genre Tracks - Only show when genre is selected and filters are visible */}
        {showFilters && selectedGenre && (
          <div>
            {isLoadingGenre ? (
              <LoadingIndicator />
            ) : genreTracks.length > 0 ? (
              <div>
                <h2 className="text-xl font-bold mb-4">
                  {selectedGenre.split('---').pop() || selectedGenre} Tracks
                </h2>
                <TrackList
                  tracks={genreTracks}
                  onTrackClick={handleTrackClick}
                  currentTrack={currentTrack}
                  isPlaying={isPlaying}
                />
              </div>
            ) : (
              <div className="text-center py-8">
                No tracks found for this genre
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

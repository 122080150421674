import React, { useState, useEffect } from 'react';
import APIConfig from '../../APIConfig';
import MerchItemView from '../artist_profiles/merch_item/MerchItemView';
import useAuth from '../../hooks/useAuth';
import merch from '../../services/merch'; // Assuming merch is imported from here

const MerchFeed = () => {
  const auth = useAuth();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const fetchMerch = async (pageNum) => {
    try {
      setLoading(true);
      const data = await merch.getMerchFeed(pageNum);
      console.log('Raw merch data:', data);
      
      // Each result already contains a merch_item property
      const merchItems = data.results;
      console.log('Processed merch items:', merchItems);
      
      if (pageNum === 1) {
        setItems(merchItems);
      } else {
        setItems(prev => [...prev, ...merchItems]);
      }
      
      setHasMore(!!data.next);
      setError(null);
    } catch (err) {
      console.error('Error fetching merch:', err);
      setError(err.message || 'Error loading merch');
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchMerch(1);
  }, [auth?.user]); // Re-fetch when auth changes

  const loadMore = () => {
    if (!loadingMore && hasMore) {
      setLoadingMore(true);
      setPage(prev => prev + 1);
      fetchMerch(page + 1);
    }
  };

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error) {
    return <div className="p-4">{error}</div>;
  }

  console.log('Rendering items:', items);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">All Merch</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {items && items.length > 0 ? (
          items.map((item) => (
            <MerchItemView key={item.id} item={item} />
          ))
        ) : (
          <div>No merch items available</div>
        )}
      </div>
      {hasMore && (
        <button
          onClick={loadMore}
          disabled={loadingMore}
          className="mt-4 px-4 py-2 bg-black text-white font-mono disabled:opacity-50"
        >
          {loadingMore ? 'Loading more...' : 'Load More'}
        </button>
      )}
    </div>
  );
};

export default MerchFeed;

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { BugReport as TestIcon } from '@material-ui/icons';

const addressValidationSchema = Yup.object({
  recipient_name: Yup.string().required('Recipient name is required'),
  address_line_1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required').length(2, 'Use 2-letter state code'),
  zip_code: Yup.string()
    .required('ZIP code is required')
    .matches(/^\d{5}$/, 'ZIP code must be 5 digits'),
  country: Yup.string().required('Country is required'),
});

const defaultValues = {
  recipient_name: '',
  nickname: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_code: '',
  country: 'US',
  delivery_instructions: '',
  is_default: false,
};

const testAddress = {
  recipient_name: "Test User",
  nickname: "Test Home",
  address_line_1: "789 Desert Lane",
  address_line_2: "Unit 42",
  city: "Las Vegas",
  state: "NV",
  zip_code: "89101",
  country: "US",
  delivery_instructions: "Ring doorbell twice",
  is_default: false
};

export const AddressForm = ({
  initialValues,
  onSubmit,
  onCancel,
  type = 'shipping',
}) => {
  const formik = useFormik({
    initialValues: initialValues || defaultValues,
    validationSchema: addressValidationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        {process.env.NODE_ENV === 'development' && (
          <Tooltip title="Fill with test address">
            <IconButton
              onClick={() => formik.setValues({
                ...testAddress,
                ...(type !== 'shipping' && { 
                  nickname: undefined,
                  delivery_instructions: undefined
                })
              })}
              size="small"
            >
              <TestIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="recipient_name"
            name="recipient_name"
            label="Recipient Name"
            value={formik.values.recipient_name}
            onChange={formik.handleChange}
            error={formik.touched.recipient_name && Boolean(formik.errors.recipient_name)}
            helperText={formik.touched.recipient_name && formik.errors.recipient_name}
          />
        </Grid>

        {type === 'shipping' && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="nickname"
              name="nickname"
              label="Address Nickname (optional)"
              value={formik.values.nickname}
              onChange={formik.handleChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="address_line_1"
            name="address_line_1"
            label="Address Line 1"
            value={formik.values.address_line_1}
            onChange={formik.handleChange}
            error={formik.touched.address_line_1 && Boolean(formik.errors.address_line_1)}
            helperText={formik.touched.address_line_1 && formik.errors.address_line_1}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="address_line_2"
            name="address_line_2"
            label="Address Line 2 (optional)"
            value={formik.values.address_line_2}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="city"
            name="city"
            label="City"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            fullWidth
            id="state"
            name="state"
            label="State"
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            fullWidth
            id="zip_code"
            name="zip_code"
            label="ZIP Code"
            value={formik.values.zip_code}
            onChange={formik.handleChange}
            error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
            helperText={formik.touched.zip_code && formik.errors.zip_code}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="country"
            name="country"
            label="Country"
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
        </Grid>

        {type === 'shipping' && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="delivery_instructions"
              name="delivery_instructions"
              label="Delivery Instructions (optional)"
              multiline
              rows={2}
              value={formik.values.delivery_instructions}
              onChange={formik.handleChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="is_default"
                checked={formik.values.is_default}
                onChange={formik.handleChange}
                color="primary"
              />
            }
            label="Set as default address"
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            {onCancel && (
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
            >
              Save Address
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

import React, { useState } from 'react';
import TrackList from './TrackList';

const AlbumList = ({ albums = [] }) => {
  const [expandedAlbums, setExpandedAlbums] = useState({});

  const toggleExpand = (albumId) => {
    setExpandedAlbums(prevExpandedAlbums => ({
      ...prevExpandedAlbums,
      [albumId]: !prevExpandedAlbums[albumId]
    }));
  };

  if (!albums?.length) {
    return (
      <div className="text-gray-400 p-4">
        No albums found
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {albums.map((album) => (
        <div key={album.id} className="rounded-md overflow-hidden">
          <div className="flex items-center group hover:bg-gray-700 p-2 transition-colors duration-200">
            <button 
              className="mr-2 w-6 h-6 flex items-center justify-center rounded hover:bg-gray-600 text-gray-400 hover:text-gray-200 transition-colors duration-200"
              onClick={() => toggleExpand(album.id)}
            >
              {expandedAlbums[album.id] ? '−' : '+'}
            </button>
            <span className="text-gray-200 group-hover:text-white">{album.name}</span>
          </div>
          {expandedAlbums[album.id] && (
            <div className="ml-4 mt-1 border-l-2 border-gray-700">
              <TrackList tracks={album.tracks} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AlbumList;

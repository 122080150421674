import React, { useEffect, useState } from "react";
import merch from "../../services/merch";
import MerchItemView from "./merch_item/MerchItemView";

const PublicMerchListView = ({ slug, initialItems = [] }) => {
  const [data, setData] = useState(initialItems);
  const [loading, setLoading] = useState(!initialItems.length);
  const [hasMerch, setHasMerch] = useState(initialItems.length > 0);

  useEffect(() => {
    // Update data when initialItems changes
    if (initialItems.length > 0) {
      setData(initialItems);
      setHasMerch(true);
      setLoading(false);
    }
  }, [initialItems]);

  // Only fetch if we don't have initialItems
  useEffect(() => {
    if (!initialItems.length && slug) {
      queryMerch();
    }
  }, [slug, initialItems]);

  async function queryMerch() {
    try {
      setLoading(true);
      const response = await merch.getMerch(slug);
      // Handle both array of items and object with items property
      const items = Array.isArray(response) ? response : response.items || [];
      // Map items to ensure consistent structure
      const processedItems = items.map(item => item.merch_item || item);
      setData(processedItems);
      setHasMerch(processedItems.length > 0);
    } catch (error) {
      console.error("Error fetching merch:", error);
      setHasMerch(false);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <div className="font-mono">Loading merch...</div>;
  }

  if (!hasMerch) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
      {data.map((item) => (
        <MerchItemView key={item.id} item={item} />
      ))}
    </div>
  );
};

export default PublicMerchListView;

import React, { useState, useEffect } from 'react';
import { eventService } from '../../services/eventService';
import VenueSelector from './VenueSelector';

export default function ShowForm({ onSubmit, loading }) {
  const [formData, setFormData] = useState({
    name: '',
    date: '',
    time: '',
    description: '',
    venue: '',
    is_private: false,
    is_published: false,
    cover_image: null,
    artists: [],
    ticket_prices: []
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [ticketPrices, setTicketPrices] = useState([{ name: '', price: '' }]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'file') {
      setFormData(prev => ({
        ...prev,
        [name]: files[0]
      }));
      setImagePreview(URL.createObjectURL(files[0]));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleTicketPriceChange = (index, field, value) => {
    const newTicketPrices = [...ticketPrices];
    newTicketPrices[index] = {
      ...newTicketPrices[index],
      [field]: value
    };
    setTicketPrices(newTicketPrices);
  };

  const addTicketPrice = () => {
    setTicketPrices([...ticketPrices, { name: '', price: '' }]);
  };

  const removeTicketPrice = (index) => {
    setTicketPrices(ticketPrices.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = {
      ...formData,
      ticket_prices: ticketPrices.filter(tp => tp.name && tp.price)
    };
    onSubmit(submitData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Event Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Date
            </label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Time
            </label>
            <input
              type="time"
              name="time"
              value={formData.time}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <VenueSelector
            value={formData.venue}
            onChange={(venue) => setFormData(prev => ({ ...prev, venue }))}
          />
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Cover Image
            </label>
            <input
              type="file"
              name="cover_image"
              onChange={handleChange}
              accept="image/*"
              className="mt-1 block w-full"
            />
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Cover preview"
                className="mt-2 h-32 w-full object-cover rounded-md"
              />
            )}
          </div>

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Ticket Prices
              </label>
              <button
                type="button"
                onClick={addTicketPrice}
                className="text-sm text-blue-600 hover:text-blue-500"
              >
                + Add Price
              </button>
            </div>
            {ticketPrices.map((price, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  value={price.name}
                  onChange={(e) => handleTicketPriceChange(index, 'name', e.target.value)}
                  placeholder="Name (e.g., Early Bird)"
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                <input
                  type="number"
                  value={price.price}
                  onChange={(e) => handleTicketPriceChange(index, 'price', e.target.value)}
                  placeholder="Price"
                  step="0.01"
                  className="w-24 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeTicketPrice(index)}
                    className="text-red-600 hover:text-red-500"
                  >
                    ×
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            name="is_private"
            checked={formData.is_private}
            onChange={handleChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="text-sm text-gray-700 dark:text-gray-300">Private Event</span>
        </label>

        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            name="is_published"
            checked={formData.is_published}
            onChange={handleChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="text-sm text-gray-700 dark:text-gray-300">Publish Event</span>
        </label>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={loading}
          className={`px-4 py-2 rounded-md text-white ${
            loading
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
              Creating...
            </div>
          ) : (
            'Create Show'
          )}
        </button>
      </div>
    </form>
  );
}

import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const usePastUploads = (userId) => {
  const [pastUploads, setPastUploads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPastUploads = async () => {
      if (!userId) return;
      
      try {
        setLoading(true);
        const db = firebase.firestore();
        
        // First get all draft albums
        const draftsRef = db
          .collection('studio')
          .doc(userId)
          .collection('drafts');
        
        const draftsSnapshot = await draftsRef.get();
        
        // For each draft, get its images subcollection
        const imagePromises = draftsSnapshot.docs.map(async (draftDoc) => {
          const imagesRef = draftsRef
            .doc(draftDoc.id)
            .collection('images')
            .orderBy('last_updated', 'desc')
            .limit(5); // Get last 5 images from each draft
            
          const imagesSnapshot = await imagesRef.get();
          
          return imagesSnapshot.docs.map(doc => {
            const data = doc.data();
            const draftData = draftDoc.data();
            return {
              id: doc.id,
              url: data.public_url,
              createdAt: data.last_updated?.toDate() || new Date(),
              title: draftData.title || 'Untitled Album'
            };
          });
        });
        
        // Wait for all image queries to complete
        const allImages = await Promise.all(imagePromises);
        
        // Flatten the array of arrays and sort by date
        const uploads = allImages
          .flat()
          .filter(upload => upload.url)
          .sort((a, b) => b.createdAt - a.createdAt)
          .slice(0, 12); // Keep only the 12 most recent images

        console.log('Found past uploads:', uploads);
        setPastUploads(uploads);
      } catch (err) {
        console.error('Error fetching past uploads:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPastUploads();
  }, [userId]);

  return { pastUploads, loading, error };
};

export default usePastUploads;

import React, { useState, useContext, useCallback, useRef, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from '@heroicons/react/24/outline';
import drafting from '../../../services/drafting';
import TaskActions from "./TaskActions";
import TrackNameEditor from "./TrackNameEditor";
import useTrackEditor from "./useTrackEditor";
import TrackArtistEditor from "./TrackArtistEditor";
import { usePlayer } from '../../../contexts/PlayerContext';
import InferTrackNameButton from "./InferTrackNameButton.jsx";

const TrackObjectCellContent = ({
  task,
  isEditing,
  startEditing,
  saveTrackName,
  cancelEditing,
  inferredTrackName,
}) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [shouldMarquee, setShouldMarquee] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && textRef.current) {
        const shouldScroll = textRef.current.scrollWidth > containerRef.current.clientWidth;
        setShouldMarquee(shouldScroll);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [inferredTrackName, task.trackName]);

  if (isEditing) {
    return (
      <TrackNameEditor
        trackName={inferredTrackName || task.trackName}
        onSave={saveTrackName}
        onCancel={cancelEditing}
      />
    );
  }

  const marqueeStyle = shouldMarquee && isHovered ? {
    animation: 'marquee 10s linear infinite',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    paddingRight: '50px', // Space between the end and start during loop
  } : {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <div className="cursor-pointer" onClick={startEditing}>
      <div
        ref={containerRef}
        className="overflow-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ position: 'relative' }}
      >
        <p 
          ref={textRef}
          className="font-theFutureBold text-gray-100 text-lg"
          style={marqueeStyle}
        >
          {inferredTrackName || task.trackName}
        </p>
      </div>
      <p className="text-gray-400 text-sm py-0.5">{task.content}</p>
      <style jsx>{`
        @keyframes marquee {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

const TrackObjectCell = ({
  task,
  index,
  album,
  column_id,
  styleVariant = "default",
  data,
  setData
}) => {
  const { isEditing, startEditing, cancelEditing, saveTrackName } = useTrackEditor(task, album, index);
  const [editMode, setEditMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentTrack, isPlaying, play, pause } = usePlayer();

  const isCurrentTrack = currentTrack?.id === task.id;

  const handlePlayPause = useCallback((event) => {
    event.stopPropagation();
    
    // Create track object for current track
    const track = {
      id: task.id,
      name: task.trackName,
      artist: album?.artist_name || 'Unknown Artist',
      album: album?.name || 'Unknown Album',
      duration: task.duration || 0,
      compressedUrl: task.compressedUrl || task.url,
      losslessUrl: task.url,
      artwork_url: album?.artwork_url,
      compressed_artwork_url: album?.compressed_artwork_url,
    };

    // Get all tracks from the draft's columns
    let allTracks = [];
    
    // Get all tasks from the first column (where tracks are stored)
    if (data?.columnOrder?.length > 1) {
      const draftColumnId = data.columnOrder[1];  // Get the second column (index 1)
      const draftColumn = data.columns[draftColumnId];
      if (draftColumn?.taskIds) {
        allTracks = draftColumn.taskIds.map(taskId => {
          const draftTask = data.tasks[taskId];
          return {
            id: draftTask.id,
            name: draftTask.trackName,
            artist: album?.artist_name || 'Unknown Artist',
            album: album?.name || 'Unknown Album',
            duration: draftTask.duration || 0,
            compressedUrl: draftTask.compressedUrl || draftTask.url,
            losslessUrl: draftTask.url,
            artwork_url: album?.artwork_url,
            compressed_artwork_url: album?.compressed_artwork_url,
          };
        });
      }
    }

    console.log('Draft tracks:', allTracks);
    console.log('Current track:', track);
    console.log('Draft data:', data);

    if (isPlaying && isCurrentTrack) {
      pause();
    } else {
      play(track, allTracks.length > 0 ? allTracks : [track]);
    }
  }, [task, album, data, isPlaying, isCurrentTrack, play, pause]);

  const handleDelete = useCallback(async () => {
    if (!album || !task.id) return;
    
    setIsDeleting(true);
    try {
      // Delete track from Firestore
      await drafting.manageTrack(album, task, "delete");

      // Update local state
      const firstColumnId = data.columnOrder[1];
      const column = data.columns[firstColumnId];
      
      // Remove track ID from column's taskIds
      const newTaskIds = column.taskIds.filter(id => id !== task.id);
      const updatedColumn = {
        ...column,
        taskIds: newTaskIds
      };

      // Remove track from tasks and update column
      const newTasks = { ...data.tasks };
      delete newTasks[task.id];

      const newData = {
        ...data,
        tasks: newTasks,
        columns: {
          ...data.columns,
          [firstColumnId]: updatedColumn
        }
      };

      setData(newData);
    } catch (error) {
      console.error('Error deleting track:', error);
    } finally {
      setIsDeleting(false);
      setEditMode(false);
    }
  }, [album, task, data, setData]);

  const draggableCellStyle = `
    bg-gray-800 
    rounded-lg 
    p-4 
    mb-2 
    border 
    ${styleVariant === "compact" ? "py-2" : ""}
  `;

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`${draggableCellStyle} ${
            snapshot.isDragging ? "border-blue-500" : "border-gray-700"
          }`}
        >
          {/* Top Section: Track Name and Player Controls */}
          <div className="flex items-start justify-between mb-3 space-x-4">
            <div className="flex-grow min-w-0">
              <TrackObjectCellContent
                task={task}
                isEditing={isEditing}
                startEditing={startEditing}
                saveTrackName={saveTrackName}
                cancelEditing={cancelEditing}
                inferredTrackName={task.inferredTrackName}
              />
            </div>

            {task.url && (
              <button
                disabled={isDeleting}
                onClick={handlePlayPause}
                className="bg-blue-600 hover:bg-blue-500 text-white rounded-full p-2 flex-shrink-0"
              >
                {isCurrentTrack && isPlaying ? (
                  <PauseIcon className="h-5 w-5" />
                ) : (
                  <PlayIcon className="h-5 w-5" />
                )}
              </button>
            )}
          </div>

          {/* Middle Section: Artist Editor and Infer Name */}
          <div className="flex items-center justify-between mt-2">
            <TrackArtistEditor track={task} album={album} />
            <InferTrackNameButton task={task} album={album} index={index} />
          </div>

          {/* Bottom Section: Edit Mode Controls */}
          <div className="mt-4 flex items-center space-x-2">
            <button
              onClick={handleDelete}
              className="text-red-500 hover:text-red-400 transition-colors"
              disabled={isDeleting}
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(TrackObjectCell);
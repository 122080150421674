// Feature flag configuration
import APIConfig from '../APIConfig';

// Global test mode - set to true to enable all test features
export const TEST_MODE = false;  // Set to true for development/testing

// Feature flags that are only enabled in test mode
export const testOnlyFeatures = {
  ENABLE_TEST_SHIPPING: { enabled: true, testOnly: true },
  ENABLE_TEST_PAYMENTS: { enabled: true, testOnly: true },
  ENABLE_TEST_WEBHOOKS: { enabled: true, testOnly: true }
};

// Feature flags that are always available
export const features = {
  ENABLE_MERCH: { enabled: true },
  ENABLE_SHIPPING: { enabled: true },
  ENABLE_NOTIFICATIONS: { enabled: true }
};

// Helper function to check if we're in test mode
export function isTestMode() {
  return TEST_MODE;
}

// Helper function to check if a feature is enabled
export function isFeatureEnabled(featureKey) {
  if (testOnlyFeatures[featureKey]) {
    return TEST_MODE && testOnlyFeatures[featureKey].enabled;
  }
  return features[featureKey]?.enabled || false;
}

// useAudioControls.js
import { useState, useEffect } from "react";

export const useAudioControls = (wavesurferObj) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [zoom, setZoom] = useState(1);
  

  useEffect(() => {
    if (wavesurferObj) {
      wavesurferObj.on("play", () => {
        setPlaying(true);
      });

      wavesurferObj.on("finish", () => {
        setPlaying(false);
      });
    }
  }, [wavesurferObj]);
  const handleTrim = () => {
    if (wavesurferObj) {
      // get start and end points of the selected region
      const region =
        wavesurferObj.regions.list[Object.keys(wavesurferObj.regions.list)[0]];

      if (region) {
        const start = region.start;
        const end = region.end;

        // obtain the original array of the audio
        const original_buffer = wavesurferObj.backend.buffer;

        // create a temporary new buffer array with the same length, sample rate and no of channels as the original audio
        const new_buffer = wavesurferObj.backend.ac.createBuffer(
          original_buffer.numberOfChannels,
          original_buffer.length,
          original_buffer.sampleRate
        );

        // create 2 indices:
        // left & right to the part to be trimmed
        const first_list_index = start * original_buffer.sampleRate;
        const second_list_index = end * original_buffer.sampleRate;
        const second_list_mem_alloc =
          original_buffer.length - end * original_buffer.sampleRate;

        // create a new array upto the region to be trimmed
        const new_list = new Float32Array(parseInt(first_list_index));

        // create a new array of region after the trimmed region
        const second_list = new Float32Array(parseInt(second_list_mem_alloc));

        // create an array to combine the 2 parts
        const combined = new Float32Array(original_buffer.length);

        // 2 channels: 1-right, 0-left
        // copy the buffer values for the 2 regions from the original buffer

        // for the region to the left of the trimmed section
        original_buffer.copyFromChannel(new_list, 1);
        original_buffer.copyFromChannel(new_list, 0);

        // for the region to the right of the trimmed section
        original_buffer.copyFromChannel(second_list, 1, second_list_index);
        original_buffer.copyFromChannel(second_list, 0, second_list_index);

        // create the combined buffer for the trimmed audio
        combined.set(new_list);
        combined.set(second_list, first_list_index);

        // copy the combined array to the new_buffer
        new_buffer.copyToChannel(combined, 1);
        new_buffer.copyToChannel(combined, 0);

        // load the new_buffer, to restart the wavesurfer's waveform display
        wavesurferObj.loadDecodedBuffer(new_buffer);
      }
    }
  };
  const handlePlayPause = () => {
    if (wavesurferObj) {
      wavesurferObj.playPause();
      setPlaying(!playing);
    }
  };

  const handleReload = () => {
    if (wavesurferObj) {
      wavesurferObj.stop();
      wavesurferObj.play();
      setPlaying(true);
    }
  };

  const handleVolumeSlider = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (wavesurferObj) {
      wavesurferObj.setVolume(newVolume);
    }
  };

  const handleZoomSlider = (e) => {
    const newZoom = parseFloat(e.target.value);
    setZoom(newZoom);
    if (wavesurferObj) {
      wavesurferObj.zoom(newZoom);
    }
  };

  return {
    playing,
    handlePlayPause,
    handleReload,
    handleTrim, // Add handleTrim to the returned object
    volume,
    handleVolumeSlider,
    zoom,
    handleZoomSlider,
  };
};
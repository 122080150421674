import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';

const auth = firebase.auth();

function UsageView() {
  const [user] = useAuthState(auth);
  
  // Get Firestore instance
  const db = firebase.firestore();

  // Use collection hook when user is available
  const [snapshot, loading, error] = useCollection(
    user ? db.collection('users').doc(user.uid).collection('metering').orderBy('createdAt', 'desc') : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  console.log('Firestore state:', {
    hasSnapshot: !!snapshot,
    loading,
    error: error?.message,
    docsCount: snapshot?.docs?.length,
    empty: snapshot?.empty,
    metadata: snapshot?.metadata,
    currentUserUid: user?.uid
  });

  // Process snapshot into usage data
  const usage = snapshot ? {
    jobs: snapshot.docs.map(doc => {
      const data = doc.data();
      console.log('Processing doc:', { id: doc.id, ...data });
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt?.toDate?.() || data.createdAt,
        updatedAt: data.updatedAt?.toDate?.() || data.updatedAt
      };
    })
  } : null;

  if (!user) {
    console.log('No user state');
    return (
      <div className="text-center text-gray-600 dark:text-gray-400 p-4">
        Please sign in to view usage data
      </div>
    );
  }

  if (loading) {
    console.log('Rendering loading state');
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black dark:border-white"></div>
      </div>
    );
  }

  if (error) {
    console.error('Error state:', error);
    return (
      <div className="text-center text-red-600 dark:text-red-400 p-4">
        Error: {error.message}
      </div>
    );
  }

  if (!usage || !usage.jobs || usage.jobs.length === 0) {
    console.log('Empty usage state');
    return (
      <div className="text-center text-gray-600 dark:text-gray-400 p-4">
        No usage data available
      </div>
    );
  }

  console.log('Rendering usage data:', usage);
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="text-sm text-gray-600 dark:text-gray-400">Videos Generated</div>
          <div className="text-2xl font-bold">{usage.jobs.length}</div>
        </div>
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="text-sm text-gray-600 dark:text-gray-400">Total Cost</div>
          <div className="text-2xl font-bold">${usage.jobs.reduce((acc, job) => acc + (job.cost || 0), 0).toFixed(2)}</div>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-900">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Cost
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {usage.jobs.map((job) => (
              <tr key={job.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">
                    {formatDistanceToNow(job.createdAt, { addSuffix: true })}
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {job.createdAt.toLocaleDateString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${job.status === 'COMPLETED' ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' : 
                      job.status === 'PROCESSING' ? 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100' : 
                      'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'}`}>
                    {job.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500 dark:text-gray-400">
                  {job.cost ? `$${job.cost.toFixed(2)}` : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UsageView;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import ArtistMusicView from './ArtistMusicView';
import PublicMerchListView from './PublicMerchListView';
import APIConfig from '../../APIConfig';

const ArtistPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [artistData, setArtistData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get artist slug either from URL params or from location state
  const artistSlug = slug || location.state?.artistSlug;

  useEffect(() => {
    const fetchArtistData = async () => {
      if (!artistSlug) {
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching artist data for slug:', artistSlug);
        // Fetch artist data including albums and tracks
        const url = `${APIConfig.baseURL()}/api/v1/artists/${artistSlug}/`;
        console.log('Request URL:', url);
        
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Artist data received:', JSON.stringify(data, null, 2));

        // Fetch merch data separately
        const merchUrl = `${APIConfig.baseURL()}/api/v1/artists/${artistSlug}/merch/`;
        const merchResponse = await fetch(merchUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (merchResponse.ok) {
          const merchData = await merchResponse.json();
          // Combine the data
          setArtistData({
            ...data,
            items: merchData.items || [],
            has_merch: merchData.items && merchData.items.length > 0
          });
        } else {
          setArtistData(data);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching artist data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchArtistData();
  }, [artistSlug]);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: artistData.name,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const getBackLink = () => {
    if (location.state?.from) {
      return {
        to: location.state.from,
        text: '← back'
      };
    }
    return {
      to: '/',
      text: '← back to home'
    };
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="font-mono">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-xl font-theFutureMonoRegular text-red-500">Error: {error}</div>
      </div>
    );
  }

  if (!artistData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="font-mono">Artist not found</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="space-y-8">
        <div className="flex justify-between items-start">
          <Link 
            to={getBackLink().to}
            className="font-mono hover:underline"
          >
            {getBackLink().text}
          </Link>
          <button 
            onClick={handleShare} 
            className="font-mono underline hover:no-underline cursor-pointer"
          >
            share
          </button>
        </div>
        
        <div>
          <div className="font-mono text-xl">{artistData.name}</div>
          {artistData.bio && (
            <div className="font-mono text-sm mt-2 max-w-2xl whitespace-pre-wrap">
              {artistData.bio}
            </div>
          )}
        </div>
        
        {artistData.website && (
          <div className="font-mono text-sm">
            <a 
              href={artistData.website} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="underline hover:no-underline"
            >
              website →
            </a>
          </div>
        )}

        <div className="mb-12">
          <div className="mt-4">–</div>
        </div>

        <ArtistMusicView albums={artistData.albums || []} />

        {artistData.has_merch && (
          <div className="mt-16">
            <div className="font-mono mb-4">merch</div>
            <PublicMerchListView slug={artistSlug} initialItems={artistData.items} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistPage;

import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../AuthContext';
import APIConfig from '../APIConfig';
import { useFetchWithAuth } from '../data layer/useFetchWithAuth';
import { getStripePublishableKey } from '../config/stripeConfig';
import { isTestMode } from '../config/featureFlags';

const PaymentContext = createContext();

export function PaymentProvider({ children }) {
  const { user, loading: authLoading } = useAuth();
  const [stripeMode, setStripeMode] = useState(isTestMode() ? 'test' : 'live');
  const [publishableKey, setPublishableKey] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      if (authLoading) {
        return;
      }

      try {
        setError(null);
        const key = getStripePublishableKey();
        setPublishableKey(key);
        const stripeInstance = await loadStripe(key);
        setStripePromise(stripeInstance);
      } catch (err) {
        console.error('Error initializing Stripe:', err);
        setError('Failed to initialize payment system');
      } finally {
        setLoading(false);
      }
    };

    initializeStripe();
  }, [user, authLoading]);

  const value = {
    stripePromise,
    stripeMode,
    publishableKey,
    loading: loading || authLoading,
    error: error,
  };

  return (
    <PaymentContext.Provider value={value}>
      {children}
    </PaymentContext.Provider>
  );
}

export const usePayment = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};

import React from 'react';
import VideoGenerator from './VideoGenerator';

const VideoCreatorView = () => {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="border-4 border-dashed border-gray-200 dark:border-gray-700 rounded-lg p-8">
            <div className="max-w-3xl mx-auto">
              <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                  Video Creator Studio
                </h1>
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  Create stunning AI-generated videos from text descriptions
                </p>
              </div>
              
              <VideoGenerator />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCreatorView;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { useAuth } from '../AuthContext';
import OrderService from '../services/orderService';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading: authLoading } = useAuth();

  // Handle checkout completion
  useEffect(() => {
    const completeCheckout = async () => {
      if (authLoading || !user) return;
      
      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');
      if (sessionId && !checkoutCompleted) {
        try {
          const token = await user.getIdToken();
          await OrderService.completeCheckout(sessionId, token);
          setCheckoutCompleted(true);
          // Remove session_id from URL
          navigate('/orders', { replace: true });
        } catch (err) {
          console.error('Error completing checkout:', err);
          setError('Failed to complete checkout. Please try again later.');
        }
      }
    };

    completeCheckout();
  }, [user, authLoading, location.search, checkoutCompleted]);

  // Fetch orders
  useEffect(() => {
    const fetchOrders = async () => {
      if (authLoading) return;

      if (!user) {
        navigate('/login', { state: { from: '/orders' } });
        return;
      }

      try {
        const token = await user.getIdToken();
        console.log('Fetching user orders...');
        const ordersData = await OrderService.getOrders(token);
        console.log('Received orders data:', ordersData);
        
        // Handle paginated response
        const ordersArray = ordersData.results || [];
        console.log('Setting orders state to:', ordersArray);
        
        setOrders(ordersArray);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching orders:', err);
        console.error('Error details:', err.response?.data || err.message);
        setError('Failed to fetch orders. Please try again later.');
        setOrders([]);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user, authLoading, checkoutCompleted]); // Only re-fetch when checkout is completed

  const handleDeleteOrder = async (orderId) => {
    if (!window.confirm('Are you sure you want to delete this order?')) {
      return;
    }

    setIsDeleting(true);
    try {
      const token = await user.getIdToken();
      await OrderService.deleteOrder(orderId, token);
      setOrders(orders.filter(order => order.id !== orderId));
    } catch (err) {
      console.error('Error deleting order:', err);
      setError('Failed to delete order. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (authLoading || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 dark:bg-red-900 p-4 rounded-lg">
          <p className="text-red-800 dark:text-red-200">{error}</p>
        </div>
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="container mx-auto px-4 py-16">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">No Orders Yet</h2>
          <p className="text-gray-600 dark:text-gray-400 mb-8">
            You haven't placed any orders yet.
          </p>
          <Link
            to="/merch"
            className="inline-block bg-blue-600 text-white py-2 px-6 rounded hover:bg-blue-700 transition-colors"
          >
            Start Shopping
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-8">Your Orders</h1>
      <div className="space-y-6">
        {orders.map((order) => (
          <div
            key={order.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
          >
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-lg font-semibold">
                    Order #{order.id}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Placed on {format(new Date(order.created_at), 'MMM d, yyyy')}
                  </p>
                </div>
                <div className="text-right">
                  <span className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${
                    order.status === 'completed' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' :
                    order.status === 'processing' ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200' :
                    order.status === 'cancelled' ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200' :
                    'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
                  }`}>
                    {order.status_display}
                  </span>
                  <button
                    onClick={() => handleDeleteOrder(order.id)}
                    disabled={isDeleting}
                    className="px-3 py-1 text-sm text-red-600 hover:text-red-800 hover:bg-red-100 rounded transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div className="border-t border-gray-200 dark:border-gray-700 -mx-6 px-6 py-4">
                {order.items.map((item) => (
                  <div key={item.id} className="flex items-center py-4">
                    {item.merch_item_image && (
                      <img
                        src={item.merch_item_image}
                        alt={item.merch_item_name}
                        className="w-16 h-16 object-cover rounded"
                      />
                    )}
                    <div className="ml-4 flex-grow">
                      <h4 className="font-medium">{item.merch_item_name}</h4>
                      {item.variant_name && (
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          {item.variant_name}
                        </p>
                      )}
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Quantity: {item.quantity}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">${(Number(item.price_at_time) * item.quantity / 100).toFixed(2)}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Shipping Address Section */}
              {order.shipping_address && (
                <div className="border-t border-gray-200 dark:border-gray-700 -mx-6 px-6 py-4">
                  <h4 className="font-medium mb-2">Shipping Address</h4>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    <p>{order.shipping_address.recipient_name}</p>
                    <p>{order.shipping_address.address_line1}</p>
                    {order.shipping_address.address_line2 && (
                      <p>{order.shipping_address.address_line2}</p>
                    )}
                    <p>
                      {order.shipping_address.city}, {order.shipping_address.state}{' '}
                      {order.shipping_address.postal_code}
                    </p>
                    <p>{order.shipping_address.country}</p>
                  </div>
                </div>
              )}

              <div className="border-t border-gray-200 dark:border-gray-700 -mx-6 px-6 py-4">
                <div className="flex justify-between items-center">
                  <h4 className="font-medium">Total</h4>
                  <p className="font-medium">${Number(order.total_amount).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;

import React, { createContext, useState, useEffect, useContext } from 'react';
import { useFetchWithAuth } from '../../../../data layer/useFetchWithAuth';
import APIConfig from '../../../../APIConfig';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const profileUrl = `${APIConfig.me()}`;
  console.log('[ProfileContext] Starting profile fetch from:', profileUrl);
  
  const { data: profileInfo, loading, error } = useFetchWithAuth(profileUrl, { method: "GET" });
  const [artistInfo, setArtistInfo] = useState(null);
  const [artistLoading, setArtistLoading] = useState(false);
  const [artistError, setArtistError] = useState(null);

  // Fetch linked artists when profile is loaded
  useEffect(() => {
    if (profileInfo?.artists?.length > 0) {
      const fetchArtistDetails = async () => {
        setArtistLoading(true);
        try {
          // Fetch details for each linked artist
          const artistPromises = profileInfo.artists.map(artistId => 
            fetch(`${APIConfig.artistsURI()}${artistId}/`, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }).then(res => res.json())
          );
          
          const artists = await Promise.all(artistPromises);
          setArtistInfo(artists);
          setArtistError(null);
        } catch (err) {
          console.error('[ProfileContext] Error fetching artist details:', err);
          setArtistError(err);
        } finally {
          setArtistLoading(false);
        }
      };

      fetchArtistDetails();
    }
  }, [profileInfo?.artists]);
  
  useEffect(() => {
    if (loading) {
      console.log('[ProfileContext] Profile loading...');
    } else if (error) {
      console.error('[ProfileContext] Profile fetch error:', error);
    } else if (profileInfo) {
      console.log('[ProfileContext] Profile loaded:', {
        username: profileInfo.username,
        is_seller: profileInfo.is_seller,
        seller_id: profileInfo.seller?.id,
        stripe_account: profileInfo.seller?.stripe_account,
        linked_artists: profileInfo.artists?.length || 0
      });
    }
  }, [loading, error, profileInfo]);

  // Computed properties
  const isSeller = profileInfo?.is_seller || false;
  const hasLinkedArtists = (profileInfo?.artists?.length || 0) > 0;
  const isArtist = hasLinkedArtists;
  const primaryArtist = artistInfo?.[0] || null;

  const value = {
    profileInfo,
    loading,
    error,
    // Role flags
    isSeller,
    isArtist,
    hasLinkedArtists,
    // Artist data
    artistInfo,
    artistLoading,
    artistError,
    primaryArtist,
    // Helper getters
    getArtistBySlug: (slug) => artistInfo?.find(a => a.slug === slug) || null,
    getArtistById: (id) => artistInfo?.find(a => a.id === id) || null
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
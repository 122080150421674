import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner, Button, Dropdown } from '../../../common';

const ActionButton = ({ icon, children, ...props }) => (
  <Button
    variant="secondary"
    className="flex-1 sm:flex-none inline-flex items-center justify-center"
    {...props}
  >
    {icon && <span className="w-5 h-5 mr-2">{icon}</span>}
    {children}
  </Button>
);

ActionButton.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

const AddIcon = () => (
  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
  </svg>
);

const RefreshIcon = () => (
  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
  </svg>
);

const ChevronDownIcon = () => (
  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
  </svg>
);

const ShippingHeader = ({
  isSaving,
  loadingZones,
  onAddMethod,
  onUseExisting,
  onUseDefaults,
  onSave,
}) => {
  const isDisabled = isSaving || loadingZones;

  const dropdownItems = [
    {
      label: 'Add Method',
      icon: <AddIcon />,
      onClick: onAddMethod,
      disabled: isDisabled,
    },
    {
      label: 'Use Existing',
      onClick: onUseExisting,
      disabled: isDisabled,
    },
    {
      label: 'Use Default Configuration',
      icon: <RefreshIcon />,
      onClick: onUseDefaults,
      disabled: isDisabled,
    },
  ];

  return (
    <header className="mb-8 border-b border-gray-200 dark:border-gray-700 pb-6">
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
            Shipping Methods
          </h2>
          <p className="mt-2 text-base text-gray-600 dark:text-gray-400">
            Configure shipping rates and methods for your store
          </p>
        </div>

        <div className="flex gap-3 items-center">
          <Dropdown
            trigger={
              <Button
                variant="secondary"
                className="inline-flex items-center gap-2"
                disabled={isDisabled}
              >
                Actions
                <ChevronDownIcon />
              </Button>
            }
            items={dropdownItems}
            disabled={isDisabled}
          />

          <Button
            variant="primary"
            onClick={onSave}
            disabled={isDisabled}
          >
            {isSaving ? (
              <div className="flex items-center justify-center">
                <LoadingSpinner className="w-5 h-5 mr-2" />
                Saving...
              </div>
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </div>
    </header>
  );
};

ShippingHeader.propTypes = {
  isSaving: PropTypes.bool,
  loadingZones: PropTypes.bool,
  onAddMethod: PropTypes.func.isRequired,
  onUseExisting: PropTypes.func.isRequired,
  onUseDefaults: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

ShippingHeader.defaultProps = {
  isSaving: false,
  loadingZones: false,
};

export default ShippingHeader;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserProfileContext } from '../../../../context/UserProfileContext';
import ArtistLinkingView from './ArtistLinkingView';

const ArtistOnboardingView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { linkedArtists } = useUserProfileContext();
  const [returnPath, setReturnPath] = useState('/drafts');

  useEffect(() => {
    // Default return path is /drafts; can be overridden by location.state
    const path = location.state?.returnTo || '/drafts';
    setReturnPath(path);
  }, [location]);

  const handleReturnClick = () => {
    navigate(returnPath, { replace: true });
  };

  return (
    <div className="min-h-screen font-mono bg-[#f3f3f3] dark:bg-black text-black dark:text-white px-4 py-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header Section */}
        <div>
          <h1 className="text-3xl font-bold">
            Link Your Artists
          </h1>
          <p className="text-base mt-2">
            Add one or more artists to start creating drafts
          </p>
        </div>

        {/* Explanation Box */}
        <div className="border border-black dark:border-white p-4">
          <h2 className="text-xl font-bold mb-4">
            Why link artist profiles?
          </h2>
          <div className="md:grid md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <div>
                <h3 className="text-lg font-bold">Multiple Artists</h3>
                <p className="text-sm">
                  Manage multiple artist profiles from one account
                </p>
              </div>
              <div>
                <h3 className="text-lg font-bold">Organized Releases</h3>
                <p className="text-sm">Keep your music organized by artist</p>
              </div>
            </div>
            <div className="space-y-2 mt-4 md:mt-0">
              <div>
                <h3 className="text-lg font-bold">Collaborations</h3>
                <p className="text-sm">Easily manage collaborations and features</p>
              </div>
              <div>
                <h3 className="text-lg font-bold">Switch Profiles</h3>
                <p className="text-sm">Quickly switch between different artist accounts</p>
              </div>
            </div>
          </div>
        </div>

        {/* Linking View */}
        <div className="border border-black dark:border-white p-4">
          <ArtistLinkingView />
        </div>

        {/* Return Button if artists are already linked */}
        {linkedArtists?.length > 0 && (
          <div className="text-center">
            <button
              onClick={handleReturnClick}
              className="border border-black dark:border-white px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-900"
            >
              Return to Drafts
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistOnboardingView;
import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import { PlusIcon } from '@heroicons/react/24/outline';
import APIConfig from '../../APIConfig';
import { useFetchWithAuth } from '../../data layer/useFetchWithAuth';
import { usePlayer } from '../../contexts/PlayerContext';
import AddToMixtapeModal from '../mixtapes/AddToMixtapeModal';

const AlbumGrid = ({ albums, onAlbumClick }) => {
  return (
    <div className="flex overflow-x-auto space-x-4 pb-4">
      {albums.map((album) => {
        if (!album.artist_slug) {
          console.warn('Missing artist_slug for album:', album);
          return null;
        }
        return (
          <div
            key={album.id}
            className="flex-none w-48 cursor-pointer border border-black"
            onClick={() => {
              console.log('Clicking album:', album);
              onAlbumClick(album.artist_slug, album.id);
            }}
          >
            <img
              src={album.artwork_url || album.compressed_artwork_url}
              alt={album.name}
              className="w-full aspect-square object-cover border-b border-black"
              loading="lazy"
            />
            <div className="p-2 font-mono">
              <div className="text-sm truncate">{album.name}</div>
              <div className="text-xs truncate text-gray-600">{album.artist_name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const AlbumPage = () => {
  const { artistSlug, albumId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, userLoading] = useAuthState(firebase.auth());
  const { play, pause, currentTrack, isPlaying, computeTrackDuration } = usePlayer();
  
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [showMixtapeModal, setShowMixtapeModal] = useState(false);
  
  const albumUrl = albumId ? `${APIConfig.albumsURI()}${albumId}/` : null;
  const recommendedUrl = albumId ? `${APIConfig.serverURI}music/albums/${albumId}/recommended/` : null;
  
  const { data: albumData, loading: albumLoading } = useFetchWithAuth(albumUrl);
  const { data: recommendedData, loading: loadingRecommended } = useFetchWithAuth(recommendedUrl);

  const [computedAlbumData, setAlbumData] = useState(null);
  const [recommendedAlbums, setRecommendedAlbums] = useState([]);

  // Update computedAlbumData when albumData changes
  useEffect(() => {
    if (albumData) {
      console.log('Setting album data:', albumData);
      setAlbumData(albumData);
    }
  }, [albumData]);

  // Update recommendedAlbums when recommendedData changes
  useEffect(() => {
    if (recommendedData) {
      console.log('Received recommended data:', recommendedData);
      // Ensure each album has the required fields for navigation
      const processedAlbums = recommendedData.map(album => ({
        ...album,
        artist_slug: album.artist_slug || album.artist?.slug,  // Try both possible locations
        artist_name: album.artist_name || album.artist?.name
      }));
      console.log('Processed recommended albums:', processedAlbums);
      // Only show up to 6 recommended albums
      setRecommendedAlbums(processedAlbums.slice(0, 6));
    }
  }, [recommendedData]);

  // Compute durations for tracks when needed
  useEffect(() => {
    if (computedAlbumData?.tracks) {
      const tracksWithoutDuration = computedAlbumData.tracks.filter(
        track => !track.duration && (track.compressed_file || track.file)
      );

      if (tracksWithoutDuration.length > 0) {
        Promise.all(
          tracksWithoutDuration.map(track =>
            computeTrackDuration(track.compressed_file || track.file)
          )
        ).then(durations => {
          const updatedTracks = computedAlbumData.tracks.map((track, index) => {
            if (!track.duration && (track.compressed_file || track.file)) {
              return { ...track, duration: durations[index] };
            }
            return track;
          });
          setAlbumData(prevData => ({
            ...prevData,
            tracks: updatedTracks,
          }));
        });
      }
    }
  }, [computedAlbumData?.tracks]);

  const handleAlbumClick = (newArtistSlug, newAlbumId) => {
    console.log('Navigating to:', { newArtistSlug, newAlbumId });
    if (!newArtistSlug) {
      console.error('Missing artist_slug for album:', newAlbumId);
      return;
    }
    
    // Navigate to the new album page with state to preserve artist info and back navigation
    navigate(`/a/${newArtistSlug}/albums/${newAlbumId}`, { 
      replace: true, 
      state: { 
        artistSlug: newArtistSlug,
        from: location.pathname,
        fromAlbum: true // Add this to indicate we came from an album
      } 
    });
  };

  const handlePlayTrack = (track) => {
    if (!track) return;

    // Create a playable track object with the correct URL fields
    const playableTrack = {
      ...track,
      id: track.id,
      name: track.name,
      artist: track.artist,
      compressedUrl: track.compressed_file || null,
      losslessUrl: track.file || null,
      duration: track.duration || 0
    };

    console.log('Playing track:', playableTrack);

    // Prepare playlist of all tracks
    const playableTracks = computedAlbumData.tracks.map(t => ({
      ...t,
      compressedUrl: t.compressed_file || null,
      losslessUrl: t.file || null,
      duration: t.duration || 0
    }));

    // If this track is already playing, pause it
    if (currentTrack?.id === track.id && isPlaying) {
      pause();
    } else {
      // Otherwise, play the track with the full playlist context
      play(playableTrack, playableTracks);
    }
  };

  const handleShare = async () => {
    try {
      if (!computedAlbumData) {
        console.warn('No album data available to share.');
        return;
      }

      // Create a clean URL without any query parameters
      const shareUrl = new URL(window.location.href);
      shareUrl.search = ''; // Remove any query parameters
      
      // Also ensure we're using the correct URL format for meta tags
      const metaUrl = new URL(shareUrl);
      metaUrl.pathname = `/a/${artistSlug}/albums/${albumId}`;

      if (navigator.share) {
        await navigator.share({
          title: `${computedAlbumData.name} by ${computedAlbumData.artist_name}`,
          url: metaUrl.toString()
        });
      } else {
        await navigator.clipboard.writeText(metaUrl.toString());
        console.log('Link copied to clipboard:', metaUrl.toString());
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const getBackLink = () => {
    // If we have a from location in state
    if (location.state?.from) {
      // Handle special case for home
      if (location.state.from === 'home' || location.state.from === '/home') {
        return {
          to: '/',
          text: '← back'
        };
      }
      // Otherwise use the stored path
      return {
        to: location.state.from,
        text: '← back'
      };
    }
    // Default to artist page
    return {
      to: `/a/${artistSlug}`,
      text: '← back to artist'
    };
  };

  const addTrackToMixtape = async (mixtapeId, track) => {
    console.group('Adding Track to Mixtape');
    console.log('Initial track data:', {
      track,
      mixtapeId,
      trackKeys: track ? Object.keys(track) : null,
      trackName: track?.name,
      trackId: track?.id,
      trackArtist: track?.artist,
      trackDuration: track?.duration
    });

    // Validate track data before attempting to add
    if (!track?.id || !track?.name) {
      console.error('Invalid track data - missing required fields:', {
        hasId: Boolean(track?.id),
        hasName: Boolean(track?.name),
        track
      });
      console.groupEnd();
      throw new Error('Invalid track data');
    }

    try {
      console.log('Fetching mixtape details for ID:', mixtapeId);
      // First get the mixtape details
      const mixtapeResponse = await fetch(
        `${APIConfig.serverURI}mixtapes/${mixtapeId}/`,
        {
          headers: {
            'Authorization': `JWT ${await user.getIdToken()}`
          }
        }
      );

      const mixtape = await mixtapeResponse.json();
      console.log('Mixtape details:', {
        mixtapeId: mixtape.id,
        mixtapeName: mixtape.name,
        currentTrackCount: mixtape.mixtapetracks?.length || 0,
        tracks: mixtape.mixtapetracks?.map(t => ({
          id: t.id,
          trackId: t.track,
          name: t.name
        }))
      });
      
      // Simply use the track count as the index, like in the Swift app
      const nextIndex = (mixtape.mixtapetracks?.length || 0) + 1;

      console.log('Adding track with details:', {
        mixtapeId,
        trackId: track.id,
        nextIndex,
        currentTracks: mixtape.mixtapetracks?.length || 0,
        requestBody: {
          mixtape: mixtapeId,
          track: track.id,
          index: nextIndex
        }
      });

      // Add the track to the mixtape
      const response = await fetch(
        `${APIConfig.serverURI}mixtapetracks/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${await user.getIdToken()}`
          },
          body: JSON.stringify({
            mixtape: mixtapeId,
            track: track.id,
            index: nextIndex
          })
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log('Track added successfully:', {
          response: responseData,
          status: response.status,
          statusText: response.statusText
        });
      } else {
        const errorData = await response.json();
        console.error('Failed to add track:', {
          error: errorData,
          status: response.status,
          statusText: response.statusText
        });
        throw new Error(errorData.non_field_errors?.[0] || 'Failed to add track');
      }
    } catch (error) {
      console.error('Error in addTrackToMixtape:', {
        error,
        errorMessage: error.message,
        errorStack: error.stack
      });
      console.groupEnd();
      throw error;
    }
    console.groupEnd();
  };

  const handleAddToMixtape = async (mixtapeId, track, onClose) => {
    if (!user) {
      navigate('/signin');
      return;
    }

    try {
      await addTrackToMixtape(mixtapeId, track);
      if (onClose) onClose();
    } catch (error) {
      console.error('Error adding track to mixtape:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!albumId) {
        return;
      }

      try {
        console.log('Navigation state:', {
          from: location.state?.from,
          artistSlug: location.state?.artistSlug
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [albumId, location.state]);

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="font-mono">Please log in to view this album</div>
      </div>
    );
  }

  if (albumLoading || userLoading || loadingRecommended) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="font-mono">Loading album...</div>
      </div>
    );
  }

  if (!computedAlbumData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="font-mono">Album not found</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="space-y-8">
        <div className="flex justify-between items-start">
          <Link 
            to={getBackLink().to}
            className="font-mono hover:underline"
          >
            {getBackLink().text}
          </Link>
          <button 
            onClick={handleShare} 
            className="font-mono underline hover:no-underline cursor-pointer"
          >
            share
          </button>
        </div>

        <div className="space-y-8">
          <div className="max-w-sm">
            <div className="border border-black p-2">
              <img
                src={computedAlbumData.artwork_url || computedAlbumData.compressed_artwork_url}
                alt={computedAlbumData.name}
                className="w-full aspect-square object-cover"
              />
              <div className="mt-2 font-mono">{computedAlbumData.name}</div>
              <Link 
                to={`/a/${artistSlug}`}
                className="mt-1 font-mono text-sm hover:underline block"
                state={{ from: location.pathname }}
              >
                {computedAlbumData.artist}
              </Link>
            </div>
          </div>

          {computedAlbumData.tracks && computedAlbumData.tracks.length > 0 && (
            <div>
              <div className="font-mono mb-4">tracks</div>
              <div className="space-y-2">
                {computedAlbumData.tracks.map((track, index) => (
                  <div 
                    key={track.id}
                    className="font-mono flex justify-between items-center p-2 border border-black hover:bg-gray-50 group"
                  >
                    <div 
                      className="flex items-center flex-grow cursor-pointer"
                      onClick={() => handlePlayTrack(track)}
                    >
                      <span className="mr-4 font-mono">
                        {currentTrack?.id === track.id && isPlaying ? '▐▐' : '►'}
                      </span>
                      <span>{index + 1}.</span>
                      <span className="ml-4">{track.name}</span>
                    </div>
                    <div className="flex items-center space-x-4">
                      <span className="text-gray-500 font-mono">
                        {track.duration || '0:00'}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedTrack(track);
                          setShowMixtapeModal(true);
                        }}
                        className="p-2 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity"
                        title="Add to mixtape"
                      >
                        <PlusIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {recommendedAlbums.length > 0 && (
            <div className="mt-12">
              <div className="font-mono mb-4">you might also like</div>
              <AlbumGrid albums={recommendedAlbums} onAlbumClick={handleAlbumClick} />
            </div>
          )}
        </div>
      </div>

      {/* Add to Mixtape Modal */}
      <AddToMixtapeModal
        isOpen={showMixtapeModal}
        onClose={() => setShowMixtapeModal(false)}
        track={selectedTrack}
        onAddToMixtape={handleAddToMixtape}
      />
    </div>
  );
};

export default AlbumPage;

// useUserProfile.js
import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import profile_service from "../services/profile_service";

const useUserProfile = () => {
  const { user, loading: authLoading } = useAuth();
  const [artistName, setArtistName] = useState("");
  const [isArtist, setArtistStatus] = useState(false);
  const [hasStudio, setStudioStatus] = useState(false);
  const [artistLink, setArtistLink] = useState("");
  const [stripeDashLink, setStripeDashLink] = useState(false);
  const [isSeller, setSellerStatus] = useState(false);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [linkedArtists, setLinkedArtists] = useState([]);
  const prevDataRef = useRef(null);

  const updateProfileData = useCallback((data) => {
    if (!data) return;

    console.log('useUserProfile: Profile data received:', data);

    // Update all states in a batch
    const updates = {
      artistName: data.artists?.length > 0 ? data.artists[0].name : "no artist",
      isArtist: data.artists?.length > 0,
      username: data.username || "user",
      hasStudio: data.has_studio || false,
      isSeller: data.is_seller || false,
      artistLink: data.artist_profile_link || "",
      stripeDashLink: data.is_seller && data.seller_info ? data.seller_info.stripe_acct_login_link : "",
      linkedArtists: data.artists || []
    };

    // Log seller status update
    console.log('useUserProfile: Seller status:', { hasSeller: updates.isSeller, is_seller: data.is_seller });

    // Update all states at once
    setArtistName(updates.artistName);
    setArtistStatus(updates.isArtist);
    setUsername(updates.username);
    setStudioStatus(updates.hasStudio);
    setSellerStatus(updates.isSeller);
    setArtistLink(updates.artistLink);
    setStripeDashLink(updates.stripeDashLink);
    setLinkedArtists(updates.linkedArtists);

    // Store seller info in localStorage if available
    if (updates.isSeller && data.seller_info) {
      localStorage.setItem("seller_info", data.seller_info.id || "");
    }

    prevDataRef.current = data;
  }, []);

  const fetchProfile = useCallback(async (skipCache = false) => {
    console.log('useUserProfile: fetchProfile called', { skipCache, hasUser: !!user });
    
    if (!user) {
      console.log('useUserProfile: No user, setting loading false');
      setLoading(false);
      setError(null);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      // Always get a fresh token
      const idToken = await user.getIdToken(true);
      console.log('useUserProfile: Got fresh token');
      
      if (skipCache) {
        console.log('useUserProfile: Clearing cache');
        profile_service.clearProfileCache(idToken);
      }
      
      console.log('useUserProfile: Fetching profile info');
      const response = await profile_service.getProfileInfo(idToken);
      const data = await response.json();
      console.log('useUserProfile: Got profile data', { hasArtists: !!data.artists?.length });
      updateProfileData(data);
    } catch (err) {
      console.error('useUserProfile: Error fetching profile:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [user, updateProfileData]);

  // Initial fetch - wait for auth to be ready
  useEffect(() => {
    console.log('useUserProfile: Auth state changed', { authLoading, hasUser: !!user });
    if (!authLoading) {
      fetchProfile(false);
    }
  }, [authLoading, fetchProfile]);

  return {
    artistName,
    username,
    isArtist,
    hasStudio,
    artistLink,
    stripeDashLink,
    isSeller,
    loading: loading || authLoading,
    error,
    linkedArtists,
    refreshProfile: () => fetchProfile(true) // Force skip cache on manual refresh
  };
};

export default useUserProfile;

import { useState, useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";

// Cache token and its expiration time
let cachedToken = null;
let tokenExpirationTime = null;

const TOKEN_REFRESH_BUFFER = 5 * 60 * 1000; // 5 minutes in milliseconds

// Cache for API responses
const responseCache = new Map();

export const useFetchWithAuth = (url, options = {}) => {
  const [data, setData] = useState(() => responseCache.get(url) || null);
  const [loading, setLoading] = useState(!responseCache.has(url));
  const [error, setError] = useState(null);
  const [user, userLoading, userError] = useAuthState(firebase.auth());
  const mounted = useRef(true);

  // Clean up function to prevent memory leaks
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (userLoading) {
        console.log('[useFetchWithAuth] Waiting for Firebase user...');
        return;
      }
      
      if (userError) {
        console.error('[useFetchWithAuth] Firebase auth error:', userError);
        return;
      }
      
      if (!user) {
        console.log('[useFetchWithAuth] No Firebase user found');
        return;
      }

      // If we have cached data, use it immediately
      const cachedData = responseCache.get(url);
      if (cachedData) {
        console.log('[useFetchWithAuth] Using cached data for:', url);
        setData(cachedData);
        setLoading(false);
        return;
      }

      if (!mounted.current) return;
      setLoading(true);
      console.log('[useFetchWithAuth] Starting fetch for:', url);

      try {
        let idToken;
        const now = Date.now();

        // Check if we have a valid cached token
        if (cachedToken && tokenExpirationTime && now < tokenExpirationTime - TOKEN_REFRESH_BUFFER) {
          console.log('[useFetchWithAuth] Using cached token');
          idToken = cachedToken;
        } else {
          // Token needs refresh
          console.log('[useFetchWithAuth] Refreshing token...');
          try {
            idToken = await user.getIdToken(false);
            const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
            tokenExpirationTime = new Date(decodedToken.expirationTime).getTime();
            cachedToken = idToken;
            console.log('[useFetchWithAuth] Token refreshed successfully');
          } catch (tokenError) {
            console.error('[useFetchWithAuth] Token refresh error:', tokenError);
            if (tokenError.code === 'auth/requires-recent-login') {
              console.log('[useFetchWithAuth] Forcing token refresh...');
              idToken = await user.getIdToken(true);
              const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
              tokenExpirationTime = new Date(decodedToken.expirationTime).getTime();
              cachedToken = idToken;
              console.log('[useFetchWithAuth] Forced token refresh successful');
            } else {
              throw tokenError;
            }
          }
        }

        console.log('[useFetchWithAuth] Making request to:', url);
        const response = await fetch(url, {
          ...options,
          method: options.method || "GET",
          headers: {
            ...options.headers,
            "Content-Type": "application/json",
            Authorization: "JWT " + idToken,
          },
        });

        if (!response.ok) {
          console.error('[useFetchWithAuth] Request failed:', {
            status: response.status,
            statusText: response.statusText,
            url
          });
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('[useFetchWithAuth] Request successful:', {
          url,
          status: response.status,
          dataReceived: !!responseData
        });
        
        // Only update state if component is still mounted
        if (mounted.current) {
          responseCache.set(url, responseData);
          setData(responseData);
          setError(null);
        }
      } catch (err) {
        console.error('[useFetchWithAuth] Error in fetchData:', {
          url,
          error: err.message,
          stack: err.stack
        });
        if (err.message.includes('401') || 
            err.message.includes('403') || 
            err.message.toLowerCase().includes('unauthorized')) {
          // Clear local storage and redirect to login
          localStorage.clear();
          window.location.href = '/login';
          return;
        }
        if (mounted.current) {
          setError(err);
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [url, user, userLoading, userError, options.method, options.headers]);

  // Clear cache when component unmounts
  useEffect(() => {
    return () => {
      responseCache.delete(url);
    };
  }, [url]);

  return { data, loading: loading || userLoading, error: error || userError };
};

export default useFetchWithAuth;

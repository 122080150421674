import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import storage from "../../../../../services/storage";

const useImageUpload = (album) => {
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const uploadImage = async (file, isExistingUrl = false) => {
    if (!album?.docId) {
      console.error("No album or docId provided");
      return;
    }

    setUploadStatus("uploading");

    try {
      let downloadURL;
      let filename = null;

      if (isExistingUrl) {
        // If it's an existing URL, use it directly
        downloadURL = file; // In this case, 'file' is actually the URL
        setProgress(100);
      } else {
        // Create preview URL for the file
        const objectUrl = URL.createObjectURL(file);
        setPreviewUrl(objectUrl);

        // Use a timestamp to ensure unique filenames
        const timestamp = Date.now();
        const fileExtension = file.name.split('.').pop();
        filename = `${timestamp}.${fileExtension}`;
        const compressedFilename = `${timestamp}_compressed.${fileExtension}`;
        
        // Upload original file
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`studio/user_storage/${firebase.auth().currentUser.uid}/drafts/${album.docId}/images/${filename}`);
        const uploadTask = fileRef.put(file);

        // Create a compressed version of the image
        const compressedImage = await compressImage(file);
        const compressedRef = storageRef.child(`studio/user_storage/${firebase.auth().currentUser.uid}/drafts/${album.docId}/images/${compressedFilename}`);
        const compressedUploadTask = compressedRef.put(compressedImage);

        // Track progress of original upload
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            console.log('Upload progress:', progress);
          },
          error => {
            console.error("Upload error:", error);
            setUploadStatus("failed");
            throw error;
          }
        );

        // Wait for both uploads to complete
        await Promise.all([
          new Promise((resolve, reject) => {
            uploadTask.then(resolve).catch(reject);
          }),
          new Promise((resolve, reject) => {
            compressedUploadTask.then(resolve).catch(reject);
          })
        ]);

        // Get the download URL for the original file
        downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        console.log("Files uploaded successfully");

        // Clean up the preview URL
        URL.revokeObjectURL(objectUrl);
        setPreviewUrl(null);
      }
            
      // Update album cover with the URL and filename (if any)
      await storage.updateAlbumCover(album, downloadURL, filename, file);
      console.log("Album cover updated successfully");
      
      setUploadStatus("success");
      return downloadURL;

    } catch (error) {
      console.error("Error during upload:", error);
      setUploadStatus("failed");
      // Clean up preview URL on error
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
      }
      throw error;
    }
  };

  // Helper function to compress the image
  const compressImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // Set target dimensions (e.g., max width/height of 800px)
          const MAX_SIZE = 800;
          let width = img.width;
          let height = img.height;
          
          if (width > height) {
            if (width > MAX_SIZE) {
              height *= MAX_SIZE / width;
              width = MAX_SIZE;
            }
          } else {
            if (height > MAX_SIZE) {
              width *= MAX_SIZE / height;
              height = MAX_SIZE;
            }
          }
          
          canvas.width = width;
          canvas.height = height;
          
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convert to blob with reduced quality
          canvas.toBlob(
            (blob) => resolve(blob),
            'image/jpeg',
            0.7 // quality
          );
        };
        img.onerror = reject;
        img.src = event.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return { uploadImage, progress, uploadStatus, previewUrl };
};

export default useImageUpload;

import React, { useState, useEffect } from 'react';
import api from '../../api';

const VideoGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [status, setStatus] = useState('idle');
  const [jobId, setJobId] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let interval;
    if (jobId && status === 'processing') {
      interval = setInterval(checkJobStatus, 3000);
    }
    return () => clearInterval(interval);
  }, [jobId, status]);

  const checkJobStatus = async () => {
    try {
      const data = await api.getVideoStatus(jobId);
      const { status: jobStatus, videoUrl: url, error: jobError } = data;

      setStatus(jobStatus.toLowerCase());
      if (jobStatus === 'COMPLETED' && url) {
        setVideoUrl(url);
      } else if (jobStatus === 'FAILED') {
        setError(jobError || 'Failed to generate video');
      }
    } catch (err) {
      console.error('Error checking job status:', err);
      setError('Error checking job status');
      setStatus('error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    setStatus('processing');
    setError(null);
    setVideoUrl(null);

    try {
      const data = await api.generateVideo(prompt, {
        durationSeconds: 6,
        fps: 24,
        dimension: '1280x720'
      });

      const { jobId: newJobId } = data;
      setJobId(newJobId);
    } catch (err) {
      console.error('Error starting video generation:', err);
      setError('Error starting video generation');
      setStatus('error');
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-6">AI Video Generator</h1>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label 
            htmlFor="prompt" 
            className="block text-sm font-medium mb-2"
          >
            Describe your video
          </label>
          <textarea
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows="4"
            placeholder="Enter a description of the video you want to generate..."
          />
        </div>

        <button
          type="submit"
          disabled={status === 'processing' || !prompt.trim()}
          className={`w-full py-2 px-4 rounded-lg font-medium ${
            status === 'processing'
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
        >
          {status === 'processing' ? 'Generating...' : 'Generate Video'}
        </button>
      </form>

      {error && (
        <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {status === 'processing' && (
        <div className="p-4 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded-lg">
          Generating your video... This may take a few minutes.
        </div>
      )}

      {videoUrl && (
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Generated Video</h2>
          <video
            controls
            className="w-full rounded-lg shadow-lg"
            src={videoUrl}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  );
};

export default VideoGenerator;

import { useState, useEffect, useContext } from 'react';
import { firebaseAuth } from '../provider/AuthProvider';
import { useFetchWithAuth } from '../data layer/useFetchWithAuth';
import APIConfig from '../APIConfig';

export function useStripeProfile() {
  const { data: profileData, loading, error } = useFetchWithAuth(`${APIConfig.profiles()}me/`, {
    method: 'GET'
  });

  return { loading, error, profileData };
}

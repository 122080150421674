import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import APIConfig from '../../APIConfig';
import MixtapeListItem from '../mixtapes/MixtapeListItem';
import LoginModal from '../auth/LoginModal';
import useAuth from '../../hooks/useAuth';

export default function UserProfileView() {
  const { username } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [mixtapes, setMixtapes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [firingMixtapeId, setFiringMixtapeId] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowLoading, setIsFollowLoading] = useState(false);
  const [targetUserId, setTargetUserId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [displayedMixtapes, setDisplayedMixtapes] = useState(5);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Fetch current user's ID
  useEffect(() => {
    const fetchCurrentUserId = async () => {
      if (!user) {
        setCurrentUserId(null);
        return;
      }
      try {
        const token = await user.getIdToken();
        const resp = await fetch(`${APIConfig.serverURI}profiles/me/`, {
          headers: { Authorization: `JWT ${token}` },
        });
        if (resp.ok) {
          const data = await resp.json();
          setCurrentUserId(data.id);
        }
      } catch (error) {
        console.error('Error fetching current user ID:', error);
      }
    };
    fetchCurrentUserId();
  }, [user]);

  // Fetch profile and mixtapes
  useEffect(() => {
    const fetchData = async () => {
      if (!username) return;
      
      try {
        setLoading(true);
        setIsProfileLoading(true);

        // Get auth token if logged in
        const headers = {};
        if (user) {
          const token = await user.getIdToken();
          headers.Authorization = `JWT ${token}`;
        }

        // Fetch profile data
        const profileResponse = await fetch(
          `${APIConfig.serverURI}profiles/by_username/${username}/`,
          { headers }
        );

        if (!profileResponse.ok) {
          throw new Error('Failed to fetch profile');
        }

        const profileData = await profileResponse.json();
        setUserProfile(profileData);
        setTargetUserId(profileData.id);
        setIsFollowing(profileData.is_followed || false);

        // Determine if viewing own profile
        const isOwnProfile = user && profileData.id === currentUserId;

        // Fetch mixtapes
        const mixtapesResponse = await fetch(
          `${APIConfig.serverURI}mixtapes/by_username/${username}/?limit=${displayedMixtapes}`,
          { headers }
        );

        if (!mixtapesResponse.ok) {
          throw new Error('Failed to fetch mixtapes');
        }

        const mixtapesData = await mixtapesResponse.json();
        const allMixtapes = mixtapesData.mixtapes || [];
        const total = mixtapesData.total_count || allMixtapes.length;
        setMixtapes(allMixtapes);
        setTotalCount(total);
        setHasMore(total > displayedMixtapes);

      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
        setIsProfileLoading(false);
      }
    };

    fetchData();
  }, [username, user, displayedMixtapes, currentUserId]);

  const handleMixtapeClick = (mixtape) => {
    navigate(`/mixtapes/${mixtape.id}`);
  };

  const handleFire = async (mixtape) => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    try {
      setFiringMixtapeId(String(mixtape.id));
      const token = await user.getIdToken();
      const response = await fetch(
        `${APIConfig.serverURI}feed/${mixtape.id}/fire/`,
        {
          method: mixtape.voted ? 'DELETE' : 'POST',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fire mixtape');
      }

      setMixtapes(prevMixtapes => prevMixtapes.map(m => {
        if (m.id === String(mixtape.id)) {
          return {
            ...m,
            voted: !m.voted,
            vote_count: m.vote_count + (m.voted ? -1 : 1)
          };
        }
        return m;
      }));
    } catch (err) {
      console.error('Fire error:', err);
    } finally {
      setFiringMixtapeId(null);
    }
  };

  const handleFollow = async () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    if (!targetUserId) {
      console.error('No target user ID available');
      return;
    }

    try {
      setIsFollowLoading(true);
      const token = await user.getIdToken();
      const endpoint = isFollowing 
        ? `${APIConfig.serverURI}profiles/${targetUserId}/unfollow/`
        : `${APIConfig.serverURI}profiles/${targetUserId}/follow/`;
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to update follow status');
      }

      setIsFollowing(!isFollowing);
    } catch (err) {
      console.error('Follow error:', err);
    } finally {
      setIsFollowLoading(false);
    }
  };

  const handleShowMore = async () => {
    try {
      setLoading(true);
      const headers = {};
      if (user) {
        const token = await user.getIdToken();
        headers.Authorization = `JWT ${token}`;
      }

      const nextLimit = displayedMixtapes + 5;
      const response = await fetch(
        `${APIConfig.serverURI}mixtapes/by_username/${username}/?limit=${nextLimit}`,
        { headers }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch more mixtapes');
      }

      const data = await response.json();
      setMixtapes(data.mixtapes || []);
      setDisplayedMixtapes(nextLimit);
      setHasMore((data.total_count || data.mixtapes.length) > nextLimit);
    } catch (err) {
      console.error('Error loading more mixtapes:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading || isProfileLoading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error || !userProfile) {
    return <div className="p-4 text-red-500">{error || 'Profile not found'}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      {/* Profile Header */}
      <div className="mb-8 p-6 border border-black dark:border-white">
        <div className="flex items-start justify-between">
          <div>
            <h1 className="text-2xl font-mono mb-2">{userProfile.username}</h1>
            <p className="text-gray-600 dark:text-gray-400 font-mono mb-4">
              {userProfile.bio || "No bio yet"}
            </p>
            <div className="flex space-x-4 text-sm font-mono">
              <span>{userProfile.followers_count} followers</span>
              <span>{userProfile.following_count} following</span>
              <span>{mixtapes.length} mixtapes</span>
            </div>
          </div>
          
          {user && user.uid !== userProfile.id && (
            <button
              onClick={handleFollow}
              disabled={isFollowLoading}
              className={`
                px-4 py-2
                border border-black dark:border-white
                font-mono
                ${isFollowing ? 'bg-black text-white dark:bg-white dark:text-black' : ''}
                hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black
                transition-colors
                disabled:opacity-50
              `}
            >
              {isFollowLoading ? '...' : (isFollowing ? 'Following' : 'Follow')}
            </button>
          )}
        </div>
      </div>

      {/* Mixtapes List */}
      <div className="space-y-4">
        <h2 className="text-xl font-mono mb-4">Mixtapes</h2>
        {mixtapes.length === 0 ? (
          <p className="text-gray-600 dark:text-gray-400 font-mono text-center py-8">
            No mixtapes yet
          </p>
        ) : (
          <>
            <div className="space-y-4">
              {mixtapes.slice(0, displayedMixtapes).map((mixtape) => {
                const isOwnProfile = user && currentUserId === mixtape.creator_id;
                return (
                  <MixtapeListItem
                    key={mixtape.id}
                    mixtape={mixtape}
                    onClick={() => handleMixtapeClick(mixtape)}
                    onFire={() => handleFire(mixtape)}
                    isFiring={firingMixtapeId === mixtape.id}
                    isLiked={mixtape.voted}
                    showActions={true}
                    currentUserId={currentUserId}
                    isSelected={false}
                    isEditing={false}
                    isPublishing={false}
                    isDeleting={false}
                    isRenaming={false}
                    isLoading={false}
                    newName=""
                    onRenameStart={() => {}}
                    onRenameSubmit={() => {}}
                    onPublishToggle={() => {}}
                    onDelete={() => {}}
                    setNewName={() => {}}
                    showPublishStatus={isOwnProfile}
                  />
                );
              })}
            </div>
            
            {hasMore && (
              <div className="flex justify-center mt-6">
                <button
                  onClick={handleShowMore}
                  className="
                    px-4 py-2
                    border border-black dark:border-white
                    font-mono
                    hover:bg-gray-100 dark:hover:bg-neutral-800
                    transition-colors
                  "
                >
                  Show More
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {showLoginModal && (
        <LoginModal onClose={() => setShowLoginModal(false)} />
      )}
    </div>
  );
}

import React, { useState, useMemo, useEffect } from 'react';

const VariantSelector = React.memo(({ options, variants, onVariantSelect }) => {
  const initialOptions = useMemo(() => {
    const init = {};
    for (const option of options) {
      init[option.uuid] = '';
    }
    return init;
  }, [options]);

  const [selectedOptions, setSelectedOptions] = useState(initialOptions);

  useEffect(() => {
    setSelectedOptions(initialOptions);
  }, [options]);

  // Get available options based on current selections
  const getAvailableOptionsForValue = (currentOption, valueId) => {
    // Create a copy of current selections with the new value
    const testSelections = {
      ...selectedOptions,
      [currentOption.uuid]: valueId
    };

    // Find variants that match current selections
    return variants.filter(variant => {
      return Object.entries(testSelections).every(([optId, valId]) => {
        // Skip empty selections
        if (!valId) return true;
        return variant.option_values[optId] === valId;
      });
    });
  };

  const selectedVariant = useMemo(() => {
    if (Object.values(selectedOptions).some(val => !val)) {
      return null;
    }
    return variants.find(variant => {
      const variantOptionValues = variant.option_values;
      return Object.entries(selectedOptions).every(
        ([optionId, valueId]) => variantOptionValues[optionId] === valueId
      );
    });
  }, [variants, selectedOptions]);

  const handleOptionChange = (optionId, valueId) => {
    setSelectedOptions(prev => {
      const updated = { ...prev, [optionId]: valueId };
      if (!Object.values(updated).some(val => !val)) {
        const variant = variants.find(v => 
          Object.entries(updated).every(
            ([optId, valId]) => v.option_values[optId] === valId
          )
        );
        if (variant) {
          onVariantSelect(variant, updated);
        }
      }
      return updated;
    });
  };

  return (
    <div className="space-y-4">
      {options.map(option => {
        // Get available variants for each option value
        const availableVariantsMap = {};
        option.values.forEach(value => {
          const availableVariants = getAvailableOptionsForValue(option, value.uuid);
          availableVariantsMap[value.uuid] = availableVariants;
        });

        return (
          <div key={option.uuid}>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              {option.name}
            </label>
            <select
              value={selectedOptions[option.uuid]}
              onChange={(e) => handleOptionChange(option.uuid, e.target.value)}
              className="block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select {option.name}</option>
              {option.values.map(value => {
                const availableVariants = availableVariantsMap[value.uuid];
                const totalStock = availableVariants.reduce((sum, v) => sum + v.stock, 0);
                const isAvailable = availableVariants.length > 0 && totalStock > 0;
                
                return (
                  <option 
                    key={value.uuid} 
                    value={value.uuid}
                    disabled={!isAvailable}
                  >
                    {value.name} {totalStock > 0 ? `(${totalStock} available)` : '(Out of stock)'}
                  </option>
                );
              })}
            </select>
          </div>
        );
      })}
      
      {selectedVariant && (
        <div className={`text-sm ${selectedVariant.stock > 0 ? 'text-green-600' : 'text-red-500'} font-medium`}>
          {selectedVariant.stock > 0 ? 
            `${selectedVariant.stock} in stock` : 
            'Out of stock'
          }
        </div>
      )}
    </div>
  );
});

export default VariantSelector;

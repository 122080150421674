import axios from 'axios';
import APIConfig from '../APIConfig';
const API_URL = APIConfig.baseURL();

class OrderService {
  static async getOrders(token) {
    try {
      const response = await axios.get(`${API_URL}/api/v1/commerce/orders/`, {
        withCredentials: true,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  }

  static async getSellerOrders(token) {
    try {
      const response = await axios.get(`${API_URL}/api/v1/commerce/orders/seller/orders/`, {
        withCredentials: true,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching seller orders:', error);
      throw error;
    }
  }

  static async getOrderDetails(orderId, token) {
    try {
      const response = await axios.get(`${API_URL}/api/v1/commerce/orders/${orderId}/`, {
        withCredentials: true,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching order details:', error);
      throw error;
    }
  }

  static async completeCheckout(sessionId, token) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/commerce/payments/complete-checkout/`,
        { session_id: sessionId },
        {
          withCredentials: true,
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error completing checkout:', error);
      throw error;
    }
  }

  static async deleteOrder(orderId, token) {
    try {
      await axios.delete(`${API_URL}/api/v1/commerce/orders/${orderId}/`, {
        withCredentials: true,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        }
      });
    } catch (error) {
      console.error('Error deleting order:', error);
      throw error;
    }
  }

  // Seller order methods
  static async getSellerOrders(token) {
    try {
      console.log('[OrderService] Fetching seller orders...');
      const url = `${API_URL}/api/v1/commerce/orders/seller/orders/`;
      console.log('[OrderService] Request URL:', url);
      console.log('[OrderService] Request headers:', {
        Authorization: `JWT ${token.substring(0, 20)}...`,
        'Content-Type': 'application/json',
      });
      
      const startTime = Date.now();
      console.log('[OrderService] Starting request...');
      
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        validateStatus: function (status) {
          // Log all responses, even non-200s
          console.log(`[OrderService] Received response with status: ${status}`);
          return true; // Don't reject any status codes
        }
      });
      
      const endTime = Date.now();
      
      if (!response.ok && response.status !== 200) {
        console.error('[OrderService] Request failed:', {
          status: response.status,
          statusText: response.statusText,
          data: response.data,
          headers: response.headers
        });
        throw new Error(`Request failed with status ${response.status}`);
      }
      
      console.log('[OrderService] Seller orders response:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        timing: `${endTime - startTime}ms`,
        dataLength: response.data?.length || 0,
        sample: response.data?.[0] ? {
          id: response.data[0].id,
          created_at: response.data[0].created_at,
          items_count: response.data[0].items?.length,
          total_amount: response.data[0].total_amount
        } : null
      });
      
      return response.data;
    } catch (error) {
      console.error('[OrderService] Error fetching seller orders:', {
        message: error.message,
        response: error.response ? {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
          headers: error.response.headers
        } : 'No response',
        request: error.request ? {
          method: error.request.method,
          url: error.request.url,
          headers: error.request.headers
        } : 'Request setup failed',
        timing: Date.now()
      });
      throw error;
    }
  }

  static async updateOrderFulfillmentStatus(orderId, status, token) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/commerce/orders/seller/orders/${orderId}/update_fulfillment_status/`,
        { status },
        {
          withCredentials: true,
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating order status:', error);
      throw error;
    }
  }

  static async updateItemFulfillmentStatus(orderId, itemId, status, token) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/commerce/orders/seller/orders/${orderId}/update_item_status/`,
        { item_id: itemId, status },
        {
          withCredentials: true,
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating item status:', error);
      throw error;
    }
  }

  static async updateOrderItemStatus(token, itemId, updateData) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/commerce/orders/seller/item/${itemId}/fulfillment/`,
        updateData,
        {
          withCredentials: true,
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating order item status:', error);
      throw error;
    }
  }

  // Artist order methods
  static async getArtistOrders() {
    try {
      console.log('[OrderService] Fetching artist orders...');
      const url = `${API_URL}/api/v1/commerce/orders/artist/orders/`;
      console.log('[OrderService] Request URL:', url);
      
      const startTime = Date.now();
      console.log('[OrderService] Starting request...');
      
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        validateStatus: function (status) {
          console.log(`[OrderService] Received response with status: ${status}`);
          return true;
        }
      });
      
      const endTime = Date.now();
      
      if (!response.ok && response.status !== 200) {
        console.error('[OrderService] Request failed:', {
          status: response.status,
          statusText: response.statusText,
          data: response.data,
          headers: response.headers
        });
        throw new Error(`Request failed with status ${response.status}`);
      }
      
      console.log('[OrderService] Artist orders response:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        timing: `${endTime - startTime}ms`,
        dataLength: response.data?.length || 0,
        sample: response.data?.[0] ? {
          id: response.data[0].id,
          created_at: response.data[0].created_at,
          items_count: response.data[0].items?.length
        } : null
      });
      
      return response.data;
    } catch (error) {
      console.error('[OrderService] Error fetching artist orders:', {
        message: error.message,
        response: error.response ? {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
          headers: error.response.headers
        } : 'No response',
        request: error.request ? {
          method: error.request.method,
          url: error.request.url,
          headers: error.request.headers
        } : 'Request setup failed',
        timing: Date.now()
      });
      throw error;
    }
  }

  static async getArtistOrders(token) {
    try {
      console.log('[OrderService] Fetching artist orders...');
      const url = `${API_URL}/api/v1/commerce/orders/artist/orders/`;
      console.log('[OrderService] Request URL:', url);
      
      const startTime = Date.now();
      console.log('[OrderService] Starting request...');
      
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        validateStatus: function (status) {
          console.log(`[OrderService] Received response with status: ${status}`);
          return true;
        }
      });
      
      const endTime = Date.now();
      
      if (!response.ok && response.status !== 200) {
        console.error('[OrderService] Request failed:', {
          status: response.status,
          statusText: response.statusText,
          data: response.data,
          headers: response.headers
        });
        throw new Error(`Request failed with status ${response.status}`);
      }
      
      console.log('[OrderService] Artist orders response:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        timing: `${endTime - startTime}ms`,
        dataLength: response.data?.length || 0,
        sample: response.data?.[0] ? {
          id: response.data[0].id,
          created_at: response.data[0].created_at,
          items_count: response.data[0].items?.length
        } : null
      });
      
      return response.data;
    } catch (error) {
      console.error('[OrderService] Error fetching artist orders:', {
        message: error.message,
        response: error.response ? {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
          headers: error.response.headers
        } : 'No response',
        request: error.request ? {
          method: error.request.method,
          url: error.request.url,
          headers: error.request.headers
        } : 'Request setup failed',
        timing: Date.now()
      });
      throw error;
    }
  }
}

export default OrderService;

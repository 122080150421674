import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import storage from '../../../services/storage';
import TaskActions from './TaskActions';
import TrackNameEditor from './TrackNameEditor';
import useTrackEditor from './useTrackEditor';
import DeleteFileButton from '../../file_mgmt/DeleteFileButton';
import { TaskTypes } from './TaskTypes';
import TrackObjectCell from './TrackObjectCell';
import FileObjectCell from './FileObjectCell';

const Task = ({ task, index, album, column_id, taskType = TaskTypes.STANDARD, data }) => {
  const isFileCell = taskType === TaskTypes.STANDARD;
  return isFileCell ? (
    <FileObjectCell task={task} index={index} album={album} />

  ) : (
    <TrackObjectCell
      task={task}
      metadata={task.metadata}
      index={index}
      album={album}
      column_id={column_id}
      data={data}
    />
  );
};

export default Task;

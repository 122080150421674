// MerchItemView.jsx

import React from 'react';
import { Link, useParams } from 'react-router-dom';

const MerchItemView = ({ item }) => {
  // Extract merch_item if it exists, otherwise use the item directly
  const merchItem = item.merch_item || item;
  const { slug: artistSlug } = useParams();
  
  return (
    <Link 
      to={artistSlug ? `/a/${artistSlug}/merch/${merchItem.id}` : `/merch/${merchItem.id}`}
      className="block border border-black hover:bg-gray-50 transition-colors"
    >
      <div className="aspect-square">
        <img
          src={merchItem.image_url}
          alt={merchItem.name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-2 font-mono">
        <div>{merchItem.name}</div>
        <div>${(merchItem.price / 100).toFixed(2)}</div>
      </div>
    </Link>
  );
};

export default MerchItemView;

import React, { useEffect } from "react";
import DraftListView from "../artist_dashboard/DraftListView";
import useRealTimeDrafts from "../artist_dashboard/drafting/hooks/useRealTimeDrafts";
import useAuth from "../../hooks/useAuth";
import { useUserProfileContext } from "../../context/UserProfileContext";
import NoArtistsOnboarding from "../artist_dashboard/dashboard/onboarding/NoArtistsOnboarding";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const DraftsView = () => {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const { drafts, loading: draftsLoading, error: draftsError } = useRealTimeDrafts(user);
  const { linkedArtists, loading: profileLoading, error: profileError } = useUserProfileContext();

  const generateUniqueDraftName = (existingDrafts) => {
    const baseNames = existingDrafts
      .map(draft => draft.name)
      .filter(name => name.startsWith('Album Draft #'));
    
    if (baseNames.length === 0) {
      return 'Album Draft #1';
    }

    // Extract numbers from existing draft names
    const numbers = baseNames
      .map(name => {
        const match = name.match(/Album Draft #(\d+)/);
        return match ? parseInt(match[1], 10) : 0;
      })
      .filter(num => !isNaN(num));

    // Find the highest number and increment by 1
    const nextNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
    return `Album Draft #${nextNumber}`;
  };

  const handleCreateDraft = async () => {
    try {
      if (!user || !linkedArtists?.length) {
        console.error("Cannot create draft: no user or linked artists");
        return;
      }

      const artistId = linkedArtists[0].id; // Use first artist by default
      
      // Create a new draft document
      const draftRef = firebase.firestore()
        .collection('studio')
        .doc(user.uid)
        .collection('drafts')
        .doc();

      const draftData = {
        artist_id: artistId,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        last_edited: firebase.firestore.FieldValue.serverTimestamp(),
        name: generateUniqueDraftName(drafts),
        status: "draft"
      };

      await draftRef.set(draftData);
      navigate("/create/album", { state: { album: { docId: draftRef.id, ...draftData } } });
      
    } catch (error) {
      console.error("Error creating draft:", error);
    }
  };

  // Debug logging
  useEffect(() => {
    console.log('DraftsView State:', {
      authLoading,
      draftsLoading,
      profileLoading,
      hasUser: !!user,
      hasLinkedArtists: !!linkedArtists?.length,
      draftsCount: drafts?.length,
      profileError,
      draftsError
    });
  }, [authLoading, draftsLoading, profileLoading, user, linkedArtists, drafts, profileError, draftsError]);

  if (authLoading || profileLoading || draftsLoading) {
    return (
      <div className="font-mono p-4">
        <div className="border border-black dark:border-white p-4">
          <p className="text-black dark:text-white">
            {authLoading && "Authenticating..."}
            {profileLoading && "Loading profile..."}
            {draftsLoading && "Loading drafts..."}
          </p>
        </div>
      </div>
    );
  }

  if (profileError) {
    return (
      <div className="font-mono p-4">
        <div className="border border-black dark:border-white p-4">
          <p className="text-black dark:text-white">
            Error loading profile: {profileError.message}
          </p>
        </div>
      </div>
    );
  }

  if (!linkedArtists?.length) {
    return <NoArtistsOnboarding />;
  }

  if (draftsError) {
    return (
      <div className="font-mono p-4">
        <div className="border border-black dark:border-white p-4">
          <p className="text-black dark:text-white">
            Error loading drafts: {draftsError.message}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="font-mono bg-white dark:bg-black text-black dark:text-white min-h-screen p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-bold">Your Drafts</h1>
        <button
          onClick={handleCreateDraft}
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors duration-200 ease-in-out"
        >
          Create New Draft
        </button>
      </div>
      <DraftListView drafts={drafts} />
    </div>
  );
};

export default DraftsView;

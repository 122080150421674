import React from 'react';

/**
 * A simplified component for selecting shipping destinations.
 * @param {Object[]} zones - Available shipping zones
 * @param {Object} value - Currently selected value
 * @param {Function} onChange - Change handler
 * @param {Object[]} existingRates - List of existing rates to check for used zones
 * @param {boolean} disabled - Whether the entire select is disabled
 */
export function ZoneOrCountrySelect({ zones, value, onChange, existingRates = [], disabled }) {
  // Handle both new format (destination.type) and old format (destination_type)
  const currentValue = value?.type === 'zone' ? value.zoneId : 
                      value?.destination_type === 'zone' ? value.zone_id : '';

  const handleChange = (e) => {
    const val = e.target.value;
    onChange({ type: 'zone', zoneId: val });
  };

  // Get set of zones already used in existing rates
  const usedZones = new Set(
    existingRates
      .map(rate => {
        // Handle both formats
        if (rate.destination?.type === 'zone') {
          return rate.destination.zoneId;
        } else if (rate.destination_type === 'zone') {
          return rate.zone_id;
        }
        return null;
      })
      .filter(Boolean)
  );

  return (
    <select
      value={currentValue}
      onChange={handleChange}
      disabled={disabled}
      className="w-full min-w-[200px] border border-gray-300 dark:border-gray-600 rounded-md px-3 py-1.5 text-sm bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:focus:ring-blue-400"
    >
      <option value="">Select destination...</option>
      {zones.map(zone => {
        const isUsed = usedZones.has(zone.id);
        return (
          <option 
            key={zone.id} 
            value={zone.id}
            disabled={isUsed && currentValue !== zone.id}
            className={`
              ${zone.is_default ? 'font-medium' : ''} 
              ${isUsed && currentValue !== zone.id ? 'text-gray-400' : ''}
            `}
          >
            {zone.name}
          </option>
        );
      })}
    </select>
  );
}

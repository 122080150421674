import React, { useCallback, memo, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

const FileUpload = memo(({
  images = [],
  accept,
  onUploadComplete,
  onDeleteComplete,
  onReorderComplete,
  maxFiles,
  className
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = useCallback(async (event) => {
    console.log('FileUpload: File input change event triggered');
    const files = event.target.files;
    console.log('FileUpload: Selected files:', files);
    await handleFiles(files);
  }, [maxFiles, onUploadComplete]);

  const handleDrop = useCallback(async (event) => {
    event.preventDefault();
    console.log('FileUpload: Drop event triggered');
    event.stopPropagation();

    const droppedFiles = event.dataTransfer?.files;
    console.log('FileUpload: Dropped files:', droppedFiles);
    await handleFiles(droppedFiles);
  }, [maxFiles, onUploadComplete]);

  const handleFiles = useCallback(async (files) => {
    console.log('FileUpload: Processing files:', files);
    if (!files?.length) {
      console.log('FileUpload: No files to process');
      return;
    }

    if (maxFiles && files.length + images.length > maxFiles) {
      console.error(`FileUpload: Cannot upload more than ${maxFiles} files`);
      return;
    }

    try {
      console.log('FileUpload: Calling onUploadComplete with files');
      await onUploadComplete(files);
      console.log('FileUpload: Upload completed successfully');
      
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('FileUpload: Error processing files:', error);
      // Show error message to user
      if (error.response?.data?.detail) {
        alert(error.response.data.detail);
      } else if (error.response?.data?.error) {
        alert(error.response.data.error);
      } else {
        alert('Failed to upload image. Please try again.');
      }
    }
  }, [images.length, maxFiles, onUploadComplete]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  }, []);

  const handleDelete = useCallback((e, imageId) => {
    e.preventDefault();
    e.stopPropagation();
    onDeleteComplete(imageId);
  }, [onDeleteComplete]);

  const handleDragEnd = useCallback((result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);

    onReorderComplete(reorderedImages.map((img, index) => ({
      id: img.id,
      order: index
    })));
  }, [images, onReorderComplete]);

  return (
    <div className={classNames('space-y-4', className)}>
      <div 
        className="flex items-center justify-center w-full"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <label 
          className={classNames(
            "flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer",
            "bg-gray-50 dark:bg-gray-700 hover:bg-gray-100",
            "border-gray-300 dark:border-gray-600 dark:hover:border-gray-500",
            "transition-colors duration-200 ease-in-out"
          )}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Click to upload</span> or drag and drop
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {maxFiles ? `Maximum ${maxFiles} images` : 'Upload multiple images'}
            </p>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept={accept}
            multiple={!maxFiles || maxFiles > 1}
          />
        </label>
      </div>

      {images.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="images" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-wrap gap-4"
              >
                {images.map((image, index) => (
                  <Draggable
                    key={image.id}
                    draggableId={image.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classNames(
                          'relative group',
                          snapshot.isDragging ? 'z-50' : 'z-0'
                        )}
                      >
                        <img
                          src={image.url}
                          alt="Uploaded content"
                          className="w-24 h-24 object-cover rounded-lg"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                          <button
                            onClick={(e) => handleDelete(e, image.id)}
                            className="text-white hover:text-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
});

FileUpload.displayName = 'FileUpload';

export default FileUpload;

import React from 'react';
import { format } from 'date-fns';
import { useStripeProfile } from '../../hooks/useStripeProfile';

function StripeProfileInfo() {
  const { loading, error, profileData } = useStripeProfile();
  const customerData = profileData?.customer_info?.[0];

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-gray-100"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 dark:text-red-400 p-4 text-center">
        {error}
      </div>
    );
  }

  if (!customerData) {
    return (
      <div className="text-gray-500 dark:text-gray-400 p-4 text-center">
        No billing profile information available.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Basic Information</h4>
        <div className="grid grid-cols-2 gap-4 text-sm">
          {customerData.email && (
            <div>
              <span className="text-gray-500 dark:text-gray-400">Email:</span>
              <div className="font-medium">{customerData.email}</div>
            </div>
          )}
          {customerData.name && (
            <div>
              <span className="text-gray-500 dark:text-gray-400">Name:</span>
              <div className="font-medium">{customerData.name}</div>
            </div>
          )}
          {customerData.phone && (
            <div>
              <span className="text-gray-500 dark:text-gray-400">Phone:</span>
              <div className="font-medium">{customerData.phone}</div>
            </div>
          )}
          {customerData.created && (
            <div>
              <span className="text-gray-500 dark:text-gray-400">Member Since:</span>
              <div className="font-medium">
                {format(new Date(customerData.created * 1000), 'MMM d, yyyy')}
              </div>
            </div>
          )}
        </div>
      </div>

      {customerData.subscriptions?.data?.length > 0 && (
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Active Subscriptions</h4>
          <div className="space-y-2">
            {customerData.subscriptions.data.map(sub => (
              <div key={sub.id} className="bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                <div className="flex justify-between items-center">
                  <span className="font-medium">{sub.plan.nickname || 'Subscription'}</span>
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    sub.status === 'active' 
                      ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                      : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                  }`}>
                    {sub.status}
                  </span>
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  ${(sub.plan.amount / 100).toFixed(2)} / {sub.plan.interval}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {customerData.invoice_settings?.default_payment_method && (
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Default Payment Method</h4>
          <div className="bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
            <div className="flex items-center space-x-2">
              <span className="text-gray-600 dark:text-gray-300">
                {customerData.invoice_settings.default_payment_method.card.brand} •••• {customerData.invoice_settings.default_payment_method.card.last4}
              </span>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Expires {customerData.invoice_settings.default_payment_method.card.exp_month}/{customerData.invoice_settings.default_payment_method.card.exp_year}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StripeProfileInfo;

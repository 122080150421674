import axios from 'axios';
import auth from './auth';
import APIConfig from '../APIConfig';
const API_URL = APIConfig.baseURL();

class AddressService {
  // Shipping Addresses
  static async getShippingAddresses() {
    const headers = await auth.getAuthHeaders();
    const response = await axios.get(`${API_URL}/api/v1/commerce/shipping/shipping-addresses/`, {
      withCredentials: true,
      headers
    });
    return response.data;
  }

  static async createShippingAddress(addressData) {
    const headers = await auth.getAuthHeaders();
    const response = await axios.post(`${API_URL}/api/v1/commerce/shipping/shipping-addresses/`, addressData, {
      withCredentials: true,
      headers
    });
    return response.data;
  }

  static async updateShippingAddress(id, addressData) {
    const headers = await auth.getAuthHeaders();
    const response = await axios.put(`${API_URL}/api/v1/commerce/shipping/shipping-addresses/${id}/`, addressData, {
      withCredentials: true,
      headers
    });
    return response.data;
  }

  static async deleteShippingAddress(id) {
    const headers = await auth.getAuthHeaders();
    await axios.delete(`${API_URL}/api/v1/commerce/shipping/shipping-addresses/${id}/`, {
      withCredentials: true,
      headers
    });
  }

  static async setDefaultShippingAddress(id) {
    const headers = await auth.getAuthHeaders();
    const response = await axios.post(`${API_URL}/api/v1/commerce/shipping/shipping-addresses/${id}/set_default/`, {}, {
      withCredentials: true,
      headers
    });
    return response.data;
  }

  // Billing Addresses
  static async getBillingAddresses() {
    const headers = await auth.getAuthHeaders();
    const response = await axios.get(`${API_URL}/api/v1/commerce/shipping/billing-addresses/`, {
      withCredentials: true,
      headers
    });
    return response.data;
  }

  static async createBillingAddress(addressData) {
    const headers = await auth.getAuthHeaders();
    const response = await axios.post(`${API_URL}/api/v1/commerce/shipping/billing-addresses/`, addressData, {
      withCredentials: true,
      headers
    });
    return response.data;
  }

  static async updateBillingAddress(id, addressData) {
    const headers = await auth.getAuthHeaders();
    const response = await axios.put(`${API_URL}/api/v1/commerce/shipping/billing-addresses/${id}/`, addressData, {
      withCredentials: true,
      headers
    });
    return response.data;
  }

  static async deleteBillingAddress(id) {
    const headers = await auth.getAuthHeaders();
    await axios.delete(`${API_URL}/api/v1/commerce/shipping/billing-addresses/${id}/`, {
      withCredentials: true,
      headers
    });
  }

  static async setDefaultBillingAddress(id) {
    const headers = await auth.getAuthHeaders();
    const response = await axios.post(`${API_URL}/api/v1/commerce/shipping/billing-addresses/${id}/set_default/`, {}, {
      withCredentials: true,
      headers
    });
    return response.data;
  }
}

export default AddressService;

// FloatingPlayer.jsx
import React from 'react';
import { usePlayer } from '../contexts/PlayerContext';

export const FloatingPlayer = () => {
  const { 
    currentTrack, 
    isPlaying, 
    play, 
    pause, 
    next,
    previous,
    progress,
    duration
  } = usePlayer();

  if (!currentTrack) {
    return null;
  }

  const trackName = currentTrack.name || 'Unknown Track';
  const artistName = currentTrack.artist || 'Unknown Artist';
  
  const handlePlayPause = () => {
    if (isPlaying) {
      pause();
    } else {
      play(currentTrack);
    }
  };

  const formatDuration = (time) => {
    if (!time || isNaN(time) || time <= 0) {
      return '--:--';
    }
    
    try {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    } catch (error) {
      console.error('Error formatting duration:', error);
      return '--:--';
    }
  };

  const currentTime = formatDuration(progress);
  const totalDuration = formatDuration(duration);

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-[#f3f3f3] dark:bg-black text-black dark:text-white font-mono border-t border-black">
      {/* Progress bar */}
      <div className="w-full h-1 bg-gray-200 dark:bg-gray-800">
        <div 
          className="h-full bg-black dark:bg-white transition-all" 
          style={{ width: `${(progress / duration) * 100}%` }}
        />
      </div>
      
      {/* Controls and info */}
      <div className="p-2 text-sm flex justify-between items-center">
        <div className="flex space-x-4 items-center">
          <button onClick={previous} className="hover:underline">
            ← prev
          </button>
          <button onClick={handlePlayPause} className="hover:underline min-w-[4ch]">
            {isPlaying ? '[ || ]' : '[ > ]'}
          </button>
          <button onClick={next} className="hover:underline">
            next →
          </button>
        </div>
        
        <div className="flex-1 text-center truncate px-4">
          <span className="font-bold">{trackName}</span>
          <span className="mx-2">–</span>
          <span>{artistName}</span>
        </div>
        
        <div className="font-mono text-sm tabular-nums">
          {currentTime} / {totalDuration}
        </div>
      </div>
    </div>
  );
};
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { usePlayer } from '../../contexts/PlayerContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import ScrollingText from '../shared/ScrollingText';
import APIConfig from '../../APIConfig';

/**
 * Renders the details (tracks) of a selected Mixtape.
 */
export default function MixtapeDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(firebase.auth());
  const { play, pause, isPlaying, currentTrack } = usePlayer();
  const [mixtape, setMixtape] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState('');
  const [isVoting, setIsVoting] = useState(false);

  // Check if current user is the creator
  const isCreator = user && mixtape && user.email === mixtape.created_by;

  const handlePublishToggle = async () => {
    if (!isCreator) return;
    
    setIsPublishing(true);
    try {
      const token = await user.getIdToken();
      const response = await fetch(`${APIConfig.serverURI}mixtapes/${mixtape.id}/`, {
        method: 'PATCH',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          is_published: !mixtape.is_published
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update mixtape');
      }

      const updatedMixtape = await response.json();
      setMixtape(updatedMixtape);
    } catch (err) {
      console.error('Error updating mixtape:', err);
      setError('Failed to update mixtape');
    } finally {
      setIsPublishing(false);
    }
  };

  const handleDelete = async () => {
    if (!isCreator) return;
    
    if (!window.confirm('Are you sure you want to delete this mixtape?')) {
      return;
    }

    try {
      const token = await user.getIdToken();
      const response = await fetch(`${APIConfig.serverURI}mixtapes/${mixtape.id}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `JWT ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete mixtape');
      }

      navigate('/mixtapes');
    } catch (err) {
      console.error('Error deleting mixtape:', err);
      setError('Failed to delete mixtape');
    }
  };

  const handleRename = async (e) => {
    e.preventDefault();
    if (!isCreator || !newName.trim()) return;
    
    setIsRenaming(true);
    try {
      const token = await user.getIdToken();
      const response = await fetch(`${APIConfig.serverURI}mixtapes/${mixtape.id}/`, {
        method: 'PATCH',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: newName.trim()
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to rename mixtape');
      }

      const updatedMixtape = await response.json();
      setMixtape(updatedMixtape);
      setIsRenaming(false);
      setNewName('');
    } catch (err) {
      console.error('Error renaming mixtape:', err);
      setError('Failed to rename mixtape');
    } finally {
      setIsRenaming(false);
    }
  };

  const handleVote = async () => {
    if (!user) {
      // TODO: Show login modal
      return;
    }

    try {
      setIsVoting(true);
      const token = await user.getIdToken();
      const response = await fetch(`${APIConfig.baseURL()}/api/v1/feed/${mixtape.id}/fire/`, {
        method: mixtape.voted ? 'DELETE' : 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Update mixtape state with new vote count
      setMixtape(prev => ({
        ...prev,
        vote_count: prev.vote_count + (prev.voted ? -1 : 1),
        voted: !prev.voted
      }));
    } catch (error) {
      console.error('Error voting:', error);
      setError('Failed to vote on mixtape');
    } finally {
      setIsVoting(false);
    }
  };

  const handlePlayPause = async (track) => {
    try {
      const playerTrack = {
        id: track.id,
        name: track.info.name,
        artist: track.info.artist,
        album: track.info.album?.name || mixtape.name,
        duration: track.info.duration,
        compressedUrl: track.info.compressed_file,
        losslessUrl: track.info.file,
        artwork_url: track.info.artwork || mixtape.artwork,
        compressed_artwork_url: track.info.compressed_artwork || mixtape.compressed_artwork,
      };

      if (currentTrack?.id === track.id) {
        if (isPlaying) {
          pause();
        } else {
          play(playerTrack);
        }
      } else {
        play(playerTrack);
      }
    } catch (err) {
      console.error('Error in handlePlayPause:', err);
      setError('An error occurred while trying to play the track.');
    }
  };

  useEffect(() => {
    const fetchMixtape = async () => {
      console.log("DEBUG: Fetching mixtape with ID:", id);
      
      try {
        const token = await user?.getIdToken();
        const response = await fetch(`${APIConfig.serverURI}mixtapes/${id}/`, {
          headers: token ? {
            'Authorization': `JWT ${token}`,
            'Content-Type': 'application/json',
          } : {
            'Content-Type': 'application/json',
          }
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch mixtape: ${response.status}`);
        }
        
        const data = await response.json();
        console.log("DEBUG: Parsed mixtape data:", data);

        // Fetch creator info
        const creatorResponse = await fetch(`${APIConfig.serverURI}profiles/by_username/${data.created_by}/`, {
          headers: token ? {
            'Authorization': `JWT ${token}`,
            'Content-Type': 'application/json',
          } : {
            'Content-Type': 'application/json',
          }
        });

        if (creatorResponse.ok) {
          const creatorData = await creatorResponse.json();
          data.creator = creatorData;
        }

        setMixtape(data);
        setNewName(data.name);
      } catch (err) {
        console.error("Error fetching mixtape:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchMixtape();
    }
  }, [id, user]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg font-mono">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-lg font-mono text-red-500 mb-4">Error: {error}</div>
        <button
          onClick={() => navigate(-1)}
          className="border border-black dark:border-white px-3 py-1 text-sm font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
        >
          Go Back
        </button>
      </div>
    );
  }

  if (!mixtape) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-lg font-mono mb-4">Mixtape not found</div>
        <button
          onClick={() => navigate(-1)}
          className="border border-black dark:border-white px-3 py-1 text-sm font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
        >
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <div className="border border-black dark:border-white bg-white dark:bg-black text-black dark:text-white font-mono">
          <div className="flex justify-between items-center p-4 border-b border-black dark:border-white">
            {isRenaming ? (
              <form onSubmit={handleRename} className="flex-1">
                <input
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="w-full bg-transparent border-b border-black dark:border-white focus:outline-none text-lg"
                  autoFocus
                />
              </form>
            ) : (
              <ScrollingText text={mixtape.name} className="text-lg font-bold" />
            )}
            <div className="flex items-center space-x-2">
              {isCreator && (
                <>
                  <button
                    onClick={() => setIsRenaming(!isRenaming)}
                    className="border border-black dark:border-white px-3 py-1 text-sm font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
                  >
                    {isRenaming ? 'Cancel' : 'Rename'}
                  </button>
                  <button
                    onClick={handlePublishToggle}
                    disabled={isPublishing}
                    className={`
                      border px-3 py-1 text-sm font-mono
                      ${mixtape.is_published
                        ? 'border-green-500 bg-green-500 text-white hover:bg-white hover:text-green-500 dark:hover:bg-black'
                        : 'border-black dark:border-white hover:bg-gray-100 dark:hover:bg-neutral-800'
                      }
                    `}
                  >
                    {isPublishing ? '...' : mixtape.is_published ? 'Published' : 'Publish'}
                  </button>
                  <button
                    onClick={handleDelete}
                    className="border border-red-500 px-3 py-1 text-sm font-mono text-red-500 hover:bg-red-500 hover:text-white"
                  >
                    Delete
                  </button>
                </>
              )}
              <button
                onClick={() => navigate(-1)}
                className="border border-black dark:border-white px-3 py-1 text-sm font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
              >
                Back
              </button>
            </div>
          </div>

          <div className="p-4">
            {error && (
              <div className="mb-4 p-3 border border-red-500 text-red-500 font-mono text-sm">
                {error}
                <button 
                  onClick={() => setError(null)} 
                  className="ml-2 hover:underline"
                >
                  Dismiss
                </button>
              </div>
            )}

            <div className="mb-4">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <span className="text-sm opacity-75">Created by</span>
                  <button 
                    onClick={() => navigate(`/u/${mixtape.creator_username}`)}
                    className="text-sm font-mono hover:underline cursor-pointer bg-transparent border-none p-0"
                  >
                    {mixtape.creator_username}
                  </button>
                </div>
                <button
                  onClick={handleVote}
                  disabled={isVoting}
                  className={`
                    text-xs px-3 py-1 rounded
                    border border-black dark:border-white
                    transition-all duration-200
                    ${isVoting ? 'opacity-50 cursor-not-allowed' : ''}
                    ${mixtape.voted
                      ? 'bg-red-500 text-white hover:bg-red-600'
                      : 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black'
                    }
                  `}
                >
                  {isVoting ? (
                    <span className="inline-block animate-pulse">🔥</span>
                  ) : (
                    `${mixtape.vote_count || 0} 🔥`
                  )}
                </button>
              </div>
              {mixtape.description && (
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {mixtape.description}
                </p>
              )}
            </div>

            <div className="space-y-2">
              {mixtape.mixtapetracks?.map((track) => (
                <div
                  key={track.id}
                  className="flex items-center justify-between p-2 border border-black dark:border-white hover:bg-gray-50 dark:hover:bg-neutral-900"
                >
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => handlePlayPause(track)}
                      className="w-8 h-8 flex items-center justify-center border border-black dark:border-white hover:bg-gray-100 dark:hover:bg-neutral-800"
                    >
                      {currentTrack?.id === track.id && isPlaying ? '⏸' : '▶'}
                    </button>
                    <div>
                      <div className="font-bold">{track.info.name}</div>
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        {track.info.artist}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

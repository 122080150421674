/**
 * Maps a draft task object to the standard track object format expected by the PlayerProvider
 */
export function mapDraftTrackToPlayerTrack(task, album) {
  return {
    id: task.id,
    name: task.trackName,
    artist: album?.artist_name || 'Unknown Artist',
    album: album?.name || 'Unknown Album',
    duration: task.duration || 0,
    compressedUrl: task.compressedUrl || task.url,
    losslessUrl: task.url,
    artwork_url: album?.artwork_url,
    compressed_artwork_url: album?.compressed_artwork_url,
  };
}

/**
 * Maps a track object from the album view to the standard track object format
 */
export function mapAlbumTrackToPlayerTrack(track, album) {
  return {
    id: track.id,
    name: track.name,
    artist: album?.artist_name || 'Unknown Artist',
    album: album?.name || 'Unknown Album',
    duration: track.duration || 0,
    compressedUrl: track.compressedUrl || track.url,
    losslessUrl: track.url,
    artwork_url: album?.artwork_url,
    compressed_artwork_url: album?.compressed_artwork_url,
  };
}

import api from '../api';
import { getAuth } from 'firebase/auth';

const getAuthHeaders = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No authenticated user');
  }
  const token = await user.getIdToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};

const handleFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (key === 'cover_image' && data[key] instanceof File) {
      formData.append(key, data[key]);
    } else if (Array.isArray(data[key])) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== null && data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export const eventService = {
  // Shows
  createShow: async (showData) => {
    const headers = await getAuthHeaders();
    const response = await api.post('/api/v1/shows/', handleFormData(showData), {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  },

  getShows: async () => {
    const headers = await getAuthHeaders();
    const response = await api.get('/api/v1/shows/', { headers });
    return response.data;
  },

  updateShow: async (id, showData) => {
    const headers = await getAuthHeaders();
    const response = await api.patch(`/api/v1/shows/${id}/`, handleFormData(showData), {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  },

  // Parties
  createParty: async (partyData) => {
    const headers = await getAuthHeaders();
    const response = await api.post('/api/v1/parties/', handleFormData(partyData), {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  },

  getParties: async () => {
    const headers = await getAuthHeaders();
    const response = await api.get('/api/v1/parties/', { headers });
    return response.data;
  },

  updateParty: async (id, partyData) => {
    const headers = await getAuthHeaders();
    const response = await api.patch(`/api/v1/parties/${id}/`, handleFormData(partyData), {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  },

  // Venues
  getVenues: async () => {
    const headers = await getAuthHeaders();
    const response = await api.get('/api/v1/venues/', { headers });
    return response.data?.results || [];
  },

  createVenue: async (venueData) => {
    const headers = await getAuthHeaders();
    const { address, ...venueFields } = venueData;
    
    // First create the address
    const addressResponse = await api.post('/api/v1/venue-addresses/', address, { headers });
    const addressId = addressResponse.data.id;

    // Then create the venue with the address ID
    const response = await api.post('/api/v1/venues/', {
      ...venueFields,
      address: addressId
    }, { headers });
    return response.data;
  }
};

import React, { useState, useEffect, useCallback } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import api from "../../../api";

import ProgressBar from "../../artist_dashboard/dashboard/ProgressBar";
import LightSpinner from "../../loading/LightSpinner";
import { sanitizeFilename } from "../../artist_dashboard/utilities/fileUtils";

const Uploader = () => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadTasks, setUploadTasks] = useState([]);

  const [inputKey, setInputKey] = useState(Date.now());
  const user = firebase.auth().currentUser;

  useEffect(() => {
    if (uploadComplete) {
      setFiles([]);
      setProgress(0);
      const timer = setTimeout(() => setUploadComplete(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [uploadComplete]);

  const onFileChange = (e) => {
    const selectedFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const newFile = e.target.files[i];
      newFile["id"] = Math.random();
      selectedFiles.push(newFile);
    }
    setFiles(selectedFiles);
  };

  const averageProgress = () => {
    let total = 0;
    files.forEach((file) => {
      total += file.progress || 0;
    });
    return files.length > 0 ? Math.trunc(total / files.length) : 0;
  };

  const updateDB = (url, filename) => {
    if (user != null) {
      api.setStudioData(url, user.uid, filename);
    }
  };

  const checkFileSize = (file) => {
    return Math.round(file.size / 1000000) > 2000;
  };

  const initializeUploadTask = (file) => {
    const sanitizedFilename = sanitizeFilename(file.name);
    console.log("sanitizedFilename", sanitizedFilename);
    return firebase
      .storage()
      .ref()
      .child(`studio/user_storage/${user.uid}/files/audio/${sanitizedFilename}`)
      .put(file);
  };

  const handleUploadProgress = (file, snapshot) => {
    const currentProgress =
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    file.progress = currentProgress;
    setProgress(averageProgress());
  };

  const handleUploadError = (error) => {
    console.log(error);
    setIsUploading(false);
  };

  const handleSuccessfulUpload = async (uploadTask, file) => {
    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
    const sanitizedFilename = sanitizeFilename(file.name);
    updateDB(downloadURL, sanitizedFilename); // Use sanitized filename to update the database
  };

  // Ensure the event is passed to this function
  const onUploadSubmission = async () => {
    try {
      setIsUploading(true);
      const newUploadTasks = [];

      for (const file of files) {
        if (checkFileSize(file)) {
          alert("Error: File too large");
          setIsUploading(false);
          return;
        }

        const uploadTask = initializeUploadTask(file);
        newUploadTasks.push(uploadTask);

        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => handleUploadProgress(file, snapshot),
          (error) => handleUploadError(error),
          () => handleSuccessfulUpload(uploadTask, file)
        );
      }

      setUploadTasks(newUploadTasks);

      await Promise.all(
        newUploadTasks.map((task) =>
          task.then((snap) => snap.ref.getDownloadURL())
        )
      );
      
      setUploadComplete(true);
      setInputKey(Date.now());
      setIsUploading(false);
    } catch (err) {
      console.error('Upload error:', err);
      setIsUploading(false);
    }
  };

  const cancelUploads = () => {
    uploadTasks.forEach((task) => task.cancel());
    setUploadTasks([]);
    setFiles([]); // Clearing the files list upon cancellation
    setProgress(0);
    setIsUploading(false);
    setInputKey(Date.now()); // Resetting the file input field
  };

  return (
    <div className="bg-gray-900 text-gray-200 p-4 border border-gray-700 font-mono rounded-lg">
      <div className="text-gray-400 text-xs py-2">
        * upload lossless audio (supported formats: AIF, WAV, FLAC)
      </div>
      <div className="flex items-center mb-4">
        <label className="flex items-center cursor-pointer hover:opacity-80 transition-opacity">
          <div className="bg-gray-700 text-gray-200 px-2 py-1 mr-2 rounded">+</div>
          <span className="text-sm hover:text-gray-300 transition-colors">Add File</span>
          <input
            key={inputKey}
            type="file"
            accept="audio/*"
            multiple
            onChange={onFileChange}
            className="hidden"
          />
        </label>
      </div>
      <ProgressBar progress={progress} uploadComplete={uploadComplete} />
      <ul className="list-none space-y-2">
        {files.map((file) => (
          <li
            key={file.name}
            className="bg-gray-800 p-2 border border-gray-700 rounded"
          >
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-300">
                {file.name} - size: {Math.round(file.size / 1000000)} mb
              </span>
              {isUploading && file.progress === 0 && <LightSpinner />}
            </div>
            <div className="mt-2">
              {file.progress > 0 && !uploadComplete && (
                <ProgressBar progress={file.progress} />
              )}
            </div>
          </li>
        ))}
      </ul>
      {isUploading && progress === 0 && <LightSpinner />}
      {!isUploading && files.length > 0 && (
        <button
          onClick={onUploadSubmission}
          className="flex items-center justify-center px-4 py-2 font-mono text-sm text-white bg-gray-700 hover:bg-gray-600 rounded transition-colors"
        >
          Upload
        </button>
      )}
      {isUploading && progress > 0 && (
        <div className="py-2">
          <button
            onClick={cancelUploads}
            className="flex items-center justify-center px-4 py-2 font-mono text-sm text-white bg-red-700 hover:bg-red-600 rounded transition-colors"
          >
            Cancel Upload
          </button>
        </div>
      )}
    </div>
  );
};

export default Uploader;
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DropdownItem = ({ icon, children, onClick, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={classNames(
      'w-full text-left px-4 py-2 text-sm',
      'flex items-center gap-2',
      'text-gray-700 dark:text-gray-200',
      'hover:bg-gray-100 dark:hover:bg-gray-700',
      'disabled:opacity-50 disabled:cursor-not-allowed',
      'transition-colors duration-150 ease-in-out'
    )}
  >
    {icon && <span className="w-5 h-5">{icon}</span>}
    {children}
  </button>
);

DropdownItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const Dropdown = ({ 
  trigger,
  items,
  disabled,
  align = 'right',
  width = 'w-56',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const alignmentClasses = {
    left: 'left-0',
    right: 'right-0',
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Trigger Button */}
      <div onClick={() => !disabled && setIsOpen(!isOpen)}>
        {trigger}
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className={classNames(
            'absolute z-50 mt-2',
            alignmentClasses[align],
            width,
            'bg-white dark:bg-gray-800',
            'rounded-lg shadow-lg',
            'border border-gray-200 dark:border-gray-700',
            'py-1'
          )}
        >
          {items.map((item, index) => (
            <DropdownItem
              key={index}
              icon={item.icon}
              onClick={() => {
                item.onClick();
                setIsOpen(false);
              }}
              disabled={item.disabled}
            >
              {item.label}
            </DropdownItem>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  })).isRequired,
  disabled: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right']),
  width: PropTypes.string,
};

export default Dropdown;

// useFetchArtistData.js
import { useState, useEffect } from 'react';
import APIConfig from '../../../APIConfig'; // Adjust the path as needed
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const useFetchArtistData = (artistIdentifier) => {
  const [artistData, setArtistData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArtistData = async () => {
      if (!artistIdentifier) {
        console.log('No artist identifier provided');
        return;
      }
      
      setLoading(true);
      try {
        console.log('Fetching artist data for identifier:', artistIdentifier);
        const idToken = await firebase.auth().currentUser?.getIdToken(true);
        const url = APIConfig.artists(artistIdentifier);
        console.log('Fetching from URL:', url);
        
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            ...(idToken && { 'Authorization': `JWT ${idToken}` }),
          },
        });
        
        if (!response.ok) {
          console.error('HTTP error:', response.status, response.statusText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const artist = await response.json();
        console.log('Received artist data:', artist);
        
        if (artist) {
          setArtistData(artist);
        } else {
          console.error('No artist found');
          setError(new Error('Artist not found'));
        }
      } catch (e) {
        console.error('Error fetching artist:', e);
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchArtistData();
  }, [artistIdentifier]);

  return { artistData, loading, error };
};

export default useFetchArtistData;

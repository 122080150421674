import axios from 'axios';
import { getAuth } from 'firebase/auth';
import APIConfig from '../APIConfig';
const API_URL = APIConfig.baseURL();

// Helper function to get the current auth token
const getAuthToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    try {
      // Wait a second before getting the token to avoid timing issues
      await new Promise(resolve => setTimeout(resolve, 1000));
      return user.getIdToken(true);  // Force refresh token
    } catch (error) {
      console.error('Error getting auth token:', error);
      throw error;
    }
  }
  return null;
};

// Create axios instance with auth interceptor
const createAuthenticatedClient = async () => {
  const token = await getAuthToken();
  if (!token) {
    throw new Error('Authentication required');
  }
  
  const client = axios.create({
    baseURL: API_URL,
    headers: {
      'Authorization': `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  });

  // Add response interceptor to handle 401s
  client.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      
      // If we get a 401 and haven't retried yet
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        
        try {
          // Wait a second and get a fresh token
          await new Promise(resolve => setTimeout(resolve, 1000));
          const newToken = await getAuthToken();
          
          // Update the request header with the new token
          originalRequest.headers['Authorization'] = `JWT ${newToken}`;
          
          // Retry the request
          return axios(originalRequest);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          throw refreshError;
        }
      }
      
      return Promise.reject(error);
    }
  );
  
  return client;
};

const getHeaders = (token) => {
  if (!token) {
    throw new Error('Authentication required');
  }
  return { Authorization: `JWT ${token}` };
};

const calculateCartShipping = async (cartItems, shippingAddress) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken(true);
    if (!token) {
      throw new Error('Please log in to calculate shipping');
    }

    const headers = getHeaders(token);
    const response = await axios.post(
      `${API_URL}/api/v1/commerce/shipping/calculate-cart-shipping/`,
      {
        items: cartItems.map(item => ({
          merch_item: item.merch_item.id,
          quantity: item.quantity
        })),
        shipping_address: {
          recipient_name: shippingAddress.recipient_name || '',
          address_line_1: shippingAddress.address_line_1 || '',
          address_line_2: shippingAddress.address_line_2 || '',
          city: shippingAddress.city || '',
          state: shippingAddress.state || '',
          zip_code: shippingAddress.zip_code || '',
          country: typeof shippingAddress === 'string' ? shippingAddress : (shippingAddress.country || 'US'),
          delivery_instructions: shippingAddress.delivery_instructions || ''
        }
      },
      {
        withCredentials: true,
        headers
      }
    );

    // Transform the response to match the expected format
    const shipping_options = response.data.shipping_methods.map(method => ({
      id: method.id,
      name: method.name,
      shipping_type: method.shipping_type,
      amount: Math.round(parseFloat(method.total_cost) * 100), // Convert to cents
      formatted_amount: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: response.data.currency || 'USD'
      }).format(parseFloat(method.total_cost)),
      zone: method.zone,
      base_rate: method.base_rate,
      additional_unit_rate: method.additional_unit_rate
    }));

    return {
      shipping_options,
      currency: response.data.currency || 'USD'
    };
  } catch (error) {
    console.error('Error calculating shipping:', error);
    throw error;
  }
};

const getCountryOptions = () => {
  return [
    { value: 'US', label: 'United States' },
    { value: 'CA', label: 'Canada' },
    { value: 'rest_of_world', label: 'International' },
    // Add any additional countries here
  ];
};

class ShippingService {
  async getDefaultShipping() {
    try {
      const client = await createAuthenticatedClient();
      const response = await client.get('/api/v1/commerce/shipping/info/default_shipping/');
      return response.data;
    } catch (error) {
      console.error('Error getting default shipping:', error);
      throw error;
    }
  }

  async getShippingInfo(merchId) {
    try {
      const client = await createAuthenticatedClient();
      const response = await client.get(`/api/v1/commerce/shipping/info/${merchId}/`);
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        // Return null if not found
        return null;
      }
      throw error;
    }
  }

  async saveShippingInfo(shippingInfo) {
    console.log('Saving shipping info:', shippingInfo);
    try {
      const client = await createAuthenticatedClient();
      let response;
      if (shippingInfo.id) {
        // Update existing shipping info
        response = await client.put(`/api/v1/commerce/shipping/info/${shippingInfo.id}/`, shippingInfo);
      } else {
        // Create new shipping info
        response = await client.post('/api/v1/commerce/shipping/info/', {
          ...shippingInfo,
          merch_item: shippingInfo.merch_item_id || shippingInfo.merch_item // Support both formats
        });
      }
      console.log('Save response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error saving shipping info:', error.response?.data || error);
      throw error;
    }
  }

  async getAddresses() {
    const client = await createAuthenticatedClient();
    const response = await client.get('/api/v1/commerce/shipping/addresses/');
    return response.data;
  }

  async getBillingAddresses() {
    const client = await createAuthenticatedClient();
    const response = await client.get('/api/v1/commerce/shipping/billing/');
    return response.data;
  }

  async getAllShippingMethods() {
    const client = await createAuthenticatedClient();
    const response = await client.get('/api/v1/commerce/shipping/info/all_shipping_methods/');
    return response.data;
  }

  async applyExistingMethod(shippingInfoId, methodId) {
    const client = await createAuthenticatedClient();
    console.log('Applying existing method:', { shippingInfoId, methodId });
    const response = await client.post(`/api/v1/commerce/shipping/info/${shippingInfoId}/apply_existing_method/`, {
      shipping_method_id: methodId
    });
    console.log('Apply method response:', response.data);
    return response.data;
  }

  async copyShippingMethod(methodId, newName) {
    const client = await createAuthenticatedClient();
    const response = await client.post(`/api/v1/commerce/shipping/shipping/${methodId}/copy_method/`, {
      name: newName
    });
    return response.data;
  }

  async createShippingMethod(methodData) {
    try {
      const client = await createAuthenticatedClient();
      const response = await client.post('/api/v1/commerce/shipping/methods/', methodData);
      return response.data;
    } catch (error) {
      console.error('Error creating shipping method:', error.response?.data || error);
      throw error;  // Re-throw to propagate to UI
    }
  }

  async updateShippingMethod(methodId, methodData) {
    try {
      const client = await createAuthenticatedClient();
      const response = await client.put(`/api/v1/commerce/shipping/methods/${methodId}/`, methodData);
      return response.data;
    } catch (error) {
      console.error('Error updating shipping method:', error.response?.data || error);
      throw error;  // Re-throw to propagate to UI
    }
  }

  async removeShippingMethod(shippingInfoId, methodId) {
    try {
      const client = await createAuthenticatedClient();
      await client.post(`/api/v1/commerce/shipping/info/${shippingInfoId}/disassociate_method/`, {
        method_id: methodId
      });
    } catch (error) {
      console.error('Error removing shipping method:', error.response?.data || error);
      if (error.response?.status === 404) {
        // Method was already removed or doesn't exist
        return;
      }
      throw error;
    }
  }

  async disassociateShippingMethod(shippingInfoId, methodId) {
    const client = await createAuthenticatedClient();
    const response = await client.post(
      `/api/v1/commerce/shipping/info/${shippingInfoId}/disassociate_method/`,
      { method_id: methodId }
    );
    return response.data;
  }

  async deleteShippingMethod(methodId) {
    const client = await createAuthenticatedClient();
    const response = await client.delete(`/api/v1/commerce/shipping/methods/${methodId}/`);
    return response.data;
  }

  async getDefaultShippingConfig() {
    const client = await createAuthenticatedClient();
    const response = await client.get('/api/v1/commerce/shipping/info/default_shipping/');
    return response.data;
  }
}

const shippingServiceInstance = new ShippingService();

export const shippingService = {
  calculateCartShipping,
  getCountryOptions,
  getDefaultShipping: (...args) => shippingServiceInstance.getDefaultShipping(...args),
  getShippingInfo: (...args) => shippingServiceInstance.getShippingInfo(...args),
  saveShippingInfo: (...args) => shippingServiceInstance.saveShippingInfo(...args),
  getAddresses: (...args) => shippingServiceInstance.getAddresses(...args),
  getBillingAddresses: (...args) => shippingServiceInstance.getBillingAddresses(...args),
  getAllShippingMethods: (...args) => shippingServiceInstance.getAllShippingMethods(...args),
  applyExistingMethod: (...args) => shippingServiceInstance.applyExistingMethod(...args),
  copyShippingMethod: (...args) => shippingServiceInstance.copyShippingMethod(...args),
  createShippingMethod: (...args) => shippingServiceInstance.createShippingMethod(...args),
  updateShippingMethod: (...args) => shippingServiceInstance.updateShippingMethod(...args),
  deleteShippingMethod: (...args) => shippingServiceInstance.deleteShippingMethod(...args),
  removeShippingMethod: (...args) => shippingServiceInstance.removeShippingMethod(...args),
  disassociateShippingMethod: (...args) => shippingServiceInstance.disassociateShippingMethod(...args),
  getDefaultShippingConfig: (...args) => shippingServiceInstance.getDefaultShippingConfig(...args),
};

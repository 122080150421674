// profile_service.js;
import APIConfig from "../APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Cache for profile data
const profileCache = new Map();
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

const getMerch = async (slug) => {
  try {
    console.log('Fetching merch for slug:', slug);
    const response = await fetch(`${APIConfig.baseURL()}/api/v1/artists/${slug}/merch/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    console.log('Response headers:', response.headers);
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      const text = await response.text();
      console.error('Non-JSON response:', text);
      throw new Error('Invalid response format');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error in getMerch:', error);
    throw error;
  }
};

const getProfileInfo = async (idToken) => {
  // Check cache first
  const cachedData = profileCache.get(idToken);
  if (cachedData && Date.now() - cachedData.timestamp < CACHE_EXPIRY) {
    return {
      ok: true,
      json: () => Promise.resolve(cachedData.data)
    };
  }

  try {
    const response = await fetch(`${APIConfig.profiles()}me/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Cache the response data
    const data = await response.json();
    profileCache.set(idToken, {
      data,
      timestamp: Date.now()
    });

    return {
      ok: true,
      json: () => Promise.resolve(data)
    };
  } catch (error) {
    console.error('Error in getProfileInfo:', error);
    throw error;
  }
};

const clearProfileCache = (idToken) => {
  if (idToken) {
    profileCache.delete(idToken);
  } else {
    profileCache.clear();
  }
};

const profile_service = {
  getMerch,
  getProfileInfo,
  clearProfileCache,
};

export default profile_service;

import React from "react";

const ArtistMusicViewV2 = ({ albums, artistId }) => {
  if (!albums || albums.length === 0) {
    return <div className="font-mono text-center">No albums available</div>;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
      {albums.map((album) => (
        <div key={album.id} className="font-mono">
          <a href={`mxt://search/albums/${artistId}`} className="block">
            <img
              src={album.artwork_url || album.compressed_artwork_url}
              alt={album.name}
              className="w-full mb-4"
            />
          </a>
          <div className="text-sm">{album.name}</div>
          {album.price && (
            <div className="text-sm">${(album.price / 100).toFixed(2)}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ArtistMusicViewV2;

import React from 'react';
import PropTypes from 'prop-types';

export function SaveStatus({ 
  show, 
  success, 
  message 
}) {
  if (!show) return null;

  return (
    <div
      className={`fixed bottom-4 right-4 p-4 rounded-md ${
        success
          ? 'bg-green-100 text-green-800'
          : 'bg-red-100 text-red-800'
      }`}
    >
      {message}
    </div>
  );
}

SaveStatus.propTypes = {
  show: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

import { useState, useCallback } from 'react';
import { isEqual } from 'lodash';

// Helper function to ensure we always get a string name
function safeName(val) {
  if (!val) return '';
  if (typeof val === 'object' && val.name) {
    return String(val.name);
  }
  return String(val);
}

// Helper function to calculate the cartesian product of arrays
function cartesianProduct(arrays) {
  if (arrays.length === 0) return [[]];
  const result = [];
  for (let i = 0; i < arrays[0].length; i++) {
    for (let j = 0; j < cartesianProduct(arrays.slice(1)).length; j++) {
      result.push([arrays[0][i]].concat(cartesianProduct(arrays.slice(1))[j]));
    }
  }
  return result;
}

// Helper function to generate UUID using browser's crypto API
function generateUUID() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function useMerchOptions(initialOptions = []) {
  // Map the initial options and values, preserving UUIDs if provided
  const [options, setOptions] = useState(() =>
    (initialOptions || []).map(opt => ({
      uuid: opt.uuid || generateUUID(),
      name: safeName(opt.name),
      values: Array.isArray(opt.values)
        ? opt.values.map(val => ({
            uuid: val.uuid || generateUUID(),
            name: safeName(val.name || val)
          }))
        : []
    }))
  );

  const [optionErrors, setOptionErrors] = useState(
    (initialOptions || []).map(() => ({ name: '', values: '' }))
  );

  const [localValues, setLocalValues] = useState(
    (initialOptions || []).map(opt => {
      const valuesStr = Array.isArray(opt.values)
        ? opt.values.map(v => safeName(v.name)).join(', ')
        : '';
      return valuesStr;
    })
  );

  const resetOptions = useCallback((newOptions = []) => {
    const sanitizedOptions = newOptions.map(opt => ({
      uuid: opt.uuid || generateUUID(),
      name: safeName(opt.name),
      values: Array.isArray(opt.values)
        ? opt.values.map(val => ({
            uuid: val.uuid || generateUUID(),
            name: safeName(val.name || val)
          }))
        : []
    }));
    
    setOptions(sanitizedOptions);
    setOptionErrors(sanitizedOptions.map(() => ({ name: '', values: '' })));
    setLocalValues(sanitizedOptions.map(opt => opt.values.map(v => safeName(v.name)).join(', ')));
  }, []);

  const addOption = useCallback(() => {
    const newOption = {
      uuid: generateUUID(),
      name: '',
      values: []
    };

    setOptions(prev => [...prev, newOption]);
    setOptionErrors(prev => [...prev, { name: '', values: '' }]);
    setLocalValues(prev => [...prev, '']);
  }, []);

  const removeOption = useCallback((index) => {
    setOptions(prev => prev.filter((_, i) => i !== index));
    setOptionErrors(prev => prev.filter((_, i) => i !== index));
    setLocalValues(prev => prev.filter((_, i) => i !== index));
  }, []);

  const updateOptionName = useCallback((index, name) => {
    setOptions(prev => {
      // First check if this name already exists in other options
      const isDuplicate = prev.some((opt, i) => i !== index && opt.name.toLowerCase() === name.toLowerCase());
      if (isDuplicate) {
        setOptionErrors(prevErrors => prevErrors.map((err, i) => 
          i === index ? { ...err, name: 'Option name must be unique' } : err
        ));
        return prev; // Don't update if duplicate
      }

      // Update the name if it's not a duplicate
      return prev.map((opt, i) => 
        i === index ? { ...opt, name: safeName(name) } : opt
      );
    });
  }, []);

  const updateOptionValuesFromString = useCallback((index, valuesString) => {
    // Split and clean the values string
    const newNames = valuesString
      .split(',')
      .map(v => safeName(v.trim()))
      .filter(Boolean);

    setOptions(prev => {
      return prev.map((opt, i) => {
        if (i !== index) return opt;

        // Create a map of existing values by lowercase name for quick lookup
        const existingValueMap = {};
        opt.values.forEach(val => {
          existingValueMap[val.name.toLowerCase()] = val;
        });

        // Build the updated values array, preserving existing UUIDs where possible
        const updatedValues = newNames.map(name => {
          const lowerName = name.toLowerCase();
          // If we have an existing value with this name, use it
          if (existingValueMap[lowerName]) {
            return existingValueMap[lowerName];
          }
          // Otherwise create a new value with a new UUID
          return {
            uuid: generateUUID(),
            name: name
          };
        });

        return {
          ...opt,
          values: updatedValues
        };
      });
    });

    // Clear validation error when user starts typing
    setOptionErrors(prev => prev.map((err, i) => 
      i === index ? { ...err, values: '' } : err
    ));
  }, []);

  const validateOption = useCallback((index) => {
    const option = options[index];
    let isValid = true;
    const newErrors = { name: '', values: '' };

    // Validate name
    if (!option.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    } else {
      // Check for duplicate names
      const isDuplicate = options.some((opt, i) => i !== index && opt.name.toLowerCase() === option.name.toLowerCase());
      if (isDuplicate) {
        newErrors.name = 'Option name must be unique';
        isValid = false;
      }
    }

    // Validate values
    if (!option.values || option.values.length === 0) {
      newErrors.values = 'At least one value is required';
      isValid = false;
    } else {
      const names = option.values.map(v => v.name.toLowerCase());
      const duplicateValues = names.some((n, i) => names.indexOf(n) !== i);
      if (duplicateValues) {
        newErrors.values = 'Duplicate values are not allowed';
        isValid = false;
      }
    }

    setOptionErrors(prev => prev.map((err, i) => 
      i === index ? newErrors : err
    ));

    return isValid;
  }, [options]);

  const setLocalValue = useCallback((index, value) => {
    setLocalValues(prev => prev.map((v, i) => 
      i === index ? value : v
    ));
    
    // Clear validation error when user starts typing
    setOptionErrors(prev => prev.map((err, i) => 
      i === index ? { ...err, values: '' } : err
    ));
  }, []);

  return {
    options,
    optionErrors,
    localValues,
    addOption,
    removeOption,
    updateOptionName,
    updateOptionValuesFromString,
    setLocalValue,
    validateOption,
    resetOptions
  };
}

export function useMerchVariants(initialVariants = [], options = [], onVariantsChange) {
  // Sanitize variants to ensure they have a uuid and stable structure
  const sanitizeVariant = useCallback((variant) => {
    return {
      uuid: variant.uuid || generateUUID(),
      option_values: variant.option_values || {},
      stock: typeof variant.stock === 'number' ? variant.stock : 0
    };
  }, []);

  // Initialize variants
  const [variants, setVariants] = useState(() => (initialVariants || []).map(sanitizeVariant));

  // Each variant can have errors
  const [variantErrors, setVariantErrors] = useState(
    (initialVariants || []).map(() => ({ options: {}, stock: '' }))
  );

  const addVariant = useCallback(() => {
    const newVariant = {
      uuid: generateUUID(),
      option_values: {},
      stock: 0
    };
    setVariants(prev => {
      const newList = [...prev, newVariant];
      if (onVariantsChange) onVariantsChange(newList);
      return newList;
    });
    setVariantErrors(prev => [...prev, { options: {}, stock: '' }]);
  }, [onVariantsChange]);

  const removeVariant = useCallback((index) => {
    setVariants(prev => {
      const newList = prev.filter((_, i) => i !== index);
      if (onVariantsChange) onVariantsChange(newList);
      return newList;
    });
    setVariantErrors(prev => prev.filter((_, i) => i !== index));
  }, [onVariantsChange]);

  const updateVariant = useCallback((index, updatedVariant) => {
    setVariants(prev => {
      const sanitized = sanitizeVariant(updatedVariant);
      const newList = prev.map((v, i) => i === index ? sanitized : v);
      return newList;
    });
  }, [sanitizeVariant]);

  const validateVariant = useCallback((index) => {
    const variant = variants[index];
    if (!variant) return false;

    const errors = { options: {}, stock: '' };
    let isValid = true;

    // Check that required options have values
    options.forEach(option => {
      if (!option || !option.uuid) return;
      const chosenValue = variant.option_values[option.uuid];
      if (!chosenValue) {
        errors.options[option.uuid] = `${option.name} is required`;
        isValid = false;
      } else {
        // Validate that chosenValue actually corresponds to a real value in that option
        const optVal = option.values.find(v => v.uuid === chosenValue);
        if (!optVal) {
          errors.options[option.uuid] = `Invalid value chosen for ${option.name}`;
          isValid = false;
        }
      }
    });

    // Check for duplicate variants by comparing option_values sets
    const variantKey = JSON.stringify(variant.option_values);
    const hasDuplicate = variants.some((v, i) => i !== index && JSON.stringify(v.option_values) === variantKey);
    if (hasDuplicate) {
      errors.options.duplicate = 'This combination already exists';
      isValid = false;
    }

    // Validate stock
    if (typeof variant.stock !== 'number' || variant.stock < 0) {
      errors.stock = 'Stock cannot be negative';
      isValid = false;
    }

    setVariantErrors(prev => prev.map((err, i) => i === index ? errors : err));

    return isValid;
  }, [variants, options]);

  // We no longer recalculate variants when options change. If the parent needs to sync variants,
  // it can call resetVariants explicitly if desired.
  const resetVariants = useCallback((existingVariants = []) => {
    const sanitized = (existingVariants || []).map(sanitizeVariant);
    setVariants(sanitized);
    setVariantErrors(sanitized.map(() => ({ options: {}, stock: '' })));
    if (onVariantsChange) onVariantsChange(sanitized);
  }, [onVariantsChange, sanitizeVariant]);

  return {
    variants,
    variantErrors,
    addVariant,
    removeVariant,
    updateVariant,
    validateVariant,
    resetVariants,
  };
}
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import AddressService from '../../services/addressService';
import { AddressForm } from './AddressForm';
import { isFeatureEnabled, isTestMode } from '../../config/featureFlags';

const testShippingAddress = {
  recipient_name: "Test User",
  nickname: "Test Shipping",
  address_line_1: "123 Test Street",
  address_line_2: "Apt 4B",
  city: "San Francisco",
  state: "CA",
  zip_code: "94105",
  country: "US",
  delivery_instructions: "Please leave at front door",
  is_default: false
};

const testBillingAddress = {
  recipient_name: "Test User",
  address_line_1: "456 Billing Ave",
  address_line_2: "Suite 100",
  city: "San Francisco",
  state: "CA",
  zip_code: "94105",
  country: "US",
  is_default: false
};

export const AddressManager = ({ type = 'shipping', onAddressSelect }) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const fetchAddresses = async () => {
    try {
      const data = await (type === 'shipping'
        ? AddressService.getShippingAddresses()
        : AddressService.getBillingAddresses());
      // Ensure addresses is always an array
      setAddresses(Array.isArray(data) ? data : data?.results || []);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setAddresses([]);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, [type]);

  const handleAddAddress = () => {
    setSelectedAddress(null);
    setIsFormOpen(true);
  };

  const handleEditAddress = (address) => {
    setSelectedAddress(address);
    setIsFormOpen(true);
  };

  const handleDeleteAddress = async (id) => {
    if (window.confirm('Are you sure you want to delete this address?')) {
      try {
        await (type === 'shipping'
          ? AddressService.deleteShippingAddress(id)
          : AddressService.deleteBillingAddress(id));
        fetchAddresses();
      } catch (error) {
        console.error('Error deleting address:', error);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (selectedAddress) {
        await (type === 'shipping'
          ? AddressService.updateShippingAddress(selectedAddress.id, values)
          : AddressService.updateBillingAddress(selectedAddress.id, values));
      } else {
        await (type === 'shipping'
          ? AddressService.createShippingAddress(values)
          : AddressService.createBillingAddress(values));
      }
      setIsFormOpen(false);
      fetchAddresses();
    } catch (error) {
      console.error('Error saving address:', error);
    }
  };

  const handleSetDefault = async (id) => {
    try {
      await (type === 'shipping'
        ? AddressService.setDefaultShippingAddress(id)
        : AddressService.setDefaultBillingAddress(id));
      fetchAddresses();
    } catch (error) {
      console.error('Error setting default address:', error);
    }
  };

  const handleAddTestAddresses = async () => {
    try {
      if (type === 'shipping') {
        await AddressService.createShippingAddress(testShippingAddress);
      } else {
        await AddressService.createBillingAddress(testBillingAddress);
      }
      fetchAddresses();
    } catch (error) {
      console.error('Error adding test address:', error);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">
            {type === 'shipping' ? 'Shipping Addresses' : 'Billing Addresses'}
          </Typography>
          <Box>
            {isTestMode() && (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleAddTestAddresses}
                style={{ marginRight: 8 }}
              >
                Add Test Address
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddAddress}
            >
              Add Address
            </Button>
          </Box>
        </Box>

        <List>
          {addresses.map((address) => (
            <ListItem
              key={address.id}
              button={!!onAddressSelect}
              onClick={() => onAddressSelect && onAddressSelect(address)}
            >
              <ListItemText
                primary={
                  <Typography>
                    {address.recipient_name}
                    {address.is_default && (
                      <Typography component="span" color="primary" style={{ marginLeft: 8 }}>
                        (Default)
                      </Typography>
                    )}
                  </Typography>
                }
                secondary={
                  <>
                    {address.address_line_1}
                    {address.address_line_2 && `, ${address.address_line_2}`}
                    <br />
                    {`${address.city}, ${address.state} ${address.zip_code}`}
                    <br />
                    {address.country}
                  </>
                }
              />
              <ListItemSecondaryAction>
                {!address.is_default && (
                  <Button
                    size="small"
                    onClick={() => handleSetDefault(address.id)}
                  >
                    Set Default
                  </Button>
                )}
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEditAddress(address)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteAddress(address.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)}>
          <DialogTitle>
            {selectedAddress ? 'Edit Address' : 'Add New Address'}
          </DialogTitle>
          <DialogContent>
            <AddressForm
              type={type}
              initialValues={selectedAddress}
              onSubmit={handleSubmit}
              onCancel={() => setIsFormOpen(false)}
            />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// Custom hook to fetch and subscribe to drafts
function useRealTimeDrafts(user) {
  const [drafts, setDrafts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('useRealTimeDrafts: Effect triggered', { hasUser: !!user, userId: user?.uid });

    if (!user || !user.uid) {
      console.log('useRealTimeDrafts: No user, setting loading false');
      setLoading(false);
      setError(null);
      setDrafts([]);
      return;
    }

    console.log('useRealTimeDrafts: Setting up listener for', user.uid);
    const unsubscribe = firebase
      .firestore()
      .collection(`studio/${user.uid}/drafts`)
      .orderBy("last_edited", "desc")
      .onSnapshot({
        next: (querySnapshot) => {
          console.log('useRealTimeDrafts: Received snapshot');
          const updatedDrafts = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            updatedDrafts.push({
              name: data.name || "Untitled Album",
              docId: doc.id,
              isShared: data.isShared || false,
              artist_id: data.artist_id,
              status: data.status,
              created_at: data.created_at,
              last_edited: data.last_edited
            });
          });
          console.log('useRealTimeDrafts: Processed drafts', { count: updatedDrafts.length });
          setDrafts(updatedDrafts);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.error("useRealTimeDrafts: Error fetching drafts:", error);
          setError(error);
          setLoading(false);
          setDrafts([]);
        },
      });

    // Clean up the subscription on unmount
    return () => {
      console.log('useRealTimeDrafts: Cleaning up listener');
      unsubscribe();
    };
  }, [user]);

  return { drafts, loading, error };
}

export default useRealTimeDrafts;
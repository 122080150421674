import axios from 'axios';
import firebase from 'firebase/compat/app';
import APIConfig from '../APIConfig';

class ChatService {
  constructor() {
    this.baseURL = APIConfig.baseURL();
    this.apiPath = '/api/v1';
    this.api = axios.create({
      baseURL: `${this.baseURL}${this.apiPath}`,
    });
  }

  async getAuthHeaders() {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();
      return {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      };
    }
    throw new Error('User not authenticated');
  }

  async sendMessage(message) {
    try {
      const headers = await this.getAuthHeaders();
      const response = await this.api.post(
        '/tools/merch/chat/',
        { message },
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error('Error sending chat message:', error);
      throw error;
    }
  }
}

export const chatService = new ChatService();

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a single Mixtape in the list with actions, in a brutalist style.
 */
export default function MixtapeListItem({
  mixtape,
  isSelected,
  isEditing,
  newName,
  onClick,
  onRenameStart,
  onRenameSubmit,
  onPublishToggle,
  onDelete,
  setNewName,
  isPublishing,
  isDeleting,
  isRenaming,
  isLoading,
  currentUserId,
  showPublishStatus = true,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  // Debug log for ownership check
  useEffect(() => {
    const isOwner = mixtape.creator_id === parseInt(currentUserId);
    console.log('Ownership check:', {
      mixtapeCreatorId: mixtape.creator_id,
      currentUserId,
      currentUserIdParsed: parseInt(currentUserId),
      isOwner,
    });
  }, [mixtape.creator_id, currentUserId]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleRenameSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (newName.trim() === '') {
      onRenameStart(null);
      return;
    }
    onRenameSubmit(mixtape.id);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onRenameStart(null);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Escape') {
      handleCancel(e);
    } else if (e.key === 'Enter') {
      handleRenameSubmit(e);
    }
  };

  return (
    <div 
      className="border border-black dark:border-white p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-neutral-900"
      onClick={onClick}
      role="listitem"
      aria-selected={isSelected}
    >
      <div className="flex flex-col">
        {isEditing ? (
          <form 
            onSubmit={handleRenameSubmit} 
            className="flex items-center space-x-2"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onKeyDown={handleKeyDown}
              className="flex-1 bg-transparent border-b border-black dark:border-white focus:outline-none text-lg font-mono"
              disabled={isRenaming}
              autoFocus
            />
            <button
              type="button"
              onClick={handleCancel}
              className="px-2 py-1 border border-black dark:border-white font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
              disabled={isRenaming}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-2 py-1 border border-black dark:border-white font-mono hover:bg-gray-100 dark:hover:bg-neutral-800"
              disabled={isRenaming || newName.trim() === ''}
            >
              Save
            </button>
          </form>
        ) : (
          <>
            <h3 className={`text-lg font-mono ${isSelected ? 'font-bold' : ''}`}>{mixtape.name}</h3>
            <div className="flex items-center justify-between">
              <div className="text-sm opacity-75 font-mono">
                {mixtape.creator_username} · {mixtape.vote_count}🔥
                {mixtape.creator_id === parseInt(currentUserId) && mixtape.is_published && ' · Published'}
              </div>
              
              {mixtape.creator_id === parseInt(currentUserId) && (
                <div className="relative" ref={menuRef}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMenu(!showMenu);
                    }}
                    className="px-2 py-1 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded font-mono"
                    aria-label="Mixtape actions"
                  >
                    ...
                  </button>

                  {showMenu && (
                    <div 
                      className="absolute right-0 mt-1 py-1 w-48 bg-white dark:bg-black border border-black dark:border-white shadow-xl rounded-lg z-50"
                      role="menu"
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onRenameStart(mixtape);
                          setShowMenu(false);
                        }}
                        disabled={isRenaming}
                        className="w-full px-4 py-2 text-left text-sm font-mono hover:bg-gray-100 dark:hover:bg-neutral-800 disabled:opacity-50"
                        role="menuitem"
                      >
                        {isRenaming ? 'Renaming...' : 'Rename'}
                      </button>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onPublishToggle(mixtape, e);
                          setShowMenu(false);
                        }}
                        disabled={isPublishing}
                        className="w-full px-4 py-2 text-left text-sm font-mono hover:bg-gray-100 dark:hover:bg-neutral-800 disabled:opacity-50"
                        role="menuitem"
                      >
                        {isPublishing ? 'Updating...' : (mixtape.is_published ? 'Make Private' : 'Make Public')}
                      </button>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(mixtape.id, e);
                          setShowMenu(false);
                        }}
                        disabled={isDeleting}
                        className="w-full px-4 py-2 text-left text-sm font-mono text-red-500 hover:bg-gray-100 dark:hover:bg-neutral-800 disabled:opacity-50"
                        role="menuitem"
                      >
                        {isDeleting ? 'Deleting...' : 'Delete'}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

MixtapeListItem.propTypes = {
  mixtape: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    creator_id: PropTypes.number.isRequired,
    creator_username: PropTypes.string.isRequired,
    vote_count: PropTypes.number.isRequired,
    is_published: PropTypes.bool.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  newName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onRenameStart: PropTypes.func.isRequired,
  onRenameSubmit: PropTypes.func.isRequired,
  onPublishToggle: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setNewName: PropTypes.func.isRequired,
  isPublishing: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isRenaming: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showPublishStatus: PropTypes.bool,
};

MixtapeListItem.defaultProps = {
  showPublishStatus: true,
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shippingService } from '../../services/shippingService';

export default function ShippingManager() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [methods, setMethods] = useState([]);
  const [notification, setNotification] = useState({ show: false, message: '', type: 'success' });

  useEffect(() => {
    loadMethods();
  }, []);

  const loadMethods = async () => {
    try {
      setLoading(true);
      const response = await shippingService.getAllShippingMethods();
      const methodsList = Array.isArray(response) ? response : response?.methods || [];
      setMethods(methodsList);
      setError(null);
    } catch (error) {
      console.error('Error loading shipping methods:', error);
      setError('Failed to load shipping methods');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMethod = async (methodId) => {
    if (!window.confirm('Are you sure you want to delete this shipping method?')) {
      return;
    }

    try {
      await shippingService.deleteShippingMethod(methodId);
      setNotification({
        show: true,
        message: 'Shipping method deleted successfully',
        type: 'success'
      });
      loadMethods(); // Refresh the list
    } catch (error) {
      console.error('Error deleting method:', error);
      setNotification({
        show: true,
        message: 'Failed to delete shipping method',
        type: 'error'
      });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          Shipping Methods
        </h1>
        <button
          onClick={() => navigate('/shipping/new')}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Create New Method
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {methods.map(method => (
          <div
            key={method.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4"
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  {method.name}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400 capitalize">
                  {method.shipping_type}
                </p>
              </div>
              <button
                onClick={() => handleDeleteMethod(method.id)}
                className="text-red-600 hover:text-red-800"
                title="Delete method"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>

            <div className="space-y-2">
              {method.rates?.map((rate, index) => (
                <div
                  key={index}
                  className="text-sm text-gray-600 dark:text-gray-400 flex justify-between"
                >
                  <span>{rate.destination_country || 'Any destination'}</span>
                  <span>
                    ${rate.single_unit} + ${rate.additional_unit}/additional unit
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {notification.show && (
        <div
          className={`fixed bottom-4 right-4 px-4 py-3 rounded-md shadow-lg ${
            notification.type === 'success'
              ? 'bg-green-100 border border-green-400 text-green-700'
              : 'bg-red-100 border border-red-400 text-red-700'
          }`}
        >
          <span className="block">{notification.message}</span>
        </div>
      )}
    </div>
  );
}

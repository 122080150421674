import React, { useState } from 'react';
import { usePayment } from '../../context/PaymentContext';
import { useAuth } from '../../AuthContext';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import APIConfig from '../../APIConfig';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

function PaymentForm({ onSuccess, onError, className }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements || !user) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Step 1: Create payment method
      const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          email: user.email,
        },
      });

      if (cardError) {
        throw new Error(cardError.message);
      }

      console.log('Getting fresh ID token...');
      const idToken = await user.getIdToken(true);
      
      // Step 2: Create the subscription with payment method
      console.log('Creating subscription...');
      const response = await fetch(`${APIConfig.baseURL}api/v1/profiles/upgrade_to_basic/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${idToken}`
        },
        body: JSON.stringify({
          email: user.email,
          paymentMethodId: paymentMethod.id
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error?.message || data.detail || 'Failed to start payment');
      }

      console.log('Subscription created:', data);
      
      if (!data?.clientSecret) {
        throw new Error('No client secret received');
      }

      // Step 3: Confirm the payment with card details
      const { error: confirmError, subscription } = await stripe.confirmCardPayment(
        data.clientSecret,
        {
          payment_method: paymentMethod.id,
        }
      );
      
      if (confirmError) {
        throw new Error(confirmError.message);
      }

      // Step 4: Poll for subscription status
      let retries = 0;
      const maxRetries = 10;
      const pollInterval = 2000; // 2 seconds

      const pollSubscriptionStatus = async () => {
        if (retries >= maxRetries) {
          throw new Error('Subscription status check timed out');
        }

        const statusResponse = await fetch(
          `${APIConfig.baseURL}api/v1/profiles/get_subscription_data/`,
          {
            headers: {
              'Authorization': `JWT ${idToken}`
            }
          }
        );
        
        const statusData = await statusResponse.json();
        
        if (statusData.status === 'active') {
          onSuccess();
          return;
        } else if (statusData.status === 'incomplete' || statusData.status === 'processing') {
          retries++;
          setTimeout(pollSubscriptionStatus, pollInterval);
        } else {
          throw new Error(`Subscription failed: ${statusData.status}`);
        }
      };

      // Start polling
      setTimeout(pollSubscriptionStatus, pollInterval);
      
    } catch (error) {
      console.error('Payment error:', error);
      setError(error.message || 'An unexpected error occurred');
      onError(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={className}>
      <div className="mb-4 p-3 border rounded-md bg-white">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {error && (
        <div className="mb-4 p-3 border border-red-300 rounded-md bg-red-50 text-red-700">
          {error}
        </div>
      )}
      <button
        type="submit"
        disabled={!stripe || loading}
        className={`w-full px-4 py-2 ${
          loading
            ? 'bg-gray-300 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-600'
        } text-white rounded-md transition-colors`}
      >
        {loading ? (
          <div className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </div>
        ) : (
          'Pay Now'
        )}
      </button>
    </form>
  );
}

function PaymentButton({ className }) {
  const { stripePromise, loading: stripeLoading } = usePayment();
  const { user, loading: authLoading } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  if (!user) {
    return (
      <button
        className={`px-4 py-2 bg-gray-300 text-gray-600 rounded-md cursor-not-allowed ${className}`}
        disabled
      >
        Sign in to upgrade
      </button>
    );
  }

  if (authLoading || stripeLoading) {
    return (
      <button
        className={`flex items-center justify-center px-4 py-2 bg-gray-300 text-gray-600 rounded-md cursor-not-allowed ${className}`}
        disabled
      >
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Loading...
      </button>
    );
  }

  if (success) {
    return (
      <button
        className={`px-4 py-2 bg-green-500 text-white rounded-md ${className}`}
        disabled
      >
        Payment Successful!
      </button>
    );
  }

  if (showForm && stripePromise) {
    return (
      <div className="mt-4">
        <Elements stripe={stripePromise}>
          <PaymentForm
            className={className}
            onSuccess={() => {
              setSuccess(true);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }}
            onError={setError}
          />
        </Elements>
      </div>
    );
  }

  return (
    <div>
      <button
        onClick={() => setShowForm(true)}
        className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${className}`}
      >
        Upgrade to Basic
      </button>
      {error && (
        <div className="mt-2 text-red-500 text-sm">{error}</div>
      )}
    </div>
  );
}

export default PaymentButton;

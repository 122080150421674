import React from 'react';
import PropTypes from 'prop-types';

const shippingTypes = [
  { value: 'standard', label: 'Standard Shipping' },
  { value: 'express', label: 'Express Shipping' },
  { value: 'free', label: 'Free Shipping' }
];

const getTypeDescription = (type) => {
  switch (type) {
    case 'standard':
      return 'Regular shipping option with standard delivery times';
    case 'express':
      return 'Faster shipping option with expedited delivery';
    case 'free':
      return 'Free shipping option, typically with minimum order requirements';
    default:
      return '';
  }
};

export function AddMethodModal({ 
  isOpen, 
  onClose, 
  onAddMethod 
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div 
          className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" 
          onClick={onClose} 
        />

        <div className="inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">
              Add Shipping Method
            </h3>
            
            <div className="mt-4 space-y-4">
              {shippingTypes.map(type => (
                <button
                  key={type.value}
                  onClick={() => onAddMethod(type.value)}
                  className="w-full text-left px-4 py-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors"
                >
                  <div className="font-medium text-gray-900 dark:text-gray-100">{type.label}</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {getTypeDescription(type.value)}
                  </div>
                </button>
              ))}
            </div>
          </div>
          
          <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-200 text-base font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

AddMethodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddMethod: PropTypes.func.isRequired
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import MixtapeList from '../mixtapes/MixtapeList';
import LoadingSpinner from '../common/LoadingSpinner';

const LoadingItem = () => (
  <div className="border border-black dark:border-white">
    <div className="aspect-square bg-gray-100 dark:bg-gray-900 animate-pulse"></div>
    <div className="p-2 space-y-2">
      <div className="h-4 bg-gray-100 dark:bg-gray-900 animate-pulse rounded"></div>
      <div className="h-3 bg-gray-100 dark:bg-gray-900 animate-pulse rounded w-2/3"></div>
    </div>
  </div>
);

/**
 * Renders a feed section for either albums, profiles, or mixtapes.
 * If it's mixtape, we delegate to MixtapeList for the old UI style.
 */
export const FeedSection = ({ title, items = [], type, loading = false }) => {
  const navigate = useNavigate();

  // If this is a mixtape section, we want the old Mixtape UI
  if (type === 'MIXTAPE') {
    return (
      <section className="space-y-4">
        <h2 className="font-theFutureMonoRegular text-lg">{title}</h2>
        {loading ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {Array.from({ length: 10 }).map((_, i) => (
              <LoadingItem key={`loading-${i}`} />
            ))}
          </div>
        ) : (
          <MixtapeList
            mixtapes={items}
            isLoading={false}
            onMixtapeClick={(id) => navigate(`/mixtapes/${id}`)}
          />
        )}
      </section>
    );
  }

  // Otherwise, handle album or profile
  const handleItemClick = (item) => {
    if (!item?.id) return;
    switch (type) {
      case 'ALBUM':
        navigate(`/a/${item.artist_slug}/albums/${item.id}`);
        break;
      case 'PROFILE':
        navigate(`/u/${item.username}`);
        break;
      default:
        break;
    }
  };

  const renderItem = (item) => {
    if (!item) return null;
    if (type === 'ALBUM') {
      return (
        <div
          key={item.id}
          className="cursor-pointer border border-black dark:border-white"
          onClick={() => handleItemClick(item)}
        >
          <img
            src={
              item.artwork_url ||
              item.compressed_artwork_url ||
              item.artwork ||
              item.compressed_artwork
            }
            alt={item.name}
            className="w-full aspect-square object-cover border-b border-black dark:border-white"
            loading="lazy"
          />
          <div className="p-2 font-theFutureMonoRegular">
            <div className="text-sm truncate underline">{item.name}</div>
            <div className="text-xs truncate">
              by {item.artist_name || item.artist}
            </div>
          </div>
        </div>
      );
    } else if (type === 'PROFILE') {
      return (
        <div
          key={item.id}
          onClick={() => handleItemClick(item)}
          className="cursor-pointer border border-black dark:border-white hover:bg-gray-50 dark:hover:bg-neutral-900 p-4"
        >
          <div className="text-center font-theFutureMonoRegular">
            <div className="text-sm truncate underline">@{item.username}</div>
            {item.name && (
              <div className="text-xs text-gray-600 dark:text-gray-400 truncate mt-1">
                {item.name}
              </div>
            )}
            <button 
              onClick={(e) => {
                e.stopPropagation();
                // TODO: Implement follow functionality
              }}
              className="mt-2 px-4 py-1 text-xs border border-black dark:border-white hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black transition-colors"
            >
              Follow
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  // Don't render if no items and not loading
  if (!loading && (!items || items.length === 0)) return null;

  return (
    <section className="space-y-4">
      <h2 className="font-theFutureMonoRegular text-lg">{title}</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {loading
          ? Array.from({ length: 10 }).map((_, i) => (
              <LoadingItem key={`loading-${i}`} />
            ))
          : items.map((item) => renderItem(item))}
      </div>
    </section>
  );
};

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import APIConfig from "../../../APIConfig";
import ArtistMusicViewV2 from "./ArtistMusicViewV2";
import ArtistMerchViewV2 from "./ArtistMerchViewV2";
import profile_service from "../../../services/profile_service";

const ArtistPageV2 = () => {
  const location = useLocation();
  const artist_slug = location.pathname.split("/")[3]; // /a/v2/[slug]
  
  const [activeTab, setActiveTab] = useState("music");
  const [artistData, setArtistData] = useState({
    artist_name: "",
    albums: [],
    items: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await profile_service.getMerch(artist_slug);
        setArtistData({
          artist_name: data.artist_name,
          albums: data.albums || [],
          items: data.items || [],
          artist_id: data.artist_id,
        });
      } catch (error) {
        console.error("Error fetching artist data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [artist_slug]);

  if (loading) {
    return <div className="min-h-screen bg-[#f3f3f3] p-4 font-mono">Loading...</div>;
  }

  if (error) {
    return <div className="min-h-screen bg-[#f3f3f3] p-4 font-mono">Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-[#f3f3f3] text-black p-4">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-6xl font-mono mb-12 text-center tracking-wider">
          {artistData.artist_name}
        </h1>

        <div className="flex justify-center mb-8 font-mono">
          <div className="flex gap-8">
            <button
              className={`px-4 pb-2 relative ${
                activeTab === "music" 
                  ? "font-bold after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-black" 
                  : ""
              }`}
              onClick={() => setActiveTab("music")}
            >
              music
            </button>
            <button
              className={`px-4 pb-2 relative ${
                activeTab === "merch"
                  ? "font-bold after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-black"
                  : ""
              }`}
              onClick={() => setActiveTab("merch")}
            >
              merch
            </button>
            <button
              className={`px-4 pb-2 relative ${
                activeTab === "video"
                  ? "font-bold after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-black"
                  : ""
              }`}
              onClick={() => setActiveTab("video")}
            >
              video
            </button>
          </div>
        </div>

        <div className="mt-8">
          {activeTab === "music" && (
            <ArtistMusicViewV2 
              albums={artistData.albums} 
              artistId={artistData.artist_id} 
            />
          )}
          {activeTab === "merch" && (
            <ArtistMerchViewV2 items={artistData.items} />
          )}
          {activeTab === "video" && (
            <div className="font-mono text-center">Coming soon</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtistPageV2;

import React, { useCallback, useEffect, useState } from 'react';
import { useMerchImages } from '../../../hooks/useMerchImages';
import FileUpload from '../../common/FileUpload';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';

const StatusIndicator = ({ type, message, loading }) => {
  const bgColor = type === 'error' ? 'bg-red-50 dark:bg-red-900' : 'bg-gray-50 dark:bg-gray-900';
  const borderColor = type === 'error' ? 'border-red-500' : 'border-black dark:border-white';
  
  return (
    <div className={`border ${borderColor} ${bgColor} p-2 text-sm animate-fade-in flex items-center justify-between`}>
      <span>{message}</span>
      {loading && (
        <div className="w-4 h-4 border-2 border-t-transparent border-black dark:border-white rounded-full animate-spin ml-2" />
      )}
    </div>
  );
};

const SortableImage = ({ image, index, onDelete, disabled, isDeleting }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: image.id });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    transition,
    zIndex: isDragging ? 1 : 0,
    opacity: isDragging ? 0.8 : isDeleting ? 0.5 : 1,
  } : undefined;

  return (
    <div 
      ref={setNodeRef}
      style={style}
      className={`relative border-2 ${isDragging ? 'border-gray-400' : 'border-black dark:border-white'} bg-white dark:bg-black cursor-move p-2`}
      {...attributes}
      {...listeners}
    >
      <img
        src={image.url}
        alt={`Merch image ${index + 1}`}
        className="w-full h-32 object-cover"
        draggable={false}
      />
      <button
        onClick={() => onDelete(image.id)}
        disabled={disabled || isDeleting}
        className={`absolute top-2 right-2 border border-black dark:border-white bg-white dark:bg-black px-2 py-1 text-sm hover:bg-gray-200 dark:hover:bg-gray-900 ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isDeleting ? '...' : '×'}
      </button>
      {index === 0 && (
        <div className="absolute bottom-2 left-2 border border-black dark:border-white bg-white dark:bg-black px-2 py-1 text-xs">
          Primary
        </div>
      )}
    </div>
  );
};

const UploadProgress = ({ files, error }) => {
  return (
    <div className={`border-2 ${error ? 'border-red-500' : 'border-black dark:border-white'} p-4 space-y-2`}>
      <div className="text-sm font-medium">
        {error ? (
          <span className="text-red-500">{error}</span>
        ) : (
          `Uploading ${files.length} image${files.length !== 1 ? 's' : ''}...`
        )}
      </div>
      {files.map((file, index) => (
        <div key={index} className="text-sm">
          {file.name} ({Math.round(file.size / 1024)}KB)
          <div className="mt-1 h-1 bg-gray-200 dark:bg-gray-700">
            <div className="h-full bg-black dark:bg-white animate-pulse" style={{ width: '100%' }}></div>
          </div>
        </div>
      ))}
    </div>
  );
};

const MerchImageUpload = ({ itemId, onChange, disabled }) => {
  const {
    images: serverImages,
    isLoading,
    error: serverError,
    handleUpload,
    handleDelete,
    handleReorder
  } = useMerchImages(itemId);

  // Local state for optimistic updates
  const [localImages, setLocalImages] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [deletingImages, setDeletingImages] = useState(new Set());
  const [reorderingImages, setReorderingImages] = useState(false);
  const [statusMessages, setStatusMessages] = useState([]);

  // Add a status message
  const addStatusMessage = (message, type = 'info', timeout = 3000) => {
    const id = Math.random().toString(36).substr(2, 9);
    setStatusMessages(prev => [...prev, { id, message, type }]);
    if (timeout) {
      setTimeout(() => {
        setStatusMessages(prev => prev.filter(msg => msg.id !== id));
      }, timeout);
    }
    return id; // Return id so we can update/remove this specific message
  };

  // Update a specific status message
  const updateStatusMessage = (id, newMessage, type = 'info', timeout = 3000) => {
    setStatusMessages(prev => prev.map(msg => 
      msg.id === id ? { ...msg, message: newMessage, type } : msg
    ));
    if (timeout) {
      setTimeout(() => {
        setStatusMessages(prev => prev.filter(msg => msg.id !== id));
      }, timeout);
    }
  };

  // Clear status message after 3 seconds
  useEffect(() => {
    if (statusMessages.length > 0) {
      const timer = setTimeout(() => {
        setStatusMessages([]);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [statusMessages]);

  // Keep local state in sync with server state
  useEffect(() => {
    if (serverImages) {
      setLocalImages(serverImages);
    }
  }, [serverImages]);

  // Update parent when local state changes
  useEffect(() => {
    if (onChange && localImages) {
      onChange(localImages);
    }
  }, [localImages, onChange]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor)
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over?.id) {
      const oldIndex = localImages.findIndex(img => img.id === active.id);
      const newIndex = localImages.findIndex(img => img.id === over.id);
      
      // Update local state immediately
      const newOrder = arrayMove(localImages, oldIndex, newIndex);
      const reorderedImages = newOrder.map((img, idx) => ({
        ...img,
        order: idx
      }));
      setLocalImages(reorderedImages);
      setReorderingImages(true);
      
      // Add status message for reordering
      const statusId = addStatusMessage('Reordering images...', 'info', 0);
      
      // Send update to server in background
      const imageOrders = reorderedImages.map((img, idx) => ({
        id: img.id,
        order: idx
      }));
      handleReorder(imageOrders)
        .then(() => {
          updateStatusMessage(statusId, 'Images reordered successfully', 'info');
          setReorderingImages(false);
        })
        .catch(error => {
          console.error('Failed to reorder images:', error);
          updateStatusMessage(statusId, 'Failed to reorder images', 'error');
          setReorderingImages(false);
          // Revert to server state on error
          if (serverImages) {
            setLocalImages(serverImages);
          }
        });
    }
  };

  const handleImageDelete = async (imageId) => {
    setDeletingImages(prev => new Set([...prev, imageId]));
    const statusId = addStatusMessage('Deleting image...', 'info', 0);
    
    try {
      await handleDelete(imageId);
      updateStatusMessage(statusId, 'Image deleted successfully');
    } catch (error) {
      console.error('Failed to delete image:', error);
      updateStatusMessage(statusId, 'Failed to delete image', 'error');
    } finally {
      setDeletingImages(prev => {
        const next = new Set(prev);
        next.delete(imageId);
        return next;
      });
    }
  };

  const handleFileUpload = async (files) => {
    setUploadingFiles(Array.from(files));
    setUploadError(null);
    const statusId = addStatusMessage('Uploading images...', 'info', 0);

    try {
      // handleUpload now returns the new images
      const newImages = await handleUpload(files);
      updateStatusMessage(statusId, 'Images uploaded successfully');
    } catch (error) {
      console.error('Upload failed:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Failed to upload images';
      setUploadError(errorMessage);
      updateStatusMessage(statusId, errorMessage, 'error');
    } finally {
      setUploadingFiles([]);
    }
  };

  if (!itemId) {
    return null;
  }

  return (
    <div className="space-y-4">
      {(!localImages || localImages.length === 0) && !uploadingFiles.length && (
        <div className="border-2 border-black dark:border-white">
          <FileUpload
            accept="image/*"
            onUploadComplete={handleFileUpload}
            maxFiles={10}
            className="p-4"
          />
        </div>
      )}

      {uploadingFiles.length > 0 && (
        <UploadProgress files={uploadingFiles} error={uploadError} />
      )}

      {statusMessages.map(({ id, message, type }) => (
        <StatusIndicator 
          key={id}
          type={type}
          message={message}
          loading={
            (message.includes('Uploading') && uploadingFiles.length > 0) ||
            (message.includes('Deleting') && deletingImages.size > 0) ||
            (message.includes('Reordering') && reorderingImages)
          }
        />
      ))}

      {localImages && localImages.length > 0 && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <div className="relative border border-black dark:border-white p-4">
            <SortableContext items={localImages.map(img => img.id)} strategy={rectSortingStrategy}>
              <div className="overflow-x-auto pb-4">
                <div className="inline-flex gap-4 min-w-max">
                  {localImages.map((image, index) => (
                    <div key={image.id} className="w-[200px]">
                      <SortableImage
                        key={image.id}
                        image={image}
                        index={index}
                        onDelete={handleImageDelete}
                        disabled={isLoading || disabled}
                        isDeleting={deletingImages.has(image.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </SortableContext>
            
            <div className="mt-4 text-center">
              <div className="border-2 border-black dark:border-white">
                <FileUpload
                  accept="image/*"
                  onUploadComplete={handleFileUpload}
                  maxFiles={10}
                  className="p-4"
                />
              </div>
            </div>
          </div>
        </DndContext>
      )}
    </div>
  );
};

export default MerchImageUpload;

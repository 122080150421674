import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { formatDistanceToNow, format } from 'date-fns';
import PaymentButton from './PaymentButton';
import PaymentModeBadge from './PaymentModeBadge';
import { usePayment } from '../../context/PaymentContext';
import { useStripeProfile } from '../../hooks/useStripeProfile';
import APIConfig from '../../APIConfig';
import useAuth from '../../hooks/useAuth';

function PaymentStatus() {
  const { user, loading: authLoading, isPrivate } = useAuth();
  const { stripePromise, loading: stripeLoading, error: stripeError } = usePayment();
  const { loading: profileLoading, error: profileError, profileData } = useStripeProfile();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancelling, setCancelling] = useState(false);

  const handleCancelSubscription = async () => {
    if (!user || !subscriptionStatus?.subscriptionId) return;
    
    setCancelling(true);
    try {
      const response = await fetch(`${APIConfig.baseURL}api/v1/profiles/cancel_subscription/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${await user.getIdToken()}`
        },
        body: JSON.stringify({
          subscriptionId: subscriptionStatus.subscriptionId
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to cancel subscription');
      }

      const data = await response.json();
      // Show success message
      setError(null);
      // Refresh the subscription status
      fetchPaymentStatus();
    } catch (err) {
      console.error('Error cancelling subscription:', err);
      setError(err.message);
    } finally {
      setCancelling(false);
    }
  };

  const fetchPaymentStatus = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      // Get subscription status from backend
      const response = await fetch(`${APIConfig.profiles()}get_subscription_data/`, {
        headers: {
          'Authorization': `JWT ${await user.getIdToken()}`
        }
      });
      const data = await response.json();
      
      if (response.ok) {
        setSubscriptionActive(data.active);
        setSubscriptionStatus(data);
        setError(null);
      } else {
        throw new Error(data.error || 'Failed to fetch subscription data');
      }
      
      // Get Firestore payment status for historical data
      const unsubscribe = firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .onSnapshot(
          (doc) => {
            const data = doc.data();
            setPaymentStatus({
              lastPayment: data?.lastPayment?.toDate() || null,
              nextPayment: data?.nextPayment?.toDate() || null,
              plan: data?.plan || null
            });
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching Firestore payment status:', error);
            setError(error.message);
            setLoading(false);
          }
        );

      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up payment status listener:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentStatus();
  }, [user]);

  if (loading || authLoading || stripeLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  if (error || stripeError || profileError) {
    return (
      <div className="text-red-500 p-4">
        {error || stripeError || profileError}
      </div>
    );
  }

  if (!user) {
    return (
      <div className="text-gray-600 dark:text-gray-400 p-4">
        Please sign in to view payment status
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex-grow">
          <h4 className="text-lg font-medium">
            Current Plan: {subscriptionActive ? 'Basic' : 'Free'}
          </h4>
          {subscriptionStatus?.status && (
            <p className={`text-sm ${
              subscriptionStatus.status === 'incomplete' 
                ? 'text-yellow-600 dark:text-yellow-400'
                : 'text-gray-600 dark:text-gray-400'
            }`}>
              Status: {subscriptionStatus.status}
              {subscriptionStatus.status === 'incomplete' && (
                <span className="ml-2">
                  (Payment required to activate subscription)
                </span>
              )}
              {subscriptionStatus.cancel_at_period_end && (
                <span className="ml-2 text-orange-500">
                  (Cancellation scheduled at end of billing period)
                </span>
              )}
            </p>
          )}
          {error && (
            <p className="text-sm text-red-500 mt-2">
              {error}
            </p>
          )}
          {paymentStatus?.lastPayment && (
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Last payment: {format(paymentStatus.lastPayment, 'PPP')}
            </p>
          )}
          {paymentStatus?.nextPayment && (
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Next payment: {format(paymentStatus.nextPayment, 'PPP')}
            </p>
          )}
        </div>
        {/* Only show cancel button for active subscriptions that aren't scheduled for cancellation */}
        {subscriptionStatus?.subscriptionId && 
         subscriptionStatus.status === 'active' && 
         !subscriptionStatus?.cancel_at_period_end && (
          <div className="flex-shrink-0 ml-4">
            <button
              onClick={handleCancelSubscription}
              disabled={cancelling}
              className={`px-4 py-2 rounded-md text-white transition-colors ${
                cancelling 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-red-500 hover:bg-red-600'
              }`}
            >
              {cancelling ? 'Cancelling...' : 'Cancel Subscription'}
            </button>
          </div>
        )}
      </div>

      {!subscriptionActive && (
        <div className="mt-4">
          <PaymentButton className="w-full" />
        </div>
      )}
    </div>
  );
}

export default PaymentStatus;

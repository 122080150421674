import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APIConfig from '../../APIConfig';

function LoadingGrid({ count = 4 }) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full">
      {Array(count).fill(null).map((_, idx) => (
        <div
          key={`loading-${idx}`}
          className="border border-black dark:border-white bg-gray-100 dark:bg-gray-900 aspect-square animate-pulse"
        />
      ))}
    </div>
  );
}

/**
 * Renders a list of Mixtapes in the old UI style.
 */
export default function MixtapeList({ mixtapes: initialMixtapes, onMixtapeClick, isLoading }) {
  const navigate = useNavigate();
  const [votedMixtapes, setVotedMixtapes] = useState(new Set());
  const [mixtapes, setMixtapes] = useState(initialMixtapes || []);
  const [loadingVotes, setLoadingVotes] = useState(new Set()); // Track loading state for each mixtape's vote

  // Update mixtapes when props change
  useEffect(() => {
    setMixtapes(initialMixtapes || []);
  }, [initialMixtapes]);

  const handleVote = async (mixtapeId, e) => {
    e.stopPropagation();
    
    const token = localStorage.getItem('token');
    const isVoted = votedMixtapes.has(mixtapeId);
    
    try {
      setLoadingVotes(prev => new Set([...prev, mixtapeId])); // Start loading

      const response = await fetch(`${APIConfig.baseURL()}/api/v1/feed/${mixtapeId}/fire/`, {
        method: isVoted ? 'DELETE' : 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Update voted mixtapes set
      setVotedMixtapes(prev => {
        const newSet = new Set(prev);
        if (isVoted) {
          newSet.delete(mixtapeId);
        } else {
          newSet.add(mixtapeId);
        }
        return newSet;
      });

      // Update mixtapes array immutably
      setMixtapes(prev => prev.map(mixtape => {
        if (mixtape.id === mixtapeId) {
          return {
            ...mixtape,
            vote_count: mixtape.vote_count + (isVoted ? -1 : 1),
            voted: !isVoted
          };
        }
        return mixtape;
      }));
    } catch (error) {
      console.error('Error voting:', error);
    } finally {
      setLoadingVotes(prev => {
        const newSet = new Set(prev);
        newSet.delete(mixtapeId);
        return newSet;
      }); // End loading
    }
  };

  if (isLoading) {
    return <LoadingGrid />;
  }

  if (!mixtapes?.length) {
    return null;
  }

  return (
    <div className="space-y-4 w-full">
      {mixtapes.map((mixtape) => (
        <div
          key={mixtape.id}
          onClick={(e) => {
            e.stopPropagation();
            onMixtapeClick?.(mixtape.id);
          }}
          className={`
            p-4
            border border-black dark:border-white
            cursor-pointer
            hover:bg-gray-50 dark:hover:bg-neutral-900
            font-theFutureMonoRegular
            transition-colors duration-200
          `}
        >
          <div className="flex justify-between items-start">
            <div>
              <div className="text-sm underline">{mixtape.name}</div>
              <div className="flex items-center space-x-1">
                <span className="text-xs opacity-75">by</span>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/u/${mixtape.creator_username || mixtape.creator}`);
                  }}
                  className="text-xs opacity-75 hover:underline cursor-pointer bg-transparent border-none p-0"
                >
                  {mixtape.creator || mixtape.creator_username}
                </button>
              </div>
            </div>
            <button
              onClick={(e) => handleVote(mixtape.id, e)}
              disabled={loadingVotes.has(mixtape.id)}
              className={`
                text-xs px-3 py-1 rounded
                border border-black dark:border-white
                transition-all duration-200
                ${loadingVotes.has(mixtape.id) ? 'opacity-50 cursor-not-allowed' : ''}
                ${mixtape.voted || votedMixtapes.has(mixtape.id)
                  ? 'bg-red-500 text-white hover:bg-red-600'
                  : 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black'
                }
              `}
            >
              {loadingVotes.has(mixtape.id) ? (
                <span className="inline-block animate-pulse">🔥</span>
              ) : (
                `${mixtape.vote_count} 🔥`
              )}
            </button>
          </div>
          
          {mixtape.description && (
            <div className="mt-2 text-xs text-gray-600 dark:text-gray-400">
              {mixtape.description}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

import axios from 'axios';
import { getAuth } from 'firebase/auth';
import APIConfig from '../APIConfig';
const API_URL = APIConfig.baseURL();

class CartService {
  static getHeaders(token) {
    if (!token) {
      throw new Error('Authentication required');
    }
    return { Authorization: `JWT ${token}` };
  }

  static async getCurrentCart(token) {
    try {
      if (!token) {
        const auth = getAuth();
        token = await auth.currentUser?.getIdToken();
      }
      if (!token) {
        throw new Error('Please log in to view your cart');
      }
      
      const headers = this.getHeaders(token);
      const response = await axios.get(`${API_URL}/api/v1/cart/`, {
        withCredentials: true,
        headers
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching cart:', error);
      throw error;
    }
  }

  static async addItem(merchItemId, variantId = null, quantity = 1, token) {
    try {
      if (!token) {
        const auth = getAuth();
        token = await auth.currentUser?.getIdToken();
      }
      if (!token) {
        throw new Error('Please log in to add items to your cart');
      }
      
      const headers = this.getHeaders(token);
      const response = await axios.post(
        `${API_URL}/api/v1/cart/add_item/`,
        {
          merch_item_id: merchItemId,
          variant_id: variantId,
          quantity: quantity
        },
        { 
          withCredentials: true,
          headers
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error adding item to cart:', error);
      throw error;
    }
  }

  static async removeItem(itemId, token) {
    try {
      if (!token) {
        const auth = getAuth();
        token = await auth.currentUser?.getIdToken();
      }
      if (!token) {
        throw new Error('Please log in to remove items from your cart');
      }
      
      const headers = this.getHeaders(token);
      await axios.post(
        `${API_URL}/api/v1/cart/remove_item/`,
        { item_id: itemId },
        { 
          withCredentials: true,
          headers
        }
      );
    } catch (error) {
      console.error('Error removing item from cart:', error);
      throw error;
    }
  }

  static async updateQuantity(itemId, quantity, token) {
    try {
      if (!token) {
        const auth = getAuth();
        token = await auth.currentUser?.getIdToken();
      }
      if (!token) {
        throw new Error('Please log in to update your cart');
      }
      
      const headers = this.getHeaders(token);
      const response = await axios.post(
        `${API_URL}/api/v1/cart/update_quantity/`,
        {
          item_id: itemId,
          quantity: quantity
        },
        { 
          withCredentials: true,
          headers
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating item quantity:', error);
      throw error;
    }
  }

  static async clearCart(token) {
    try {
      if (!token) {
        const auth = getAuth();
        token = await auth.currentUser?.getIdToken();
      }
      if (!token) {
        throw new Error('Please log in to clear your cart');
      }
      
      const headers = this.getHeaders(token);
      await axios.post(
        `${API_URL}/api/v1/cart/clear/`,
        {},
        { 
          withCredentials: true,
          headers
        }
      );
    } catch (error) {
      console.error('Error clearing cart:', error);
      throw error;
    }
  }

  static async createCheckoutSession(token, shippingDetails = null) {
    try {
      if (!token) {
        const auth = getAuth();
        token = await auth.currentUser?.getIdToken();
      }
      if (!token) {
        throw new Error('Please log in to checkout');
      }
      
      const headers = this.getHeaders(token);
      const response = await axios.post(
        `${API_URL}/api/v1/commerce/payments/create-checkout-session/`,
        shippingDetails || {},
        { 
          withCredentials: true,
          headers
        }
      );
      
      if (!response.data?.sessionId) {
        throw new Error('No session ID received from server');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  }
}

export const clearCart = async () => {
  try {
    // Clear cart in local storage
    localStorage.removeItem('cart');
    
    // Clear cart in backend
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    
    if (token) {
      const headers = {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      };
      await axios.post(
        `${API_URL}/api/v1/commerce/cart/clear/`,
        {},
        {
          headers,
          withCredentials: true,
        }
      );
    }
    
    // Dispatch event to notify components
    window.dispatchEvent(new CustomEvent('cartUpdated', { detail: { items: [] } }));
  } catch (error) {
    console.error('Error clearing cart:', error);
    throw error;
  }
};

export default CartService;

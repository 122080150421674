import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import APIConfig from '../../APIConfig';

const LoadingIndicator = () => (
  <div className="w-full aspect-square border border-black dark:border-white bg-gray-100 dark:bg-gray-900" />
);

const LoadingGrid = ({ count = 4 }) => (
  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
    {Array(count).fill(0).map((_, i) => (
      <LoadingIndicator key={i} />
    ))}
  </div>
);

const AlbumGrid = ({ albums, onAlbumClick, isLoading, loadingMore, observerTarget }) => {
  if (isLoading) {
    return <LoadingGrid />;
  }

  if (!albums?.length) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {albums.map((album) => (
        <div
          key={album.id}
          className="cursor-pointer border border-black dark:border-white"
          onClick={() => onAlbumClick(album.artist_slug, album.id)}
        >
          <img
            src={album.artwork_url || album.compressed_artwork_url}
            alt={album.name}
            className="w-full aspect-square object-cover border-b border-black dark:border-white"
            loading="lazy"
          />
          <div className="p-2 font-theFutureMonoRegular">
            <div className="text-sm truncate underline">{album.name}</div>
            <div className="text-xs truncate">{album.artist_name}</div>
          </div>
        </div>
      ))}
      {loadingMore && (
        <div ref={observerTarget} className="w-full py-8 flex justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
    </div>
  );
};

const GenreFilter = ({ genres, selectedGenre, onGenreSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const initialDisplayCount = 5;

  const displayedGenres = isExpanded ? genres : genres.slice(0, initialDisplayCount);
  
  return (
    <div className="mb-6 font-theFutureMonoRegular">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold">Filter by Genre</h2>
        {genres.length > initialDisplayCount && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-sm underline hover:bg-gray-200 dark:hover:bg-gray-800 px-2"
          >
            {isExpanded ? '[ Show Less ]' : `[ Show All (${genres.length}) ]`}
          </button>
        )}
      </div>
      
      <div className="flex flex-wrap gap-2 border border-black dark:border-white p-4">
        <button
          onClick={() => onGenreSelect(null)}
          className={`px-4 py-2 text-sm transition-colors border ${
            !selectedGenre
              ? 'bg-black text-white dark:bg-white dark:text-black border-black dark:border-white'
              : 'bg-white text-black dark:bg-black dark:text-white border-black dark:border-white hover:bg-gray-100 dark:hover:bg-gray-900'
          }`}
        >
          ALL
        </button>
        {displayedGenres.map((genre) => (
          <button
            key={genre}
            onClick={() => onGenreSelect(genre)}
            className={`px-4 py-2 text-sm transition-colors border ${
              selectedGenre === genre
                ? 'bg-black text-white dark:bg-white dark:text-black border-black dark:border-white'
                : 'bg-white text-black dark:bg-black dark:text-white border-black dark:border-white hover:bg-gray-100 dark:hover:bg-gray-900'
            }`}
          >
            {genre.toUpperCase()}
          </button>
        ))}
      </div>
    </div>
  );
};

const PublicHomePage = () => {
  const [feedData, setFeedData] = useState({
    featured: [],
    new_releases: [],
    discover: {
      results: [],
      page: 1,
      has_more: false
    },
    available_genres: []
  });
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const observerTarget = useRef(null);
  const navigate = useNavigate();

  const fetchFeedData = async (page = 1) => {
    try {
      console.log('Fetching feed data...', { page });
      const queryParams = new URLSearchParams({
        page: page,
        ...(selectedGenre && { genre: selectedGenre })
      });

      const response = await fetch(`${APIConfig.baseURL()}/api/v1/music/public/albums/feed/?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      console.log('Response:', response);
      if (!response.ok) {
        throw new Error('Failed to fetch feed data');
      }
      const data = await response.json();
      console.log('Feed data:', data);
      
      if (page === 1) {
        // Initial load
        setFeedData(data);
      } else {
        // Append to discover section
        setFeedData(prev => ({
          ...prev,
          discover: {
            ...data.discover,
            results: [...prev.discover.results, ...data.discover.results]
          }
        }));
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching feed data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  // Intersection Observer callback
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && feedData.discover.has_more && !loadingMore) {
      setLoadingMore(true);
      fetchFeedData(feedData.discover.page + 1);
    }
  }, [feedData.discover.has_more, feedData.discover.page, loadingMore]);

  // Set up intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [handleObserver]);

  // Refetch when genre changes
  useEffect(() => {
    fetchFeedData(1);
  }, [selectedGenre]);

  // Initial data fetch
  useEffect(() => {
    fetchFeedData();
  }, []);

  const handleAlbumClick = (artistSlug, albumId) => {
    navigate(`/a/${artistSlug}/albums/${albumId}`, { state: { from: 'home' } });
  };

  if (loading) {
    return (
      <div className="w-screen h-screen font-theFutureMonoRegular text-black bg-[#f3f3f3] dark:bg-black dark:text-white overflow-y-auto">
        {/* Featured Section */}
        <section className="p-8">
          <h2 className="text-2xl mb-4">Featured</h2>
          <LoadingGrid count={10} />
        </section>

        {/* New Releases Section */}
        <section className="p-8">
          <h2 className="text-2xl mb-4">New Releases</h2>
          <LoadingGrid count={10} />
        </section>

        {/* Discover Section */}
        <section className="p-8">
          <h2 className="text-2xl mb-4">Discover</h2>
          <LoadingGrid count={10} />
        </section>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div className="text-red-500">Error loading albums: {error}</div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen overflow-auto text-black bg-[#f3f3f3] dark:bg-black dark:text-white">
      <div className="max-w-[2000px] mx-auto p-4">
        {feedData.available_genres?.length > 0 && (
          <GenreFilter
            genres={feedData.available_genres}
            selectedGenre={selectedGenre}
            onGenreSelect={setSelectedGenre}
          />
        )}
        
        <div className="mb-8">
          <h2 className="text-lg font-theFutureMonoRegular font-bold underline mb-4 border-b border-black dark:border-white pb-2">
            Featured Albums
          </h2>
          <AlbumGrid 
            albums={feedData.featured} 
            onAlbumClick={handleAlbumClick} 
            isLoading={loading}
          />
        </div>

        <div className="mb-8">
          <h2 className="text-lg font-theFutureMonoRegular font-bold underline mb-4 border-b border-black dark:border-white pb-2">
            New Releases
          </h2>
          <AlbumGrid 
            albums={feedData.new_releases} 
            onAlbumClick={handleAlbumClick} 
            isLoading={loading}
          />
        </div>

        <div className="mb-8">
          <h2 className="text-lg font-theFutureMonoRegular font-bold underline mb-4 border-b border-black dark:border-white pb-2">
            Discover
          </h2>
          <AlbumGrid 
            albums={feedData.discover.results} 
            onAlbumClick={handleAlbumClick} 
            isLoading={loading}
            loadingMore={loadingMore}
            observerTarget={observerTarget}
          />
        </div>
      </div>
    </div>
  );
};

export default PublicHomePage;

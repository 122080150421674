import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import APIConfig from '../../APIConfig';
import { FeedSection } from './FeedSection';
import LoadingSpinner from '../common/LoadingSpinner';
import PublicHomePage from './PublicHomePage';
import HomePageSkeleton from './HomePageSkeleton';
import useAuth from '../../hooks/useAuth';

const HomePage = () => {
  const { user, loading: authLoading } = useAuth();
  const isAuthenticated = !!user;

  const [feedData, setFeedData] = useState({
    featured_albums: [],
    new_releases: [],
    social_mixtapes: [],
    trending_mixtapes: [],
    suggested_profiles: [],
  });
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  
  const mounted = useRef(true);
  const loadingRef = useRef(false);
  const lastPageRef = useRef(1);

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0.1,
    rootMargin: '200px',
    delay: 100
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const loadFeedData = useCallback(async (pageNum) => {
    if (!mounted.current || loadingRef.current || pageNum < lastPageRef.current || !isAuthenticated) {
      return;
    }

    loadingRef.current = true;
    lastPageRef.current = pageNum;

    if (pageNum === 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    try {
      const url = new URL(`${APIConfig.baseURL()}${APIConfig.endpoints.feed.homepage}`);
      url.searchParams.append('page', pageNum);
      
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (!data.results || data.results.length === 0) {
        setHasMore(false);
        return;
      }

      const items = data.results || [];
      setFeedData((prev) => {
        const mergeArrays = (existing, incoming) => {
          if (!incoming?.length) return existing || [];
          const seen = new Set(existing?.map((item) => item.id) || []);
          return [
            ...(existing || []),
            ...incoming.filter((item) => !seen.has(item.id)),
          ];
        };

        return {
          featured_albums: mergeArrays(prev.featured_albums, items.filter(item => item.type === 'ALBUM' && item.featured)),
          new_releases: mergeArrays(prev.new_releases, items.filter(item => item.type === 'ALBUM' && !item.featured)),
          social_mixtapes: mergeArrays(prev.social_mixtapes, items.filter(item => item.type === 'MIXTAPE' && !item.trending)),
          trending_mixtapes: mergeArrays(prev.trending_mixtapes, items.filter(item => item.type === 'MIXTAPE' && item.trending)),
          suggested_profiles: mergeArrays(prev.suggested_profiles, items.filter(item => item.type === 'PROFILE')),
        };
      });

      setHasMore(data.has_more);
      if (data.has_more) {
        setPage((prev) => prev + 1);
      }
    } catch (err) {
      console.error('Feed loading error:', err);
      if (mounted.current) {
        setError(err.message);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
        setLoadingMore(false);
        loadingRef.current = false;
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      loadFeedData(1);
    }
  }, [loadFeedData, isAuthenticated]);

  useEffect(() => {
    if (inView && !loading && !loadingMore && hasMore && isAuthenticated) {
      loadFeedData(page);
    }
  }, [inView, loading, loadingMore, hasMore, page, loadFeedData, isAuthenticated]);

  if (!isAuthenticated) {
    return <PublicHomePage />;
  }

  if (loading || authLoading) {
    return <HomePageSkeleton />;
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center text-red-600 dark:text-red-400">
          Error loading feed: {error.message}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="min-h-screen space-y-8">
        {/* Show loading skeleton for initial load */}
        {loading && page === 1 ? (
          <>
            <FeedSection
              title="Recent Activity"
              items={[]}
              type="MIXTAPE"
              loading={true}
            />
            <FeedSection
              title="Trending Mixtapes"
              items={[]}
              type="MIXTAPE"
              loading={true}
            />
            <FeedSection
              title="Featured Albums"
              items={[]}
              type="ALBUM"
              loading={true}
            />
          </>
        ) : (
          <>
            {/* Social Mixtapes */}
            {feedData.social_mixtapes?.length > 0 && (
              <FeedSection
                title="Recent Activity"
                items={feedData.social_mixtapes}
                type="MIXTAPE"
                loading={false}
              />
            )}

            {/* Trending Mixtapes */}
            {feedData.trending_mixtapes?.length > 0 && (
              <FeedSection
                title="Trending Mixtapes"
                items={feedData.trending_mixtapes}
                type="MIXTAPE"
                loading={false}
              />
            )}

            {/* Featured Albums */}
            {feedData.featured_albums?.length > 0 && (
              <FeedSection
                title="Featured Albums"
                items={feedData.featured_albums}
                type="ALBUM"
                loading={false}
              />
            )}

            {/* New Releases */}
            {feedData.new_releases?.length > 0 && (
              <FeedSection
                title="New Releases"
                items={feedData.new_releases}
                type="ALBUM"
                loading={false}
              />
            )}

            {/* Suggested Profiles */}
            {feedData.suggested_profiles?.length > 0 && (
              <FeedSection
                title="Suggested Profiles"
                items={feedData.suggested_profiles}
                type="PROFILE"
                loading={false}
              />
            )}
          </>
        )}

        {/* Infinite scroll trigger */}
        <div ref={loadMoreRef} className="h-20 flex items-center justify-center">
          {loadingMore && <LoadingSpinner />}
        </div>

        {/* End of feed message */}
        {!hasMore && !loading && !loadingMore && (
          <div className="text-center text-gray-500 py-4">
            You're all caught up! ✨
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;

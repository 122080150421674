import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ScrollingText.module.css';

/**
 * A component that truncates text to a single line,
 * but animates a scrolling marquee if the text is longer than the container.
 */
export default function ScrollingText({ text, className }) {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [overflowed, setOverflowed] = useState(false);

  useEffect(() => {
    if (containerRef.current && textRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const textWidth = textRef.current.scrollWidth;
      setOverflowed(textWidth > containerWidth);
    }
  }, [text]);

  return (
    <div
      ref={containerRef}
      className={`overflow-hidden whitespace-nowrap ${className}`}
      style={{ maxWidth: '100%' }}
    >
      <div
        ref={textRef}
        className={`${overflowed ? styles.scroller : 'truncate'}`}
      >
        {text}
      </div>
    </div>
  );
}

ScrollingText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ScrollingText.defaultProps = {
  className: '',
};

import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import OrderService from '../services/orderService';
import { MapPinIcon } from '@heroicons/react/24/outline';

const STATUS_OPTIONS = [
  { value: 'pending', label: 'Pending' },
  { value: 'processing', label: 'Processing' },
  { value: 'shipped', label: 'Shipped' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'cancelled', label: 'Cancelled' },
];

function getOrderBadge(status) {
  switch (status) {
    case 'completed':
    case 'delivered':
      return { text: 'Paid', bg: 'bg-green-100 dark:bg-green-900', textColor: 'text-green-800 dark:text-green-200' };
    case 'processing':
      return { text: 'Processing', bg: 'bg-blue-100 dark:bg-blue-900', textColor: 'text-blue-800 dark:text-blue-200' };
    case 'shipped':
      return { text: 'Shipped', bg: 'bg-blue-50 dark:bg-blue-900', textColor: 'text-blue-800 dark:text-blue-200' };
    case 'cancelled':
      return { text: 'Cancelled', bg: 'bg-red-100 dark:bg-red-900', textColor: 'text-red-800 dark:text-red-200' };
    default:
      return { text: 'Pending', bg: 'bg-gray-100 dark:bg-gray-700', textColor: 'text-gray-800 dark:text-gray-200' };
  }
}

const SellerOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();

  const [updatingItemId, setUpdatingItemId] = useState(null);
  const [itemStatus, setItemStatus] = useState({});
  const [trackingNumber, setTrackingNumber] = useState({});
  const [carrier, setCarrier] = useState({});
  const [updateError, setUpdateError] = useState({});
  const [successMessage, setSuccessMessage] = useState({});

  useEffect(() => {
    const fetchOrders = async () => {
      if (authLoading) return;
      if (!user) {
        navigate('/login', { state: { from: '/seller/orders' } });
        return;
      }

      try {
        const token = await user.getIdToken();
        const ordersData = await OrderService.getSellerOrders(token);

        const initTracking = {};
        const initCarriers = {};
        (ordersData || []).forEach(order => {
          order.items?.forEach(item => {
            if (item.tracking_number) initTracking[item.id] = item.tracking_number;
            if (item.carrier) initCarriers[item.id] = item.carrier;
          });
        });

        setTrackingNumber(initTracking);
        setCarrier(initCarriers);
        setOrders(Array.isArray(ordersData) ? ordersData : []);
      } catch (err) {
        console.error('Error fetching seller orders:', err);
        setError('Failed to fetch orders. Please try again later.');
        if (err.response?.status === 403) {
          navigate('/orders');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user, authLoading, navigate]);

  const currentOrDefaultStatus = (item) => itemStatus[item.id] || item.fulfillment_status;

  const handleStatusChange = (itemId, status) => {
    setItemStatus(prev => ({ ...prev, [itemId]: status }));
    if (status !== 'shipped') {
      setTrackingNumber(prev => ({ ...prev, [itemId]: '' }));
      setCarrier(prev => ({ ...prev, [itemId]: '' }));
    }
    setUpdateError(prev => ({ ...prev, [itemId]: null }));
    setSuccessMessage(prev => ({ ...prev, [itemId]: null }));
  };

  const handleUpdateItem = async (orderItem) => {
    const itemId = orderItem.id;
    setUpdatingItemId(itemId);
    setUpdateError(prev => ({ ...prev, [itemId]: null }));
    setSuccessMessage(prev => ({ ...prev, [itemId]: null }));

    try {
      const status = currentOrDefaultStatus(orderItem);
      const updateData = { status };

      if (status === 'shipped') {
        if (!trackingNumber[itemId] || !carrier[itemId]) {
          throw new Error('Please provide tracking number and carrier.');
        }
        updateData.tracking_number = trackingNumber[itemId];
        updateData.carrier = carrier[itemId];
      }

      const token = await user.getIdToken();
      const updatedItem = await OrderService.updateOrderItemStatus(token, itemId, updateData);

      setOrders(prev => prev.map(order => ({
        ...order,
        items: order.items?.map(i => i.id === itemId ? { ...i, ...updatedItem } : i)
      })));

      setSuccessMessage(prev => ({ ...prev, [itemId]: 'Status updated successfully!' }));

      if (status !== 'shipped') {
        setTrackingNumber(prev => ({ ...prev, [itemId]: '' }));
        setCarrier(prev => ({ ...prev, [itemId]: '' }));
      }
    } catch (err) {
      console.error('Error updating item:', err);
      setUpdateError(prev => ({ ...prev, [itemId]: err.message || 'Failed to update status' }));
    } finally {
      setUpdatingItemId(null);
    }
  };

  if (authLoading || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 dark:bg-red-900 p-4 rounded-lg">
          <p className="text-red-800 dark:text-red-200">{error}</p>
        </div>
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h2 className="text-2xl font-bold mb-4 dark:text-gray-100">No Orders Yet</h2>
        <p className="text-gray-600 dark:text-gray-400">You haven’t received any orders yet.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 dark:text-gray-100">Seller Orders</h1>
      
      <div className="space-y-8">
        {orders.map(order => {
          const total = (Number(order.total_amount) || 0).toFixed(2);
          const badge = getOrderBadge(order.status);

          return (
            <div key={order.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
              {/* Order Header */}
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h3 className="text-lg font-semibold dark:text-gray-100">Order #{order.id}</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Placed on {format(new Date(order.created_at), 'MMM d, yyyy')}
                  </p>
                </div>
                <span className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${badge.bg} ${badge.textColor}`}>
                  {badge.text}
                </span>
              </div>

              {/* Items */}
              <div className="space-y-6 border-b border-gray-200 dark:border-gray-700 pb-6">
                {order.items?.map(item => {
                  const currentStatus = currentOrDefaultStatus(item);
                  const isUpdating = updatingItemId === item.id;
                  const itemTotal = ((Number(item.price_at_time) || 0) * (item.quantity || 1) / 100).toFixed(2);

                  return (
                    <div key={item.id} className="flex flex-col md:flex-row md:items-start md:justify-between py-4 border-b border-gray-100 dark:border-gray-700 last:border-0">
                      <div className="flex items-start space-x-4 flex-1">
                        {item.merch_item_image && (
                          <img
                            src={item.merch_item_image}
                            alt={item.merch_item_name}
                            className="w-16 h-16 object-cover rounded"
                          />
                        )}
                        <div>
                          <h4 className="font-medium dark:text-gray-100">{item.merch_item_name}</h4>
                          {item.variant_name && (
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              {item.variant_name}
                            </p>
                          )}
                          <p className="text-sm text-gray-600 dark:text-gray-400">Qty: {item.quantity}</p>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            Current: {item.fulfillment_status_display || item.fulfillment_status}
                          </p>
                          {item.tracking_number && item.carrier && (
                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                              Tracking: {item.tracking_number} ({item.carrier})
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col md:items-end md:justify-center space-y-2 mt-4 md:mt-0 w-full md:w-auto md:ml-4">
                        <div className="text-sm text-gray-600 dark:text-gray-400 text-right">
                          ${itemTotal}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Fulfillment Status
                          </label>
                          <select
                            value={currentStatus}
                            onChange={(e) => handleStatusChange(item.id, e.target.value)}
                            disabled={isUpdating}
                            className="block w-full border border-gray-300 dark:border-gray-600 rounded px-3 py-1.5 bg-white dark:bg-gray-700 text-sm focus:ring-blue-500 focus:border-blue-500"
                          >
                            {STATUS_OPTIONS.map(opt => (
                              <option key={opt.value} value={opt.value}>{opt.label}</option>
                            ))}
                          </select>
                        </div>

                        {currentStatus === 'shipped' && (
                          <div className="space-y-2">
                            <input
                              type="text"
                              placeholder="Tracking Number"
                              value={trackingNumber[item.id] || item.tracking_number || ''}
                              onChange={e => setTrackingNumber(prev => ({ ...prev, [item.id]: e.target.value }))}
                              className="block w-full border border-gray-300 dark:border-gray-600 rounded px-3 py-1.5 bg-white dark:bg-gray-700 text-sm"
                              disabled={isUpdating}
                            />
                            <input
                              type="text"
                              placeholder="Carrier"
                              value={carrier[item.id] || item.carrier || ''}
                              onChange={e => setCarrier(prev => ({ ...prev, [item.id]: e.target.value }))}
                              className="block w-full border border-gray-300 dark:border-gray-600 rounded px-3 py-1.5 bg-white dark:bg-gray-700 text-sm"
                              disabled={isUpdating}
                            />
                          </div>
                        )}

                        <button
                          onClick={() => handleUpdateItem(item)}
                          disabled={isUpdating}
                          className="inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          {isUpdating && (
                            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                          )}
                          Update
                        </button>

                        {updateError[item.id] && (
                          <p className="text-sm text-red-600 dark:text-red-400 mt-1">
                            {updateError[item.id]}
                          </p>
                        )}
                        {successMessage[item.id] && (
                          <p className="text-sm text-green-600 dark:text-green-400 mt-1">
                            {successMessage[item.id]}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* Totals & Shipping Address */}
              <div className="pt-6 space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium dark:text-gray-100">Total:</span>
                  <span className="text-lg font-semibold dark:text-gray-100">${total}</span>
                </div>

                {order.shipping_address && (
                  <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
                    <div className="flex items-start space-x-2">
                      <MapPinIcon className="h-5 w-5 text-gray-400" />
                      <div>
                        <h4 className="font-medium dark:text-gray-100">Shipping Address</h4>
                        <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                          <p>{order.shipping_address.recipient_name}</p>
                          <p>{order.shipping_address.address_line1}</p>
                          {order.shipping_address.address_line2 && <p>{order.shipping_address.address_line2}</p>}
                          <p>
                            {[order.shipping_address.city, order.shipping_address.state, order.shipping_address.postal_code]
                              .filter(Boolean)
                              .join(', ')}
                          </p>
                          {order.shipping_address.country && (
                            <p>{order.shipping_address.country}</p>
                          )}
                        </div>
                        {order.shipping_method && (
                          <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                            Method: {order.shipping_method}{order.formatted_shipping && ` (${order.formatted_shipping})`}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SellerOrders;
import React, { useState, useMemo } from 'react';

/**
 * GenreFilter - Brutalist style genre selection with hierarchical organization
 * and a scrollable subgenre panel.
 *
 * The "genre" format is something like "MAIN---SUB" (e.g. "ELECTRONIC---GLITCH").
 * We'll parse them into a mainGenre -> subGenres array structure.
 *
 * Props:
 *   - genres: string[]  (array of "MAIN---SUB" or just "MAIN")
 *   - selectedGenre: string|null  (the currently selected "MAIN---SUB", or null)
 *   - onGenreSelect(genre: string|null): void
 */
export default function GenreFilter({ genres, selectedGenre, onGenreSelect }) {
  const [expandedGenre, setExpandedGenre] = useState(null);

  // Organize "MAIN---SUB" into { MAIN: [SUB, SUB2, ...], ... }
  const organizedGenres = useMemo(() => {
    if (!genres?.length) return {};
    
    return genres.reduce((acc, genre) => {
      const [mainGenre, subGenre] = genre.split('---');
      if (!acc[mainGenre]) {
        acc[mainGenre] = [];
      }
      if (subGenre) {
        acc[mainGenre].push(subGenre);
      }
      return acc;
    }, {});
  }, [genres]);

  // If the user has no genres loaded yet, show a quick skeleton or nothing
  if (!genres) {
    return (
      <div className="mb-6 font-theFutureMonoRegular">
        <h2 className="text-lg font-bold border-b-2 border-black dark:border-white inline-block">
          Loading Genres...
        </h2>
      </div>
    );
  }

  // If genres is loaded but empty, show a "No Genres" message
  if (!genres.length) {
    return (
      <div className="mb-6 font-theFutureMonoRegular">
        <h2 className="text-lg font-bold border-b-2 border-black dark:border-white inline-block">
          No Genres Available
        </h2>
      </div>
    );
  }

  const toggleGenre = (mainGenre) => {
    setExpandedGenre(expandedGenre === mainGenre ? null : mainGenre);
  };

  const isSelected = (genre) => selectedGenre === genre;

  return (
    <div className="mb-6 font-theFutureMonoRegular">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <h2 className="text-lg font-bold border-b-2 border-black dark:border-white inline-block">
            Filter by Genre
          </h2>
          {expandedGenre && (
            <button
              onClick={() => setExpandedGenre(null)}
              className="
                px-2 py-1
                border-2 border-black dark:border-white
                text-sm underline
                bg-gray-100 dark:bg-neutral-900
                hover:bg-black hover:text-white
                dark:hover:bg-white dark:hover:text-black
                transition-colors
              "
            >
              [ Back to All Genres ]
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-2">
        {/* "ALL" option */}
        <button
          onClick={() => {
            onGenreSelect(null);
            setExpandedGenre(null);
          }}
          className={`
            px-4 py-2 border-2 transition-colors
            ${!selectedGenre 
              ? 'border-white bg-white text-black dark:border-black dark:bg-black dark:text-white'
              : 'border-black dark:border-white hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black'
            }
          `}
        >
          ALL
        </button>

        {/* Show either all genres or just the expanded one */}
        {Object.entries(organizedGenres)
          .filter(([mainGenre]) => !expandedGenre || mainGenre === expandedGenre)
          .map(([mainGenre, subGenres]) => {
            const hasSubgenres = subGenres.length > 0;
            const isExpanded = expandedGenre === mainGenre;

            return (
              <div key={mainGenre} className="border-2 border-black dark:border-white">
                <button
                  onClick={() => {
                    if (hasSubgenres) {
                      toggleGenre(mainGenre);
                    } else {
                      onGenreSelect(mainGenre);
                    }
                  }}
                  className="
                    w-full
                    px-4 py-2
                    text-left
                    flex justify-between items-center
                    hover:bg-black hover:text-white
                    dark:hover:bg-white dark:hover:text-black
                    transition-colors
                  "
                >
                  <span>{mainGenre}</span>
                  {hasSubgenres && <span>{isExpanded ? '[-]' : '[+]'}</span>}
                </button>

                {/* Subgenre list if expanded */}
                {hasSubgenres && isExpanded && (
                  <div 
                    className="
                      pl-4 border-t-2 border-black dark:border-white
                      max-h-48 overflow-y-auto
                    "
                  >
                    {subGenres.map(subGenre => {
                      const fullGenre = `${mainGenre}---${subGenre}`;
                      return (
                        <button
                          key={fullGenre}
                          onClick={() => onGenreSelect(fullGenre)}
                          className={`
                            w-full
                            px-4 py-2
                            text-left
                            block
                            hover:bg-black hover:text-white 
                            dark:hover:bg-white dark:hover:text-black
                            transition-colors
                            ${isSelected(fullGenre)
                              ? 'bg-black text-white dark:bg-white dark:text-black'
                              : ''
                            }
                          `}
                        >
                          {subGenre}
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

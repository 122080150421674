import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import api from "../../../api";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";

const AddArtistComponent = ({ onArtistAdded }) => {
  const [artistName, setArtistName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [user] = useAuthState(getAuth());
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const clearError = () => {
    setError("");
  };

  const handleAddArtist = async () => {
    clearError();
    if (!artistName.trim()) {
      setError("Please enter an artist name");
      return;
    }

    setIsLoading(true);
    try {
      const newArtist = await api.newArtist(artistName, user?.uid);
      setIsLoading(false);
      setArtistName("");
      setIsOpen(false);
      setShowSuccess(true);
      
      if (onArtistAdded) {
        onArtistAdded(newArtist);
      }
      
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error adding new artist:", error);
      setIsLoading(false);
      setShowSuccess(false);
      setError(error.message);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {isOpen ? (
          <>
            <MinusIcon className="h-5 w-5 mr-2" />
            Cancel
          </>
        ) : (
          <>
            <PlusIcon className="h-5 w-5 mr-2" />
            Add Artist
          </>
        )}
      </button>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-4">
          <div className="space-y-4">
            <div>
              <label htmlFor="artistName" className="block text-sm font-medium text-gray-700">
                Artist Name
              </label>
              <input
                type="text"
                name="artistName"
                id="artistName"
                value={artistName}
                onChange={(e) => {
                  setArtistName(e.target.value);
                  clearError();
                }}
                className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                  error ? 'border-red-500' : ''
                }`}
                placeholder="Enter artist name"
                disabled={isLoading}
              />
              {error && (
                <p className="mt-2 text-sm text-red-600">
                  {error}
                </p>
              )}
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleAddArtist}
                disabled={isLoading || !artistName.trim()}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                  ${isLoading || !artistName.trim() 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  }`}
              >
                {isLoading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Adding...
                  </>
                ) : (
                  'Add Artist'
                )}
              </button>
            </div>
          </div>
        </div>
      </Transition>

      {/* Success notification */}
      <Transition
        show={showSuccess}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <div className="fixed bottom-0 right-0 mb-4 mr-4">
          <div className="bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg">
            Artist added successfully!
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default AddArtistComponent;

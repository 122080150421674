import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const variants = {
  primary: 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500',
  secondary: 'text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 border border-gray-300 dark:border-gray-600 focus:ring-blue-500 dark:focus:ring-blue-400',
};

const Button = ({
  variant,
  className,
  disabled,
  children,
  ...props
}) => {
  const buttonClasses = classNames(
    'px-4 py-2.5 rounded-lg',
    'text-sm font-medium',
    'focus:outline-none focus:ring-2 focus:ring-offset-2',
    'disabled:opacity-50 disabled:cursor-not-allowed',
    'transition-colors',
    variants[variant],
    className
  );

  return (
    <button
      className={buttonClasses}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  variant: 'primary',
  className: '',
  disabled: false,
};

export default Button;

import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import { Dialog, Transition } from '@headlessui/react';
import APIConfig from '../../APIConfig';

export default function AddToMixtapeModal({ isOpen, onClose, track, onAddToMixtape }) {
  const [user] = useAuthState(firebase.auth());
  const [mixtapes, setMixtapes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [newMixtapeName, setNewMixtapeName] = useState('');
  const [showNewMixtapeForm, setShowNewMixtapeForm] = useState(false);

  const [loadingStates, setLoadingStates] = useState({
    creating: false,        // for "new mixtape" creation
    adding: new Set(),      // for track->mixtape additions
  });

  // -----------------------------
  // FETCH USER'S MIXTAPES
  // -----------------------------
  useEffect(() => {
    const fetchMixtapes = async () => {
      if (!user) return;
      try {
        const response = await fetch(
          `${APIConfig.serverURI}mixtapes/get_profile_mixtapes/`,
          {
            headers: {
              Authorization: `JWT ${await user.getIdToken()}`,
            },
          }
        );
        const { mixtapes: userMixtapes } = await response.json();
        setMixtapes(userMixtapes || []);
      } catch (error) {
        console.error('Error fetching mixtapes:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      setLoading(true);
      fetchMixtapes();
    }
  }, [user, isOpen]);

  // -----------------------------
  // HOTKEYS: ESC & ENTER
  // -----------------------------
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        if (showNewMixtapeForm) {
          setShowNewMixtapeForm(false);
          setNewMixtapeName('');
        } else {
          onClose();
        }
      } else if (e.key === 'Enter' && showNewMixtapeForm && newMixtapeName.trim()) {
        handleCreateMixtape();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, showNewMixtapeForm, newMixtapeName, onClose]);

  // -----------------------------
  // STATE HELPERS
  // -----------------------------
  const setActionLoading = (action, id, isLoading) => {
    setLoadingStates((prev) => {
      const next = { ...prev };
      if (action === 'creating') {
        next.creating = isLoading;
      } else if (action === 'adding') {
        const s = new Set(prev.adding);
        if (isLoading) s.add(id);
        else s.delete(id);
        next.adding = s;
      }
      return next;
    });
  };

  // -----------------------------
  // CREATE A NEW MIXTAPE
  // -----------------------------
  const handleCreateMixtape = async () => {
    if (!newMixtapeName.trim() || loadingStates.creating) return;
    setActionLoading('creating', null, true);

    try {
      const response = await fetch(`${APIConfig.serverURI}mixtapes/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${await user.getIdToken()}`,
        },
        body: JSON.stringify({
          name: newMixtapeName,
          is_published: false,
        }),
      });
      const newMixtape = await response.json();
      setMixtapes((prev) => [...prev, newMixtape]);

      // reset form
      setNewMixtapeName('');
      setShowNewMixtapeForm(false);

      // Add the track to newly created mixtape
      handleAddToExistingMixtape(newMixtape.id);
    } catch (error) {
      console.error('Error creating mixtape:', error);
    } finally {
      setActionLoading('creating', null, false);
    }
  };

  // -----------------------------
  // ADD TRACK TO AN EXISTING MIXTAPE
  // -----------------------------
  const handleAddToExistingMixtape = async (mixtapeId) => {
    if (loadingStates.adding.has(mixtapeId)) return; // already adding

    setActionLoading('adding', mixtapeId, true);
    try {
      await onAddToMixtape(mixtapeId, track, onClose);
    } catch (error) {
      console.error('Error adding track to mixtape:', error);
    } finally {
      setActionLoading('adding', mixtapeId, false);
    }
  };

  // -----------------------------
  // PREVENT CLOSING IF STILL LOADING
  // -----------------------------
  const handleClose = () => {
    const addingInProgress = loadingStates.adding.size > 0;
    if (!loadingStates.creating && !addingInProgress) {
      onClose();
    }
  };

  // -----------------------------
  // RENDER
  // -----------------------------
  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog onClose={handleClose} className="relative z-50 font-mono">
        {/* Backdrop */}
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        {/* Modal Panel */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform border border-black bg-white p-6"
                style={{ boxShadow: '8px 8px 0px 0px #000' }}
              >
                {/* Header */}
                <div className="flex items-center justify-between border-b border-black pb-4 mb-4">
                  <Dialog.Title className="text-lg font-bold uppercase">
                    Add to Mixtape
                  </Dialog.Title>
                  <button
                    onClick={handleClose}
                    disabled={loadingStates.creating || loadingStates.adding.size > 0}
                    className="text-sm hover:bg-gray-100 px-2 py-1 border border-black"
                  >
                    (Esc)
                  </button>
                </div>

                {/* Content */}
                {loading ? (
                  <div className="py-8 flex flex-col items-center space-y-4">
                    <div>Loading mixtapes...</div>
                    <div className="flex space-x-1">
                      <div className="w-2 h-2 bg-black rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                      <div className="w-2 h-2 bg-black rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                      <div className="w-2 h-2 bg-black rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Show the track being added */}
                    <div className="mb-4">
                      <div className="bg-gray-100 border border-black p-3">
                        <span className="text-xs uppercase text-gray-600 block mb-1">
                          Currently Adding Track
                        </span>
                        <span className="font-bold break-words">{track?.name || 'Untitled Track'}</span>
                      </div>
                    </div>

                    {/* Existing Mixtapes */}
                    <div>
                      <div className="text-sm uppercase mb-2">Your Mixtapes</div>
                      <div className="max-h-48 overflow-y-auto space-y-2 mb-4 border border-black p-2">
                        {mixtapes.map((mixtape) => {
                          const isAdding = loadingStates.adding.has(mixtape.id);
                          return (
                            <button
                              key={mixtape.id}
                              onClick={() => handleAddToExistingMixtape(mixtape.id)}
                              disabled={isAdding}
                              className="w-full flex items-center justify-between border border-black p-1 hover:bg-gray-50 transition-colors disabled:opacity-50 relative group"
                            >
                              <span className="truncate pr-2">{mixtape.name}</span>
                              <div className="flex items-center space-x-2">
                                {isAdding && (
                                  <div className="w-2 h-2 bg-black rounded-full animate-bounce" />
                                )}
                                <span className="text-xs">
                                  {isAdding ? 'Adding...' : 'Add'}
                                </span>
                              </div>
                              {isAdding && (
                                <div className="absolute inset-0 bg-white bg-opacity-40" />
                              )}
                            </button>
                          );
                        })}
                      </div>
                    </div>

                    {/* CREATE NEW */}
                    <div className="border-t border-black pt-4">
                      {showNewMixtapeForm ? (
                        <div className="border border-black p-3">
                          <label className="block text-xs uppercase mb-1">New Mixtape Name</label>
                          <div className="flex items-center space-x-2 mb-2">
                            <input
                              type="text"
                              value={newMixtapeName}
                              onChange={(e) => setNewMixtapeName(e.target.value)}
                              className="flex-1 border border-black px-2 py-1"
                              disabled={loadingStates.creating}
                              autoFocus
                            />
                            {loadingStates.creating && (
                              <div className="w-2 h-2 bg-black rounded-full animate-bounce" />
                            )}
                          </div>
                          <div className="flex justify-end space-x-2 text-sm">
                            <button
                              onClick={() => {
                                setShowNewMixtapeForm(false);
                                setNewMixtapeName('');
                              }}
                              className="border border-black px-3 py-1 hover:bg-gray-100 transition disabled:opacity-50"
                              disabled={loadingStates.creating}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleCreateMixtape}
                              disabled={!newMixtapeName.trim() || loadingStates.creating}
                              className="border border-black px-3 py-1 hover:bg-gray-100 transition disabled:opacity-50"
                            >
                              {loadingStates.creating ? 'Creating...' : 'Create'}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <button
                          onClick={() => setShowNewMixtapeForm(true)}
                          className="w-full border border-black p-2 text-sm hover:bg-gray-50 transition-colors"
                        >
                          + New Mixtape
                        </button>
                      )}
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

import React, { useState, useEffect } from "react";
import { useSetAlbumArtist } from "../hooks/useSetAlbumArtist";
import useProfileInfo from "../../hooks/useProfileInfo";
import useAlbumInfo from "../hooks/useAlbumInfo";
import LightSpinner from "../../../../loading/LightSpinner";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const SetAlbumArtistView = ({ album }) => {
  const { artists, loading: artistsLoading, error: artistsError } = useProfileInfo();
  const { albumInfo, artistId, loading: albumInfoLoading } = useAlbumInfo(album);
  const { setAlbumArtist, loading: settingArtistLoading, error: settingArtistError } = useSetAlbumArtist();
  const [selectedArtistId, setSelectedArtistId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Initialize selection with current artist ID
  useEffect(() => {
    if (artistId) {
      setSelectedArtistId(artistId);
    }
  }, [artistId]);

  // Handle artist selection change
  const handleArtistChange = async (newArtistId) => {
    try {
      setSuccessMessage("");
      
      // Only update if the artist has actually changed
      if (newArtistId !== artistId) {
        console.log(`Updating artist from ${artistId} to ${newArtistId}`);
        await setAlbumArtist(album?.docId, newArtistId);
        setSelectedArtistId(newArtistId);
        setSuccessMessage("Artist updated successfully");
      }
    } catch (error) {
      console.error("Error setting artist:", error);
    }
  };

  if (artistsLoading || albumInfoLoading) {
    return (
      <div className="flex justify-center py-4">
        <LightSpinner />
      </div>
    );
  }

  if (artistsError || settingArtistError) {
    return (
      <div className="text-red-400 p-4">
        Error: {artistsError || settingArtistError}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        {/* <p className="text-xs text-gray-400 font-theFutureMonoRegular">
          *Leave artist blank for compilations
        </p> */}
      </div>

      <div className="relative">
        <select
          className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-gray-200 focus:ring-blue-500 focus:border-blue-500 focus:outline-none appearance-none cursor-pointer transition-colors duration-200"
          value={selectedArtistId}
          onChange={(e) => handleArtistChange(e.target.value)}
          disabled={artistsLoading || settingArtistLoading || !album?.docId}
        >
          <option value="" className="bg-gray-700">No Artist (Compilation)</option>
          {artists?.map((artist) => (
            <option key={artist.id} value={artist.id} className="bg-gray-700">
              {artist.name}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
          </svg>
        </div>
      </div>

      {settingArtistLoading && (
        <div className="flex items-center space-x-2 text-blue-400 text-sm">
          <LightSpinner />
          <span>Setting artist...</span>
        </div>
      )}

      {successMessage && (
        <div className="flex items-center space-x-2 text-green-400 text-sm">
          <CheckCircleIcon className="h-5 w-5" />
          <span>{successMessage}</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(SetAlbumArtistView);